import assignmentService from '../../services/assignment.service';
import React, { useEffect, useState } from 'react';
import { useProfileContext } from '../../contexts/profileContext';
import { useHistory } from 'react-router-dom';
import useHash from '../../hooks/useHash';
import courseService from '../../services/course.service';
import lessonService from '../../services/lesson.service';

const ReviewMyAssignmentsBanner = ({ extraClasses }) => {
  const { profileInfo } = useProfileContext();
  const history = useHistory();
  const [userAssignments, setUserAssignments] = useState([]);
  const { hash } = useHash();
  const defaultPagination = { page: 1, limit: 1000 };
  const [pagination, setPagination] = useState(defaultPagination);
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [, setLoading] = useState(false);

  const fetchUserAssignments = async () => {
    const self = true;
    const filter = {
      order: [['dueAt', 'desc']],
    };
    const response = await assignmentService.getAssignments({
      page: pagination.page,
      limit: pagination.limit,
      self,
      status: 'active',
      retrieveAssigned: true,
      ...filter,
    });

    setUserAssignments(response?.data);
    setPagination(response?.pagination);

    // Get Courses and Lessos for aLL Assignments
    await getCoursesLessons(response);
  };

  const getCoursesLessons = async (response) => {
    setLoading(true);

    try {
      const resultData = response?.data.map(async (assignment) => {
        return Promise.all(
          assignment.contents.map(async (item) => {
            if (item.courseId) {
              try {
                const progress = await courseService.getCourseProgress(
                  item.courseId,
                  { self: true }
                );

                if (progress?.progress !== 100) {
                  try {
                    const course = await courseService.getCourseById(
                      item.courseId,
                      { favorites: 'include' }
                    );

                    if (course !== undefined) {
                      course.dueAt = assignment.dueAt;
                      course.assignmentId = assignment.id;
                      setCourses((courses) => [...courses, course]);
                    }
                  } catch (error) {
                    console.error('Error fetching course details:', error);
                  }
                }
              } catch (error) {
                console.error('Error fetching course progress:', error);
              }
            } else if (item.lessonId) {
              try {
                const progress = await lessonService.getLessonProgress(
                  item.lessonId,
                  { self: true }
                );

                if (progress?.progress !== 100) {
                  try {
                    const lesson = await lessonService.getLessonById(
                      item.lessonId,
                      { favorites: 'include' }
                    );

                    if (lesson !== undefined) {
                      lesson.dueAt = assignment.dueAt;
                      lesson.assignmentId = assignment.id;
                      setLessons((lessons) => [...lessons, lesson]);
                    }
                  } catch (error) {
                    console.error('Error fetching lesson details:', error);
                  }
                }
              } catch (error) {
                console.error('Error fetching lesson progress:', error);
              }
            }
          })
        );
      });

      await Promise.all(resultData);
      // Sort courses and lessons by dueAt in descending order
      setCourses((courses) =>
        courses.sort((a, b) => new Date(b.dueAt) - new Date(a.dueAt))
      );
      setLessons((lessons) =>
        lessons.sort((a, b) => new Date(b.dueAt) - new Date(a.dueAt))
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      fetchUserAssignments();
    }
    if (hash) {
      setUserAssignments([]);
    }
  }, [profileInfo]);

  return (
    <>
      {(courses.length !== 0 || lessons.length !== 0) &&
      userAssignments.length > 0 ? (
        <div
          onClick={() => {
            history.push(`/learn?viewType=my-assignments`);
          }}
          className="ml-10 d-flex p-1 align-items-center rounded-pill border"
        >
          <a className="py-1 px-2 bg-primary-soft fw-bold text-center fs-7 d-flex align-items-center justify-content-center bg-hover-gray cursor-pointer rounded-pill">
            You have Assignments Due
          </a>
        </div>
      ) : null}
    </>
  );
};

export default ReviewMyAssignmentsBanner;

import React, { useState, useEffect } from 'react';
import userService from '../../services/user.service';
import { isModuleAllowed } from '../../utils/Utils';
import { useTenantContext } from '../../contexts/TenantContext';
import RightPanelModal from '../modal/RightPanelModal';

import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import AddOrganization from '../organizations/AddOrganization';
import AddNewNoteModal from '../peopleProfile/contentFeed/AddNewNoteModal';
import AddDeal from '../peopleProfile/deals/AddDeal';
import AddPeople from '../peoples/AddPeople';
import AddNewActivityModal from '../steps/feedTypes/AddNewActivityModal';
import { useNavBarShortKey } from '../../hooks/useNavBarShortKey';
import { useNewPermissionContext } from '../../contexts/newPermissionContext';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import pipelineServices from '../../services/pipeline.services';
import Loading from '../Loading';
import useIsTenant from '../../hooks/useIsTenant';
import MaterialIcon from '../commons/MaterialIcon';
import useCreateChecklist from '../checklist/useCreateChecklist';
import { PermissionsConstants } from '../../utils/permissions.constants';

const DropdownChildren = ({ item, className, moduleMap }) => {
  return (
    <div
      className={`w-100 d-flex align-items-center justify-content-between text-block px-2 ${className}`}
    >
      <div className="d-inline-flex align-items-center">
        <i className="material-symbols-rounded list-group-icon mr-2">
          {item.icon}
        </i>
        <span className="font-weight-medium">
          {item.title === 'Pipeline'
            ? moduleMap.deal.singular
            : item.title === 'Company'
            ? moduleMap.organization.singular
            : item.title === 'Contact'
            ? moduleMap.contact.singular
            : item.title === 'Task'
            ? moduleMap.task.singular
            : item.title === 'Call'
            ? moduleMap.call.singular
            : item.title === 'Event'
            ? moduleMap.event.singular
            : item.title}
        </span>
      </div>
    </div>
  );
};

const NavbarFilters = (data) => {
  const [openDeal, setOpenDeal] = useState(false);
  const context = useNewPermissionContext();
  const [openOrganization, setOpenOrganization] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [openPeople, setOpenPeople] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openList, setOpenList] = useState(false);
  const [pipelineId, setPipelineId] = useState('');
  const [me, setMe] = useState(null);
  const [optionList, setOptionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { tenant } = useTenantContext();
  const isSynovus = useIsTenant().isSynovusBank;
  const { setShowModal: setShowCreateChecklist, ChecklistCreateModal } =
    useCreateChecklist(() => {}, { setErrorMessage, setSuccessMessage });
  const moduleMap = data.moduleMap;
  const options = [
    {
      title: isSynovus ? 'Insights' : 'Company',
      icon: 'business',
      tenantModule: 'companies',
      shortcut: 'O',
      permission: { collection: 'organizations', action: 'create' },
      callbackFunction: setOpenOrganization,
      component: <></>,
      id: 'add_company',
    },
    {
      title: 'Contact',
      icon: 'person',
      tenantModule: 'contacts',
      shortcut: 'P',
      permission: { collection: 'contacts', action: 'create' },
      callbackFunction: setOpenPeople,
      component: <></>,
      id: 'add_contact',
    },
    {
      title: 'Pipeline',
      tenantModule: 'pipelines',
      permission: { collection: 'deals', action: 'create' },
      icon: 'monetization_on',
      shortcut: 'D',
      callbackFunction: setOpenDeal,
      component: <></>,
      childern: optionList,
      id: 'add_opportunity',
    },
    {
      title: 'Task',
      icon: 'task',
      tenantModule: 'activities',
      shortcut: 'T',
      btnType: 'task',
      permission: { collection: 'activities', action: 'create' },
      callbackFunction: setOpenTask,
      component: <></>,
    },
    {
      title: 'Event',
      icon: 'event',
      shortcut: 'E',
      tenantModule: 'activities',
      btnType: 'event',
      permission: { collection: 'activities', action: 'create' },
      callbackFunction: setOpenEvent,
      component: <></>,
    },
    {
      title: 'Call',
      icon: 'phone',
      tenantModule: 'activities',
      shortcut: 'C',
      btnType: 'call',
      permission: { collection: 'activities', action: 'create' },
      callbackFunction: setOpenActivity,
      component: <></>,
    },
    {
      title: 'Checklist',
      icon: 'checklist',
      byModule: PermissionsConstants.Checklist.checklist,
      shortcut: 'CH',
      btnType: 'call',
      callbackFunction: () => setShowCreateChecklist(true),
      component: <></>,
    },
    {
      title: 'Note',
      icon: 'text_snippet',
      tenantModule: 'crm',
      shortcut: 'N',
      permission: { collection: 'notes', action: 'create' },
      callbackFunction: setOpenNote,
      component: <></>,
    },
  ];
  const moduleNoteCheck = (modules, moduleName) => {
    const isCRMModule = moduleName === 'crm';

    if (isCRMModule) {
      const notesModule = modules?.includes('pipelines_notes')
        ? 'pipelines_notes'
        : '' || modules?.includes('contacts_notes')
        ? 'contacts_notes'
        : '' || modules?.includes('companies_notes')
        ? 'companies_notes'
        : '';
      return isModuleAllowed(modules, notesModule);
    } else {
      return isModuleAllowed(modules, moduleName);
    }
  };
  const isPermissionAllowed = (collection, action, tenantName = '') => {
    try {
      const { permissionChanges } = context;
      const permissionsCheck = permissionChanges.filter((item) => {
        return item.collection === collection;
      });
      const permissionAction = permissionsCheck?.find((child) => {
        return child.action === action;
      });
      if (tenantName) {
        return (
          permissionAction.collection === collection &&
          permissionAction.action === action &&
          moduleNoteCheck(tenant.modules, tenantName)
        );
      } else if (permissionAction) {
        return (
          permissionAction.collection === collection &&
          permissionAction.action === action
        );
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  options.forEach((opt) => {
    useNavBarShortKey(['shift', opt.shortcut.toLowerCase()], () =>
      opt.callbackFunction(true)
    );
  });
  useEffect(() => {
    const getCurrentUser = async () => {
      const self = await userService
        .getUserInfo()
        .catch((err) => console.error(err));

      setMe(self);
    };

    getCurrentUser();
  }, []);
  useEffect(() => {
    const listData = options.filter((el) => {
      if (el?.byModule) {
        // for checklist check by module
        return isModuleAllowed(tenant?.modules, el.byModule);
      }
      return isPermissionAllowed(
        el?.permission?.collection,
        el?.permission?.action,
        el?.tenantModule
      );
    });
    setOptionList(listData);
  }, [context?.permissionChanges]);

  const renderIcon = () => {
    return <MaterialIcon icon={openList ? 'close' : 'add'} clazz="fs-5" />;
  };
  const handleGetPipelineId = (id) => {
    setOpenDeal(true);
    setPipelineId(id);
  };
  const handleClick = () => {
    const hasCollectionDealsPermission = isPermissionAllowed('deals', 'create');
    if (hasCollectionDealsPermission) {
      getPipelines();
    }

    setOpenList(true);
  };
  const getPipelines = async () => {
    setIsLoading(true);
    try {
      const { data } = await pipelineServices.getPipelines();
      const pipelineArray = data?.map((item, i) => {
        const { name, ...rest } = item;
        return {
          title: name,
          permission: { collection: 'deals', action: 'create' },
          shortcut: i,
          tenantModule: 'pipelines',
          icon: 'monetization_on',
          callbackFunction: () => handleGetPipelineId(item),
          ...rest,
        };
      });
      const nevOptions = optionList?.map((item) => ({
        ...item,
        ...(item.title === 'Pipeline' && { children: pipelineArray }),
      }));
      setOptionList(nevOptions);
    } catch (error) {
      setErrorMessage('Error fetching pipelines:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const loader = () => {
    if (isLoading) return <Loading />;
  };

  return (
    <>
      {openDeal && (
        <AddDeal
          className="btn-transparent"
          setOpenDeal={setOpenDeal}
          openDeal={openDeal}
          setOpenList={setOpenList}
          errorMessage={errorMessage}
          pipeline={pipelineId}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          fromNavbar={true}
        />
      )}
      {openPeople && (
        <AddPeople
          openPeople={openPeople}
          setOpenPeople={setOpenPeople}
          setOpenList={setOpenList}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          fromNavbar={true}
        />
      )}

      {openOrganization && (
        <AddOrganization
          moduleMap={moduleMap.organization.singular}
          openOrganization={openOrganization}
          setOpenOrganization={setOpenOrganization}
          setOpenList={setOpenList}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          fromNavbar={true}
          me={me}
        />
      )}

      {openActivity && (
        <AddNewActivityModal
          call={moduleMap.call.singular}
          openActivity={openActivity}
          setOpenActivity={setOpenActivity}
          setOpenList={setOpenList}
          btnType={'call'}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          fromNavbar={true}
          shortcut="shift+c"
        />
      )}
      {openTask && (
        <AddNewActivityModal
          task={moduleMap.task.singular}
          openActivity={openTask}
          setOpenActivity={setOpenTask}
          setOpenList={setOpenList}
          btnType={'task'}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          fromNavbar={true}
          shortcut="shift+t"
        />
      )}
      {openEvent && (
        <AddNewActivityModal
          event={moduleMap.event.singular}
          openActivity={openEvent}
          setOpenActivity={setOpenEvent}
          setOpenList={setOpenList}
          btnType={'event'}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          fromNavbar={true}
          shortcut="shift+e"
        />
      )}
      {openNote && (
        <AddNewNoteModal
          openNote={openNote}
          setOpenNote={setOpenNote}
          setOpenList={setOpenList}
          fromNavbar={true}
        />
      )}
      <ChecklistCreateModal
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
      {optionList?.length > 0 && (
        <div>
          <Button
            id="add_record"
            className="btn btn-icon btn-sm2 dropdown-hide-arrow"
            variant="primary"
            onClick={() => handleClick()}
          >
            {renderIcon()}
          </Button>
        </div>
      )}
      <RightPanelModal
        showModal={openList}
        setShowModal={() => setOpenList(false)}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={'480px'}
        containerPosition={'position-fixed'}
        headerBgColor="bg-gray-5"
        Title={
          <div className="d-flex py-2 align-items-center text-capitalize">
            <h3 className="mb-0">Create New Record</h3>
          </div>
        }
      >
        {!isLoading ? (
          <ListGroup className="px-1">
            {optionList?.map((item) => {
              const { component, ...restProps } = item;
              return (
                <>
                  <ListGroupItem
                    key={item.shortcut}
                    id={item.id}
                    className={`cr-p border-left-0 border-right-0 ${
                      item.title === 'Pipeline' ? 'px-0 pb-0' : ''
                    }`}
                    onClick={() => {
                      setOpenList(false);
                      item.callbackFunction(true);
                    }}
                  >
                    <>
                      {React.cloneElement(
                        component,
                        {
                          errorMessage,
                          setErrorMessage,
                          successMessage,
                          setSuccessMessage,
                          fromNavbar: true,
                          ...restProps,
                        },
                        <>
                          {' '}
                          {moduleMap.deal && (
                            <DropdownChildren
                              moduleMap={moduleMap}
                              item={item}
                              className={`${
                                item.title === 'Pipeline'
                                  ? 'pl-4 ml-1 pb-2'
                                  : ''
                              }`}
                            />
                          )}
                        </>
                      )}
                      <>
                        {item?.children?.length > 0 &&
                          item?.children?.map((child, i) => {
                            return (
                              <ListGroupItem
                                key={child?.shortcut}
                                className={`cr-p border-left-0 border-right-0 pl-5 ${
                                  i === item?.children?.length - 1
                                    ? 'border-bottom-0'
                                    : ''
                                }`}
                                onClick={() => {
                                  setOpenList(false);
                                  child.callbackFunction(true);
                                }}
                              >
                                <DropdownChildren
                                  moduleMap={moduleMap.deal.singular}
                                  item={child}
                                />
                              </ListGroupItem>
                            );
                          })}
                      </>
                    </>
                  </ListGroupItem>
                </>
              );
            })}
          </ListGroup>
        ) : (
          loader()
        )}
      </RightPanelModal>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
    </>
  );
};

export default NavbarFilters;

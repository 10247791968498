import React from 'react';
import { Skeleton } from '@mui/material';
import TableSkeleton from '../../../components/commons/TableSkeleton';

const Tab = () => {
  return (
    <Skeleton
      variant="rectangular"
      className="rounded-lg"
      width={'80%'}
      height={20}
    />
  );
};

const StatsItem = () => {
  return (
    <div className="w-100 border-2 border rounded px-3 pt-4 pb-5">
      <div className="d-flex align-items-start justify-content-between">
        <Skeleton variant="text" width={100} height={20} />
        <Skeleton variant="circular" width={40} height={40} />
      </div>

      <div className="w-100 d-flex align-items-center justify-content-between gap-3 pt-3">
        <div className="w-100">
          <Skeleton variant="text" width={'50%'} height={20} />
          <Skeleton variant="text" width={'75%'} height={20} />
        </div>
        <div className="w-100">
          <Skeleton variant="text" width={'50%'} height={20} />
          <Skeleton variant="text" width={'75%'} height={20} />
        </div>
        <div className="w-100">
          <Skeleton variant="text" width={'50%'} height={20} />
          <Skeleton variant="text" width={'75%'} height={20} />
        </div>
      </div>
    </div>
  );
};

const ReportItem = () => {
  return (
    <div className="w-100 d-flex flex-column gap-2 align-items-center">
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="text" width={100} height={20} />
    </div>
  );
};

export default function HomeLoadingSkelton() {
  return (
    <div className="w-100 h-100">
      <div className="d-flex align-items-center border-bottom justify-content-between">
        <div className="d-flex align-items-center gap-2 mb-3">
          <Skeleton variant="circular" width={60} height={60} />
          <div>
            <Skeleton variant="text" width={120} height={20} />
            <Skeleton variant="text" width={230} height={20} />
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex flex-column align-items-end">
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width={180} height={20} />
          </div>
        </div>
      </div>
      <div className="row mt-0" style={{ height: 'calc(100vh - 170px)' }}>
        <div className="col-md-4 d-flex flex-column gap-3 pr-2 pt-3">
          <div className="w-100 d-flex align-items-center gap-2">
            <Skeleton
              variant="text"
              className="rounded"
              width={'25%'}
              height={30}
            />
            <Skeleton
              variant="text"
              className="rounded"
              width={'25%'}
              height={30}
            />
          </div>
          <div className="d-flex flex-column gap-3">
            <StatsItem />
            <StatsItem />
            <StatsItem />
          </div>
        </div>

        <div className="col-md-8 pl-2 border-left h-100">
          <div className="w-100 pt-3">
            <div className="w-90 d-flex justify-content-center mx-auto gap-2">
              <div className="d-flex align-items-start justify-content-between gap-4">
                <ReportItem />
                <ReportItem />
                <ReportItem />
                <ReportItem />
                <ReportItem />
              </div>
            </div>
            <div className="w-50 d-flex justify-content-center mx-auto gap-2 pt-3 pb-2 my-3">
              <Tab />
              <Tab />
              <Tab />
              <Tab />
            </div>
          </div>
          <TableSkeleton cols={5} rows={10} />
        </div>
      </div>
    </div>
  );
}

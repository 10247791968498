import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import AutoComplete from '../../AutoComplete';
import userService from '../../../services/user.service';
import NewKindOfSearchDropdown from '../../commons/NewKindOfSearchDropdown';

export const ActivityOwnerSelect = ({
  field,
  newKindOfSearch,
  ownerData,
  setOwnerData,
  clearState,
  ownerError,
  setOwnerError,
}) => {
  const { key } = field;

  const [ownerSearch, setOwnerSearch] = useState({
    search: '',
  });
  const [users, setUsers] = useState([]);
  const [ownerCharactersRequire, setOwnerCharactersRequire] = useState('');

  const getUsers = async (search) => {
    const { data } = await userService.getUsers(
      { status: 'active', search },
      { page: 1, limit: 100 }
    );
    setUsers(data?.users);
  };

  const stateChange = (e) => {
    const match = e.target.value.match(/([A-Za-z])/g);
    if (match && (newKindOfSearch || match.length >= 2)) {
      setOwnerCharactersRequire('');
      setOwnerSearch({
        ...ownerSearch,
        search: e.target.value,
      });
      getUsers(e.target.value);
    } else {
      setUsers([]);
      return setOwnerCharactersRequire(match?.length);
    }
  };

  useEffect(() => {
    getUsers();
  }, [ownerSearch, ownerData]);

  return (
    <>
      <FormGroup
        row
        className={`align-items-center mt-3 mr-1 ${
          newKindOfSearch && 'new-kind-of-owner-select'
        }`}
      >
        <Label md={4} className="text-right font-size-sm col-form-label">
          {key === 'Task Owner' ? 'Owner' : key}
        </Label>
        <Col md={8} className="pl-0">
          {newKindOfSearch ? (
            <NewKindOfSearchDropdown
              readOnly={true}
              pillButton="text-black bg-gray-5 fs-7 font-weight-medium py-1"
              preData={users}
              placeholder={`Select ${key === 'Task Owner' ? 'Owner' : key}`}
              errorClass="owner-btn py-1 pl-2 pr-4 min-w-185px"
              optionsClass="owner-options"
              showAvatarButton
              onItemSelect={(item) => {
                setOwnerData([item]);
                setOwnerError(false);
              }}
              currentSelected={ownerData?.length > 0 ? ownerData[0] : {}}
              onInputSearch={stateChange}
            />
          ) : (
            <AutoComplete
              id="isUserId"
              title={`Select ${key === 'Task Owner' ? 'Owner' : key}`}
              name="isUserId"
              placeholder={`Select ${key === 'Task Owner' ? 'Owner' : key}`}
              showAvatar={false}
              customKey="name"
              customTitle={''}
              charactersRequire={ownerCharactersRequire}
              onChange={(e) => stateChange(e)}
              clearState={(e) => clearState(e)}
              search={ownerSearch?.search}
              data={users}
              onHandleSelect={(item) => {
                setOwnerData([item]);
                setOwnerError(false);
              }}
              selected={ownerData?.length > 0 ? ownerData[0]?.name : ''}
            />
          )}

          {ownerError && (
            <p className="text-danger mt-2 text-sm text-left">
              Please Select Owner
            </p>
          )}
        </Col>
      </FormGroup>
    </>
  );
};

import React, { useState, useEffect } from 'react';
import {
  DATE_FORMAT,
  DATE_FORMAT_EJS,
  DATE_FORMAT_EJS_UPDATED,
  formatHHMM,
  formatHHMMSS,
  getPattern,
  getPatternErrorMessage,
  isPermissionAllowed,
  isToFixedNoRound,
  setDateFormat,
} from '../../../utils/Utils';
import contactService from '../../../services/contact.service';
import stringConstants from '../../../utils/stringConstants.json';
import { renderComponent, VIEW_CARD } from '../../peoples/constantsPeople';
import AutoComplete from '../../AutoComplete';
import {
  SEARCH_FOR_COMPANY,
  SEARCH_FOR_INSIGHT,
} from '../../../utils/constants';
import organizationService from '../../../services/organization.service';
import {
  onInputSearch,
  removeCustomFieldsFromActivityForm,
} from '../../../views/Deals/contacts/utils';
import IdfSelectLabel from '../../idfComponents/idfDropdown/IdfSelectLabel';
import { Col, Form, FormGroup, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import ControllerValidation from '../../commons/ControllerValidation';
import IdfFormInput from '../../idfComponents/idfFormInput/IdfFormInput';
import { CheckboxInput } from '../../layouts/CardLayout';
import { PricingField } from '../../PricingFieldComponent';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import ReactDatepicker from '../../inputs/ReactDatpicker';
import moment from 'moment';
import useIsTenant from '../../../hooks/useIsTenant';
import DropdownSelect from '../../DropdownSelect';
import AddPicklistOptions from '../contentFeed/AddPicklistOptions';
import { usePagesContext } from '../../../contexts/pagesContext';
import MaterialIcon from '../../commons/MaterialIcon';
import LastModifiedOn from '../../commons/LastModifiedOn';
import LabelComponent from '../../commons/LabelComponent';
import { ProspectTypes } from '../../prospecting/v2/constants';
import AutoAwesomeImport from '../../commons/AutoAwesomeImport';

const labelCache = {};
const InlineOverviewForm = ({
  overviewData,
  editMode,
  setEditMode,
  getProfileInfo,
  isFieldsData,
  fieldData,
  moduleMap,
  labelType,
  setSuccessMessage,
  setErrorMessage,
  setProfileInfo,
  ...props
}) => {
  const constants = stringConstants.deals.contacts.profile;
  const { pageContext, setPageContext } = usePagesContext();
  const [formValue, setFormValue] = useState({
    ...overviewData,
    label_id: overviewData?.label?.id,
  });
  const [loading, setLoading] = useState(false);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [searchOrg, setSearchOrg] = useState({
    search: '',
  });
  const [customFieldData, setCustomFieldData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: formValue,
  });
  useEffect(() => {
    if (Object.keys(overviewData)) {
      setFormValue({
        ...overviewData,
        label_id: overviewData?.label?.id,
      });
    }
  }, [overviewData]);
  const [loadingOrg, setLoadingOrg] = useState(false);
  async function onGetOrganzations() {
    setLoadingOrg(true);
    const response = await organizationService
      .getOrganizations(searchOrg, { limit: 10 })
      .catch((err) => err);

    setLoadingOrg(false);

    if (response?.data) {
      const { organizations } = response?.data;
      setAllOrganizations(organizations.filter((o) => !!o.name));
    }
  }
  useEffect(() => {
    onGetOrganzations();
  }, [searchOrg]);
  useEffect(() => {
    setFormValue({
      ...overviewData,
      ...formValue,
      label_id: overviewData?.label?.id,
    });
  }, [overviewData, isFieldsData]);

  const onHandleSubmit = async () => {
    setLoading(true);
    const updateFields = removeCustomFieldsFromActivityForm(
      formValue,
      customFieldData
    );
    delete updateFields.fields;
    try {
      const promises = [
        contactService.updateContact(overviewData.id, {
          ...updateFields,
        }),
      ];
      await Promise.all(
        promises,
        customFieldData?.map(async (item) => {
          if (item.value === '$' || item.value === '') {
            await contactService.deleteCustomField(
              overviewData.id,
              item.field_id
            );
          } else {
            await new Promise((resolve) => {
              contactService
                .updateCustomField(overviewData?.id, item)
                .then(resolve);
            });
          }
        })
      );
      setEditMode(VIEW_CARD);
      getProfileInfo(
        constants.contactUpdated.replace(/Contact/g, moduleMap.contact.singular)
      );
      reset(formValue);
    } catch (error) {
      setErrorMessage(constants.errorContactUpdated).replace(
        /contact/g,
        moduleMap.contact.singular
      );
    } finally {
      setLoading(false);
      // clearing out context so when user open share popup it will load updated
      setPageContext({ ...pageContext, CompanyContacts: null });
    }
  };
  const handleChange = (e) => {
    const target = e.target;
    const overViewFormData = {
      ...formValue,
      [target.name]: target.value,
    };
    setFormValue(overViewFormData);
  };
  const handleOrganizationSelected = (item) => {
    const selectOrg = {
      ...formValue,
      organization_id: item?.id,
    };
    setFormValue(selectOrg);
  };
  const handleOrganizationCreating = (item) => {
    const selectOrg = {
      ...formValue,
      contact_organization_new: item?.organization_id,
    };
    setFormValue(selectOrg);
  };
  useEffect(() => {
    const groups = Object.keys(isFieldsData);
    if (groups.length) {
      for (const grp of groups) {
        const fields = isFieldsData[grp];
        fields.forEach((field) => {
          const { columnName, key } = field;
          const fieldName = columnName
            ? columnName.toLowerCase()
            : key?.toLowerCase().replace(/\s+/g, '');
          setValue(fieldName, formValue[fieldName]);
        });
      }
    }
  }, [isFieldsData]);
  const clearState = (name) => {
    if (name === 'organization_id') {
      setValue('organization_id', '');
      const data = {
        ...formValue,
        organization: {},
        organization_id: '',
      };
      setFormValue(data);
    }
  };
  const onHandleCustomDate = (date, id, fieldName) => {
    if (date === '') {
      setCustomFieldData([...customFieldData, { field_id: id, value: '' }]);
    } else {
      setCustomFieldData([
        ...customFieldData,
        { field_id: id, value: new Date(date) },
      ]);
    }
    const contactData = {
      ...formValue,
      [fieldName]: new Date(date),
    };
    setValue(fieldName, new Date(date));
    setFormValue(contactData);
  };
  const onHandleCustomField = (e, id, value_type, field_type) => {
    const target = e.target;
    let value = '';
    if (field_type === 'CURRENCY') {
      value = parseInt(target.value);
    }
    if (value_type === 'string' && target.value !== '') {
      value = target.value;
    }
    if (value_type === 'number' && target.value !== '') {
      value = parseInt(target.value);
    }
    if (field_type === 'CURRENCY') {
      value = `$${target.value}`;
    }
    if (field_type === 'TIME') {
      value = moment(value).format(formatHHMMSS);
    }
    let updated = false;
    const contactData = {
      ...formValue,
      [target.name]: target.value,
    };
    setValue(target?.name, target?.value);
    setFormValue(contactData);
    const fieldData = customFieldData.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return {
          field_id: id,
          value,
        };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomFieldData(fieldData);
    } else {
      setCustomFieldData([...fieldData, { field_id: id, value }]);
    }
  };

  const onHandleCustomCheckBox = (e, id) => {
    const target = e.target;
    setCustomFieldData([
      ...customFieldData,
      { field_id: id, value: target.checked },
    ]);
    const contactData = {
      ...formValue,
      [target.name]: target.checked,
    };
    setValue(target?.name, target?.value);
    setFormValue(contactData);
  };

  const onHandlePicklistSingle = (item, id, fieldName) => {
    let picked;
    if (item.name === '-None-') {
      picked = '';
    } else {
      picked = [{ value: item.name }];
    }
    let updated = false;
    const fieldData = customFieldData.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomFieldData(fieldData);
    } else {
      setCustomFieldData([
        ...customFieldData,
        { field_id: id, value: picked, key: fieldName },
      ]);
    }
    setFormValue({
      ...formValue,
      [fieldName]: picked,
    });
    setValue(fieldName, picked);
  };

  const selectPicklistValue = (fieldName, value_option, id) => {
    if (formValue[fieldName] === '') {
      return '-None-';
    }
    if (
      formValue[fieldName] &&
      formValue[fieldName][0] &&
      typeof formValue[fieldName][0].value === 'string'
    ) {
      return formValue[fieldName][0].value;
    }

    return '-None-';
  };

  const onHandlePicklistMulti = (val, id, fieldName) => {
    let picked;
    if (val.length === 0) {
      picked = '';
    } else {
      picked = val;
    }
    let updated = false;
    const fieldData = customFieldData.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomFieldData(fieldData);
    } else {
      const tempCustom = [
        ...customFieldData,
        { field_id: id, value: picked, key: fieldName },
      ];
      setCustomFieldData(tempCustom);
    }
    setFormValue({
      ...formValue,
      [fieldName]: picked,
    });
    setValue(fieldName, picked);
  };
  const selectPicklistMultiValue = (fieldName, value_option, id) => {
    if (formValue[fieldName] === '') {
      return [];
    }
    if (formValue[fieldName]) {
      return formValue[fieldName];
    }

    return [];
  };
  const handlePhoneChange = (e) => {
    const target = e.target;

    // Use a regular expression to filter out non-numeric characters
    const numericValue = target.value.replace(/[^0-9]/g, '');

    const overViewFormData = {
      ...formValue,
      [target.name]: numericValue,
    };

    setFormValue(overViewFormData);
  };

  const updateValues = (payload) => {
    setProfileInfo((prev) => ({ ...prev, ...payload }));
    setFormValue((prev) => ({ ...prev, ...payload }));
    // getProfileInfo();
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <div className="card-body bg-light pt-0 px-3">
          {Object.keys(isFieldsData).map((fieldType, index) => {
            return (
              <div key={`fields-${index}`}>
                <h5
                  className={`pb-0 d-flex align-items-center px-2 ${
                    index !== 0 && 'mt-2'
                  }`}
                >
                  {fieldType.replace(/Contact/g, moduleMap.contact.singular)}
                  {index === 0 && (
                    <AutoAwesomeImport
                      data={overviewData}
                      type={ProspectTypes.people}
                      refresh={updateValues}
                    />
                  )}
                </h5>
                {isFieldsData[fieldType]?.length > 0 &&
                  isFieldsData[fieldType]
                    ?.slice(0, isFieldsData?.length)
                    .map((item) => {
                      const {
                        field_type,
                        columnName,
                        key,
                        mandatory,
                        isCustom,
                        id,
                        value_type,
                        value_option,
                      } = item;
                      const fieldName =
                        columnName || key?.toLowerCase().replace(/\s+/g, '');
                      return (
                        <>
                          {key !== 'Company' &&
                          key !== 'Label' &&
                          field_type !== 'PHONE' &&
                          field_type !== 'CHECKBOX' &&
                          field_type !== 'CURRENCY' &&
                          field_type !== 'DATE' &&
                          field_type !== 'TIME' &&
                          field_type !== 'PICKLIST' &&
                          field_type !== 'PICKLIST_MULTI' ? (
                            <div key={item?.id}>
                              <EditableRow
                                field={item}
                                editMode={editMode}
                                loading={loading}
                                setEditMode={setEditMode}
                                formValue={formValue}
                              >
                                {renderComponent(field_type, {
                                  value: formValue,
                                  name: fieldName,
                                  className: 'text-capitalize',
                                  containerClasses: 'flex-column',
                                  inputClass: mandatory
                                    ? 'border-left-4 border-left-danger'
                                    : '',
                                  validationConfig: {
                                    required: mandatory,
                                    inline: false,
                                    onChange: (e, date) =>
                                      isCustom
                                        ? field_type === 'DATE'
                                          ? onHandleCustomDate(
                                              date,
                                              id,
                                              fieldName
                                            )
                                          : onHandleCustomField(
                                              e,
                                              id,
                                              value_type,
                                              field_type
                                            )
                                        : handleChange(e),
                                    pattern: getPattern(field_type),
                                    validateSpaces:
                                      field_type !== 'NUMBER'
                                        ? mandatory
                                        : false,
                                  },
                                  errorMessage:
                                    getPatternErrorMessage(field_type),

                                  errors,
                                  register,
                                  errorDisplay: 'mb-0',
                                  fieldType:
                                    field_type.toLowerCase() === 'url'
                                      ? 'text'
                                      : field_type.toLowerCase(),
                                  type:
                                    field_type === 'TEXT'
                                      ? 'textarea'
                                      : 'input',
                                  key: columnName,
                                  placeholder: key,
                                })}
                              </EditableRow>
                            </div>
                          ) : (
                            ''
                          )}
                          {field_type === 'DATE' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                            >
                              <FormGroup
                                row
                                className="py-1 align-items-center"
                              >
                                <Col md={9} className="pl-0">
                                  <div className="date-picker input-time w-100">
                                    <ControllerValidation
                                      name={fieldName}
                                      errors={errors}
                                      form={formValue}
                                      errorDisplay="mb-0"
                                      control={control}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key} is required.`
                                          : '',
                                      }}
                                      renderer={({ field }) => (
                                        <ReactDatepicker
                                          id={fieldName}
                                          name={fieldName}
                                          format={DATE_FORMAT_EJS}
                                          minDate={new Date()}
                                          autoComplete="off"
                                          todayButton="Today"
                                          validationConfig={{
                                            required: mandatory
                                              ? `${key} is required.`
                                              : '',
                                          }}
                                          fieldState={getFieldState(fieldName)}
                                          value={formValue[fieldName]}
                                          className="form-control"
                                          placeholder={DATE_FORMAT_EJS_UPDATED}
                                          onChange={(date) => {
                                            if (isCustom) {
                                              onHandleCustomDate(
                                                date,
                                                id,
                                                fieldName
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                </Col>
                              </FormGroup>
                            </EditableRow>
                          )}
                          {field_type === 'TIME' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                            >
                              <FormGroup
                                row
                                className="py-1 align-items-center"
                              >
                                <Col md={9} className="pl-0">
                                  <div className="date-picker input-time w-100">
                                    <TimePickerComponent
                                      id={`start-time`}
                                      cssClass="e-custom-style"
                                      openOnFocus={true}
                                      value={
                                        formValue?.start_time || '12:00 PM'
                                      }
                                      format="hh:mm a"
                                      placeholder="Start Time"
                                      onChange={(date) =>
                                        onHandleCustomDate(
                                          date,
                                          id,
                                          fieldName,
                                          field_type
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                              </FormGroup>
                            </EditableRow>
                          )}
                          {key === 'Company' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                            >
                              <FormGroup row className="py-1">
                                <Col md={9} className="pl-0">
                                  <ControllerValidation
                                    name={fieldName}
                                    validationConfig={{
                                      required: mandatory
                                        ? `${key} is required.`
                                        : '',
                                    }}
                                    errors={errors}
                                    form={formValue}
                                    errorDisplay="mb-0"
                                    control={control}
                                    renderer={({ field }) => (
                                      <AutoComplete
                                        id="organization_id"
                                        placeholder={
                                          useIsTenant().isSynovusBank
                                            ? SEARCH_FOR_INSIGHT
                                            : SEARCH_FOR_COMPANY
                                        }
                                        name="organization_id"
                                        loading={loadingOrg}
                                        type="comapny"
                                        clearState={(name) => clearState(name)}
                                        onChange={(e) =>
                                          onInputSearch(
                                            e,
                                            searchOrg,
                                            setSearchOrg
                                          )
                                        }
                                        data={allOrganizations}
                                        validationConfig={{
                                          required: mandatory
                                            ? `${key} is required.`
                                            : '',
                                        }}
                                        fieldState={getFieldState(fieldName)}
                                        selected={formValue?.organization?.name}
                                        onHandleSelect={(item) =>
                                          handleOrganizationSelected(item)
                                        }
                                        customKey="name"
                                        extraTitles={[
                                          'address_city',
                                          'address_state',
                                        ]}
                                        search={searchOrg.search}
                                        createItem={(data) =>
                                          handleOrganizationCreating(data)
                                        }
                                      />
                                    )}
                                  />
                                </Col>
                              </FormGroup>
                            </EditableRow>
                          )}
                          {key === 'Label' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                              labelType={labelType}
                            >
                              <FormGroup row className="py-1">
                                <Col md={9} className="pl-0">
                                  <ControllerValidation
                                    name={fieldName}
                                    validationConfig={{
                                      required: mandatory
                                        ? `${key} is required.`
                                        : '',
                                    }}
                                    errors={errors}
                                    form={formValue}
                                    errorDisplay="mb-0"
                                    control={control}
                                    renderer={({ field }) => (
                                      <IdfSelectLabel
                                        fromNavBar
                                        value={formValue?.label_id}
                                        onChange={handleChange}
                                        name="label_id"
                                        type={labelType}
                                        validationConfig={{
                                          required: mandatory
                                            ? `${key} is required.`
                                            : '',
                                        }}
                                        fieldState={getFieldState(fieldName)}
                                        placeholder={field?.key}
                                        {...props}
                                        required={field?.mandatory}
                                      />
                                    )}
                                  />
                                </Col>
                              </FormGroup>
                            </EditableRow>
                          )}
                          {field_type === 'PICKLIST' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                            >
                              <FormGroup row className="py-1">
                                <Col md={9} className="pl-0">
                                  <div className="date-picker input-time w-100">
                                    <ControllerValidation
                                      name={fieldName}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key} is required.`
                                          : '',
                                      }}
                                      errors={errors}
                                      form={formValue}
                                      errorDisplay="mb-0"
                                      control={control}
                                      renderer={({ field }) => (
                                        <DropdownSelect
                                          data={value_option.map((item, i) => {
                                            return {
                                              id: i,
                                              name: item.value,
                                            };
                                          })}
                                          onHandleSelect={(item) => {
                                            onHandlePicklistSingle(
                                              item,
                                              id,
                                              fieldName
                                            );
                                          }}
                                          select={selectPicklistValue(
                                            fieldName,
                                            value_option,
                                            id
                                          )}
                                          placeholder="Select Option"
                                          customClasses={
                                            'w-100 overflow-y-auto max-h-300'
                                          }
                                          validationConfig={{
                                            required: mandatory,
                                          }}
                                          fieldState={getFieldState(fieldName)}
                                        />
                                      )}
                                    />
                                  </div>
                                </Col>
                              </FormGroup>
                            </EditableRow>
                          )}
                          {field_type === 'PICKLIST_MULTI' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                            >
                              <FormGroup row className="py-1">
                                <Col md={9} className="pl-0">
                                  <ControllerValidation
                                    name={fieldName}
                                    validationConfig={{
                                      required: mandatory
                                        ? `${key} is required.`
                                        : '',
                                    }}
                                    errors={errors}
                                    form={formValue}
                                    errorDisplay="mb-0"
                                    control={control}
                                    renderer={({ field }) => (
                                      <AddPicklistOptions
                                        dropdownList={value_option.filter(
                                          (item) => item.value !== '-None-'
                                        )}
                                        validationConfig={{
                                          required: mandatory,
                                        }}
                                        fieldState={getFieldState(fieldName)}
                                        placeholder={'Add Options'}
                                        value={selectPicklistMultiValue(
                                          fieldName,
                                          value_option,
                                          id,
                                          fieldName
                                        )}
                                        setValue={(e) =>
                                          onHandlePicklistMulti(
                                            e,
                                            id,
                                            fieldName
                                          )
                                        }
                                        tooltip={constants.tooltipTagInput}
                                        labelSize="full"
                                        onChange={(e) => {}}
                                      />
                                    )}
                                  />
                                </Col>
                              </FormGroup>
                            </EditableRow>
                          )}
                          {field_type === 'PHONE' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                            >
                              <FormGroup row className="py-1">
                                <Col md={9} className="pl-0">
                                  <IdfFormInput
                                    className="mb-0 w-100"
                                    placeholder={key}
                                    value={formValue}
                                    name={columnName}
                                    maxLength={14}
                                    onChange={handlePhoneChange}
                                    autocomplete="off"
                                  />
                                </Col>
                              </FormGroup>
                            </EditableRow>
                          )}
                          {field_type === 'CURRENCY' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                            >
                              <PricingField
                                name={fieldName}
                                placeholder={key}
                                errors={errors}
                                register={register}
                                onChange={(e) =>
                                  isCustom
                                    ? onHandleCustomField(
                                        e,
                                        id,
                                        value_type,
                                        field_type
                                      )
                                    : handleChange(e)
                                }
                                validationConfig={{
                                  required: mandatory,
                                  inline: false,
                                }}
                                value={formValue}
                              />
                            </EditableRow>
                          )}
                          {field_type === 'CHECKBOX' && (
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={formValue}
                            >
                              <FormGroup row className="py-1">
                                <Col md={9} className="pl-0">
                                  <CheckboxInput
                                    id={fieldName}
                                    onChange={(e) => {
                                      if (isCustom) {
                                        onHandleCustomCheckBox(e, id);
                                      }
                                    }}
                                    label={key}
                                    name={fieldName}
                                    checked={formValue[fieldName]}
                                  />
                                </Col>
                              </FormGroup>
                            </EditableRow>
                          )}
                        </>
                      );
                    })}
              </div>
            );
          })}

          <div className="pt-4">
            <LastModifiedOn data={overviewData} />
          </div>
        </div>
      </Form>
    </>
  );
};

export default InlineOverviewForm;

const EditableRow = ({
  field,
  editMode,
  loading,
  setEditMode,
  formValue,
  children,
  labelType,
}) => {
  const { columnName, key, field_type } = field;
  const fieldName = columnName || key?.toLowerCase().replace(/\s+/g, '');

  const isAnyOtherEditModeActive = (currentKey) => {
    return Object.keys(editMode).some((k) => k !== currentKey && editMode[k]);
  };

  const editableClass = !isAnyOtherEditModeActive(key) ? 'editable-field' : '';
  const noHoverBgClass = editMode[key] && 'no-hover';
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  return (
    <div className={`pipeline-overview-row ${noHoverBgClass} ${editableClass}`}>
      <p>{key}</p>
      {editMode[key] ? (
        <div className={`relative ${fieldName}`}>
          {children}
          {editMode[key] && (
            <div
              className="text-right position-absolute"
              style={{ top: 4, right: '-16px' }}
            >
              <div className="d-flex align-items-center bg-white px-1 z-index-99 py-1">
                {!loading ? (
                  <>
                    <a className="cursor-pointer icon-hover-bg">
                      <button type="submit">
                        <MaterialIcon
                          icon="check_circle"
                          clazz="text-green fs-4 text-success"
                          filled
                        />
                      </button>
                    </a>
                    <a
                      className="cursor-pointer icon-hover-bg"
                      onClick={() => setEditMode({ ...editMode, [key]: false })}
                    >
                      <MaterialIcon
                        icon="cancel"
                        clazz="text-gray-400 fs-4"
                        filled
                      />{' '}
                    </a>
                  </>
                ) : (
                  <Spinner className="spinner-grow-xs" />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <p>
            {field_type !== 'DATE' &&
              field_type !== 'PICKLIST' &&
              field_type !== 'PICKLIST_MULTI' &&
              field_type !== 'CHECKBOX' &&
              field_type !== 'TIME' &&
              key !== 'Company' &&
              key !== 'Label' &&
              (formValue[fieldName]
                ? columnName === 'amount'
                  ? isToFixedNoRound(formValue[fieldName], 2)
                  : formValue[fieldName]
                : '--')}

            {key === 'Company' && <span>{formValue?.organization?.name}</span>}

            {field_type === 'DATE' && (
              <span
                className={
                  key === 'Closing Date' &&
                  (formValue[fieldName] &&
                  new Date(formValue[fieldName]) >= currentDate
                    ? ''
                    : 'text-red fw-bold')
                }
              >
                {setDateFormat(formValue[fieldName], DATE_FORMAT)}
              </span>
            )}

            {(field_type === 'PICKLIST' || field_type === 'PICKLIST_MULTI') &&
              formValue[fieldName]?.length &&
              formValue[fieldName].map((item) => item.value).join(',')}

            {field_type === 'CHECKBOX' &&
              (formValue[fieldName] ? 'True' : 'False')}

            {field_type === 'TIME' &&
              setDateFormat(formValue[fieldName], formatHHMM)}

            {key === 'Label' && (
              <LabelComponent
                labelType={labelType}
                formValue={formValue}
                fieldName={fieldName}
                labelCache={labelCache}
              />
            )}
          </p>
          {isPermissionAllowed('contacts', 'edit') && (
            <div className="position-absolute right-0 edit-div">
              <button
                className="btn btn-icon btn-sm hover-bg-none"
                onClick={() => setEditMode({ ...editMode, [key]: true })}
              >
                <MaterialIcon icon="edit" size="fs-5" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

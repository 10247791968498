import React, { useEffect, useState } from 'react';
import Table from '../../../components/GenericTable';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import { useProfileContext } from '../../../contexts/profileContext';
import organizationService from '../../../services/organization.service';
import Avatar from '../../../components/Avatar';
import { Link, useHistory } from 'react-router-dom';
import useIsTenant from '../../../hooks/useIsTenant';
import TextOverflowTooltip from '../../../components/commons/TextOverflowTooltip';
import TableRowHover from '../../../components/commons/TableRowHover';
import IdfOwnersHeader from '../../../components/idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import {
  DATE_FORMAT_MONTH,
  formatPhoneNumber,
  setDateFormat,
} from '../../../utils/Utils';
import routes from '../../../utils/routes.json';
import OwnerAvatar from '../../../components/ActivitiesTable/OwnerAvatar';
import PillFilters from '../../../components/commons/PillFilters';
import contactService from '../../../services/contact.service';

const filterStatuses = [
  {
    id: 'companies',
    label: 'Companies',
  },
  {
    id: 'contacts',
    label: 'Contacts',
  },
];

const companyColumns = [
  {
    key: 'content',
    component: <span>Company Name</span>,
    classes: 'w-250px',
  },
  {
    key: 'created_by',
    component: <span>Location</span>,
  },
  {
    key: 'created_by',
    component: <span>Primary Contact</span>,
  },
  {
    key: 'created_by',
    component: <span>Owners</span>,
  },
  {
    key: 'date_modified',
    component: <span>Last Modified</span>,
  },
];

const contactColumns = [
  {
    key: 'name',
    component: <span>Contact Name</span>,
    classes: 'w-250px',
  },
  {
    key: 'organization',
    component: <span>Company Name</span>,
    classes: 'w-200px',
  },
  {
    key: 'phone',
    component: <span>Phone</span>,
  },
  {
    key: 'owners',
    component: <span>Owners</span>,
  },
  {
    key: 'date_modified',
    component: <span>Last Modified</span>,
  },
];

const MY_KEY = 'My';

export default function MostRecents({ selectedFilter }) {
  const { profileInfo } = useProfileContext();
  const { isSynovus } = useIsTenant();
  const history = useHistory();

  const [selected, setSelected] = useState(filterStatuses[0]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    MyOrganizations: [],
    AllOrganizations: [],
    MyContacts: [],
    AllContacts: [],
  });

  const onFilterChange = (fil) => {
    setSelected(fil);
  };

  const getRecents = async () => {
    const pagination = { page: 1, limit: 10 };
    const filter = { order: ['lastActivityDate', 'desc'] };
    const ownerFilter = {
      filter: {
        assigned_user_id: profileInfo?.id,
        self: true,
      },
      order: ['lastActivityDate', 'desc'],
    };

    const countRequests = [
      {
        key: 'AllOrganizations',
        apiCall: () =>
          organizationService.getOrganizations({ ...filter }, pagination),
      },
      {
        key: 'MyOrganizations',
        apiCall: () =>
          organizationService.getOrganizations({ ...ownerFilter }, pagination),
      },
      {
        key: 'AllContacts',
        apiCall: () => contactService.getContacts({ ...filter }, pagination),
      },
      {
        key: 'MyContacts',
        apiCall: () =>
          contactService.getContacts({ ...ownerFilter }, pagination),
      },
    ];

    try {
      const responses = await Promise.allSettled(
        countRequests?.map((request) => request.apiCall())
      );

      const updatedData = {};
      countRequests?.forEach((request, index) => {
        const response = responses[index].value?.data;
        updatedData[request.key] = response?.data || [];
      });

      setData(updatedData);
    } catch (error) {
      console.error('Error fetching recent data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      getRecents();
    }
  }, [profileInfo?.id]);

  const isOrganization = selected.id === 'companies';
  const companiesMostRecents =
    selectedFilter?.id === MY_KEY
      ? data.MyOrganizations
      : data.AllOrganizations;
  const contactsMostRecents =
    selectedFilter?.id === MY_KEY ? data.MyContacts : data.AllContacts;

  const companiesRows = companiesMostRecents?.map((organization) => {
    return {
      ...organization,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <Link
              to={
                isSynovus
                  ? `${routes.insightsCompanies}/${organization.id}/organization/profile`
                  : `${routes.companies}/${organization.id}/organization/profile`
              }
              className="text-black text-truncate fw-bold d-flex align-items-center gap-2"
            >
              <Avatar
                classModifiers="bg-primary-soft text-primary"
                sizeIcon="font-size-lg"
                defaultSize="xs"
                user={organization}
                type="organization"
              />
              <TextOverflowTooltip
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0"
                text={organization.name}
              />
            </Link>
          ),
        },
        {
          key: 'address',
          label: 'address',
          component: (
            <span>
              {organization.address_city || organization.address_state
                ? `            
              ${
                organization.address_city
                  ? organization.address_city + ', '
                  : ''
              } 
              ${organization.address_state ? organization.address_state : ''}
            `
                : '--'}
            </span>
          ),
        },
        {
          key: 'assigned_user',
          label: '',
          component: (
            <span>
              {organization?.assigned_user ? (
                <p className="d-flex align-items-center mb-0 gap-1">
                  <OwnerAvatar
                    item={organization?.assigned_user}
                    isMultiple={true}
                  />
                </p>
              ) : (
                '--'
              )}
            </span>
          ),
        },
        {
          key: 'owners',
          label: 'owners',
          component: (
            <div className="position-relative z-index-10">
              <IdfOwnersHeader
                mainOwner={organization.assigned_user}
                service={organizationService}
                serviceId={null}
                headerType="organization"
                isClickable={false}
                onClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                }}
                listOwners={organization.owners}
                defaultSize="xs"
                isprincipalowner={false}
                small
              />
            </div>
          ),
        },
        {
          key: 'date_modified',
          label: '',
          component: (
            <>
              {' '}
              <TableRowHover
                zIndex="z-index-5"
                onClick={(e) => {
                  history.push(
                    isSynovus
                      ? `${routes.insightsCompanies}/${organization.id}/organization/profile`
                      : `${routes.companies}/${organization.id}/organization/profile`
                  );
                }}
              />
              <span>
                {organization?.date_modified ? (
                  <p className="d-flex align-items-center mb-0 gap-1">
                    <span>
                      {setDateFormat(
                        organization?.date_modified,
                        DATE_FORMAT_MONTH
                      )}
                    </span>
                  </p>
                ) : (
                  '--'
                )}
              </span>
            </>
          ),
        },
      ],
    };
  });

  const contactRows = contactsMostRecents?.map((contact) => {
    return {
      ...contact,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <Link
              to={`${routes.contacts}/${contact.id}/profile`}
              className="text-black fw-bold text-truncate d-flex align-items-center gap-2"
            >
              <Avatar
                classModifiers="bg-primary-soft text-primary min-w-25"
                defaultSize="xs"
                user={contact}
                type="contact"
              />
              <TextOverflowTooltip
                text={`${contact.first_name} ${contact.last_name}`}
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0"
              />
            </Link>
          ),
        },
        {
          key: 'organization',
          component: (
            <span>
              {contact.organization ? (
                <Link
                  to={`${routes.companies}/${contact.organization?.id}/organization/profile`}
                  className="text-black text-truncate d-flex align-items-center gap-2"
                >
                  <Avatar
                    classModifiers="bg-primary-soft text-primary min-w-25"
                    defaultSize="xs"
                    sizeIcon="font-size-lg"
                    user={contact.organization}
                    type="organization"
                  />

                  <TextOverflowTooltip
                    text={contact.organization?.name}
                    maxLength={25}
                  />
                </Link>
              ) : (
                <div className="pl-4">--</div>
              )}
            </span>
          ),
        },
        {
          key: 'phone',
          component: (
            <span>
              {formatPhoneNumber(
                contact.phone_work ||
                  contact.phone_home ||
                  contact.phone_mobile ||
                  contact.phone_other
              ) || '--'}
            </span>
          ),
        },
        {
          key: 'owners',
          component: (
            <div className="position-relative z-index-99 h-100 d-flex align-items-center">
              <IdfOwnersHeader
                mainOwner={contact.assigned_user}
                service={contactService}
                serviceId={null}
                isClickable={false}
                onClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                }}
                listOwners={contact.owners}
                defaultSize="xs"
                isprincipalowner={false}
                small
                prevalue="true"
                className="align-items-center"
              />
            </div>
          ),
        },
        {
          key: 'date_modified',
          component: (
            <>
              <TableRowHover
                zIndex="z-index-5"
                onClick={(e) => {
                  history.push(`${routes.contacts}/${contact.id}/profile`);
                }}
              />
              <span>
                {contact?.date_modified ? (
                  <p className="d-flex align-items-center mb-0 gap-1">
                    <span>
                      {setDateFormat(contact?.date_modified, DATE_FORMAT_MONTH)}
                    </span>
                  </p>
                ) : (
                  '--'
                )}
              </span>
            </>
          ),
        },
      ],
    };
  });

  const rows = isOrganization ? companiesRows : contactRows;
  const columns = isOrganization ? companyColumns : contactColumns;

  const mostRecents = isOrganization
    ? companiesMostRecents
    : contactsMostRecents;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-end justify-content-between mx-4 mb-3">
        <h5 className="font-weight-semi-bold fs-6 card-title mb-0">
          Most Recent
        </h5>
        <PillFilters
          items={filterStatuses}
          currentFilter={selected}
          onFilterChange={onFilterChange}
          className="w-max-content"
        />
      </div>

      {loading ? (
        <TableSkeleton cols={5} rows={7} />
      ) : (
        <div className="table-min-height">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              <Table
                columns={columns}
                data={rows}
                dataInDB={!!mostRecents?.length}
                showLoading={loading}
                emptyDataText="No recent activity"
                headClass="bg-gray-table-head border-top"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import InputValidation from '../../components/commons/InputValidation';
import { useForm } from 'react-hook-form';
import tenantService from '../../services/tenant.service';
import MaterialIcon from '../commons/MaterialIcon';
import { Spinner } from 'reactstrap';

const TenantNameEdit = ({
  tenantInfo,
  updateTenant,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const [tenantData, setTenantData] = useState({ ...tenantInfo });

  useEffect(() => {
    if (editMode) {
      clearForm();
    }
  }, [editMode]);

  const clearForm = () => {
    setValue('name', tenantInfo.name);
    clearErrors('name');
    setTenantData(tenantInfo);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setTenantData({
      ...tenantData,
      [name]: value,
    });
    setValue(name, value);
    clearErrors(name);
  };

  const onSubmit = async () => {
    try {
      setSaving(true);

      const response = await tenantService.updateTenant(
        {
          ...tenantInfo,
          name: tenantData.name,
        },
        tenantInfo.id
      );

      updateTenant(response);
      setEditMode(false);
      setSuccessMessage('Organization Name Updated');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center w-40 flex-fill gap-1 pipeline-overview-row">
        {editMode ? (
          <div className="d-flex align-items-center w-100 gap-2">
            <InputValidation
              name="name"
              type="input"
              placeholder="Organization Name"
              value={tenantData.name || ''}
              errorDisplay="mb-0 fs-9"
              iconExtraClass="fs-6"
              classNames="mr-2"
              validationConfig={{
                required: 'Organization Name is required.',
                validateSpaces: true,
                inline: false,
                borderLeft: true,
                onChange: handleOnChange,
              }}
              errors={errors}
              register={register}
            />

            <div className="d-flex align-items-center bg-white px-1 z-index-99 py-1">
              {!saving ? (
                <>
                  <a className="cursor-pointer icon-hover-bg">
                    <button type="submit" onClick={handleSubmit(onSubmit)}>
                      <MaterialIcon
                        icon="check_circle"
                        clazz="text-green fs-4 text-success"
                        filled
                      />
                    </button>
                  </a>
                  <a
                    className="cursor-pointer icon-hover-bg"
                    onClick={() => setEditMode(false)}
                  >
                    <MaterialIcon
                      icon="cancel"
                      clazz="text-gray-400 fs-4"
                      filled
                      onClick={() => clearForm?.()}
                    />{' '}
                  </a>
                </>
              ) : (
                <Spinner className="spinner-grow-xs" />
              )}
            </div>
          </div>
        ) : (
          <>
            <h4 className="mb-0 text-capitalize" data-uw-styling-context="true">
              {tenantInfo.name}
            </h4>
            <button
              className="btn btn-icon btn-sm hover-bg-none"
              onClick={() => setEditMode(true)}
            >
              <MaterialIcon icon="edit" size="fs-18" />
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default TenantNameEdit;

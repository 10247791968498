import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import Table from '../../../components/NewGenericTable';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import { initialFilters, initialPeopleForm } from './Contacts.constants';
import contactService from '../../../services/contact.service';
import {
  EMPTY_NAME,
  INVALID_EMAIL,
  OWNER,
  paginationDefault,
  CONTACT_CREATED,
} from '../../../utils/constants';
import {
  DATE_FORMAT_MONTH,
  RIGHT_PANEL_WIDTH,
  endOfLastWeekString,
  endOfWeekString,
  formatPhoneNumber,
  setDateFormat,
  startOfLastWeekString,
  startOfWeekString,
  validateEmail,
} from '../../../utils/Utils';
import PeopleForm from '../../../components/peoples/PeopleForm';
import { changePaginationLimit, changePaginationPage, reducer } from './utils';
import userService from '../../../services/user.service';
import Loading from '../../../components/Loading';
import routes from '../../../utils/routes.json';
import IdfOwnersHeader from '../../../components/idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import stringConstants from '../../../utils/stringConstants.json';
import LayoutHead from '../../../components/commons/LayoutHead';
import { sortingTable } from '../../../utils/sortingTable';
import FilterTabsButtonDropdown from '../../../components/commons/FilterTabsButtonDropdown';
import fieldService from '../../../services/field.service';
import { useForm } from 'react-hook-form';
import RightPanelModal from '../../../components/modal/RightPanelModal';
import { groupBy } from 'lodash';
import { useModuleContext } from '../../../contexts/moduleContext';
import { useProfileContext } from '../../../contexts/profileContext';
import Avatar from '../../../components/Avatar';
import TableFooterStats from '../../../components/TableFooterStats';
import { usePagesContext } from '../../../contexts/pagesContext';
import Pagination from '../../../components/Pagination';

const contactConstants = stringConstants.deals.contacts;

const defaultFilter = {
  id: 6,
  type: 'filter',
  key: 'RecentlyModified',
  name: 'Recently Modified',
};

const PEOPLES_FILTER_OPTIONS_LIST = [
  { ...defaultFilter },
  { id: 5, type: 'filter', key: 'RecentlyCreated', name: 'Recently Created' },
  { id: 4, type: 'filter', key: 'AddedThisWeek', name: 'Added This Week' },
  { id: 3, type: 'filter', key: 'AddedLastWeek', name: 'Added Last Week' },
];

const Peoples = ({
  selectedOwnership,
  setSelectedOwnership,
  stats,
  isViewMore,
  setIsViewMore,
}) => {
  const peopleForm = {
    first_name: '',
    last_name: '',
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: peopleForm,
  });
  const { pageContext, setPageContext } = usePagesContext();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [modal, setModal] = useState(false);
  const [allContacts, setAllContacts] = useState([]);
  const [filtersItems, setFiltersItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [allOwners, setAllOwners] = useState([]);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [filters] = useReducer(reducer, initialFilters);
  const [customFields, setCustomFields] = useState([]);
  const [peopleFormData, dispatchFormData] = useReducer(
    reducer,
    initialPeopleForm
  );
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);
  const [preOwners, setPreOwners] = useState([]);
  const [order, setOrder] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterTabs, setFilterTabs] = useState('filters');
  const { moduleMap } = useModuleContext();
  const [addButtonLabel, setButtonLabel] = useState();
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [fieldsDataFilter, setFieldsDataFilter] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState('');
  const currentView = 'contact';
  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };
  const { profileInfo } = useProfileContext();
  const [me] = useState(profileInfo);

  const [modified, setModified] = useState(
    pageContext?.PeoplePage?.isModified || 0
  );

  const getIsModified = () => {
    return pageContext?.PeoplePage?.isModified || 0;
  };
  const [filterOptionSelected, setFilterOptionSelected] = useState(
    pageContext?.PeoplePage?.filterOptionSelected
  );
  const defaultFilterSelected = {
    filter: {},
  };
  const [filterSelected, setFilterSelected] = useState(
    pageContext?.PeoplePage?.selectedFilter ?? defaultFilterSelected
  );

  useEffect(() => {
    // whenever filter applied from left side, remove existing filter
    if (selectedOwnership === 'My' && filterOptionSelected?.type === 'owner') {
      setFilterOptionSelected({});
      setFilterSelected(defaultFilterSelected);
      setSelectedOwner('');
      setPageContext({
        ...pageContext,
        PeoplePage: {
          ...pageContext.PeoplePage,
          filterOptionSelected: {},
          selectedFilter: defaultFilterSelected,
          isModified: getIsModified() + 1,
          user: '',
        },
      });
    } else if (
      selectedOwnership === 'All' &&
      pageContext?.PeoplePage?.user &&
      pageContext?.PeoplePage?.user !== '' &&
      filterOptionSelected?.type === 'owner'
    ) {
      onHandleFilterOrg(pageContext?.PeoplePage?.user);
    }

    setPaginationPage(paginationDefault);
    setModified((prevState) => prevState + 1);
  }, [selectedOwnership]);

  useEffect(() => {
    if (moduleMap.contact) {
      setButtonLabel(`Add ${moduleMap.contact.singular}`);
    }
  }, [moduleMap.contact]);

  useEffect(() => {
    if (isViewMore) {
      const syntheticEvent = {
        preventDefault: () => {}, // Prevent default is required for the function
      };

      handleFilterSelect(syntheticEvent, defaultFilter);

      // Reset `isViewMore` to prevent repeated triggers
      setIsViewMore(false);
    }
  }, [isViewMore]);

  const handleFilterSelect = (e, status) => {
    setPaginationPage(paginationDefault);
    e.preventDefault();
    setOpenFilter(false);

    let newFilterSelected = {
      ...filterSelected,
    };

    if (status.key === 'AddedLastWeek') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {
          startDate: startOfLastWeekString,
          endDate: endOfLastWeekString,
        },
      };
    } else if (status.key === 'AddedThisWeek') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {
          startDate: startOfWeekString,
          endDate: endOfWeekString,
        },
      };
    } else if (status.key === 'RecentlyCreated') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {},
      };
      setOrder(['date_entered', 'desc']);
    } else if (status.key === 'RecentlyModified') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {},
      };
      setOrder(['lastActivityDate', 'desc']);
    }

    const hasFilters = Object.keys(newFilterSelected.filter);

    if (!hasFilters.length) delete newFilterSelected.filter;

    setFilterSelected(newFilterSelected);
    setFilterOptionSelected(status);
    setModified((prevState) => prevState + 1);
    setPageContext({
      ...pageContext,
      PeoplePage: {
        ...pageContext.PeoplePage,
        filterOptionSelected: status,
        selectedFilter: newFilterSelected,
        isModified: getIsModified() + 1,
      },
    });
  };

  const getContacts = async (count) => {
    if (Object.keys(filterSelected).length || modified > 0 || order?.length) {
      setTableLoading(true);

      let newFilterSelected = {
        ...filterSelected,
        filter: {
          ...filterSelected.filter,
        },
      };

      if (selectedOwnership === 'My') {
        newFilterSelected = {
          ...newFilterSelected,
          filter: {
            ...newFilterSelected.filter,
            assigned_user_id: me?.id,
            self: true,
          },
        };
      } else if (selectedOwnership === 'All') {
        newFilterSelected = {
          ...newFilterSelected,
          filter: {
            ...newFilterSelected.filter,
            assigned_user_id: filterSelected?.filter?.assigned_user_id || null,
          },
        };
      }

      let newOrder = order;
      if (filterOptionSelected?.key === 'RecentlyCreated') {
        newOrder = ['date_entered', 'desc'];
      }

      const contacts = await contactService
        .getContacts(
          { ...newFilterSelected, order: newOrder },
          {
            page: paginationPage.page,
            limit: paginationPage.limit,
          }
        )
        .catch((err) => console.log(err));

      const { data } = contacts || {};

      setAllContacts(data?.data);
      setPagination(data?.pagination);

      setDataInDB(count ? Boolean(data?.pagination?.totalPages) : false);
      setTableLoading(false);
      setModified(0);
    }
  };

  useEffect(() => {
    if (modified > 0) {
      getContacts(true);
    }
  }, [modified, order]);

  async function onGetUsers() {
    const response = await userService
      .getUsers(
        {
          search: '',
          users: [],
          filters: '',
        },
        {
          limit: 3,
        }
      )
      .catch((err) => err);

    const { data } = response || {};

    const newFilterOptions = filtersItems.slice();

    newFilterOptions.push({
      id: newFilterOptions.length,
      label: OWNER,
      name: 'assigned_user_id',
      options: data?.users,
      type: 'search',
    });

    setFiltersItems(newFilterOptions);
    setAllOwners(response?.users);
  }

  useEffect(() => {
    onGetUsers();
  }, []);

  const onHandleFilterOrg = (item) => {
    const newFilterSelected = {
      ...filterSelected,
      filter: item && item.id ? { assigned_user_id: item.id } : filters,
    };

    const hasFilters = Object.keys(newFilterSelected.filter);
    if (!hasFilters.length) delete newFilterSelected.filter;

    // Perform setSelectedOwnership action when owner filter applied
    if (item?.id !== me?.id) {
      setSelectedOwnership('All');
    }

    setFilterSelected(newFilterSelected);
    const newFilterOptionSelected = {
      key: item.id,
      id: item.id,
      name: item?.id ? `${item?.first_name} ${item?.last_name}` : 'Filters',
      type: 'owner',
    };
    setFilterOptionSelected(newFilterOptionSelected);
    setOpenFilter(false);
    setModified((prevState) => prevState + 1);
    setPageContext({
      ...pageContext,
      PeoplePage: {
        ...pageContext.PeoplePage,
        filterOptionSelected,
        selectedFilter: newFilterSelected,
        isModified: getIsModified() + 1,
        user: item,
      },
    });
  };

  const getFields = async () => {
    setIsLoading(true);
    const fieldsData = await fieldService.getFields(currentView, {
      preferred: true,
    });
    setFieldsDataFilter(fieldsData?.data);
    groupBySection(fieldsData?.data);
    setIsLoading(false);
  };

  const toggle = () => {
    getFields();
    setModal(!modal);
    setCustomFields([]);
  };

  const removeObjectFields = (object) => {
    const updatedObject = { ...object };
    fieldsDataFilter?.forEach((obj) => {
      if (
        obj.isCustom &&
        Object.prototype.hasOwnProperty.call(
          updatedObject,
          obj.key.toLowerCase()
        )
      ) {
        delete updatedObject[obj.key.toLowerCase()];
      }
    });

    return updatedObject;
  };

  const onHandleSubmit = async () => {
    setLoading(true);
    const updatedObject = removeObjectFields(peopleFormData);
    if (!peopleFormData.first_name || !peopleFormData.last_name) {
      setLoading(false);

      return setErrorMessage(EMPTY_NAME);
    }

    const isEmail = peopleFormData.email && validateEmail(peopleFormData.email);

    if (peopleFormData.email && !isEmail) {
      setLoading(false);

      return setErrorMessage(INVALID_EMAIL);
    }
    const newContact = await contactService
      .createContact(updatedObject)
      .catch((err) => console.log(err));

    if (newContact) {
      await Promise.all(
        customFields?.map(async (item) => {
          await new Promise((resolve) => {
            if (item?.value !== '')
              contactService
                .updateCustomField(newContact?.data?.id, item)
                .then(resolve);
          });
        }),
        preOwners?.map(async (item) => {
          await new Promise((resolve) => {
            contactService
              .addOwner(newContact?.data?.id, item.user_id)
              .then(resolve);
          });
        })
      );

      getContacts(true);
      reset(
        initialPeopleForm,
        dispatchFormData({
          type: 'reset-peopleForm',
        })
      );
      setPreOwners([]);
      setSuccessMessage(
        CONTACT_CREATED.replace(/Contact/g, moduleMap.contact.singular)
      );
      toggle();
    }

    setLoading(false);
  };

  const deleteContacts = async () => {
    const msg = `${moduleMap.contact.singular} Deleted.`;

    try {
      const data = await contactService.deleteContacts(selectedData);
      if (data[0]?.result === 'failed') {
        setShowDeleteContactModal(true);
        setErrorMessage(data[0]?.msg);
      } else {
        setShowDeleteContactModal(false);
        setSelectedData([]);
        setSuccessMessage(msg);
        setModified(true);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const openDeleteModal = () => {
    setShowDeleteContactModal(true);
  };

  const onClose = () => {
    reset(
      initialPeopleForm,
      dispatchFormData({
        type: 'reset-peopleForm',
      })
    );
    setModal(false);
    setCustomFields([]);
  };
  const sortTable = ({ name }) => {
    setModified((prevState) => prevState + 1);
    sortingTable({ name, order, setOrder });
    setFilterOptionSelected({});
    setFilterSelected(defaultFilterSelected);
  };

  const handleRowClick = (event) => {
    const rowData = event.data;
    history.push(`${routes.contacts}/${rowData.id}/profile`);
  };

  const handleClearSelection = () => {
    setSelectAll(false);
    setSelectedData([]);
  };

  const formLoader = () => {
    if (isLoading) return <Loading />;
  };

  const isMultiple = selectedData?.length > 1;
  const heading = `Delete ${
    moduleMap.contact[isMultiple ? 'plural' : 'singular']
  }`;
  const description = `Are you sure you want to delete ${
    isMultiple ? 'all these' : 'this'
  } ${moduleMap.contact[isMultiple ? 'plural' : 'singular']}?`;

  const ContactLogoRenderer = (row) => {
    const contact = row.data;
    return (
      <Link
        to={`${routes.contacts}/${contact.id}/profile`}
        className="text-black fw-bold text-truncate d-flex align-items-center gap-2"
      >
        <Avatar
          classModifiers="bg-primary-soft text-primary min-w-25"
          defaultSize="xs"
          user={contact}
          type="contact"
        />
        <p className="font-weight-medium text-truncate font-size-sm2 mb-0">
          {`${contact.first_name} ${contact.last_name}`}
        </p>
      </Link>
    );
  };

  const CompanyNameRenderer = (row) => {
    const contact = row.data;
    return (
      <span>
        {contact.organization ? (
          <Link
            to={`${routes.companies}/${contact.organization?.id}/organization/profile`}
            className="text-black text-truncate d-flex align-items-center gap-2"
          >
            <Avatar
              classModifiers="bg-primary-soft text-primary min-w-25"
              defaultSize="xs"
              sizeIcon="font-size-lg"
              user={contact.organization}
              type="organization"
            />
            {contact.organization?.name}
          </Link>
        ) : (
          <div className="pl-4">--</div>
        )}
      </span>
    );
  };

  const EmailRenderer = (row) => {
    const contact = row.data;
    return (
      <span>
        {contact.email_work ||
          contact.email_home ||
          contact.email_mobile ||
          contact.email_other ||
          '--'}
      </span>
    );
  };

  const PhoneRenderer = (row) => {
    const contact = row.data;
    return (
      <span>
        {formatPhoneNumber(
          contact.phone_work ||
            contact.phone_home ||
            contact.phone_mobile ||
            contact.phone_other
        ) || '--'}
      </span>
    );
  };

  const OwnersRenderer = (row) => {
    const contact = row.data;
    const isPrincipalOwner =
      me && contact
        ? me?.role?.admin_access ||
          me?.role?.owner_access ||
          contact?.assigned_user_id === me?.id
        : false;
    return (
      <>
        <div className="position-relative z-index-99 h-100 d-flex align-items-center">
          <IdfOwnersHeader
            mainOwner={contact.assigned_user}
            service={contactService}
            serviceId={null}
            isClickable={false}
            onClick={(e) => {
              e?.stopPropagation();
              e?.preventDefault();
            }}
            listOwners={contact.owners}
            defaultSize="xs"
            headerType={{ collection: 'deals', action: 'edit' }}
            isprincipalowner={isPrincipalOwner}
            small
            prevalue="true"
            className="align-items-center"
          />
        </div>
      </>
    );
  };

  const LastModifiedRenderer = (row) => {
    const contact = row.data;
    return (
      <span>
        {contact?.date_modified ? (
          <p className="d-flex align-items-center mb-0 gap-1">
            <span>
              {setDateFormat(contact?.date_modified, DATE_FORMAT_MONTH)}
            </span>
          </p>
        ) : (
          '--'
        )}
      </span>
    );
  };

  const columnDefs = [
    {
      field: 'name',
      orderBy: 'first_name',
      headerName: 'Contact Name',
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: ContactLogoRenderer,
    },
    {
      field: 'organization',
      orderBy: 'organization.name',
      headerName: 'Company Name',
      cellRenderer: CompanyNameRenderer,
    },
    {
      field: 'email',
      orderBy: 'email_work',
      headerName: 'Email',
      cellRenderer: EmailRenderer,
    },
    {
      field: 'phone',
      orderBy: 'phone_work',
      headerName: 'Phone',
      cellRenderer: PhoneRenderer,
    },
    {
      field: 'owner',
      orderBy: 'assigned_user',
      headerName: 'Owners',
      cellRenderer: OwnersRenderer,
    },
    {
      field: 'date_modified',
      orderBy: 'date_modified',
      headerName: 'Last Modified',
      cellRenderer: LastModifiedRenderer,
    },
  ];

  return (
    <div className="bg-white">
      <div className="d-flex align-items-center justify-content-between positon-relative mb-2 pt-2 px-3">
        <FilterTabsButtonDropdown
          options={PEOPLES_FILTER_OPTIONS_LIST}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          filterOptionSelected={filterOptionSelected}
          filterSelected={filterSelected}
          filterTabs={filterTabs}
          handleFilterSelect={handleFilterSelect}
          onHandleFilterOwner={onHandleFilterOrg}
          setFilterOptionSelected={setFilterOptionSelected}
          setFilterSelected={setFilterSelected}
          setFilterTabs={setFilterTabs}
          setModified={setModified}
          extraClasses={'dropdown-left'}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
          module="contact"
        />
        {moduleMap.contact && (
          <LayoutHead
            id="add-contact-button"
            onHandleCreate={toggle}
            buttonLabel={addButtonLabel}
            selectedData={selectedData}
            onDelete={openDeleteModal}
            dataInDB={dataInDB}
            onClear={handleClearSelection}
            alignTop="my-0"
            permission={{
              collection: 'contacts',
              action: 'create',
            }}
          ></LayoutHead>
        )}
      </div>

      <DeleteConfirmationModal
        showModal={showDeleteContactModal}
        setShowModal={setShowDeleteContactModal}
        handleCloseModal={handleClearSelection}
        event={deleteContacts}
        heading={heading}
        description={description}
        extraBody={
          <p className="mt-2 mb-0">{contactConstants.delete.confirm}</p>
        }
      />

      <Card className="card-0">
        <Card.Body className="p-0">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              <Table
                checkbox
                stickyColumn
                tableId="people"
                showLoading={tableLoading}
                columns={columnDefs}
                data={allContacts}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                paginationInfo={{}}
                title={`${moduleMap.contact.singular}`}
                emptyDataText="No records in this view."
                dataInDB={dataInDB}
                toggle={toggle}
                permission={{
                  collection: 'contacts',
                  action: 'create',
                }}
                sortingTable={sortTable}
                sortingOrder={order}
                handleRowClick={handleRowClick}
                stickyFooter={true}
                stickyClass="accounts-table-container"
                tableLoading={tableLoading}
              />
            </div>
            <div className="border-top bg-gray-table-head">
              <div className="d-flex py-1">
                <ul className="px-3 mb-0 flex-fill">
                  <TableFooterStats stats={stats} loading={loading} />
                </ul>
                <div className="py-1 ml-auto">
                  <Pagination
                    showPerPage
                    paginationInfo={pagination}
                    onPageChange={(newPage) => {
                      changePaginationPage(newPage, setPaginationPage);
                      setModified((prevState) => prevState + 1);
                    }}
                    onLimitChange={(perPage) => {
                      changePaginationLimit(perPage, setPaginationPage);
                      setModified((prevState) => prevState + 1);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      {modal && (
        <RightPanelModal
          showModal={modal}
          setShowModal={() => onClose()}
          showOverlay={true}
          containerBgColor={'pb-0'}
          containerWidth={RIGHT_PANEL_WIDTH}
          containerPosition={'position-fixed'}
          headerBgColor="bg-gray-5"
          Title={
            <div className="d-flex py-2 align-items-center">
              <h3 className="mb-0">{`Add ${moduleMap.contact.singular}`}</h3>
            </div>
          }
        >
          {isLoading ? (
            formLoader()
          ) : (
            <PeopleForm
              dispatch={dispatchFormData}
              moduleMap={moduleMap}
              allUsers={allOwners}
              peopleFormData={peopleFormData}
              refresh={() => getContacts(true)}
              isprincipalowner="true"
              register={register}
              loading={loading}
              setValue={setValue}
              getFieldState={getFieldState}
              control={control}
              customFields={customFields}
              setCustomFields={setCustomFields}
              errors={errors}
              onClose={onClose}
              handleSubmit={handleSubmit}
              onHandleSubmit={onHandleSubmit}
              fields={isFieldsData}
              prevalue="true"
              preowners={preOwners}
              setPreOwners={setPreOwners}
              peopleForm={peopleForm}
            />
          )}
        </RightPanelModal>
      )}
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
    </div>
  );
};

export default Peoples;

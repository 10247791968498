import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { numbersWithComma } from '../../../../utils/Utils';

const avgFeesAndRate = [
  {
    title: 'Visa',
    key: 'visaInterchangeFees',
    key2: 'visaAverageInterchangeFeePaid',
    value: 'in IC Fees',
    value2: 'Avg IC Rate',
  },
  {
    title: 'MasterCard',
    key: 'mcInterchangeFees',
    value: 'in IC Fees',
    value2: 'Avg IC Rate',
    key2: 'mcAverageInterchangeFeePaid',
  },
  {
    title: 'Discover',
    key: 'discoverInterchangeFees',
    value: 'in IC Fees',
    value2: 'Avg IC Rate',
    key2: 'discoverAverageInterchangeFeePaid',
  },
];

const InterchangeSavingsWidget = ({ widgetConfig, whenPrinting }) => {
  const isAggregateView = widgetConfig?.aggregate === true;
  const aggregateOrIndividualObject = isAggregateView
    ? widgetConfig?.data?.aggregate
    : widgetConfig?.data;
  return (
    <>
      <Card className="report-widget">
        <CardBody className="justify-content-center align-items-center d-flex">
          <div className="flex-fill w-100">
            <div className="d-flex flex-column text-left">
              <h5 className="mb-2 hide-heading">{widgetConfig.header}</h5>
              <CardBody className="d-flex mx-2 border-2 widget-border-2 bg-white rpt-rounded px-4 pt-4">
                <div className="d-flex flex-column justify-content-center">
                  <p
                    className={`mb-1 ${
                      whenPrinting ? 'fs-4' : 'fs-3'
                    } text-center font-weight-bolder`}
                  >
                    <span className="text-success">
                      $
                      {numbersWithComma(
                        aggregateOrIndividualObject?.[widgetConfig?.keyDollars]
                      )}
                    </span>
                  </p>
                  <p
                    className={`mb-4 ${
                      whenPrinting ? 'fs-9' : 'font-size-sm2'
                    } text-center font-weight-medium`}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: widgetConfig.potentialSavings,
                      }}
                    />
                  </p>
                  <p
                    className={`mb-0 w-75 mx-auto ${
                      whenPrinting ? 'fs-9' : 'font-size-sm2'
                    } text-center font-weight-medium`}
                  >
                    <span className="text-black">
                      {numbersWithComma(
                        aggregateOrIndividualObject?.[widgetConfig?.keyCount]
                      )}
                      {widgetConfig.description}
                    </span>
                  </p>
                </div>
              </CardBody>
            </div>
            <div className="mt-5 d-flex px-2 flex-column text-left">
              <h5 className="mb-2">{widgetConfig.summaryHeading}</h5>
              <div className="d-grid grid-col-3 gap-3 fs-7 font-weight-medium text-black">
                {avgFeesAndRate.map((item, index) => (
                  <CardBody
                    key={index}
                    className="d-flex justify-content-center widget-border-2 border-2 bg-white rpt-rounded text-center"
                  >
                    <div className="d-flex flex-column justify-content-center gap-2">
                      <span className="font-size-xl font-weight-bolder">
                        {item.title}
                      </span>
                      <div className="d-flex flex-column">
                        <span>
                          $
                          {numbersWithComma(
                            aggregateOrIndividualObject?.[item.key],
                            {
                              maximumFractionDigits: 2,
                            }
                          )}{' '}
                          {item.value}
                        </span>
                        <span>
                          {Math.round(aggregateOrIndividualObject?.[item.key2])}
                          % {item.value2}
                        </span>
                      </div>
                    </div>
                  </CardBody>
                ))}
              </div>
              <p
                className={`mb-0 mt-1 ${
                  whenPrinting ? 'fs-9' : 'font-size-sm2'
                } text-center`}
              >
                <span className="text-black">
                  {aggregateOrIndividualObject?.amexDisclosure}
                </span>
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default InterchangeSavingsWidget;

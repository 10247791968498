import _ from 'lodash';
import moment from 'moment';
import { PayableKeys, ReceivableKeys, ReportTypes } from './reports.constants';
import { parseCurrency } from '../../utils/Utils';
import {
  MerchantMetricsAggregationKeys,
  MerchantMetricsAggregationSVBKeys,
  MerchantMetricsKeys,
  MerchantMetricsSVBKeys,
} from '../reportbuilder/constants/metricsConstants';
import { CCSWData } from '../reportbuilder/constants/widgetsConstants';

export const sumItemValues = (data) => {
  let total = 0;

  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      let itemValue = data[key].itemValue;
      try {
        itemValue = parseFloat(data[key].itemValue.replace(/,/g, '')); // Remove commas and parse as float
      } catch (e) {
        itemValue = data[key].itemValue;
      }
      if (!isNaN(itemValue)) {
        total += itemValue;
      }
    }
  }

  return total;
};

export const workingCapitalMapping = (reportValues, insightsData) => {
  const yearly = 365;
  const mapping = {};

  try {
    const accountsPayable = parseCurrency(
      reportValues.accountsPayable || '$0.00'
    );
    const accountsReceivable = parseCurrency(
      reportValues.accountsReceivable || '$0.00'
    );
    const costOfGoodsSold = parseCurrency(
      reportValues.costOfGoodsSold || '$0.00'
    );
    const netSales = parseCurrency(reportValues.netSales || '$0.00');

    // Accounts Payable/Cost of Goods Sold*365
    const yourDPO = Math.round((accountsPayable / costOfGoodsSold) * yearly);
    // Accounts Receivable/Net Sales *365
    const yourDSO = Math.round((accountsReceivable / netSales) * yearly);

    // ((Your DPO+1)/365*COGS) - (Your DPO/365*COGS)
    // value 8 and 16 in pdf
    const oneDayDPODiff = Math.round(
      ((yourDPO + 1) / yearly) * costOfGoodsSold -
        (yourDPO / yearly) * costOfGoodsSold
    );

    // (Your DSO/365*Net Sales) - ((Your DSO-1)/365*Net Sales)
    // value 9 and 26 in pdf
    const oneDayDSODiff = Math.round(
      (yourDSO / yearly) * netSales - ((yourDSO - 1) / yearly) * netSales
    );

    const avgDPO = insightsData?.average?.dpo || 0;
    const avgDSO = insightsData?.average?.dso || 0;
    const bestInClassDPO = insightsData?.bestInClass?.dpo || 0;
    const bestInClassDSO = insightsData?.bestInClass?.dso || 0;

    // new v2 changes
    // DPO Improvement to industry Average:
    // If (Industry Average DPO)>(YourDPO) Then =((Industry Average DPO)-(YourDPO)*DPOOneDay), Otherwise = 0
    // value 2 in pdf
    mapping.dpoImprovmentToIndustryAvg =
      avgDPO > yourDPO ? (avgDPO - yourDPO) * Math.round(oneDayDPODiff) : 0;

    // DPO Improvement to industry Average:
    // If (Industry Average DSO<YourDSO) Then = ((Your DSO-Industry Average DSO)*DSOOneDay), Otherwise = 0
    // value 3 in pdf
    mapping.dsoImprovmentToIndustryAvg =
      avgDSO < yourDSO ? (yourDSO - avgDSO) * Math.round(oneDayDSODiff) : 0;

    // Total Improvement by moving to Industry Average = DPO Improvement to Industry Average + DSO Improvement to Industry Average
    // value 1 and 6 in pdf
    mapping.totalImprovementToIndustryAvg =
      mapping.dpoImprovmentToIndustryAvg + mapping.dsoImprovmentToIndustryAvg;

    // Cash unlocked by improving your DPO to the industry Average:
    // Dark Blue Bar = If (Industry Average DPO)>(YourDPO) Then =((Industry Average DPO)-(YourDPO)*DPOOneDay Otherwise = 0
    // value 4 in pdf graph progress bar
    mapping.cashUnlockedDPOAvg = mapping.dpoImprovmentToIndustryAvg;

    // Cash unlocked by improving your DSO to the industry Average:
    // Dark Blue Bar = If (Industry Average DSO<YourDSO) Then = ((Your DSO-Industry Average DSO)*DSOOneDay), Otherwise = 0
    // value 5 in pdf graph progress bar
    mapping.cashUnlockedDSOAvg = mapping.dsoImprovmentToIndustryAvg;

    // if its true show a diff message mentioned in widget
    mapping.improved =
      mapping.dpoImprovmentToIndustryAvg === 0 &&
      mapping.dsoImprovmentToIndustryAvg === 0;

    // value 10 in pdf
    mapping.totalOneDayDiff = Math.round(oneDayDPODiff + oneDayDSODiff);

    // value 11, 12, 13 in pdf
    mapping.yourDPO = yourDPO;
    // value 14 in pdf
    mapping.avgDPO = avgDPO;
    // value 15 in pdf
    mapping.bestInClassDPO = bestInClassDPO;
    mapping.bestInClassDPO_ = Math.abs(bestInClassDPO - yourDPO);

    // DPO Best In Class Difference:
    // If (Industry Best In Class DPO)>(YourDPO) Then =((Industry Best In Class DPO)-(YourDPO)*DPOOneDay Otherwise = 0
    // value 17 in pdf
    mapping.totalBestInClassDPODiff =
      bestInClassDPO > yourDPO ? (bestInClassDPO - yourDPO) * oneDayDPODiff : 0;

    // value 21, 22, 23 in pdf
    mapping.yourDSO = yourDSO;
    // value 24 in pdf
    mapping.avgDSO = avgDSO;
    // value 25 in pdf
    mapping.bestInClassDSO = bestInClassDSO;
    mapping.bestInClassDSO_ = Math.abs(bestInClassDSO - yourDSO);

    // DSO Best In Class Difference:
    // If (Industry Best In Class DSO)<(YourDSO) Then =((YourDSO)-(Industry Best In Class DSO)*DSOOneDay Otherwise = 0
    // value 27 in pdf
    mapping.totalBestInClassDSODiff =
      bestInClassDSO < yourDSO ? (yourDSO - bestInClassDSO) * oneDayDSODiff : 0;

    mapping.oneDayDPODiff = oneDayDPODiff;
    mapping.oneDayDSODiff = oneDayDSODiff;
  } catch (e) {
    console.error('sss-working-capital-mapping', e);
  }
  return mapping;
};

const findIsEditableByKey = (searchKey, keys) => {
  const foundItem = keys.find((item) => item[0] === searchKey);

  if (foundItem) {
    return foundItem[2]; // it will return whether its true/false
  }

  return false;
};

// this converts {ACH: 0} -> {ACH: {key: ACH, value: 0}}
export const updateJsonObject = (originalObject = {}) => {
  const keyPairs = Object.hasOwn(originalObject, 'Cash Vault')
    ? ReceivableKeys
    : PayableKeys;
  const finalObject = Object.entries(originalObject).reduce(
    (acc, [key, value]) => {
      try {
        if (_.isObject(value)) {
          acc[key] = {
            key: value.key,
            value: value.value,
            isEdited: findIsEditableByKey(key, keyPairs),
            itemValue: value.itemValue || value.value,
            isChecked:
              value.isChecked === undefined && parseFloat(value.value) > 0
                ? true
                : value.isChecked,
          };
        } else {
          acc[key] = {
            key,
            value,
            isEdited: findIsEditableByKey(key, keyPairs),
            isChecked: parseFloat(value) > 0,
            itemValue: value,
          };
        }
      } catch (e) {
        acc[key] = {
          key,
          value,
          isEdited: findIsEditableByKey(key, keyPairs),
          isChecked: parseFloat(value) > 0,
          itemValue: value,
        };
      }
      return acc;
    },
    {}
  );

  // sorting based on payable/receivable keys order
  const keyValueArray = Object.entries(finalObject);
  keyValueArray.sort((a, b) => {
    const keyA = keyPairs.find((pair) => pair[0] === a[0]);
    const keyB = keyPairs.find((pair) => pair[0] === b[0]);
    if (keyA && keyB) {
      return keyA[1] - keyB[1];
    }
    return -1;
  });

  return Object.hasOwn(originalObject, 'Cash Vault') ||
    Object.hasOwn(originalObject, 'Wires')
    ? Object.fromEntries(keyValueArray)
    : finalObject;
};

// this extracts date from pdf
export const getCycleDate = (dateText) => {
  const momentDate = moment(dateText).format('MMMM, YYYY');
  const dateParts = momentDate.split(', ');
  const monthName = dateParts[0];
  const year = dateParts[1];

  const monthNumber = new Date(Date.parse(monthName + ' 1, 2000')).getMonth();

  return new Date(year, monthNumber);
};

export const getReportPrettyDate = (rpt) => {
  try {
    const justDate = rpt?.value2 || rpt?.date || rpt.updatedAt;
    return moment(justDate.split('T')[0]).format('MMMM YYYY');
  } catch (e) {
    return moment
      .utc(rpt?.value2 || rpt?.date || rpt.updatedAt)
      .format('MMMM YYYY');
  }
};

export const getReportName = (rpt) => {
  // name and date
  return `${rpt?.value1 || rpt?.companyName || rpt?.name} ${getReportPrettyDate(
    rpt
  )}`;
};

export const PdfFileNames = {
  [ReportTypes.Treasury]: 'Treasury Management',
  [ReportTypes.WorkingCapital]: 'Working Capital',
  [ReportTypes.Merchant]: 'Merchant Services',
  [ReportTypes.AccountStructure]: 'Account Structure',
  [ReportTypes.Fraud]: 'Fraud Insights',
  [ReportTypes.KnowledgeAssessment]: 'Knowledge Assessment',
  [ReportTypes.CommercialCard]: 'Commercial Card',
  [ReportTypes.MerchantV2]: 'Merchant Services',
  [ReportTypes.SvbBank]: 'Merchant Services',
};
export const getReportPDFName = (reportType, rpt) => {
  // name and date
  return `${moment(rpt?.value2 || rpt?.reportDate).format('MMMM YYYY')} ${
    PdfFileNames[reportType]
  } ${rpt?.value1 || rpt?.companyName || rpt?.name}`;
};

export const getReportPDFNameMerchant = (reportType, rpt, isAggregate) => {
  // name and date
  return `${isAggregate ? rpt.date : moment(rpt?.date).format('MMMM YYYY')} ${
    PdfFileNames[reportType]
  } ${rpt?.name}`;
};

export const calculateOpportunity = (checks, selectedTenant) => {
  return Math.round(checks * 0.3 * 2.705 * 12);
};

export const updateWidgetBySection = (
  widgets,
  section,
  widgetType,
  newReport,
  updatedConfig
) => {
  const sectionWidgets = widgets[section];
  let updatedWidgets = [];
  // what if we update multiple widgets in a section ?
  if (_.isArray(widgetType)) {
    updatedWidgets = sectionWidgets.map((widget) => {
      const foundType = widgetType.find((wgType) => widget.type === wgType);
      return foundType
        ? { ...widget, widgetConfig: { ...updatedConfig, data: newReport } }
        : {
            ...widget,
            widgetConfig: {
              ...widget.widgetConfig,
              data: newReport,
            },
          };
    });
  } else {
    // set update report object into the editing widget
    updatedWidgets = sectionWidgets.map((widget) =>
      widget.type === widgetType
        ? { ...widget, widgetConfig: { ...updatedConfig, data: newReport } }
        : {
            ...widget,
            widgetConfig: { ...widget.widgetConfig, data: newReport },
          }
    );
  }

  return {
    ...widgets,
    [section]: updatedWidgets,
  };
};

export const calculateMonthDataUsage = (newReport) => {
  // lowUtilization, peakUtilization, avgMonthlyUtilization, calculatedTotalExpense
  const total = 12;
  const values = newReport.loanOverview.monthData.map((mp) =>
    parseFloat(mp.value)
  );
  const sum = values.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const nonZeroValues = values.every((v) => v === 0)
    ? values
    : values.filter((value) => value !== 0);
  const lowUtilization =
    Math.min(...nonZeroValues) === Infinity ? 0 : Math.min(...nonZeroValues);
  const peakUtilization =
    Math.max(...values) === -Infinity ? 0 : Math.max(...values);
  const avgMonthlyUtilization = Math.round(sum / total);
  const calculatedTotalExpense =
    sum *
    0.1 *
    (parseFloat(newReport.loanOverview.avgAnnualRate || '0.0') / 100);

  return {
    lowUtilization,
    peakUtilization,
    avgMonthlyUtilization,
    sum,
    calculatedTotalExpense,
  };
};

function applyToFixedForFloats(obj, decimalPlaces = 2) {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'number' && !Number.isInteger(obj[key])) {
      obj[key] = parseFloat(obj[key].toFixed(decimalPlaces));
    }
  });
  return obj;
}

// Keys could be like these:
// analysisDate
// fees.byType[type='interchange'].percentageOfTotalFeeAmount
// fees.byType[type='network'].percentageOfTotalFeeAmount
// fees.nonBankTotalAmount
// transactions.authorized.percentageOfTotalTransactions
// fees.byType[type ='interchange'].byNetworks[type='discover'].totalAmount
export const extractMetricsNestedDataByKeys = (key, obj) => {
  const parts = key.split('.');
  return parts.reduce((current, part) => {
    if (!current) return '0';
    // match array properties with filters like fees.byType[type='interchange']
    const arrayMatch = part.match(/(\w+)\[(\w+)='?([\w-]+)'?]/);
    if (arrayMatch) {
      const [, arrayProp, filterProp, filterValue] = arrayMatch;
      current = current[arrayProp];
      if (Array.isArray(current)) {
        return current.find((item) => item[filterProp] === filterValue);
      }
      return '0';
    }

    return current?.[part];
  }, obj);
};

const convertRate = (metrics, key) => {
  const rate = metrics[key];
  return rate ? rate / 100 : 0;
};

export const MerchantReportAggregateData = (aggregation, data = [{}]) => {
  const [firstData = {}] = data;
  const lastData = data.at(-1) || {};
  const acc = {
    Customer_Name: firstData.Customer_Name,
    tax_id_token: firstData.employerIdentificationNumber,
    employerIdentificationNumber: firstData.employerIdentificationNumber,
    TotalGrossVolume: 0,
    TotalTransactions: 0,
    AverageTransactions: 0,
    AuthorizationApprovalPercentage: 0,
    ChargebackPercentage: 0,
    NetSales: 0,
    ChargebackDollars: 0,
    PotentialSavings: 0,
    DollarValueOfDowngrades: 0,
    AverageChargeback: 0,
    CountOfDowngradedTransactions: 0,
    PotentialLevel2Transactions: 0,
    PotentialLevel3Transactions: 0,
    AverageFee: 0,
    InterchangePercentage: 0,
    NetworkFeesPercentage: 0,
    BankFeesPercentage: 0,
    EffectiveRate: 0,
    NonBankFees: 0,
    TotalFees: 0,
    contacts: [],
    monthRange: {
      start: firstData.analysisDate,
      end: lastData.analysisDate,
    },
  };

  const metricsAggregateData = Object.keys(
    MerchantMetricsAggregationKeys
  ).reduce((metrics, key) => {
    metrics[MerchantMetricsAggregationKeys[key]] =
      extractMetricsNestedDataByKeys(key, aggregation);
    if (MerchantMetricsAggregationKeys[key] === 'EffectiveRate') {
      metrics.EffectiveRate = convertRate(metrics, 'EffectiveRate');
    }
    return metrics;
  }, {});

  return applyToFixedForFloats({ ...acc, ...metricsAggregateData });
};

export const MerchantReportSVBAggregateData = (aggregation, ein) => {
  const acc = {};
  const metricsAggregateData = Object.keys(
    MerchantMetricsAggregationSVBKeys
  ).reduce((metrics, key) => {
    metrics[MerchantMetricsAggregationSVBKeys[key]] =
      extractMetricsNestedDataByKeys(key, aggregation);
    return metrics;
  }, {});

  metricsAggregateData.effectiveRate = convertRate(
    metricsAggregateData,
    'effectiveRate'
  );
  metricsAggregateData.ccisDollarsProcessedAtHigherRate = 0;
  metricsAggregateData.ccisDollars = 0;
  // special cases.
  if (
    aggregation.total.fees.component.interchange?.excludedPaymentNetworks
      ?.length &&
    aggregation.total.fees.component.interchange?.excludedPaymentNetworks[0] ===
      'amex'
  ) {
    metricsAggregateData.amexDisclosure = CCSWData.savingsOpportunity.note;
  }
  if (Object.hasOwn(aggregation.average, 'ccis')) {
    const avg = aggregation.average;
    metricsAggregateData.ccisDollarsProcessedAtHigherRate = 0;
    metricsAggregateData.ccisDollars = 0;
    if (Object.keys(avg.ccis.savingsEligibilityByLevel)?.length) {
      try {
        metricsAggregateData.ccisDollarsProcessedAtHigherRate =
          avg.ccis.savingsEligibilityByLevel[2].ineligibleAmountProcessedOnLowerLevel;
        metricsAggregateData.ccisDollars =
          avg.ccis.savingsEligibilityByLevel[2].eligibleAmountProcessedOnLowerLevel;
      } catch (e) {
        console.log(e);
      }
    }
  }
  metricsAggregateData.employerIdentificationNumber = ein; // MID
  return applyToFixedForFloats({ ...acc, ...metricsAggregateData });
};

export const MerchantReportSVBSingleData = (obj) => {
  const metrics = Object.keys(MerchantMetricsSVBKeys).reduce((metrics, key) => {
    metrics[MerchantMetricsSVBKeys[key]] = extractMetricsNestedDataByKeys(
      key,
      obj
    );
    return metrics;
  }, {});

  metrics.effectiveRate = convertRate(metrics, 'effectiveRate');
  metrics.ccisDollarsProcessedAtHigherRate = 0;
  metrics.ccisDollars = 0;
  // special cases.
  if (
    obj.fees.component.interchange?.excludedPaymentNetworks?.length &&
    obj.fees.component.interchange?.excludedPaymentNetworks[0] === 'amex'
  ) {
    metrics.amexDisclosure = CCSWData.savingsOpportunity.note;
  }
  if (Object.hasOwn(obj, 'ccis')) {
    metrics.ccisDollarsProcessedAtHigherRate = 0;
    metrics.ccisDollars = 0;
    if (Object.keys(obj.ccis.savingsEligibilityByLevel)?.length) {
      try {
        metrics.ccisDollarsProcessedAtHigherRate =
          obj.ccis.savingsEligibilityByLevel[2].ineligibleAmountProcessedOnLowerLevel;
        metrics.ccisDollars =
          obj.ccis.savingsEligibilityByLevel[2].eligibleAmountProcessedOnLowerLevel;
      } catch (e) {
        console.log(e);
      }
    }
  }
  metrics.employerIdentificationNumber = obj.employerIdentificationNumber; // MID
  return metrics;
};

export const MerchantReportAggregateSingleData = (obj) => {
  const item = Object.keys(MerchantMetricsKeys).reduce((metrics, key) => {
    metrics[MerchantMetricsKeys[key]] = extractMetricsNestedDataByKeys(
      key,
      obj
    );
    return metrics;
  }, {});
  return {
    Customer_Name: obj.Customer_Name,
    tax_id_token: obj.tax_id_token,
    employerIdentificationNumber: obj.employerIdentificationNumber,
    TotalGrossVolume: item.TotalGrossVolume || 0,
    TotalTransactions: item.TotalTransactions || 0,
    AverageTransactions: item.AverageTransactions || 0,
    AuthorizationApprovalPercentage: item.AuthorizationApprovalPercentage || 0,
    ChargebackPercentage: item.ChargebackPercentage || 0,
    NetSales: item.NetSales || 0,
    ChargebackDollars: item.ChargebackDollars || 0,
    PotentialSavings: item.PotentialSavings || 0,
    DollarValueOfDowngrades: item.DollarValueOfDowngrades || 0,
    AverageChargeback: item.AverageChargeback || 0,
    CountOfDowngradedTransactions: item.CountOfDowngradedTransactions || 0,
    PotentialLevel2Transactions: item.PotentialLevel2Transactions || 0,
    PotentialLevel3Transactions: item.PotentialLevel3Transactions || 0,
    AverageFee: item.AverageFee || 0,
    InterchangePercentage: item.InterchangePercentage || 0,
    NetworkFeesPercentage: item.NetworkFeesPercentage || 0,
    BankFeesPercentage: item.BankFeesPercentage || 0,
    EffectiveRate: item.EffectiveRate ? item.EffectiveRate / 100 : 0,
    NonBankFees: item.NonBankFees || 0,
    contacts: item.contacts || [],
    TotalFees: item.TotalFees || 0,
    Month: obj.analysisDate,
    reportDate: obj.analysisDate,
  };
};

export const createManualReportMerchantCoreData = (
  organization,
  aggregation,
  coreDataReports
) => {
  coreDataReports[0].Customer_Name = organization.name;
  const aggregate = MerchantReportAggregateData(aggregation, coreDataReports);

  return {
    companyName: aggregate.Customer_Name || organization.name, // 0
    reportDate: aggregate.monthRange.end, // 4
    aggregate,
    coreData: coreDataReports.map((dt) => {
      return {
        ...MerchantReportAggregateSingleData({
          ...dt,
          Customer_Name: coreDataReports[0].Customer_Name,
          tax_id_token: coreDataReports[0].employerIdentificationNumber,
        }),
        feId: crypto.randomUUID(),
      };
    }),
  };
};

export const createManualReportSVBMerchantCoreData = (
  organization,
  aggregation,
  coreDataReports
) => {
  const uniqueReports = _.uniqBy(coreDataReports, 'analysisDate');
  coreDataReports[0].Customer_Name = organization.name;
  const aggregate = MerchantReportSVBAggregateData(
    aggregation,
    coreDataReports[0].employerIdentificationNumber
  );

  return {
    companyName: aggregate.Customer_Name || organization.name, // 0
    reportDate: uniqueReports.at(-1).analysisDate, // 4
    aggregate: {
      ...aggregate,
      monthRange: {
        start: uniqueReports[0].analysisDate,
        end: coreDataReports.at(-1).analysisDate,
      },
    },
    coreData: uniqueReports.map((dt) => {
      return {
        ...MerchantReportSVBSingleData(dt),
        feId: crypto.randomUUID(),
        reportDate: dt.analysisDate,
        Month: dt.analysisDate,
        analysisDate: dt.analysisDate,
      };
    }),
  };
};

export const convertEcrRateToBps = (bps) => {
  if (!bps) {
    return '';
  }
  if (bps < 1) {
    return bps * 100;
  }
  return bps;
};

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import routes from '../utils/routes.json';

import { TabsContext } from '../contexts/tabsContext';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import Organizations from './Organizations';
import PageTitle from '../components/commons/PageTitle';
import { PermissionsConstants } from '../utils/permissions.constants';
import { isModuleAllowed } from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';
import Contacts from './Contacts';
import useIsTenant from '../hooks/useIsTenant';
import organizationService from '../services/organization.service';
import { useProfileContext } from '../contexts/profileContext';
import contactService from '../services/contact.service';
import { usePagesContext } from '../contexts/pagesContext';
import MenuAccordion from '../components/commons/MenuAccordion';
import { Form } from 'react-bootstrap';
import RecentlyViewedList from '../components/commons/RecentlyViewedList';

const MY_KEY = 'My';
const ALL_KEY = 'All';

const companiesOwnershipOptions = [
  {
    id: 2,
    key: 'MyOrganization',
    value: MY_KEY,
    name: `My ${useIsTenant().isSynovusBank ? 'Insights' : 'Companies'}`,
  },
  {
    id: 1,
    key: 'AllOrganizations',
    value: ALL_KEY,
    name: `All ${useIsTenant().isSynovusBank ? 'Insights' : 'Companies'}`,
  },
];

const contactsOwnershipOptions = [
  { id: 2, key: 'MyContacts', value: MY_KEY, name: 'My Contacts' },
  { id: 1, key: 'AllContacts', value: ALL_KEY, name: 'All Contacts' },
];

const CAccountsTabs = {
  Companies: 1,
  Contacts: 2,
};

const CAccounts = () => {
  const history = useHistory();
  const location = useLocation();
  const { tenant } = useTenantContext();
  const { profileInfo } = useProfileContext();
  const { pageContext, setPageContext } = usePagesContext();

  const tabsData = [
    {
      title: 'Companies',
      tabId: CAccountsTabs.Companies,
      byModule: PermissionsConstants.CAccounts.Companies,
    },
    {
      title: 'Contacts',
      tabId: CAccountsTabs.Contacts,
      byModule: PermissionsConstants.CAccounts.Contacts,
    },
  ];

  const { activatedTab, setActivatedTab } = useContext(TabsContext);
  const [activeTab, setActiveTab] = useState(
    tabsData?.length ? tabsData[0] : {}
  );

  const [ownershipOptions, setOwnershipOptions] = useState(
    companiesOwnershipOptions
  );
  const [selectedOwnershipCompanies, setSelectedOwnershipCompanies] = useState(
    pageContext?.CAccountsPage?.ownershipCompanies ?? MY_KEY
  );
  const [selectedOwnershipContacts, setSelectedOwnershipContacts] = useState(
    pageContext?.CAccountsPage?.ownershipContacts ?? MY_KEY
  );
  const [recentList, setRecentList] = useState({});
  const [recentFetched, setRecentFetched] = useState({});
  const [recentLoading, setRecentLoading] = useState(true);
  const [isViewMore, setIsViewMore] = useState(false);

  const [count, setCount] = useState({
    MyOrganization: 0,
    AllOrganizations: 0,
    MyContacts: 0,
    AllContacts: 0,
  });

  const toggle = (tab) => {
    if (activeTab?.tabId !== tab.tabId) {
      setActiveTab(tab);
      history.replace({ search: '' });
      setActivatedTab({
        CAccountsPage: { tab },
      });

      // Update ownership options and reset selected ownership
      if (tab.tabId === CAccountsTabs.Companies) {
        setOwnershipOptions(companiesOwnershipOptions);
      } else if (tab.tabId === CAccountsTabs.Contacts) {
        setOwnershipOptions(contactsOwnershipOptions);
      }
    }
  };

  useEffect(() => {
    if (tenant?.id) {
      const isCompaniesAllowed = isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.CAccounts.Companies
      );
      const isContactsAllowed = isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.CAccounts.Contacts
      );

      if (isCompaniesAllowed) {
        setActiveTab(tabsData[0]);
        setOwnershipOptions(companiesOwnershipOptions);
      } else if (isContactsAllowed) {
        setActiveTab(tabsData[1]);
        setOwnershipOptions(contactsOwnershipOptions);
      } else {
        setActiveTab({});
        setOwnershipOptions([]);
      }
    }
  }, [tenant]);

  useEffect(() => {
    if (location.hash === `#ownership=${MY_KEY}`) {
      setOwnershipOptions(companiesOwnershipOptions);
      setSelectedOwnershipCompanies(MY_KEY);
      setSelectedOwnershipContacts(MY_KEY);
    } else if (location.hash === `#ownership=${ALL_KEY}`) {
      setOwnershipOptions(companiesOwnershipOptions);
      setSelectedOwnershipCompanies(ALL_KEY);
      setSelectedOwnershipContacts(ALL_KEY);
    } else if (location.hash === '#Contacts') {
      setActiveTab(tabsData[1]);
      setOwnershipOptions(contactsOwnershipOptions);
      setSelectedOwnershipContacts(MY_KEY);
    } else if (location.hash === '#Companies') {
      setActiveTab(tabsData[0]);
      setOwnershipOptions(companiesOwnershipOptions);
      setSelectedOwnershipCompanies(MY_KEY);
    } else {
      const savedTab = activatedTab?.CAccountsPage?.tab;
      if (savedTab) {
        setActiveTab(savedTab);
        setOwnershipOptions(
          savedTab.tabId === CAccountsTabs.Companies
            ? companiesOwnershipOptions
            : savedTab.tabId === CAccountsTabs.Contacts
            ? contactsOwnershipOptions
            : []
        );
      }
    }
  }, [location]);

  const getCounts = async () => {
    const filter = { filter: {} };
    const ownerFilter = {
      filter: { assigned_user_id: profileInfo?.id },
    };
    const pagination = { page: 1, limit: 1 };

    const countRequests = [
      {
        key: 'AllOrganizations',
        apiCall: () =>
          organizationService.getOrganizations({ ...filter }, pagination),
      },
      {
        key: 'MyOrganization',
        apiCall: () =>
          organizationService.getOrganizations({ ...ownerFilter }, pagination),
      },
      {
        key: 'AllContacts',
        apiCall: () => contactService.getContacts({ ...filter }, pagination),
      },
      {
        key: 'MyContacts',
        apiCall: () =>
          contactService.getContacts({ ...ownerFilter }, pagination),
      },
    ];

    try {
      const responses = await Promise.allSettled(
        countRequests.map((request) => request.apiCall())
      );

      const updatedCounts = {};

      countRequests.forEach((request, index) => {
        if (responses[index].status === 'fulfilled') {
          const responseValue = responses[index].value?.data;
          updatedCounts[request.key] = responseValue?.pagination?.count || 0;
        } else {
          updatedCounts[request.key] = 0;
        }
      });
      setCount(updatedCounts);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      getCounts();
    }
  }, [profileInfo?.id]);

  const getRecents = async (tabId, ownership) => {
    const isOrganization = tabId === CAccountsTabs.Companies;
    const recentKey = isOrganization
      ? ownership === MY_KEY
        ? 'MyOrganization'
        : 'AllOrganizations'
      : ownership === MY_KEY
      ? 'MyContacts'
      : 'AllContacts';

    // Avoid redundant API calls
    if (recentFetched[recentKey]) return;
    setRecentLoading(true);

    const filter = { order: ['lastActivityDate', 'desc'] };
    const ownerFilter = {
      filter: {
        assigned_user_id: profileInfo?.id,
        self: true,
      },
      order: ['lastActivityDate', 'desc'],
    };
    const pagination = { page: 1, limit: 5 };

    const apiCall = isOrganization
      ? ownership === MY_KEY
        ? organizationService.getOrganizations({ ...ownerFilter }, pagination)
        : organizationService.getOrganizations({ ...filter }, pagination)
      : ownership === MY_KEY
      ? contactService.getContacts({ ...ownerFilter }, pagination)
      : contactService.getContacts({ ...filter }, pagination);

    try {
      const { data } = await apiCall;
      setRecentList((prev) => ({ ...prev, [recentKey]: data }));
      setRecentFetched((prev) => ({ ...prev, [recentKey]: true }));
    } catch (error) {
      console.error('Error fetching recent data:', error);
    } finally {
      setRecentLoading(false);
    }
  };

  useEffect(() => {
    const isOrganization = activeTab.tabId === CAccountsTabs.Companies;
    const ownership = isOrganization
      ? selectedOwnershipCompanies
      : selectedOwnershipContacts;

    if (profileInfo?.id) {
      getRecents(activeTab.tabId, ownership);
    }
  }, [
    profileInfo?.id,
    activeTab.tabId,
    selectedOwnershipCompanies,
    selectedOwnershipContacts,
  ]);

  const getStats = (type) => {
    let stats = [];
    switch (type) {
      case CAccountsTabs.Companies:
        stats = [
          {
            id: 1,
            label: `All ${
              useIsTenant().isSynovusBank ? 'Insights' : 'Companies'
            }`,
            count: count.AllOrganizations,
            order: 2,
          },
          {
            id: 2,
            label: `My ${
              useIsTenant().isSynovusBank ? 'Insights' : 'Companies'
            }`,
            count: count.MyOrganization,
            order: 1,
          },
        ];
        break;
      case CAccountsTabs.Contacts:
        stats = [
          {
            id: 1,
            label: 'All Contacts',
            count: count.AllContacts,
            order: 2,
          },
          {
            id: 2,
            label: 'My Contacts',
            count: count.MyContacts,
            order: 1,
          },
        ];
        break;
    }
    return stats;
  };

  useEffect(() => {
    if (activeTab.tabId === CAccountsTabs.Contacts) {
      if (location.hash !== '#contacts') {
        history.push(`${routes.caccounts}#contacts`);
      }
    } else if (location.hash === '#contacts') {
      history.push(`${routes.caccounts}`);
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab.tabId === CAccountsTabs.Companies) {
      setPageContext({
        ...pageContext,
        CAccountsPage: {
          ...pageContext.CAccountsPage,
          ownershipCompanies: selectedOwnershipCompanies,
        },
      });
    } else {
      setPageContext({
        ...pageContext,
        CAccountsPage: {
          ...pageContext.CAccountsPage,
          ownershipContacts: selectedOwnershipContacts,
        },
      });
    }
  }, [selectedOwnershipCompanies, selectedOwnershipContacts]);

  const handleOwnershipSelect = (e, item) => {
    e.preventDefault();
    if (activeTab.tabId === CAccountsTabs.Companies) {
      setSelectedOwnershipCompanies(item.value);
    } else {
      setSelectedOwnershipContacts(item.value);
    }
  };

  const renderOwnershipOptions = () => {
    return (
      <div className="d-flex flex-column gap-2 mx-3 py-2">
        {ownershipOptions?.map((item) => (
          <Form.Check
            key={item.key}
            id={item.key}
            inline
            label={item.name}
            name={item.key}
            className={`large-radio fs-7`}
            type="radio"
            checked={
              activeTab.tabId === CAccountsTabs.Companies
                ? selectedOwnershipCompanies === item.value
                : selectedOwnershipContacts === item.value
            }
            onChange={(e) => handleOwnershipSelect(e, item)}
          />
        ))}
      </div>
    );
  };

  const renderRecentlyViewed = () => {
    const isOrganization = activeTab.tabId === CAccountsTabs.Companies;
    const recentKey = isOrganization
      ? selectedOwnershipCompanies === MY_KEY
        ? 'MyOrganization'
        : 'AllOrganizations'
      : selectedOwnershipContacts === MY_KEY
      ? 'MyContacts'
      : 'AllContacts';

    const updatedRecentList = recentList[recentKey];

    const handleClick = (item) => {
      const baseRoute = isOrganization
        ? useIsTenant().isSynovusBank
          ? `${routes.insightsCompanies}/${item?.id}/organization/profile`
          : `${routes.companies}/${item?.id}/organization/profile`
        : `${routes.contacts}/${item?.id}/profile`;

      history.push(baseRoute);
    };

    return (
      <RecentlyViewedList
        list={updatedRecentList}
        type={isOrganization ? 'organization' : 'contact'}
        avatarSize={isOrganization ? 'font-size-lg' : ''}
        onClick={handleClick}
        loading={recentLoading}
        onMoreClick={() => setIsViewMore(true)}
      />
    );
  };

  const sidebarOptions = [
    {
      id: 1,
      label: 'Ownership',
      icon: 'supervisor_account',
      component: renderOwnershipOptions(),
    },
    {
      id: 2,
      label: 'Recently Modified',
      icon: 'history',
      component: renderRecentlyViewed(),
    },
  ];

  return (
    <>
      <PageTitle page={activeTab?.title} />

      <div className="custom-layout accounts-layout">
        <div className="custom-layout-sidebar overflow-x-hidden">
          <div className="d-flex flex-column gap-3 p-3 transparent-scroll-track">
            <MenuAccordion options={sidebarOptions} defaultCollapsedIds={[2]} />
          </div>
        </div>

        <div className="custom-layout-content overflow-hidden bg-white">
          <div className="mb-2">
            <AnimatedTabs
              tabsData={tabsData}
              activeTab={activeTab?.tabId}
              tabClasses="px-3"
              toggle={toggle}
              permissionCheck={true}
              borderClasses="border-bottom"
            />
          </div>
          <div className="accounts-tabs">
            <TabContent>
              <TabPane>
                {activeTab?.tabId === CAccountsTabs.Companies && (
                  <Organizations
                    selectedOwnership={selectedOwnershipCompanies}
                    setSelectedOwnership={setSelectedOwnershipCompanies}
                    stats={getStats(CAccountsTabs.Companies)}
                    isViewMore={isViewMore}
                    setIsViewMore={setIsViewMore}
                  />
                )}
                {activeTab?.tabId === CAccountsTabs.Contacts && (
                  <Contacts
                    selectedOwnership={selectedOwnershipContacts}
                    setSelectedOwnership={setSelectedOwnershipContacts}
                    stats={getStats(CAccountsTabs.Contacts)}
                    isViewMore={isViewMore}
                    setIsViewMore={setIsViewMore}
                  />
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default CAccounts;

import { PdfFileNames } from './reports.helper.functions';
import { ReportTileStyles, ReportTypes } from './reports.constants';
import MaterialIcon from '../commons/MaterialIcon';
import React from 'react';

const ReportModalTitle = ({ type }) => {
  const GenerateTitle = {
    [ReportTypes.AccountStructure]: 'Generate',
  };
  return (
    <div className="d-flex gap-1 align-items-center">
      <div
        className={`${ReportTileStyles[type].styleClass} p-2 rounded-circle`}
      >
        <MaterialIcon icon={ReportTileStyles[type].icon} size="fs-5 d-flex" />
      </div>
      <h3 className="mb-0">
        {GenerateTitle[type] || ''} {PdfFileNames[type]} Report
      </h3>
    </div>
  );
};

export default ReportModalTitle;

import {
  Card as CardBt,
  Row,
  Col,
  Form,
  Container,
  Button,
} from 'react-bootstrap';
import { Spinner } from 'reactstrap';

import './layout.css';
import MaterialIcon from '../commons/MaterialIcon';
import TooltipComponent from '../lesson/Tooltip';
import {
  DemoTenantsKeys,
  RiskColorCoverage,
  TreasuryReportSections,
} from '../reports/reports.constants';
import useIsTenant from '../../hooks/useIsTenant';
import React from 'react';
import ColorSelectionDropdown from '../commons/ColorSelectionDropdown';

export const Card = ({ children }) => {
  return <CardBt>{children}</CardBt>;
};

export const CardHeader = ({ children }) => {
  return (
    <CardBt.Header className="p-3 sticky-header">{children}</CardBt.Header>
  );
};

export const CardForm = ({ children, wrapInContainer = true }) => {
  return (
    <>
      {wrapInContainer ? (
        <Container>
          <Form>{children}</Form>
        </Container>
      ) : (
        <Form>{children}</Form>
      )}
    </>
  );
};

export const CardSection = ({ children, endLine, className }) => {
  const endLineClass = endLine ? ' border-bottom' : '';

  return (
    <Row className={`${className || 'px-3 mx-0'}${endLineClass}`}>
      {children}
    </Row>
  );
};

export const CardBlock = ({ children }) => {
  return <Col className="p-1">{children}</Col>;
};

export const CardContent = ({ children }) => {
  return (
    <Col className="pl-0 pr-0 pr-md-3 mt-3" sm={12} md={8}>
      {children}
    </Col>
  );
};

export const CardSide = ({ children }) => {
  return (
    <Col
      className="pl-0 pl-md-3 pr-0 mt-3 mb-4 border-md-left custom-switch-setting"
      sm={12}
      md={4}
    >
      {children}
    </Col>
  );
};

// Custom Columns
export const CardContentCustom = ({ children, className }) => {
  return (
    <Col className={`pl-0 pr-0 pr-md-3 pt-3 ${className}`} sm={12} md={5}>
      {children}
    </Col>
  );
};

export const CardSideCustom = ({ children, className }) => {
  return (
    <Col
      className={`pl-0 pl-md-3 pr-0 pt-3 border-md-left custom-switch-setting ${className}`}
      sm={12}
      md={7}
    >
      {children}
    </Col>
  );
};

export const CardTitle = ({ children, className }) => {
  return (
    <CardBt.Title as="h3" className={`p-1 ${className}`}>
      {children}
    </CardBt.Title>
  );
};

export const CardSubtitle = ({ children, endLine, className }) => {
  const endLineClass = endLine ? ' border-bottom' : '';

  return (
    <CardBt.Title as="h4" className={`mx-1 pb-3${endLineClass} ${className}`}>
      {children}
    </CardBt.Title>
  );
};

export const CardSubContent = ({ children, classname }) => {
  return <div className={`${classname} mx-1 pt-3 pb-6`}>{children}</div>;
};

export const TextInput = ({
  onChange,
  value,
  name,
  placeholder,
  label,
  classNameLabel,
}) => {
  return (
    <Form.Group as={Row} className="my-2">
      <Form.Label className={`label-mw ${classNameLabel}`} column xs>
        <h5>{label}</h5>
      </Form.Label>
      <Col xs>
        <Form.Control
          type="text"
          onChange={onChange}
          value={value}
          id={name}
          name={name}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const List = ({ children, className }) => {
  return (
    <Col xs={12} className={`px-0 mt-3 ${className}`}>
      {children}
    </Col>
  );
};

export const Item = ({ children, onClick }) => {
  return (
    <div className="p-2 item-btn rounded w-100" onClick={onClick}>
      <Row className="item-container" noGutters>
        {children}
      </Row>
    </div>
  );
};

export const ItemAvatar = ({ children }) => {
  return <Col className="item-avatar rounded-circle">{children}</Col>;
};

export const ItemUser = (itemUserProps) => {
  return (
    <Col id={itemUserProps.id} className="item-user mx-3 col-md-9">
      <h5 className="mb-0">{itemUserProps.name}</h5>
      <TooltipComponent title={itemUserProps.email}>
        <p className="m-0 text-truncate w-90">{itemUserProps.email}</p>
      </TooltipComponent>
    </Col>
  );
};

export const ItemActions = ({ children }) => {
  return <Col className="item-actions text-right">{children}</Col>;
};

export const CardButton = ({
  variant,
  disabled,
  onClick,
  title,
  isLoading,
  className,
  block,
  icon,
  id,
  type = 'button',
  style,
}) => {
  const btnClass =
    title === 'Save' ? `${className} min-w-100` : className || '';
  return (
    <Button
      id={id}
      className={btnClass}
      type={type}
      block={block || false}
      variant={variant || ''}
      onClick={onClick}
      disabled={disabled || false}
      style={style}
    >
      {isLoading ? (
        <Spinner className="spinner-grow-xs" />
      ) : (
        <>
          <span className="material-symbols-rounded fs-6">{icon}</span> {title}
        </>
      )}
    </Button>
  );
};

export const SwitchInput = ({
  id,
  checked,
  onChange,
  onClick,
  classnames,
  label,
  disabled = false,
}) => {
  return (
    <div className="custom-control custom-switch py-1">
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
      />
      <label className={`${classnames} custom-control-label`} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export const SwitchInputWithEditableControls = ({
  id,
  checked,
  onChange,
  onClick,
  label,
  entry,
  section,
  disabled = false,
  controls,
  selectedTenant,
  placeholder = '0',
  itemsLength = 0,
  placeHolderText = '',
}) => {
  const { isSVB } = useIsTenant();
  const achLabel =
    (isSVB || selectedTenant?.key === DemoTenantsKeys.svb) &&
    section === TreasuryReportSections.Fraud &&
    label === 'ACH Positive Pay'
      ? 'ACH Filter'
      : label;
  return (
    <div className="d-flex align-items-center gap-1 flex-fill hover-actions">
      <div className="custom-control w-100 custom-switch py-1">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
        {controls ? (
          <>
            <label
              className={`custom-control-label with-input ${
                controls?.onRemove &&
                section === TreasuryReportSections.Overview
                  ? 'w-250px'
                  : section === TreasuryReportSections.Fraud
                  ? 'w-100'
                  : 'w-90'
              }`}
              htmlFor={id}
            >
              <input
                type="text"
                className="form-control w-100"
                maxLength={controls.maxLength}
                onChange={controls.onChange}
                value={achLabel}
                placeholder={placeHolderText}
                readOnly={
                  section === TreasuryReportSections.Overview &&
                  !entry[1]?.isEdited
                }
              />
            </label>
            {section === TreasuryReportSections.Overview && (
              <div className="d-inline-block ml-2">
                <input
                  type="text"
                  style={{ width: 115 }}
                  className="form-control"
                  placeholder={placeholder}
                  maxLength={controls.maxLength}
                  onChange={controls.onChangeNumber}
                  value={entry[1]?.itemValue === 0 ? '' : entry[1]?.itemValue}
                />
              </div>
            )}
            {controls.onRemove && (
              <>
                {section === TreasuryReportSections.Overview &&
                itemsLength <= 1 ? (
                  <>&nbsp;</>
                ) : (
                  <TooltipComponent title="Remove">
                    <a
                      className="position-absolute cursor-pointer icon-hover-bg right-0 abs-center-y"
                      onClick={controls.onRemove}
                    >
                      <MaterialIcon icon="delete" />{' '}
                    </a>
                  </TooltipComponent>
                )}
              </>
            )}
          </>
        ) : (
          <label className="custom-control-label" htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export const ColorCoverageWithEditableControls = ({
  id,
  onChange,
  label,
  entry,
  section,
  disabled = false,
  controls,
  selectedTenant,
  placeHolderText = '',
}) => {
  const ColorOptions = Object.entries(RiskColorCoverage).map(
    ([key, value]) => ({
      key,
      name: key,
      ...value,
    })
  );
  const { isSVB } = useIsTenant();
  const achLabel =
    (isSVB || selectedTenant?.key === DemoTenantsKeys.svb) &&
    section === TreasuryReportSections.Fraud &&
    label === 'ACH Positive Pay'
      ? 'ACH Filter'
      : label;
  return (
    <div className="d-flex align-items-center gap-1 flex-fill hover-actions">
      <div className="w-100 gap-1 d-flex py-1">
        <ColorSelectionDropdown
          id={id}
          disabled={disabled}
          showOnlyIcon
          btnToggleStyle="w-100 no-caret"
          menuClass="w-100"
          ignoreChildHover="w-100"
          options={ColorOptions}
          filterOptionSelected={entry}
          handleFilterSelect={(e, item) => {
            onChange({
              target: {
                value: item,
              },
            });
          }}
        />
        <div className="flex-1">
          <input
            type="text"
            className="form-control w-100"
            maxLength={controls.maxLength}
            onChange={controls.onChange}
            value={achLabel}
            placeholder={placeHolderText}
          />
        </div>
      </div>
    </div>
  );
};

export const CheckboxInput = ({
  id,
  checked,
  onChange,
  onClick,
  fieldState,
  validationConfig,
  label,
  disabled,
  name,
  ...rest
}) => {
  return (
    <div className="custom-control custom-checkbox py-1">
      <input
        type="checkbox"
        className={`custom-control-input ${
          validationConfig?.required ? 'border-left-4 border-left-danger' : ''
        } ${
          fieldState?.invalid && !fieldState?.error?.ref?.value
            ? 'border-danger'
            : ''
        } `}
        id={id}
        {...rest}
        checked={checked}
        onChange={onChange}
        name={name}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

import React, { useCallback, useContext, useMemo, useState } from 'react';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { AlertMessageContext } from '../../../contexts/AlertMessageContext';
import ReportService from '../../../services/report.service';
import { cleanDataWidgets, overflowing } from '../../../utils/Utils';
import _ from 'lodash';
import {
  ActionTypes,
  EngagementReportConfig,
  FraudReportSections,
  ReportTypes,
} from '../../../components/reports/reports.constants';
import {
  getReportName,
  updateJsonObject,
} from '../../../components/reports/reports.helper.functions';
import MetricsService from '../../../services/metrics.service';
import moment from 'moment/moment';
import {
  DisclaimerData,
  ElectronicPaymentsSavingsData,
  ElectronicPaymentsToGrowthData,
  FraudPreventData,
  FraudSolutionToEmpowerData,
  PCData,
  PFData,
  PFIBData,
  SimplifyingFraudData,
  WidgetTypes,
} from '../../../components/reportbuilder/constants/widgetsConstants';

const PageConfig = EngagementReportConfig[ReportTypes.Fraud].PageConfig;

const DefaultRisksItems = {
  'Positive Pay': 0,
  'ACH Positive Pay': 0,
  'Payee Match': 0,
};
const SyncFraudReportModal = ({
  show,
  setShow,
  report,
  organization,
  handleGenerateReport,
  setParentModalShow,
}) => {
  const { setErrorMessage } = useContext(AlertMessageContext);
  const [refreshing, setRefreshing] = useState(false);
  const getDefaultReportPages = () => {
    return _.cloneDeep(PageConfig);
  };
  const disclaimerData = DisclaimerData;
  const defaultPreventionProducts = updateJsonObject(DefaultRisksItems);

  const getDefaultWidgets = (reportObject) => {
    return {
      [FraudReportSections.FraudWhyItMatters]: [
        {
          id: 1,
          widgetConfig: PFIBData,
          type: WidgetTypes.PFIB,
        },
        {
          id: 2,
          widgetConfig: {
            data: reportObject || {
              fraudPreventionProducts: defaultPreventionProducts,
            },
          },
          type: WidgetTypes.FBAR,
        },
        {
          id: 3,
          widgetConfig: PFData,
          type: WidgetTypes.PF,
        },
      ],
      [FraudReportSections.WhatCanYouDo]: [
        {
          id: 1,
          widgetConfig: FraudPreventData,
          type: WidgetTypes.BEC,
        },
        {
          id: 2,
          widgetConfig: FraudSolutionToEmpowerData,
          type: WidgetTypes.FSE,
        },
        {
          id: 3,
          widgetConfig: SimplifyingFraudData,
          type: WidgetTypes.QRCode,
        },
      ],
      [FraudReportSections.PrioritizeElectronicPayments]: [
        {
          id: 1,
          widgetConfig: ElectronicPaymentsToGrowthData,
          type: WidgetTypes.EPG,
        },
        {
          id: 2,
          widgetConfig: ElectronicPaymentsSavingsData,
          type: WidgetTypes.EPS,
        },
        {
          id: 3,
          widgetConfig: PCData,
          type: WidgetTypes.PC,
        },
      ],
      [FraudReportSections.Disclaimer]: [
        {
          id: 1,
          widgetConfig: { data: disclaimerData },
          type: WidgetTypes.DISC,
        },
      ],
    };
  };

  const updateManualReportFromCoreData = (metrics) => {
    const paymentRisksValueCheck =
      metrics.services.positivePay.check.overallCoverage;
    const paymentRisksValueAch =
      metrics.services.positivePay.ach.overallCoverage;
    const paymentRisksValuePayeeMatch =
      metrics.services.positivePay.check.addOn.payeeMatch.overallCoverage;
    const paymentRisks = {
      balancesAtRisk:
        paymentRisksValueCheck !== 'none' && paymentRisksValueAch !== 'none',
      multipleAccounts: metrics.isMultiAccountAggregation,
      balance: metrics.accounts.source.bank.balance,
      fraudPreventionProducts: updateJsonObject({
        'Positive Pay': paymentRisksValueCheck,
        'ACH Positive Pay': paymentRisksValueAch,
        'Payee Match': paymentRisksValuePayeeMatch,
      }),
      companyName: organization.name,
      name: organization.name,
      reportDate: metrics.analysisDate,
      date: metrics.analysisDate,
      coreData: {
        reportDate: metrics.analysisDate,
      },
    };

    const reportMapped = {
      ...paymentRisks,
    };

    const defaultWidgets = getDefaultWidgets(reportMapped);
    const defaultPages = getDefaultReportPages();

    return {
      name: organization.name,
      date: metrics.analysisDate,
      type: ReportTypes.Fraud,
      manualInput: {
        ...reportMapped,
        widgets: cleanDataWidgets(defaultWidgets),
        reportPages: defaultPages,
        isAuto: true, // track whether this was generated by core
      },
    };
  };

  const handleConfirmDeleteReport = async () => {
    try {
      setRefreshing(true);
      const coreData = report?.manualInput?.coreData ?? report.coreData;
      const rptDate = coreData?.reportDate?.split('T')[0];
      const coreDataReports =
        await MetricsService.getOrganizationTreasuryMetricsData(
          organization.id,
          {
            date: moment(rptDate).format('YYYY-MM'),
          }
        );
      if (coreDataReports?.data?.length) {
        const newCoreData = updateManualReportFromCoreData(
          coreDataReports.data[0]
        );
        await ReportService.updateReport(report.reportId, newCoreData);
        overflowing();
        setParentModalShow(false);
        handleGenerateReport(report, ActionTypes.UPDATE, {
          ...newCoreData,
          reportId: report.reportId,
          key: report.reportId,
        });
      } else {
        setErrorMessage(
          "Nothing to be synced. You're already viewing latest data."
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <DeleteConfirmationModal
      showModal={show}
      loader={refreshing}
      setShowModal={setShow}
      customEvent={handleConfirmDeleteReport}
      itemsConfirmation={[{ ...report, title: getReportName(report) }]}
      heading="Refresh Report"
      description="Are you sure you want to refresh this Report?"
      positiveBtnText="Refresh"
    />
  );
};

const useResyncFraudReportModal = (
  handleGenerateReport,
  setParentModalShow
) => {
  const [showModal, setShowModal] = useState(false);
  const [report, setReport] = useState('');
  const [organization, setOrganization] = useState('');

  const SyncFraudReportModalCallback = useCallback(() => {
    return (
      <SyncFraudReportModal
        show={showModal}
        setShow={setShowModal}
        report={report}
        organization={organization}
        handleGenerateReport={handleGenerateReport}
        setParentModalShow={setParentModalShow}
      />
    );
  }, [
    showModal,
    setShowModal,
    report,
    setReport,
    organization,
    setOrganization,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      setReport,
      setOrganization,
      SyncFraudReportModal: showModal
        ? SyncFraudReportModalCallback
        : () => <div />,
    }),
    [setShowModal, SyncFraudReportModalCallback]
  );
};

export default useResyncFraudReportModal;

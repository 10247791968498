import React, { useState } from 'react';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import { TabContent, TabPane } from 'reactstrap';
import PageTitle from '../components/commons/PageTitle';
import SiteSettingsForm from '../components/siteSettings/SiteSettingsForm';
import SiteEmailSettings from '../components/siteSettings/SiteEmailSettings';

const tabsData = [
  {
    title: 'Organization Details',
    tabId: '1',
  },
  {
    title: 'Email',
    tabId: '2',
  },
];

const SiteSettings = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [selectedPageTitle, setSelectedPageTitle] = useState(tabsData[0].title);

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
      setSelectedPageTitle(tabsData.find((t) => t.tabId === tab.tabId)?.title);
    }
  };

  return (
    <>
      <PageTitle page={selectedPageTitle} />

      <AnimatedTabs
        tabsData={tabsData}
        activeTab={activeTab}
        tabClasses="px-4 pt-1"
        borderClasses="border-bottom"
        toggle={toggle}
      />

      <TabContent activeTab={activeTab}>
        <TabPane className="position-relative" tabId={'1'}>
          <SiteSettingsForm />
        </TabPane>
        <TabPane className="position-relative" tabId={'2'}>
          <SiteEmailSettings />
        </TabPane>
      </TabContent>
    </>
  );
};

export default SiteSettings;

import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import React, { useEffect, useState } from 'react';
import {
  ActionTypes,
  EngagementReportConfig,
  FraudReportSections,
  ReportTypes,
} from '../../reports/reports.constants';
import _ from 'lodash';
import useWidgetsLibrary from '../../reportbuilder/widgets/useWidgetsLibrary';
import {
  DisclaimerData,
  DisclaimerDataFFB,
  ElectronicPaymentsSavingsData,
  ElectronicPaymentsToGrowthData,
  FraudPreventData,
  FraudSolutionToEmpowerData,
  PCData,
  PFData,
  PFIBData,
  SimplifyingFraudData,
  WidgetTypes,
} from '../../reportbuilder/constants/widgetsConstants';
import ReportDragDrop from '../../reports/ReportDragDrop';
import NoDataFound from '../../commons/NoDataFound';
import ReportBlocksSkeleton from '../../loaders/ReportBlocksSkeleton';
import ReportPDFWrapper from '../../reportbuilder/ReportPDFWrapper';
import ReportCover from '../../reports/ReportCover';
import ReportPages from '../../reportbuilder/ReportPages';
import { ListGroup } from 'reactstrap';
import WidgetWithActionButtons from '../../reportbuilder/widgets/WidgetWithActionButtons';
import useIsTenant from '../../../hooks/useIsTenant';
import ReportService from '../../../services/report.service';
import {
  cleanDataWidgets,
  extractReportId,
  getNAICSWithIndustry,
  getNaicsWithTitle,
  overflowing,
} from '../../../utils/Utils';
import ReportAction from '../../reports/ReportAction';
import useGenerateFraudReportModal from '../../../hooks/reports/modals/useGenerateFraudReportModal';
import {
  getCycleDate,
  getReportName,
  updateJsonObject,
} from '../../reports/reports.helper.functions';
import ReportDropdownItem from '../../reports/ReportDropdownItem';
import moment from 'moment/moment';
import { useProfileContext } from '../../../contexts/profileContext';
import {
  NO_REPORTS_AVAILABLE,
  NO_REPORTS_AVAILABLE_ICON,
} from '../../../utils/constants';
import useHash from '../../../hooks/useHash';
import OrganizationService from '../../../services/organization.service';
import MetricsService from '../../../services/metrics.service';
const DOWNLOAD_OPTIONS = [
  {
    id: 1,
    icon: 'picture_as_pdf',
    key: 'downloadAsPdf',
    name: 'PDF Download',
  },
];
const PageConfig = EngagementReportConfig[ReportTypes.Fraud].PageConfig;

const FraudReportSectionKeys =
  EngagementReportConfig[ReportTypes.Fraud].SectionKeys;

const MapReportDropdownItem = (rpt, index) => {
  const rptObject = rpt.input || rpt.manualInput;
  return {
    key: rpt.reportId || index,
    reportId: rpt.reportId || rptObject?.coreData?.report_id,
    customElement: (
      <ReportDropdownItem item={rptObject} showClickToCreate={!rpt.reportId} />
    ),
    name: getReportName(rptObject),
    isManual: !!rpt.manualInput,
    createdById: rpt.createdById || rptObject?.coreData?.tenant_id,
    createdAt: rpt.createdAt || rpt.date,
    updatedAt: rpt.updatedAt || rpt.date,
    prettyDate: moment(rptObject.reportDate).format('MMMM YYYY'),
    type: ReportTypes.Fraud,
    isAuto: rptObject?.isAuto,
    isGenerated: rpt.reportId !== undefined,
  };
};

const hasCoreDataReportCreated = (coreDataReport, oldReports) => {
  // since we are currently looking by month/year so...
  const dateFormat = 'MMMM YYYY';
  const analysisDate = moment(coreDataReport.analysisDate.split('T')[0]).format(
    dateFormat
  );
  const foundReport = oldReports.find((rpt) => {
    const rptDate = moment(rpt.date).format(dateFormat);
    return analysisDate === rptDate;
  });
  return foundReport !== undefined;
};

const DefaultRisksItems = {
  'Positive Pay': 0,
  'ACH Positive Pay': 0,
  'Payee Match': 0,
};
const FraudReport = ({
  organization,
  readOnly,
  selectedTenant,
  currentTab,
  getProfileInfo,
}) => {
  const { hash, updateHash } = useHash();
  const { profileInfo } = useProfileContext();
  const [selectedRpt, setSelectedRpt] = useState({});
  const { isFFB } = useIsTenant();
  const [loadingReport, setLoadingReport] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingPastReports, setLoadingPastReports] = useState(false);
  const [otherOldReports, setOtherOldReports] = useState([]); // reports other then the latest report

  const disclaimerData = isFFB ? DisclaimerDataFFB : DisclaimerData;
  const getDefaultReportPages = () => {
    return _.cloneDeep(PageConfig);
  };
  const defaultPreventionProducts = updateJsonObject(DefaultRisksItems);

  const defaultDisclaimerWidget = {
    [FraudReportSections.Disclaimer]: [
      {
        id: 1,
        widgetConfig: { data: disclaimerData },
        type: WidgetTypes.DISC,
      },
    ],
  };

  const getDefaultWidgets = (reportObject) => {
    return {
      [FraudReportSections.FraudWhyItMatters]: [
        {
          id: 1,
          widgetConfig: PFIBData,
          type: WidgetTypes.PFIB,
        },
        {
          id: 2,
          widgetConfig: {
            data: reportObject || {
              fraudPreventionProducts: defaultPreventionProducts,
            },
          },
          type: WidgetTypes.FBAR,
        },
        {
          id: 3,
          widgetConfig: PFData,
          type: WidgetTypes.PF,
        },
      ],
      [FraudReportSections.WhatCanYouDo]: [
        {
          id: 1,
          widgetConfig: FraudPreventData,
          type: WidgetTypes.BEC,
        },
        {
          id: 2,
          widgetConfig: FraudSolutionToEmpowerData,
          type: WidgetTypes.FSE,
        },
        {
          id: 3,
          widgetConfig: SimplifyingFraudData,
          type: WidgetTypes.QRCode,
        },
      ],
      [FraudReportSections.PrioritizeElectronicPayments]: [
        {
          id: 1,
          widgetConfig: ElectronicPaymentsToGrowthData,
          type: WidgetTypes.EPG,
        },
        {
          id: 2,
          widgetConfig: ElectronicPaymentsSavingsData,
          type: WidgetTypes.EPS,
        },
        {
          id: 3,
          widgetConfig: PCData,
          type: WidgetTypes.PC,
        },
      ],
      [FraudReportSections.Disclaimer]: [
        {
          id: 1,
          widgetConfig: { data: disclaimerData },
          type: WidgetTypes.DISC,
        },
      ],
    };
  };

  const [widgets, setWidgets] = useState(getDefaultWidgets());
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const [reportPages, setReportPages] = useState(getDefaultReportPages());
  const [rptGenerated, setRptGenerated] = useState(false);
  const [report, setReport] = useState({
    fraudPreventionProducts: defaultPreventionProducts,
  });
  const [startDownload, setStartDownload] = useState(false);
  const [, setOpenGenerateReport] = useState(false);
  const { isExcelBank } = useIsTenant();
  const excelBankMode = isExcelBank;
  const widgetsSections = {
    [FraudReportSections.FraudWhyItMatters]: [],
    [FraudReportSections.WhatCanYouDo]: [],
    [FraudReportSections.PrioritizeElectronicPayments]: [],
  };
  const [, setEditWidgets] = useState(widgetsSections);
  const [, setIsEdited] = useState(false);
  const [reports, setReports] = useState([]);

  const getUpdatedWidgetConfig = (wg) => {
    const newDescriptions = {
      [WidgetTypes.PF]: PFData.description,
      [WidgetTypes.EPG]: ElectronicPaymentsToGrowthData.description,
      [WidgetTypes.BEC]: FraudPreventData.description,
      [`${WidgetTypes.QRCode}-${SimplifyingFraudData.oldHeading}`]:
        SimplifyingFraudData.description,
    };
    const newHeadings = {
      [WidgetTypes.PF]: PFData.heading,
    };
    const newItems = {
      [WidgetTypes.PFIB]: PFIBData.items,
    };
    let updatedWidgetConfig = {
      ...wg.widgetConfig,
      heading: newHeadings[wg.type] || wg.widgetConfig.heading,
      videoDuration: wg.widgetConfig.videoDuration,
      items: newItems[wg.type] || wg.widgetConfig?.items,
    };
    if (
      wg.type === WidgetTypes.QRCode &&
      wg.widgetConfig.heading === SimplifyingFraudData.oldHeading
    ) {
      return {
        ...updatedWidgetConfig,
        videoDuration: SimplifyingFraudData.videoDuration,
        description:
          newDescriptions[`${wg.type}-${SimplifyingFraudData.oldHeading}`] ||
          wg.widgetConfig.description,
      };
    }
    updatedWidgetConfig = {
      ...updatedWidgetConfig,
      description: newDescriptions[wg.type] || wg.widgetConfig.description,
    };

    return updatedWidgetConfig;
  };
  const updateDefaultWidgetsWithMapping = (
    defaultOrSavedWidgets,
    reportObjectWithMapping
  ) => {
    let updatedWidgets = {};
    delete reportObjectWithMapping.widgets;
    delete reportObjectWithMapping.reportPages;
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => {
        const updatedConfig = getUpdatedWidgetConfig(wg);
        return {
          ...wg,
          widgetConfig: {
            ...wg.widgetConfig,
            data:
              wg.type || (wg.type === WidgetTypes.DISC) === WidgetTypes.FBAR
                ? reportObjectWithMapping
                : null,
            heading: updatedConfig.heading,
            description: updatedConfig.description,
            videoDuration: updatedConfig.videoDuration,
            items: updatedConfig.items,
          },
        };
      });
    }

    // add default disclaimer widget
    if (!Object.hasOwn(defaultOrSavedWidgets, FraudReportSections.Disclaimer)) {
      updatedWidgets = {
        ...updatedWidgets,
        ...defaultDisclaimerWidget,
      };
    }
    return {
      ...updatedWidgets,
    };
  };

  const createManualReportFromCoreData = (metrics) => {
    const paymentRisksValueCheck =
      metrics.services.positivePay.check.overallCoverage;
    const paymentRisksValueAch =
      metrics.services.positivePay.ach.overallCoverage;
    const paymentRisksValuePayeeMatch =
      metrics.services.positivePay.check.addOn.payeeMatch.overallCoverage;
    const paymentRisks = {
      balancesAtRisk:
        paymentRisksValueCheck !== 'none' && paymentRisksValueAch !== 'none',
      multipleAccounts: metrics.isMultiAccountAggregation,
      balance: metrics.accounts.source.bank.balance,
      fraudPreventionProducts: updateJsonObject({
        'Positive Pay': paymentRisksValueCheck,
        'ACH Positive Pay': paymentRisksValueAch,
        'Payee Match': paymentRisksValuePayeeMatch,
      }),
      companyName: organization.name,
      name: organization.name,
      reportDate: metrics.analysisDate,
      date: metrics.analysisDate,
      coreData: {
        reportDate: metrics.analysisDate,
      },
    };

    const reportMapped = {
      ...paymentRisks,
    };

    const defaultWidgets = getDefaultWidgets(reportMapped);
    const defaultPages = getDefaultReportPages();

    return {
      name: organization.name,
      date: metrics.analysisDate,
      type: ReportTypes.Fraud,
      manualInput: {
        ...reportMapped,
        widgets: cleanDataWidgets(defaultWidgets),
        reportPages: defaultPages,
        isAuto: true, // track whether this was generated by core
      },
    };
  };
  const getReports = async (dontSelect) => {
    setLoadingPastReports(true);
    try {
      const requests = [
        OrganizationService.getReports(organization.id, {
          limit: 100,
          page: 1,
          type: ReportTypes.Fraud,
        }),
      ];

      // if organization has customer id we will call core api to get its data
      if (organization.leadAccountNumber) {
        requests.push(
          MetricsService.getOrganizationTreasuryMetricsData(organization.id)
        );
      }

      const responses = await Promise.all(requests);
      const { data: oldReports } = responses[0].data;
      let reports = oldReports
        .filter((rpt) => !!rpt.input || !!rpt.manualInput)
        .map(MapReportDropdownItem)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      // means we got the core data call
      if (responses.length > 1) {
        const coreDataReports = responses[1].data;

        if (coreDataReports.length > 1) {
          const otherReports = coreDataReports
            .slice(1)
            .filter((coreData) => {
              return !hasCoreDataReportCreated(coreData, oldReports);
            })
            .map((coreData) => {
              return createManualReportFromCoreData(coreData);
            });

          const dropdownItems = otherReports
            .filter((rpt) => !!rpt.input || !!rpt.manualInput)
            .map((e, index) => MapReportDropdownItem(e, index))
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

          setOtherOldReports(otherReports);
          reports = [...reports, ...dropdownItems];
        }

        // if core data exists
        if (coreDataReports.length) {
          // first filter out those that hasn't been created
          // only create latest, rest is dependent on click to create.
          const latest = [coreDataReports[0]];
          const mappedReports = latest
            .filter((coreData) => {
              return !hasCoreDataReportCreated(coreData, oldReports);
            })
            .map((coreData) => {
              return createManualReportFromCoreData(coreData);
            });
          // if we got the reports then
          if (mappedReports.length) {
            // ready all create requests
            const newReportRequests = mappedReports.map((newReport) => {
              return OrganizationService.createManualReport(
                organization.id,
                newReport
              );
            });
            const newReportResponses = await Promise.all(newReportRequests);
            const newReports = newReportResponses.map(MapReportDropdownItem);
            // add these reports at first/latest to show in FE
            reports = [...newReports, ...reports];
            const mappedReport = mappedReports[0];
            setSelectedRpt(mappedReport);
            setReport(mappedReport.manualInput);
            setReportPages(mappedReport.manualInput.reportPages);
            setWidgets(mappedReport.manualInput.widgets);
            setRptGenerated(true);
          }
        }
      }

      setReports(reports);

      if (!dontSelect) {
        const firstReport = reports.length
          ? hash?.includes('/new')
            ? {}
            : reports[0]
          : {};
        // if we have the reportId then get it
        if (firstReport?.key || hash?.includes('/id')) {
          // we got the id from url now we want to find that in our reports array and get full object
          const urlHashId = extractReportId(hash); // get the last index which has report id
          const reportFound = reports.find((rp) => rp.reportId === urlHashId);
          if (reportFound) {
            setSelectedRpt(reportFound);
          } else {
            setSelectedRpt(firstReport);
          }
        } else {
          setSelectedRpt({});
          setRptGenerated(false);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPastReports(false);
    }
  };
  const getReportById = async (selectedReport) => {
    setLoadingReport(true);
    setRptGenerated(true);
    try {
      const defaultReportPages = getDefaultReportPages();
      const data = await ReportService.getReport(selectedReport.key);
      const { manualInput } = data;
      const rptObject = manualInput;
      const fraudPreventionProducts =
        rptObject?.fraudPreventionProducts ||
        updateJsonObject({
          'Positive Pay': rptObject.positivePay ? 1 : 0,
          'ACH Positive Pay': rptObject.achPositivePay ? 1 : 0,
        });
      if (Object.hasOwn(rptObject, 'widgets')) {
        const savedWidgets = updateDefaultWidgetsWithMapping(
          rptObject.widgets,
          { ...rptObject, fraudPreventionProducts }
        );
        setWidgets(savedWidgets);
        setModalWidgets(savedWidgets);
      } else {
        const defWidgets = getDefaultWidgets(selectedReport);
        setWidgets(defWidgets);
        setModalWidgets(defWidgets);
      }
      // if we have .reportPages saved in report then load those otherwise load default ones to cater old reports too
      if (Object.hasOwn(rptObject, 'reportPages')) {
        setReportPages(rptObject.reportPages);
        setModalReportPages(rptObject.reportPages);
      } else {
        setReportPages(defaultReportPages);
        setModalReportPages(defaultReportPages);
      }

      const newReport = {
        ...rptObject,
        fraudPreventionProducts,
        id: selectedReport.key,
        reportId: selectedReport.key,
        valueN: getNaicsWithTitle(rptObject, organization),
        reportDate: getCycleDate(rptObject.reportDate),
        disclaimer: rptObject.disclaimer || disclaimerData.disclaimer,
        prettyDate: moment(rptObject.reportDate).format('MMMM YYYY'),
        disclaimerHeading:
          rptObject.disclaimerHeading || disclaimerData.disclaimerHeading,
      };
      setSelectedReport(newReport);
      setReport(newReport);

      if (hash?.includes('/edit')) {
        handleEditReport();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReport(false);
    }
  };

  const handleGenerateReport = (
    newReport,
    action,
    newOrUpdatedReport,
    newWidgets,
    newReportPages
  ) => {
    if (action === ActionTypes.REMOVE) {
      const newReports = [
        ...reports.filter((rpt) => rpt.reportId !== newReport.reportId),
      ];
      const reportsAvailable = newReports.length > 0;
      setReports(reportsAvailable ? newReports : []);
      setSelectedRpt(reportsAvailable ? newReports.at(-1) : {});
      const defaultWidgets = getDefaultWidgets({});
      const defaultReportPages = getDefaultReportPages();
      setWidgets(defaultWidgets);
      setReportPages(defaultReportPages);
      setModalWidgets(defaultWidgets);
      setModalReportPages(defaultReportPages);
      setRptGenerated(false);
      setOpenGenerateReport(false);
      setSuccessMessage('Report Deleted');
    } else {
      setReportPages(newReportPages);
      setModalReportPages(newReportPages);
      setRptGenerated(true);
      if (newReport) {
        try {
          if ('key' in selectedRpt) {
            const newReports = [
              ...reports.map((rpt) =>
                rpt.key === selectedRpt.key
                  ? {
                      ...rpt,
                      customElement: (
                        <ReportDropdownItem
                          item={{
                            ...newOrUpdatedReport.manualInput,
                            key: rpt.key,
                            reportId: rpt.key,
                          }}
                        />
                      ),
                      reportPages: newReportPages,
                      name: getReportName(newOrUpdatedReport.manualInput),
                      createdById: profileInfo.id,
                      updatedAt: new Date().toISOString(),
                      prettyDate: moment(
                        newOrUpdatedReport.manualInput?.reportDate
                      ).format('MMMM YYYY'),
                      widgets: updateDefaultWidgetsWithMapping(
                        newWidgets,
                        newOrUpdatedReport.manualInput
                      ),
                    }
                  : rpt
              ),
            ];
            setReports(newReports);
            const reportMapping = {
              ...report,
              ...newOrUpdatedReport.manualInput,
            };
            setReport(reportMapping);
            const selected = newReports.find((r) => r.key === selectedRpt.key);
            setSelectedRpt(selected);
            setSelectedReport({
              ...newOrUpdatedReport.manualInput,
              reportDate: getCycleDate(
                newOrUpdatedReport.manualInput.reportDate
              ),
            });
            const updatedWidgets = updateDefaultWidgetsWithMapping(
              selected.widgets,
              reportMapping
            );
            setWidgets(updatedWidgets);
            setModalWidgets(updatedWidgets);
            setSuccessMessage('Report Saved');
          } else {
            const rptObject = newOrUpdatedReport;
            const pastReportObject = {
              key: newOrUpdatedReport.reportId,
              reportId: newOrUpdatedReport.reportId,
              customElement: (
                <ReportDropdownItem
                  item={{
                    ...rptObject.manualInput,
                    key: newOrUpdatedReport.reportId,
                    reportId: newOrUpdatedReport.reportId,
                  }}
                />
              ),
              reportPages: newReportPages,
              name: getReportName(rptObject.manualInput),
              isManual: true,
              createdById: profileInfo.id,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              prettyDate: moment(rptObject.manualInput.reportDate).format(
                'MMMM YYYY'
              ),
            };
            const newReports = [pastReportObject].concat([...reports]);
            setReports(newReports);
            setSelectedRpt(pastReportObject);
            setSelectedReport({
              ...newOrUpdatedReport.manualInput,
              reportDate: getCycleDate(
                newOrUpdatedReport.manualInput.reportDate
              ),
            });
            const updatedWigets = updateDefaultWidgetsWithMapping(
              pastReportObject.widgets,
              newReport
            );
            setWidgets(updatedWigets);
            setModalWidgets(updatedWigets);
            setSuccessMessage('Report Created');
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoadingReport(false);
          setOpenGenerateReport(false);
        }
      }
    }
  };

  const {
    setShowModal,
    setSelectedReport,
    setModalReportPages,
    setModalWidgets,
    GenerateFraudReportModal,
  } = useGenerateFraudReportModal(
    report,
    organization,
    reportPages,
    widgets,
    handleGenerateReport,
    getProfileInfo
  );

  const handleManualReport = () => {
    updateHash('');
    setSelectedRpt({});
    setIsEdited(false);
    setRptGenerated(false);
    const defaultWidgets = getDefaultWidgets({});
    const defaultReportPages = getDefaultReportPages();
    setWidgets(defaultWidgets);
    setReportPages(defaultReportPages);
    setModalWidgets(defaultWidgets);
    setModalReportPages(defaultReportPages);
    const newReport = {
      companyName: organization.name,
      reportDate: getCycleDate(new Date().toISOString()),
      fraudPreventionProducts: updateJsonObject(DefaultRisksItems),
      disclaimer: disclaimerData.disclaimer,
      disclaimerHeading: disclaimerData.disclaimerHeading,
      ...getNAICSWithIndustry(organization),
    };
    setReport(newReport);
    setSelectedReport(newReport);
    setShowModal(true);
  };

  const handleCreateButtonReport = () => {
    setSelectedRpt({});
    setIsEdited(false);
    setRptGenerated(false);
    const defaultWidgets = getDefaultWidgets({});
    const defaultReportPages = getDefaultReportPages();
    setWidgets(defaultWidgets);
    setReportPages(defaultReportPages);
    setModalWidgets(defaultWidgets);
    setModalReportPages(defaultReportPages);
  };

  const handleEditReport = () => {
    setIsEdited(true);
    setOpenGenerateReport(true);
    setShowModal(true);
    updateHash('');
  };

  const onAddWidget = async (e, newWidget, oldWidget) => {
    const sectionWidgets = widgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: crypto.randomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: crypto.randomUUID() }
          : { ...wg }
      );
    }
    // since we are giving add/remove in direct report so we need to update report widgets right here
    const newWidgets = { ...widgets, [oldWidget.section]: updatedWidgets };
    setWidgets(newWidgets);
    setEditWidgets(newWidgets); // this needs to be added in order to open updated in modal
    await ReportService.updateReport(report.reportId, {
      name: report.value1,
      date: report.value2,
      manualInput: {
        ...report,
        widgets: newWidgets,
      },
    });
    setShowWidgetsLibrary(false);
    overflowing();
    setSuccessMessage('Report Saved');
  };

  const onDeleteWidget = async (oldWidget) => {
    const sectionWidgets = widgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    // since we are giving add/remove in direct report so we need to update report widgets right here
    const newWidgets = { ...widgets, [oldWidget.section]: updatedWidgets };
    setWidgets(newWidgets);
    setEditWidgets(newWidgets); // this needs to be added in order to open updated in modal
    await ReportService.updateReport(report.reportId, {
      name: report.value1,
      date: report.value2,
      manualInput: {
        ...report,
        widgets: newWidgets,
      },
    });
    setSuccessMessage('Report Saved');
  };

  const qrCodeExternalLinkChannel = SimplifyingFraudData.channel;

  useEffect(() => {
    if (organization?.id && organization?.name) {
      getReports();
    }
  }, [organization]);

  const generateReport = async (report) => {
    if (report) {
      try {
        setLoadingPastReports(true);
        await OrganizationService.createManualReport(organization.id, report);
        getReports();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!selectedRpt.isGenerated) {
      // No id from BE for otherOldReport so comparing on timestamp
      const report = otherOldReports.find(
        (e) => e.date === selectedRpt.createdAt
      );
      generateReport(report);
      return;
    }
    if (selectedRpt?.key || hash?.includes('/id')) {
      getReportById(selectedRpt);
    }
  }, [selectedRpt?.key, selectedTenant?.key]);

  useEffect(() => {
    if (hash?.includes('/new')) {
      handleCreateButtonReport();
    }
  }, [hash]);

  return (
    <>
      <WidgetsLibraryModal />
      <GenerateFraudReportModal />
      <AlertWrapper className="alert-position">
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
          time={8000}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
          time={8000}
        />
      </AlertWrapper>
      {reports.length === 0 &&
        readOnly &&
        !loadingReport &&
        !loadingPastReports && (
          <NoDataFound
            icon={NO_REPORTS_AVAILABLE_ICON}
            iconRounded={true}
            containerStyle="my-6 py-6 text-gray-search"
            title={NO_REPORTS_AVAILABLE}
            titleStyle="text-gray-search font-normal font-size-sm2"
          />
        )}
      <ReportAction
        report={report}
        readOnly={readOnly}
        reportType={ReportTypes.Fraud}
        organization={organization}
        pastReports={reports}
        selectedRpt={selectedRpt}
        profileInfo={profileInfo}
        rptGenerated={rptGenerated}
        loadingReport={loadingReport}
        startDownload={startDownload}
        setSelectedRpt={(newReport) => {
          updateHash('');
          setSelectedRpt(newReport);
        }}
        downloadOptions={DOWNLOAD_OPTIONS}
        setStartDownload={setStartDownload}
        handleEditReport={handleEditReport}
        handleManualReport={handleCreateButtonReport}
        loadingPastReports={loadingPastReports}
        linkConfig={{
          area: {
            areaX: 520,
            areaY: 650,
            areaWidth: 110,
            areaHeight: 30,
          },
          page: 3,
          url: qrCodeExternalLinkChannel,
        }}
      />
      <div className="text-center">
        {!rptGenerated &&
          !loadingReport &&
          !readOnly &&
          !loadingPastReports && (
            <ReportDragDrop
              file={null}
              setFile={() => {}}
              loader={false}
              onRemoveFile={() => {}}
              onLoadFile={() => {}}
              handleGenerate={handleManualReport}
              uploadIcon="edit_document"
              fileUpload="Enter data to generate Fraud report."
            />
          )}

        {loadingReport || loadingPastReports ? (
          <ReportBlocksSkeleton />
        ) : (
          <>
            {rptGenerated && (
              <>
                {startDownload && (
                  <ReportPDFWrapper>
                    <ReportCover
                      name={report.companyName}
                      date={report.reportDate}
                      excelBankMode={excelBankMode}
                      selectedTenant={selectedTenant}
                      report={report}
                      type={ReportTypes.Fraud}
                      organization={organization}
                    />
                    <ReportPages
                      pages={widgets}
                      report={report}
                      reportPages={reportPages}
                      sectionKeys={FraudReportSectionKeys}
                      pageConfig={PageConfig}
                      showIcon={false}
                      selectedTenant={selectedTenant}
                      reportType={ReportTypes.Fraud}
                    />
                  </ReportPDFWrapper>
                )}
                <div
                  className={`pb-3 ${readOnly ? 'client-dashboard-rpt' : ''}`}
                >
                  <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
                    {FraudReportSectionKeys.flatMap((key) => {
                      const widgetsForCurrentKey = widgets ? widgets[key] : [];
                      const pageEnabled = reportPages[key]?.enabled;
                      const widgetsWithoutBlank = widgetsForCurrentKey
                        ?.filter((widget) => widget.type !== WidgetTypes.BLANK)
                        .map((wg) => ({
                          ...wg,
                          id: crypto.randomUUID(),
                          section: key,
                        }));
                      if (widgetsWithoutBlank.length && pageEnabled) {
                        return widgetsWithoutBlank?.map((widget) => (
                          <WidgetWithActionButtons
                            key={widget.id}
                            widget={widget}
                            section={key}
                            onAddWidget={onAddWidget}
                            onDeleteWidget={onDeleteWidget}
                            selectedTenant={selectedTenant}
                            setSelectedWidget={setSelectedWidget}
                            setShowWidgetsLibrary={setShowWidgetsLibrary}
                            actionButtons={{}}
                          />
                        ));
                      }
                      return null;
                    })}
                  </ListGroup>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FraudReport;

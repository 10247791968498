import React, { useContext, useState } from 'react';
import { isModuleAllowed, isPermissionAllowed } from '../../utils/Utils';
import { TenantContext } from '../../contexts/TenantContext';
import PageTitle from '../../components/commons/PageTitle';
import { useProfileContext } from '../../contexts/profileContext';
import Header from './Home/Header';
import EngagementReports from './Home/EngagementReports';
import SideBarStats from './Home/SideBarStats';
import MostRecents from './Home/MostRecents';
import IDASection from './Home/IDASection';
import HomeLoadingSkelton from './Home/HomeLoadingSkelton';
import PillFilters from '../../components/commons/PillFilters';

const filterStatuses = [
  {
    id: 'My',
    label: 'My Overview',
  },
  {
    id: 'All',
    label: 'Full Overview',
  },
];

const Home = () => {
  const { tenant } = useContext(TenantContext);
  const { profileInfo } = useProfileContext();
  const [selectedFilter, setSelectedFilter] = useState(filterStatuses[0]);

  const isCompaniesAllowed =
    isModuleAllowed(tenant.modules, 'companies') &&
    isPermissionAllowed('organizations', 'view');

  const onFilterChange = (fil) => {
    setSelectedFilter(fil);
  };

  return (
    <>
      <PageTitle page="Home" />

      {!profileInfo?.id ? (
        <div className="splitted-content-fluid bg-white position-relative container-fluid overflow-hidden">
          <HomeLoadingSkelton />
        </div>
      ) : (
        <div className="splitted-content-fluid position-relative container-fluid content-with-insights overflow-hidden bg-white p-0">
          <Header />
          <hr className="my-0" />

          <div className="row" style={{ height: 'calc(100vh - 148px)' }}>
            <div className="col-lg-4 overflow-y-auto h-100">
              <div className="pr-2 pl-3 py-4 d-flex flex-column gap-3">
                <PillFilters
                  items={filterStatuses}
                  currentFilter={selectedFilter}
                  onFilterChange={onFilterChange}
                  className="w-max-content"
                />

                <SideBarStats selectedFilter={selectedFilter} />
              </div>
            </div>
            <div className="col-lg-8 border-left overflow-y-auto h-100 px-0">
              <div className="d-flex flex-column gap-4 pt-2 pb-4">
                <EngagementReports />
                <IDASection />
                {isCompaniesAllowed && (
                  <MostRecents selectedFilter={selectedFilter} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;

import React from 'react';
import './siteSettings.css';
import Loading from '../Loading';
import { useTenantContext } from '../../contexts/TenantContext';
import SMTPConfiguration from '../Tenant/SMTPConfiguration';

const SiteEmailSettings = () => {
  const { tenant } = useTenantContext();

  return (
    <>
      {!tenant.id ? (
        <Loading bars={true} />
      ) : (
        <div className="card shadow-none border-0 border-bottom-1 rounded-0">
          <div className="card-header">
            <h4 className="card-title">SMTP Configuration</h4>
          </div>
          <div className="card-body">
            <p className="text-muted">
              Configure your email server settings for sending notifications.
            </p>
            <SMTPConfiguration tenantId={tenant.id} />
          </div>
        </div>
      )}
    </>
  );
};

export default SiteEmailSettings;

import MaterialIcon from '../../commons/MaterialIcon';
import React from 'react';
import { numbersWithComma } from '../../../utils/Utils';
import useIsTenant from '../../../hooks/useIsTenant';
import {
  ColorCoverage,
  DemoTenantsKeys,
  RiskColorCoverage,
  TreasuryReportSections,
} from '../../reports/reports.constants';

const IconItem = ({
  item,
  bordered = 'border-bottom ',
  textWrap = 'font-weight-medium text-wrap',
  selectedTenant = {},
  section,
  iconBg,
  itemStyle = 'justify-content-between',
  containerStyle = 'py-2',
  textStyle = 'fs-8',
}) => {
  const { isSVB } = useIsTenant();

  const getItemValue = (itemVal) => {
    return typeof itemVal === 'string' ? parseFloat(itemVal) : itemVal;
  };
  return (
    <p className={`font-size-sm2 mb-0 ${bordered} ${containerStyle}`}>
      <div className={`d-flex align-items-center ${itemStyle}`}>
        <div className="d-flex align-items-center gap-2">
          {item[1]?.icon ? (
            <>
              {iconBg ? (
                <div
                  className={`d-flex align-items-center bg-primary-soft p-2 rounded-circle`}
                >
                  <MaterialIcon
                    icon={item[1]?.icon}
                    symbols
                    rounded={false}
                    clazz={`font-size-em text-primary`}
                  />
                </div>
              ) : (
                <div className={`d-flex align-items-center`}>
                  <MaterialIcon
                    icon={item[1]?.icon}
                    symbols
                    rounded={false}
                    clazz={`font-size-em text-primary`}
                  />
                </div>
              )}
            </>
          ) : (
            <div
              className={`d-flex rounded-circle align-items-center green-red-check-item ${
                ColorCoverage[item[1]?.value || RiskColorCoverage.none.value]
                  ?.bg
              }`}
            >
              <MaterialIcon
                icon={
                  ColorCoverage[item[1]?.value || RiskColorCoverage.none.value]
                    ?.icon
                }
                rounded={true}
                clazz={`font-size-lg text-white ${
                  ColorCoverage[item[1]?.value || RiskColorCoverage.none.value]
                    ?.color
                }`}
              />
            </div>
          )}
          {(isSVB || selectedTenant.key === DemoTenantsKeys.svb) &&
          section === TreasuryReportSections.Fraud &&
          item[1]?.key === 'ACH Positive Pay' ? (
            <span className={`fs-8 sub-text text-left ${textWrap}`}>
              ACH Filter
            </span>
          ) : (
            <span
              className={`${textStyle} sub-text text-left ${textWrap}`}
              dangerouslySetInnerHTML={{
                __html:
                  item[1]?.key === 'Automated Payable Solution'
                    ? 'Automated Payables Solution'
                    : item[1]?.key,
              }}
            ></span>
          )}
        </div>
        {section === TreasuryReportSections.Overview && (
          <span className="fs-8 sub-text text-right">
            {numbersWithComma(getItemValue(item[1]?.itemValue || '0'))} items
          </span>
        )}
      </div>
    </p>
  );
};
export default IconItem;

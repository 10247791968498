import React, { useCallback, useMemo, useState } from 'react';
import NumberedTabs from '../../commons/NumberedTabs';
import { ReportTypes } from '../reports.constants';
import { AccountStructureTabs } from './account.structure.constants';
import ReportModalTitle from '../ReportModalTitle';

const ReportTabs = ({
  tabs,
  setTabs,
  selectedTab,
  setSelectedTab,
  setErrorMessage,
  handleSaveOrContinue,
}) => {
  const onSelect = (tab) => {
    const newTabs = [...tabs].map((tb) =>
      tb.id === tab.id ? { ...tab, isActive: true } : { ...tb, isActive: false }
    );
    setSelectedTab(tab);
    setTabs(newTabs);
    handleSaveOrContinue(true);
  };
  return (
    <div>
      <ReportModalTitle type={ReportTypes.AccountStructure} />
      <div className="pt-3">
        <NumberedTabs tabs={tabs} onSelect={onSelect} />
      </div>
    </div>
  );
};

const useAccountStructureTabs = (
  setErrorMessage,
  handleSaveOrContinue = () => {}
) => {
  const [tab, setTab] = useState(AccountStructureTabs[0]);
  const [reportTabs, setReportTabs] = useState(AccountStructureTabs);
  const onSelect = (tab) => {
    const newTabs = [...reportTabs].map((tb) =>
      tb.id === tab.id ? { ...tab, isActive: true } : { ...tb, isActive: false }
    );
    setTab(tab);
    setReportTabs(newTabs);
  };
  const ReportTabsCallback = useCallback(() => {
    return (
      <ReportTabs
        tabs={reportTabs}
        setTabs={setReportTabs}
        selectedTab={tab}
        setSelectedTab={setTab}
        setErrorMessage={setErrorMessage}
        handleSaveOrContinue={handleSaveOrContinue}
      />
    );
  }, [tab, setTab]);

  return useMemo(
    () => ({
      selectedTab: tab,
      setSelectedTab: onSelect,
      Tabs: ReportTabsCallback,
    }),
    [setTab, ReportTabsCallback]
  );
};

export default useAccountStructureTabs;

import { useContext } from 'react';

import { CategoriesContext } from '../../contexts/categoriesContext';

function SubMenuWrapper(props) {
  const { icon, title, children, active, setActive } = props;
  const { categoryList } = useContext(CategoriesContext);
  const customListData = categoryList?.filter((c) => c.isPublic === false);
  const toggle = () => setActive(active !== title ? title : '');
  const className = active === title && 'show';
  return (
    <li
      className={`navbar-vertical-aside-has-menu ${className} ${
        customListData?.length === 0 && title === 'Custom' ? 'd-none' : ''
      }`}
    >
      <div
        className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle cursor-pointer"
        onClick={toggle}
      >
        {icon && (
          <i className="material-symbols-rounded fs-5 nav-icon">{icon}</i>
        )}
        <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate cursor-pointer fw-bold">
          {title}
        </span>
      </div>
      <ul className={`js-navbar-vertical-aside-submenu nav nav-sub`}>
        {children}
      </ul>
    </li>
  );
}

export default SubMenuWrapper;

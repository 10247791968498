import moment from 'moment';
import {
  DemoTenantsKeys,
  getDemoTenantByKey,
  Pages,
  PayablesDataPointsV2Comerica,
  PaymentRisksDataPoints,
  ReceivablesDataPointsV2,
  SVBMapping,
} from './reports.constants';
import { calculateTotalFees } from './pdf.scrape.engine';
import { parseCurrency } from '../../utils/Utils';
import {
  calculateOpportunity,
  sumItemValues,
  updateJsonObject,
} from './reports.helper.functions';
import _ from 'lodash';

const findValueInPage = (data, searchKeys) => {
  let finalObject = {};
  try {
    data.forEach((row, rowIndex) => {
      const text = row.str;
      searchKeys.forEach((searchKey) => {
        if (text && text.match(searchKey.keyToFind)) {
          const indexJump = searchKey.indexJump || 0;
          const textV = data[rowIndex + 2 + indexJump]?.str;
          const objKey = searchKey.objectKeyName;
          const valueGrabIndex = searchKey.keyToFindValueIndex;
          if (valueGrabIndex === -1) {
            finalObject = {
              ...finalObject,
              [objKey]: text.match(searchKey.keyToFindRegex)[0],
            };
          } else {
            if (objKey === 'value6') {
              finalObject = {
                ...finalObject,
                [objKey]: text.match(searchKey.keyToFindRegex)[0],
                value9: textV, // earnings allowance
              };
            } else {
              if (!Object.hasOwn(finalObject, objKey)) {
                finalObject = {
                  ...finalObject,
                  [objKey]: textV,
                };
              }
            }
          }
        }
      });
    });
  } catch (e) {}
  return finalObject;
};

const flattenDataBetweenIndexes = (
  pdfData,
  sectionIndexes,
  excludeLastPage
) => {
  let sectionsData = [];
  const from = sectionIndexes[0];
  const to = sectionIndexes[1];

  if (excludeLastPage) {
    for (let i = from; i < to; i++) {
      sectionsData = [...sectionsData, ...pdfData[i]];
    }
  } else {
    for (let i = from; i <= to; i++) {
      sectionsData = [...sectionsData, ...pdfData[i]];
    }
  }

  return sectionsData;
};

const getPayablesOrReceivablesValuesV2 = (
  data,
  serviceDetailsPageIndex,
  jsonKeyObject
) => {
  const allServiceDetailArea = flattenDataBetweenIndexes(
    data,
    serviceDetailsPageIndex
  );

  const finalData = {};
  for (const key in jsonKeyObject) {
    const dataPoints = jsonKeyObject[key];
    const lowerCaseDataPoints = dataPoints.map((s) => s.toLowerCase());
    let fee = 0;
    let volume = 0;
    for (let i = 0; i < allServiceDetailArea.length; i++) {
      const textObj = allServiceDetailArea[i];
      if (
        textObj?.str &&
        !textObj?.str.includes('Comerica Bank') // heck no :\
      ) {
        const name = textObj.str;
        if (
          dataPoints.includes(name) ||
          lowerCaseDataPoints.includes(name.toLowerCase())
        ) {
          const feeValue = allServiceDetailArea[i + 6]?.str || '0';
          const volumeValue = allServiceDetailArea[i + 2]?.str || '0';
          fee += parseCurrency(feeValue);
          volume += parseCurrency(volumeValue);
        }
      }
    }
    finalData[key] = {
      'Total Fee': fee,
      'Total Volume': volume,
    };
  }
  return finalData;
};

const findPagesBetweenSectionNames = (
  pdfData,
  fromSectionName,
  toSectionName = null
) => {
  const pages = pdfData;
  let fromIndex = -1;
  let toIndex = -1;
  const indices = [];

  for (let i = 0; i < pages.length; i++) {
    const columns = pages[i];
    const column = columns.find(
      (col) => col.str?.toLowerCase() === fromSectionName?.toLowerCase()
    );
    const columnTo = columns.find(
      (col) => col.str?.toLowerCase() === toSectionName?.toLowerCase()
    );

    if (column?.str?.toLowerCase() === fromSectionName?.toLowerCase()) {
      fromIndex = i;
      if (toSectionName === null) {
        toIndex = pages.length - 1;
      }
    }
    if (
      toSectionName !== null &&
      columnTo?.str?.toLowerCase() === toSectionName?.toLowerCase() &&
      fromIndex !== -1
    ) {
      toIndex = i;
      indices.push(fromIndex, toIndex);
      break;
    }
    if (toIndex !== -1 && i <= toIndex) {
      if (toSectionName !== null) {
        indices.push(fromIndex, toIndex);
      } else {
        indices.push(fromIndex);
      }
    }
  }
  return [Math.min(...indices), Math.max(...indices)];
};

const svbServiceSectionName = (fullText) => {
  const words = fullText.split(' ');
  const formattedWords = words.slice(1).map((word) => {
    if (word !== '&') {
      const lowerWord = word.toLowerCase();
      if (
        lowerWord === 'se' ||
        lowerWord === 'servic' ||
        lowerWord === 'servi' ||
        lowerWord === 'serv' ||
        lowerWord === 'ser'
      ) {
        return 'Services';
      }
      return word.charAt(0) + lowerWord.slice(1);
    }
    return '&';
  });
  return formattedWords.join(' ');
};

const processServiceDetailsPage = (
  data,
  serviceDetailsPageIndex,
  excludeLastPage,
  isSVBB
) => {
  // get the service details area first
  const bolds = [];
  const allServiceDetailArea = flattenDataBetweenIndexes(
    data,
    serviceDetailsPageIndex,
    excludeLastPage
  );

  let indexStart = 0;
  allServiceDetailArea.every((col, index) => {
    if (
      col?.str === 'Service Details' ||
      col?.str?.toLowerCase() === Pages.ServiceInformation.title.toLowerCase()
    ) {
      indexStart = index;
      return false;
    }
    return true;
  });

  for (let i = indexStart; i < allServiceDetailArea.length; i++) {
    const firstColumn = allServiceDetailArea[i];
    const textObj = firstColumn?.str;
    if (
      textObj &&
      !/\d/.test(textObj) &&
      !textObj?.includes('Comerica Bank') &&
      !textObj?.includes(',') // heck no :\
    ) {
      if (isSVBB) {
        if (textObj?.toLowerCase().includes('subtotal')) {
          bolds.push({
            page: 1,
            row: i,
            section: firstColumn?.str,
          });
        }
      } else {
        // apart from 0th index rest of it should be text: ''
        if (
          allServiceDetailArea[i + 1]?.str === ' ' &&
          allServiceDetailArea[i + 2]?.str &&
          allServiceDetailArea[i + 2]?.str !== ' '
        ) {
          bolds.push({
            page: 1,
            row: i,
            section: firstColumn?.str,
          });
        }
      }
    }
  }

  const boldsWithSections = bolds.map((bb) => bb.section);
  const regexNumber = /^(\d{1,3}(,\d{3})*|\d{1,3}(,\d{3})*\.\d+)$/;
  const fees = [];

  if (isSVBB) {
    for (let i = 0; i < bolds.length; i++) {
      const textObj = allServiceDetailArea[bolds[i].row];
      if (textObj?.str) {
        const name = textObj.str;
        fees.push({
          mainSection: svbServiceSectionName(name),
          subSection: name,
          value: allServiceDetailArea[bolds[i]?.row + 2]?.str,
        });
      }
    }
  } else {
    for (let i = 0; i < allServiceDetailArea.length; i++) {
      const textObj = allServiceDetailArea[i];
      if (textObj?.str) {
        const name = textObj.str;
        const boldSection = bolds.find((b) => b.section === name);
        if (
          boldsWithSections.includes(name) &&
          regexNumber.test(allServiceDetailArea[i + 2]?.str)
        ) {
          fees.push({
            mainSection: regexNumber.test(
              allServiceDetailArea[boldSection?.row - 2]?.str
            )
              ? ''
              : allServiceDetailArea[boldSection?.row - 2]?.str,
            subSection: name,
            value: allServiceDetailArea[boldSection?.row + 6]?.str,
          });
        }
      }
    }
  }
  // Step 1: Group data by mainSection
  const groupedData = {};

  let currentMainSection = null;

  for (const item of fees) {
    if (item.mainSection) {
      currentMainSection = item.mainSection;
      if (!groupedData[currentMainSection]) {
        groupedData[currentMainSection] = { items: [], totalValue: 0 };
      }
    }

    if (currentMainSection !== null) {
      groupedData[currentMainSection].items.push(item);
      if (item.value) {
        const numericValue = parseFloat(item.value) || 0;
        groupedData[currentMainSection].totalValue += numericValue;
      }
    }
  }

  // Step 2: Calculate total sum and determine the top 3 mainSections

  const sortedSections = Object.keys(groupedData).sort((a, b) => {
    const totalValueA = groupedData[a].totalValue;
    const totalValueB = groupedData[b].totalValue;
    return totalValueB - totalValueA;
  });

  const topSections = sortedSections.slice(0, 3);
  const totalSum = sortedSections.reduce(
    (sum, mainSection) => sum + groupedData[mainSection].totalValue,
    0
  );

  // Step 3: Calculate percentages and create final grouped data
  const finalGroupedData = {};

  for (const mainSection of sortedSections) {
    const section = groupedData[mainSection];
    const isTopSection = topSections.includes(mainSection);
    const percentage = Math.round((section.totalValue / totalSum) * 100);

    if (isTopSection) {
      finalGroupedData[mainSection] = { items: section.items, percentage };
    } else {
      finalGroupedData.Other = finalGroupedData.Other || {
        items: [],
        percentage: 0,
      };
      finalGroupedData.Other.items = finalGroupedData.Other.items.concat(
        section.items
      );
      finalGroupedData.Other.percentage += percentage;
    }
  }

  // Rearrange the sections with top 3 above "Other"
  const rearrangedGroupedData = {};

  for (const mainSection of [...topSections, 'Other']) {
    rearrangedGroupedData[mainSection] = finalGroupedData[mainSection];
  }

  // Create the cleanedData object using reduce
  const cleanedData = Object.entries(rearrangedGroupedData).reduce(
    (accumulator, [mainSection, data]) => {
      accumulator[mainSection] = data?.percentage;
      return accumulator;
    },
    {}
  );

  return cleanedData;
};

export const processSVBBankStatement = (pdfData, nameAndDate) => {
  const flatterPdfData = _.flatten(pdfData);
  const serviceDetailsPageIndex = findPagesBetweenSectionNames(
    pdfData,
    Pages.ServiceInformation.title
  );

  const payableData = getPayablesOrReceivablesValuesV2(
    pdfData,
    serviceDetailsPageIndex,
    SVBMapping.PayablesDataPoints,
    false,
    true
  );

  const receivableData = getPayablesOrReceivablesValuesV2(
    pdfData,
    serviceDetailsPageIndex,
    SVBMapping.ReceivablesDataPoints,
    false,
    true
  );

  const paymentMethodsUsed = {
    ACH: payableData['ACH Origination']['Total Volume'],
    Checks: payableData['Checks Paid']['Total Volume'],
    'Domestic Wire': payableData['TPA Wire']['Total Volume'],
    'International Wire':
      payableData['Integrated Payables ACH']['Total Volume'],
  };

  const typesOfReceivables = {
    ACH: receivableData['Integrated Payables ACH']['Total Volume'],
    Checks: receivableData['Positive Pay Usage']['Total Volume'],
    Wires: receivableData['Checks Paid']['Total Volume'],
  };

  // formula B+C+D+E+RTP (from Data Mapping Sheet)
  // this is F
  const estimatedTotalPayables = sumItemValues(
    updateJsonObject(paymentMethodsUsed)
  );

  // this is K
  const estimatedTotalReceivables = sumItemValues(
    updateJsonObject({ ...typesOfReceivables }) // subtracting Cash Vault from final count
  );

  const paymentRisksData = getPayablesOrReceivablesValuesV2(
    pdfData,
    serviceDetailsPageIndex,
    SVBMapping.PaymentRisksDataPoints,
    false,
    true
  );

  const opportunity = calculateOpportunity(
    paymentMethodsUsed.Checks,
    getDemoTenantByKey(DemoTenantsKeys.svb)
  );

  const paymentRisksValueD = paymentRisksData['Incoming Wires']['Total Volume'];
  const paymentRisksValueE =
    paymentRisksData['Incoming ACH/Check']['Total Volume'];
  const paymentRisks = {
    balance: {
      total: paymentRisksValueD + paymentRisksValueE,
      isChecked: paymentRisksValueD > 0 && paymentRisksValueE > 0,
    },
    fraudPreventionProducts: updateJsonObject({
      'Positive Pay': paymentRisksValueD,
      'ACH Positive Pay': paymentRisksValueE,
    }),
  };

  const totalFeesPaid = findValueInPage(flatterPdfData, [
    {
      keyToFind: 'Service Charges - Debited',
      keyToFindRegex: /Service Charges - Debited/,
      keyToFindValueIndex: 1,
      objectKeyName: 'totalFeesPaid',
    },
  ]);

  const totalBankFees = findValueInPage(flatterPdfData, [
    {
      keyToFind: 'Total Service Charges Listed',
      keyToFindRegex: /Total Service Charges Listed/,
      keyToFindValueIndex: 3,
      objectKeyName: 'totalBankFees',
    },
  ]);

  const earningsAllowance = findValueInPage(flatterPdfData, [
    {
      keyToFind: /\bEarnings Allowance\b/,
      keyToFindRegex: /\bEarnings Allowance\b/,
      keyToFindValueIndex: 1,
      objectKeyName: 'earningsAllowance',
    },
  ]);

  const ecrRate = findValueInPage(flatterPdfData, [
    {
      keyToFind: /\bEarnings Allowance Rate\b/,
      keyToFindRegex: /\bEarnings Allowance Rate\b/,
      keyToFindValueIndex: 2,
      objectKeyName: 'ecrRate',
    },
  ]);

  const avgNetCollectedBalance = findValueInPage(flatterPdfData, [
    {
      keyToFind: 'Balance To Support Services',
      keyToFindRegex: /Balance To Support Services/,
      keyToFindValueIndex: 2,
      objectKeyName: 'avgNetCollectedBalance',
    },
  ]);

  const totalServicesChargesListed = findValueInPage(flatterPdfData, [
    {
      keyToFind: 'Total Service Charges Listed',
      keyToFindRegex: /Total Service Charges Listed/,
      keyToFindValueIndex: 3,
      indexJump: 2,
      objectKeyName: 'totalServicesChargesListed',
    },
  ]);

  // wow
  const balancesToOffset = parseCurrency(
    totalServicesChargesListed?.totalServicesChargesListed || '$0'
  );
  // fee allocation logic
  const feeAllocationData = processServiceDetailsPage(
    pdfData,
    serviceDetailsPageIndex,
    false,
    true
  );

  const allData = {
    value1: nameAndDate.name,
    value2: nameAndDate.date,
    valueN: [nameAndDate.naics],
    opportunity,
    estimatedTotalPayables,
    estimatedTotalReceivables,
    payableData,
    receivableData,
    paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
    typesOfReceivables: updateJsonObject(typesOfReceivables),
    paymentRisks,
    totalFees: totalFeesPaid?.totalFeesPaid || '$0',
    value5: totalBankFees?.totalBankFees || '$0',
    value6: ecrRate?.ecrRate,
    value7: avgNetCollectedBalance?.avgNetCollectedBalance || '$0',
    value8: balancesToOffset || '$0',
    value9: earningsAllowance?.earningsAllowance || '$0',
    feeAllocationData,
  };
  return allData;
};

const cleanDataFromEmptySpace = (o) => o.str !== '';
const cleanDataFromEmptySpaces = (o) => o.str !== ' ';

function getDataFromStringTillEnd(
  pdfData,
  startString,
  splitWordFlag = false,
  delimiter = '----'
) {
  const startStrings = splitWordFlag ? startString.split(' ') : startString;

  const isString = typeof startStrings === 'string';

  const dataStartIndex = isString
    ? pdfData.findIndex((obj) => obj.str.includes(startStrings))
    : pdfData.findIndex((obj, idx) =>
        startStrings.every((startStr, i) => {
          return pdfData[idx + i]?.str.includes(startStr);
        })
      );

  if (dataStartIndex === -1) return [{ str: 0 }];

  const data = pdfData.slice(dataStartIndex);
  const dataEndIndex = data.findIndex((l) => l.str.includes(delimiter));

  if (dataEndIndex === -1) return [{ str: 0 }];

  return isString
    ? data.slice(2, dataEndIndex).filter(cleanDataFromEmptySpace)
    : data
        .slice(startStrings.length, dataEndIndex)
        .filter(cleanDataFromEmptySpace);
}

const mapMappingItemsAndSumValues = (arr, pdfData) => {
  return arr
    .map((cStr) => {
      return getDataFromStringTillEnd(pdfData, cStr, true)[0];
    })
    .reduce((total, b) => total + parseInt(b.str), 0);
};

export const findGroupOrIndividualSummary = (pdfPagesData) => {
  const pageData = {
    group: {
      data: null,
      index: 0,
    },
    individual: {
      data: null,
      index: 0,
    },
  };
  pdfPagesData.forEach((pdfData, pageIndex) => {
    const cleanData = pdfData.filter(cleanDataFromEmptySpaces);
    if (cleanData) {
      const groupSummary = getDataFromStringTillEnd(
        cleanData,
        'GROUP SUMMARY ANALYSIS',
        true
      );
      if (groupSummary.length > 1 && !pageData.group.data) {
        pageData.group.data = cleanData;
        pageData.group.index = pageIndex;
      }

      const individualSummary = getDataFromStringTillEnd(
        cleanData,
        'INDIVIDUAL SUMMARY ANALYSIS',
        true
      );
      if (individualSummary.length > 1 && !pageData.individual.data) {
        pageData.individual.data = cleanData;
        pageData.individual.index = pageIndex;
      }
    }
  });

  return pageData.group.data ? pageData.group.data : pageData.individual.data;
};
export const processFFBBankStatement = (allText, nameAndDate) => {
  const pdfData = findGroupOrIndividualSummary(allText);
  const checks = getDataFromStringTillEnd(
    pdfData,
    'Checks & Other Debits',
    true
  );

  /// ACH Payables - start
  const achPayables = mapMappingItemsAndSumValues(
    [
      'ACH Origination Fees',
      'Same Day ACH Per Item Fee',
      'ACH Orig - Next Day Per Item',
      'ACH Orig - Same Day Per Item',
    ],
    pdfData
  );
  /// ACH Payables - end

  /// ACH Wires - start
  const wiresPayables = mapMappingItemsAndSumValues(
    [
      "Int'l Wire Transfer Fee",
      'Outgoing Domestic Wire Fee',
      'Outgoing Domestic Wire Online',
      'Outgoing Intl Wire Online',
    ],
    pdfData
  );
  /// ACH Wires - end

  const paymentMethodsUsed = {
    ACH: achPayables, // 4: ACH // ACH Origination Fees - done
    Checks: checks?.length ? checks[0].str : 0, // 3: Checks // Checks & Other Debits - done
    Wires: wiresPayables,
  };

  /// Checks Receivables - start
  const checksReceivables = mapMappingItemsAndSumValues(
    [
      'Encoding Fee - Deposited Items',
      'Out-Of-State Transit Items',
      'Oklahoma Country Transit Items',
      'Tulsa RCPC Transit Items',
      'Transit Items #5',
      'Deposits & Other Credits',
      'Mobile Deposit Fee',
      'On-Us Deposited Items',
      'Local Transit Items',
      'Oklahoma City Transit Items',
      'Mobile Deposit',
    ],
    pdfData
  );
  /// Checks Receivables - end

  /// ACH Receivables - start
  const achReceivables = mapMappingItemsAndSumValues(
    ['Same Day ACH Per Item Fee', 'ACH Block'],
    pdfData
  );
  /// ACH Receivables - end

  /// Wires Receivables - start
  const wiresReceivables = mapMappingItemsAndSumValues(
    [
      'Incoming Domestic Wire Fee',
      'Incoming International Wire',
      'Incoming International  Wire',
    ],
    pdfData
  );
  /// Wires Receivables - end

  /// Lockbox Receivables - start
  const lockboxReceivables = mapMappingItemsAndSumValues(
    ['Lockbox - Per Item Fee', 'ACH Positive Pay Module'],
    pdfData
  );
  /// Lockbox Receivables - end

  const typesOfReceivables = {
    ACH: achReceivables,
    Checks: checksReceivables,
    Wires: wiresReceivables,
    Lockbox: lockboxReceivables,
  };

  let value5 = 0 || '$0'; // 9 - done
  let value8 = 0 || '$0'; // 10 - done
  const totalServices = getDataFromStringTillEnd(
    pdfData,
    'TOTAL SERVICES AND REQUIRED BALANCES',
    true
  );

  const accountCharges = getDataFromStringTillEnd(
    pdfData,
    'YOUR ACCOUNT WILL BE CHARGED',
    true
  ); // 11 - done - totalFees
  const totalFees = accountCharges[0].str || '$0';

  if (totalServices.length) {
    value5 = parseCurrency(totalServices[0].str || '$0');
    if (totalServices.length > 1) {
      value8 = parseCurrency(totalServices[1].str || '$0');
    }
  }
  const opportunity = calculateOpportunity(
    paymentMethodsUsed.Checks,
    getDemoTenantByKey(DemoTenantsKeys.ffb)
  );
  let paymentRisksValuePositivePay = 0; // 5: Positive Pay - done
  let paymentRisksValueACH = 0;

  const positivePayData = mapMappingItemsAndSumValues(
    [
      'Positive Pay',
      'Check Pos Pay - Addl Entity',
      'Check Positive Pay Module',
      'Check Pos Pay-Per Item(Ck Iss)',
      'Check Positive Pay Except Item',
    ],
    pdfData
  );

  const positivePayACHData = mapMappingItemsAndSumValues(
    [
      'ACH Block',
      'ACH Positive Pay Module',
      'ACH Pos Pay - Addl Entity',
      'ACH Pos Pay - Exception Trans',
      'ACH Orig - Next Day Per Item',
    ],
    pdfData
  );

  if (positivePayACHData) {
    paymentRisksValueACH = 1;
  }

  if (positivePayData) {
    paymentRisksValuePositivePay = 1;
  }

  let balance = 0 || '$0'; // 6: EARNINGS ON - dollar value - done
  let value6 = 0.0; // 7: EARNINGS ON - ecr rate - percentage - done
  let value9 = 0 || '$0'; // 8 - none

  const earningsData = getDataFromStringTillEnd(pdfData, 'EARNINGS');
  if (earningsData.length) {
    const cleanedDataSpace = earningsData.map((m) => m.str);
    if (cleanedDataSpace.length >= 3) {
      const [newBalance, newValue6, newValue9] = cleanedDataSpace;
      balance = parseCurrency(newBalance || '$0');
      value6 = newValue6 || '0.0';
      value9 = parseCurrency(newValue9 || '$0');
    } else {
      const avgNetBalance = getDataFromStringTillEnd(
        pdfData,
        'AVERAGE NET COLLECTED BALANCE',
        true
      );
      if (avgNetBalance.length) {
        balance = parseCurrency(avgNetBalance[0].str || '$0');
      }
    }
  }
  const positivePayOrAchObject = {
    'Positive Pay': paymentRisksValuePositivePay === 1 ? 'all' : 'none',
    'ACH Positive Pay': paymentRisksValueACH === 1 ? 'all' : 'none',
  };

  const paymentRisks = {
    balance: {
      total: balance,
      isChecked: paymentRisksValuePositivePay > 0 && paymentRisksValueACH > 0,
    },
    fraudPreventionProducts: updateJsonObject(positivePayOrAchObject),
  };

  const estimatedTotalPayables = sumItemValues(
    updateJsonObject(paymentMethodsUsed)
  );
  const estimatedTotalReceivables = sumItemValues(
    updateJsonObject(typesOfReceivables) // subtracting Cash Vault from final count
  );

  return {
    value1: nameAndDate.name, // 1
    value2: nameAndDate.date, // 2
    valueN: nameAndDate.naics ? [nameAndDate.naics] : [],
    opportunity,
    paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
    typesOfReceivables: updateJsonObject(typesOfReceivables),
    estimatedTotalPayables,
    estimatedTotalReceivables,
    paymentRisks,
    value5,
    value6,
    value7: balance,
    totalFees: totalFees.replace('-', ''),
    value8,
    value9,
    datePrepared: nameAndDate.statementDate,
    officerName: nameAndDate.officerName,
  };
};

export const processComericaBankStatement = (pdfData, nameAndDate) => {
  const balanceSummaryPageIndex = findPagesBetweenSectionNames(
    pdfData,
    Pages.BalanceSummary.title
  );
  const resultsSummaryPageIndex = findPagesBetweenSectionNames(
    pdfData,
    Pages.ResultsSummary.title
  );
  const historySectionPageIndex = findPagesBetweenSectionNames(
    pdfData,
    Pages.HistoricalSummary.title
  );
  let serviceDetailsPageIndex = findPagesBetweenSectionNames(
    pdfData,
    Pages.ServiceDetails.title,
    !historySectionPageIndex.length ||
      historySectionPageIndex.includes(Infinity)
      ? null
      : Pages.HistoricalSummary.title
  );

  // handling when history page not found in statement
  if (
    !historySectionPageIndex.length ||
    historySectionPageIndex.includes(Infinity)
  ) {
    const sdpi = new Set(serviceDetailsPageIndex);
    sdpi.add(pdfData.length - 1);
    serviceDetailsPageIndex = Array.from(sdpi);
  }

  // will give us value of points 7, 8
  const balanceSummaryPage = pdfData[balanceSummaryPageIndex[0]];

  const balanceSummaryPageData = findValueInPage(balanceSummaryPage, [
    {
      keyToFind: 'Average Collected Balance',
      keyToFindRegex: /Average Collected Balance/,
      keyToFindValueIndex: 2,
      objectKeyName: 'value7',
    },
    {
      keyToFind: 'Less Balance Required For Services',
      keyToFindRegex: /Less Balance Required For Services/,
      keyToFindValueIndex: 2,
      objectKeyName: 'value8',
    },
  ]);
  // will give us value of points 4, 5, 6, 9
  const resultsSummaryPage = pdfData[resultsSummaryPageIndex[0]];
  const resultsSummaryPageData = findValueInPage(resultsSummaryPage, [
    {
      keyToFind: 'Analyzed Results',
      keyToFindRegex: /\$\d{1,3}(?:,\d{3})*\.\d{2}/,
      keyToFindValueIndex: 1,
      objectKeyName: 'value4',
    },
    {
      keyToFind: 'Less Total Analyzed Fees',
      keyToFindRegex: /Less Total Analyzed Fees/,
      keyToFindValueIndex: 1,
      objectKeyName: 'value5',
    },
    {
      keyToFind: 'Earnings Credit at',
      keyToFindRegex: /(\d+\.\d{6})%/,
      keyToFindValueIndex: 1,
      objectKeyName: 'value6',
    },
    {
      keyToFind: 'The total fee-based charge',
      keyToFindRegex: /\$\d{1,3}(?:,\d{3})*\.\d{2}/,
      keyToFindValueIndex: -1,
      objectKeyName: 'totalAnalyzedFeeBasedCharge',
    },
    {
      keyToFind: 'The total analyzed charge',
      keyToFindRegex: /\$\d{1,3}(?:,\d{3})*\.\d{2}/,
      keyToFindValueIndex: -1,
      objectKeyName: 'totalAnalyzedCharge',
    },
  ]);

  const totalFees = calculateTotalFees(resultsSummaryPageData);

  const pData2 = getPayablesOrReceivablesValuesV2(
    pdfData,
    serviceDetailsPageIndex,
    PayablesDataPointsV2Comerica
  );

  const rData2 = getPayablesOrReceivablesValuesV2(
    pdfData,
    serviceDetailsPageIndex,
    ReceivablesDataPointsV2
  );

  const prData2 = getPayablesOrReceivablesValuesV2(
    pdfData,
    serviceDetailsPageIndex,
    PaymentRisksDataPoints
  );

  const paymentMethodsUsed = {
    Checks: pData2['Checks Paid']['Total Volume'],
    ACH: pData2['ACH Origination']['Total Volume'],
    Wires: pData2['TPA Wire']['Total Volume'],
  };

  const typesOfReceivables = {
    'ACH/Checks': prData2['Incoming ACH/Check']['Total Volume'],
    Wires: prData2['Incoming Wires']['Total Volume'],
  };

  // formula B+C+D+E+RTP (from Data Mapping Sheet)
  // this is F
  const estimatedTotalPayables = sumItemValues(
    updateJsonObject(paymentMethodsUsed)
  );

  // this is K
  const estimatedTotalReceivables = sumItemValues(
    updateJsonObject({ ...typesOfReceivables }) // subtracting Cash Vault from final count
  );

  const opportunity = calculateOpportunity(
    paymentMethodsUsed.Checks,
    getDemoTenantByKey(DemoTenantsKeys.cb)
  );

  const paymentRisksValueD = rData2['Positive Pay Usage']['Total Volume'];
  const paymentRisksValueE = rData2['ACH Positive Pay Usage']['Total Volume'];
  const paymentRisks = {
    balance: {
      total: paymentRisksValueD + paymentRisksValueE,
      isChecked: paymentRisksValueD > 0 && paymentRisksValueE > 0,
    },
    fraudPreventionProducts: updateJsonObject({
      'Positive Pay': paymentRisksValueD,
      'ACH Positive Pay': paymentRisksValueE,
    }),
  };

  const feeAllocationData = processServiceDetailsPage(
    pdfData,
    serviceDetailsPageIndex,
    true,
    false
  );
  return {
    ...balanceSummaryPageData,
    ...resultsSummaryPageData,
    value1: nameAndDate.name,
    value2: nameAndDate.date,
    valueN: [nameAndDate.naics],
    opportunity,
    estimatedTotalPayables,
    estimatedTotalReceivables,
    payableData: pData2,
    receivableData: rData2,
    paymentMethodsUsed: updateJsonObject(paymentMethodsUsed),
    typesOfReceivables: updateJsonObject(typesOfReceivables),
    paymentRisks,
    totalFees,
    feeAllocationData,
  };
};

export const getMonthNumber = (monthName) => {
  return new Date(Date.parse(monthName + ' 1, 2000')).getMonth();
};

const findHoldingString = (startIndex, data) => {
  let found = false;
  for (let i = startIndex; i < data.length; i++) {
    if (
      data[i].str !== '' &&
      data[i].str === 'Holding' &&
      !/\d/.test(data[i].str)
    ) {
      found = true;
    }
  }
  return found;
};
function getCompanyNameFFB(data, startIndex) {
  let name = '';
  const newStartIndex = findHoldingString(startIndex, data)
    ? startIndex
    : startIndex + 1;

  for (let i = newStartIndex; i < data.length; i++) {
    if (
      data[i].str !== '' &&
      data[i].str !== 'Holding' &&
      !/\d/.test(data[i].str)
    ) {
      name += data[i].str + ' ';
    } else {
      break;
    }
  }
  return name.trim();
}

function extractMonthAndYearOnly(stringsArray) {
  // Regular expression to match the month and year
  const monthYearRegex =
    /\b(?:JANUARY|FEBRUARY|MARCH|APRIL|MAY|JUNE|JULY|AUGUST|SEPTEMBER|OCTOBER|NOVEMBER|DECEMBER|July|August|September|October|November|December)\s+\d{4}\b/i;

  const extractedMonthYear = stringsArray
    .map((str) => str.match(monthYearRegex)) // Extract matches directly
    .filter(Boolean) // Filter out null matches
    .map((match) => match[0]); // Get the matched string

  return extractedMonthYear[0];
}
function extractDataFFB(data) {
  const result = {
    statementDate: '',
    companyName: '',
    officerName: '',
    datePrepared: '',
  };

  let isCompanyNameSection = false;

  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    // extract company name (starts with "ANALYSIS" and continues until an empty string)
    if (obj.str === 'ANALYSIS') {
      isCompanyNameSection = true; // Start capturing company name
    } else if (isCompanyNameSection) {
      result.companyName = getCompanyNameFFB(data, i);
      isCompanyNameSection = false;
    }

    // extract officer name (starts with "OFFICER:" and captures subsequent objects and combine str)
    if (obj.str.includes('OFFICER:')) {
      result.officerName = '';
      for (let j = i + 1; j < data.length; j++) {
        const nextObj = data[j];
        if (nextObj.str.trim() === '' || nextObj.str.includes('DATE')) {
          break;
        }
        result.officerName += nextObj.str + ' ';
      }
    }

    // extracting date prepared (next to "PREPARED")
    if (obj.str.includes('PREPARED')) {
      result.datePrepared = data[i + 1]?.str.trim() || '';
    }
  }

  // everything before 'XXXXXXXXXXXX' is statement date
  const xxxIndex = data.findIndex((d) => d.str.includes('XXXXXXXXXXXX'));
  if (xxxIndex > -1) {
    result.statementDate = '';
    for (let i = xxxIndex - 1; i >= 0 && i >= xxxIndex - 2; i--) {
      result.statementDate = data[i].str + ' ' + result.statementDate;
    }
  }

  result.statementDate = extractMonthAndYearOnly([result.statementDate.trim()]);
  result.companyName = result.companyName.trim();
  result.officerName = result.officerName.trim();
  result.datePrepared = result.datePrepared.trim();

  return result;
}

export const getCompanyNameAndStatementDate = (
  data,
  organization,
  isCenturyBank,
  isComericaBank,
  isSVBB,
  isFFB,
  selectedTenant
) => {
  const rows = data[0]; // 0 has first page data as array of objects
  if (isSVBB || selectedTenant?.key === DemoTenantsKeys.svb) {
    const accountNameIndex = rows.findIndex((s) =>
      s.str.includes('Statement Ending')
    );
    const regex = /(\d{2}\/\d{2}\/\d{4})/;
    const indexJump = accountNameIndex + 2;
    const statementDate = rows[accountNameIndex].str;
    const companyName = rows[indexJump].str;
    const match = statementDate.match(regex);
    if (match) {
      const convertScrappedDate = moment(match[1]);
      return {
        name: companyName,
        date: new Date(
          convertScrappedDate.year(),
          convertScrappedDate.month(),
          convertScrappedDate.date()
        ),
        naics: organization.naics_code,
      };
    }
    return {};
  } else if (isComericaBank || selectedTenant?.key === DemoTenantsKeys.cb) {
    // for comerica
    const accountNameIndex = rows.findIndex((s) => s.str === 'Account:');
    const dateCycleIndex = rows.findIndex((s) => s.str === 'Cycle:');
    const accountName = rows[accountNameIndex - 2].str;
    const cycleDateText = rows[dateCycleIndex + 2].str;
    const dateParts = cycleDateText.split(', ');
    const monthName = dateParts[0];
    const year = dateParts[1];
    const monthNumber = getMonthNumber(monthName);
    const dateObj = new Date(year, monthNumber, 2);
    return {
      date: dateObj,
      name: accountName,
      naics: organization.naics_code,
    };
  } else {
    const cleanData = rows.filter((f) => f.str !== ' ');
    const result = extractDataFFB(cleanData);
    const monthName = result.statementDate.split(' ')[0];
    const year = result.statementDate.split(' ')[1];
    const monthNumber = getMonthNumber(monthName);
    const dateObj = new Date(year, monthNumber, 2);
    return {
      ...result,
      date: dateObj,
      name: result.companyName,
      naics: organization.naics_code,
    };
  }
};

import React, { useCallback, useContext, useMemo, useState } from 'react';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { AlertMessageContext } from '../../../contexts/AlertMessageContext';
import ReportService from '../../../services/report.service';
import { getNAICSWithIndustry, overflowing } from '../../../utils/Utils';
import {
  ActionTypes,
  EngagementReportConfig,
  MerchantReportV2Sections,
  ReportTypes,
} from '../../../components/reports/reports.constants';
import {
  createManualReportMerchantCoreData,
  getReportName,
} from '../../../components/reports/reports.helper.functions';
import _ from 'lodash';
import {
  APWData,
  ARRWData,
  FBCOWData,
  FBCWData,
  OVWData,
  RecommendationsDidYouKnowData,
  RefundAndChargebacksAmountProcessedData,
  RefundAndChargebacksOverviewData,
  WidgetTypes,
} from '../../../components/reportbuilder/constants/widgetsConstants';
import MetricsService from '../../../services/metrics.service';
import moment from 'moment';

const PageConfig = EngagementReportConfig[ReportTypes.MerchantV2].PageConfig;

const SyncMerchantReportModal = ({
  show,
  setShow,
  report,
  organization,
  handleGenerateReport,
  setParentModalShow,
}) => {
  const { setErrorMessage } = useContext(AlertMessageContext);
  const [refreshing, setRefreshing] = useState(false);

  const getDefaultReportPages = () => {
    return _.cloneDeep(PageConfig);
  };
  const getDefaultWidgets = (reportObject = {}) => {
    return {
      [MerchantReportV2Sections.CardPaymentAcceptanceOverview]: [
        {
          id: 1,
          widgetConfig: {
            ...APWData,
            data: reportObject,
          },
          type: WidgetTypes.APW,
        },
        {
          id: 2,
          widgetConfig: {
            ...OVWData,
            data: reportObject,
          },
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.FeeBreakdown]: [
        {
          id: 1,
          widgetConfig: { ...FBCWData, reportObject },
          type: WidgetTypes.FBCW,
        },
        {
          id: 2,
          widgetConfig: FBCOWData,
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.RefundsAndChargebacks]: [
        {
          id: 1,
          widgetConfig: {
            ...RefundAndChargebacksAmountProcessedData,
            data: reportObject,
          },
          type: WidgetTypes.APW,
        },
        {
          id: 2,
          widgetConfig: RefundAndChargebacksOverviewData,
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.Recommendations]: [
        {
          id: 1,
          widgetConfig: RecommendationsDidYouKnowData,
          type: WidgetTypes.OVW,
        },
        {
          id: 2,
          widgetConfig: { ...reportObject },
          type: WidgetTypes.FREE,
        },
      ],
      [MerchantReportV2Sections.YourDedicatedTeam]: [
        {
          id: 1,
          widgetConfig: { ...reportObject },
          type: WidgetTypes.TCW,
        },
      ],
      [MerchantReportV2Sections.Disclaimer]: [
        {
          id: 1,
          widgetConfig: ARRWData,
          type: WidgetTypes.ARRW,
        },
      ],
    };
  };

  const createManualReportFromCoreData = (aggregation, coreDataReports) => {
    const reportMapped = createManualReportMerchantCoreData(
      organization,
      aggregation,
      coreDataReports
    );

    const defaultWidgets = getDefaultWidgets(reportMapped);
    const defaultPages = getDefaultReportPages();

    return {
      name: reportMapped.companyName,
      date: reportMapped.reportDate,
      type: ReportTypes.Merchant,
      manualInput: {
        ...reportMapped,
        widgets: defaultWidgets,
        reportPages: defaultPages,
        ...getNAICSWithIndustry(organization),
        isAuto: true, // track whether this was generated by core
      },
    };
  };

  const handleConfirmDeleteReport = async () => {
    try {
      setRefreshing(true);
      if (organization?.leadAccountNumber) {
        const coreData = report?.manualInput?.aggregate ?? report.aggregate;
        const rptDate =
          coreData?.monthRange?.end || report?.reportDate?.toISOString();
        const coreDataReportData =
          await MetricsService.getOrganizationMerchantMetricsData(
            organization.id,
            {
              date: moment(rptDate).format('YYYY-MM'),
            }
          );
        if (Object.keys(coreDataReportData).length) {
          const coreDataReports = coreDataReportData.data;
          const newCoreData = createManualReportFromCoreData(
            coreDataReportData.aggregation,
            coreDataReports
          );
          await ReportService.updateReport(report.reportId, newCoreData);
          overflowing();
          setParentModalShow(false);
          setShow(false);
          handleGenerateReport(report, ActionTypes.UPDATE, {
            ...newCoreData,
            reportId: report.reportId,
            key: report.reportId,
          });
        } else {
          setErrorMessage('No data found.');
        }
      } else {
        setErrorMessage('No data found.');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <DeleteConfirmationModal
      showModal={show}
      loader={refreshing}
      setShowModal={setShow}
      customEvent={handleConfirmDeleteReport}
      itemsConfirmation={[{ ...report, title: getReportName(report) }]}
      heading="Refresh Report"
      description="Are you sure you want to refresh this Report?"
      positiveBtnText="Refresh"
      positiveBtnIcon="refresh"
    />
  );
};

const useResyncMerchantReportModal = (
  handleGenerateReport,
  setParentModalShow
) => {
  const [showModal, setShowModal] = useState(false);
  const [report, setReport] = useState('');
  const [organization, setOrganization] = useState('');

  const SyncMerchantReportModalModalCallback = useCallback(() => {
    return (
      <SyncMerchantReportModal
        show={showModal}
        setShow={setShowModal}
        report={report}
        organization={organization}
        handleGenerateReport={handleGenerateReport}
        setParentModalShow={setParentModalShow}
      />
    );
  }, [
    showModal,
    setShowModal,
    report,
    setReport,
    organization,
    setOrganization,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      setReport,
      setOrganization,
      SyncMerchantReportModal: SyncMerchantReportModalModalCallback,
    }),
    [setShowModal, SyncMerchantReportModalModalCallback]
  );
};

export default useResyncMerchantReportModal;

import React from 'react';

const FreeFormTextWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <>
      {widgetConfig?.data?.furtherRecommendationsText ? (
        <div
          className={`d-flex flex-column text-left w-100 px-2 report-widget ${
            whenPrinting ? 'p-2' : 'p-4'
          } gap-3`}
          style={{
            borderRadius: 'var(--rpt-widget-border-radius)',
            background: '#ffffff',
          }}
        >
          <h5 className="mb-0">More Recommendations</h5>
          <p className="px-2">
            {widgetConfig?.data?.furtherRecommendationsText || 'Add text.'}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default FreeFormTextWidget;

import SimpleModalCreation from '../../modal/SimpleModalCreation';
import { CardButton } from '../../layouts/CardLayout';
import RocketReachPeopleCard from './RocketReachPeopleCard';
import {
  isModuleAllowed,
  isPermissionAllowed,
  overflowing,
} from '../../../utils/Utils';
import React, { useEffect, useState } from 'react';
import prospectService from '../../../services/prospect.service';
import contactService from '../../../services/contact.service';
import Skeleton from 'react-loading-skeleton';
import AlreadyImportedWaterMark from './AlreadyImportedWaterMark';

// made the component generic so that we can call it from everywhere
// currently its being used in Import Profile click from Find Prospects UI and right bar in Org profile
const ImportProfile = ({
  children,
  openImportModal,
  setOpenImportModal,
  prospect,
  handleImport,
  loading,
  tenant,
  multiple,
  data = {},
  fromAutoAwesome = {},
}) => {
  const [infoLoading, setInfoLoading] = useState(true);
  const [loadingProspects, setLoadingProspects] = useState(false);
  const [newProspects, setNewProspects] = useState([...prospect]);
  const [importedList, setImportedList] = useState([]);
  const [isImported, setIsImported] = useState(false);
  const modalTitle = prospect.length > 1 || multiple ? 'contacts' : 'contact';

  const fetchContactsByExternalId = async () => {
    setLoadingProspects(true);
    try {
      const requests = prospect?.map(async ({ id, name, ...rest }) => {
        const [contactResponse, peopleResponse] = await Promise.all([
          contactService.getContacts({ external_id: id }, { limit: 1 }),
          prospectService.getContact({ id, name }),
        ]);

        return {
          contact: contactResponse.data.contacts[0] || null,
          people: { ...rest, ...peopleResponse.data } || null,
        };
      });

      const responses = await Promise.all(requests);

      const allContacts = responses.map((resp) => resp.contact).filter(Boolean);

      const rrProspects = responses.map((resp) => resp.people).filter(Boolean);

      setNewProspects(rrProspects);

      if (allContacts.length) {
        setImportedList(allContacts.map((cnt) => parseInt(cnt.external_id)));
        setIsImported(prospect.length === allContacts.length);
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setLoadingProspects(false);
    }
  };

  useEffect(() => {
    openImportModal && fetchContactsByExternalId();
  }, [prospect, openImportModal]);

  const getImportedListMsg = (multiple) => {
    const importedCount = importedList?.length || 0;
    const prospectsCount = prospect?.length || 0;
    if (prospectsCount === 0) {
      return '';
    }
    if (prospectsCount === importedCount) {
      return multiple
        ? `All ${importedCount} contacts are already imported.`
        : 'This contact is already imported.';
    }
    if (importedCount > 0) {
      return multiple
        ? `(${importedCount} ${
            importedCount > 1 ? 'contacts are' : 'contact is'
          } already imported)`
        : importedCount === 1
        ? `(${importedCount} contact is already imported)`
        : '';
    }
    return prospectsCount === 1 ? 'Would you like to import this contact?' : '';
  };

  const handleCloseModal = () => {
    overflowing();
    setImportedList([]);
    setIsImported(false);
    setOpenImportModal(false);
  };
  return (
    <div>
      {openImportModal && (
        <SimpleModalCreation
          open={openImportModal}
          bodyClassName="text-center"
          modalTitle={`Import ${modalTitle}`}
          customModal="w-50"
          onHandleCloseModal={handleCloseModal}
          noFooter
          bankTeam
        >
          <div>
            <p className="font-inter text-left">
              {loadingProspects ? (
                <Skeleton height={10} width={300} />
              ) : (
                <>
                  {(() => {
                    const remainingProspects =
                      prospect?.length - importedList?.length || 0;
                    if (remainingProspects <= 0) {
                      return getImportedListMsg(prospect?.length > 1);
                    }
                    if (prospect?.length > 1) {
                      return `Would you like to import ${remainingProspects} ${
                        remainingProspects === 1 ? 'contact' : 'contacts'
                      }? ${getImportedListMsg(true)}`;
                    }
                    return getImportedListMsg(false);
                  })()}
                </>
              )}
            </p>
            {multiple ? (
              <div
                className="d-flex align-items-center gap-2 flex-column"
                style={{
                  maxHeight: 410,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {newProspects?.map(({ id, ...prospect }) => {
                  const alreadyImported = importedList.includes(id);
                  return (
                    <div key={id} className="card w-100 position-relative">
                      <div className="card-body">
                        <div
                          className={`rocketreach-card ${
                            alreadyImported ? 'opacity-md' : ''
                          }`}
                        >
                          <RocketReachPeopleCard
                            prospect={{ ...prospect, id }}
                            showSocialLinks={false}
                            withContactInfo={true}
                            setInfoLoading={setInfoLoading}
                            containerStyle="align-items-start"
                          />
                        </div>
                        {alreadyImported && <AlreadyImportedWaterMark />}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="card w-100">
                <div className="card-body position-relative mb-2">
                  <RocketReachPeopleCard
                    prospect={newProspects[0]}
                    showSocialLinks={false}
                    withContactInfo={true}
                    setInfoLoading={setInfoLoading}
                    containerStyle="align-items-start"
                  />
                  {importedList.includes(newProspects[0]?.id) && (
                    <AlreadyImportedWaterMark />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mb-0">
            <CardButton
              type="button"
              title="Cancel"
              className="btn btn-sm btn-outline-danger w-100"
              onClick={handleCloseModal}
            />

            {data?.external_id && (
              <CardButton
                type="button"
                title="Merge"
                variant="primary"
                className="btn-sm w-100"
                isLoading={fromAutoAwesome.loading}
                disabled={fromAutoAwesome.loading || infoLoading}
                onClick={fromAutoAwesome.handleMerge}
              />
            )}

            {isPermissionAllowed('contacts', 'create') &&
              isModuleAllowed(tenant?.modules, 'contacts') &&
              isModuleAllowed(
                tenant?.modules,
                'prospecting_peoples_import'
              ) && (
                <CardButton
                  type="button"
                  title={isImported ? 'Imported' : 'Import'}
                  variant="primary"
                  className="btn-sm w-100"
                  isLoading={loading}
                  disabled={infoLoading || isImported}
                  onClick={() => {
                    overflowing();
                    handleImport(newProspects, importedList);
                  }}
                />
              )}
          </div>
        </SimpleModalCreation>
      )}
      {children}
    </div>
  );
};

export default ImportProfile;

import React from 'react';

const AlreadyImportedWaterMark = () => {
  return (
    <span
      style={{ letterSpacing: 0.5 }}
      className="position-absolute right-0 top-0 py-1 px-2 z-index-99 rounded-0 text-black font-weight-semi-bold bg-gray-400 fs-10 text-uppercase"
    >
      ALREADY IMPORTED
    </span>
  );
};

export default AlreadyImportedWaterMark;

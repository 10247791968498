import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import SimpleModal from '../modal/SimpleModal';
import stringConstants from '../../utils/stringConstants.json';
import {
  createBlobObject,
  emailRegex,
  getPatternErrorMessage,
  overflowing,
} from '../../utils/Utils';
import FormColor from '../siteSettings/FormColor';
import IdfTooltip from '../idfComponents/idfTooltip';
import IdfIcon from '../idfComponents/idfIcon';
import { BRANDING_LABEL, CHOOSE_IMAGE_FILE } from '../../utils/constants';
import DragDropUploadFile from '../commons/DragDropUploadFile';
import userService from '../../services/user.service';
import InputValidation from '../commons/InputValidation';
import Asterick from '../commons/Asterick';
import { useForm } from 'react-hook-form';
import ModulesToggles from '../TenantCheckBox';
import MaterialIcon from '../commons/MaterialIcon';
import ControllerValidation from '../commons/ControllerValidation';
import { CheckboxInput } from '../layouts/CardLayout';
import { FormCheck } from 'react-bootstrap';
import TooltipComponent from '../lesson/Tooltip';
import SMTPConfiguration from './SMTPConfiguration';

const ExpandCollapseCard = ({
  label,
  heading,
  state = false,
  showAsterick = true,
  children,
}) => {
  const [isExpand, setIsExpand] = useState(state);
  return (
    <Card className="w-100 my-3">
      <CardHeader
        className={`bg-hover-gray p-3 cursor-pointer`}
        onClick={() => setIsExpand(!isExpand)}
      >
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-1">
            {showAsterick && <Asterick />}
            <h5 className="mb-0">{heading}</h5>
            <span
              className="text-muted fs-7 position-relative label-description"
              style={{ top: 2 }}
            >
              ({label})
            </span>
          </div>
          <MaterialIcon icon={isExpand ? 'expand_less' : 'expand_more'} />
        </div>
      </CardHeader>
      {isExpand && <CardBody className="p-3">{children}</CardBody>}
    </Card>
  );
};

const defaultIdpSettings = {
  hideLoginInputs: false,
  hideForgetPassword: false,
  disableProfileChanges: false,
  disablePasswordChanges: false,
};

const defaultSettings = {
  idp: {
    enabled: false,
    providers: [{ alias: 'oidc' }],
    settings: defaultIdpSettings,
  },
  organizations: { dataTab: true },
};

const IDP_SETTINGS_TOGGLES = [
  {
    id: 1,
    key: 'hideLoginInputs',
    text: 'Hide Login Inputs',
    description:
      'This will hide both email and password fields from login screen.',
  },
  {
    id: 2,
    key: 'hideForgetPassword',
    text: 'Hide Forget Password Link',
    description: 'This will hide forget password link from login screen.',
  },
  {
    id: 3,
    key: 'disableProfileChanges',
    text: 'Disable Profile Changes',
    description:
      'This will disable user profile update under profile settings.',
  },
  {
    id: 4,
    key: 'disablePasswordChanges',
    text: 'Disable Password Change',
    description:
      'This will disable ability for users to change their passwords in profile settings security area.',
  },
];

const CreateTenantModal = ({
  setErrorMessage,
  setSuccessMessage,
  showLoading,
  showModal,
  setSelectedEditData,
  setShowModal,
  isLoading,
  handleUpdateTenant,
  data = [],
  selectedEditData,
  handleCreateTenant,
}) => {
  const defaultOptions = {
    owner: {
      email: '',
    },
    name: '',
    domain: '',
    description: '',
    use_logo: false,
    modules: [],
    logo: '',
    icon: '',
    type: 'owner',
    colors: {
      secondaryColor: '',
    },
  };
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultOptions,
  });
  const constants = stringConstants.tenants;
  const [logo, setLogo] = useState();
  const [icon, setIcon] = useState();
  const [tenantForm, setTenantForm] = useState(defaultOptions);
  const [iconId, setIconId] = useState();
  const [logoId, setLogoId] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [iconLoading, setIconLoading] = useState(false);
  const domainInputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.getModifierState('CapsLock')) {
      event.preventDefault();
      setErrorMessage('Please Off your caps lock');
    }
  };
  const onLoadIcon = async (event) => {
    setIconLoading(true);
    const target = event.target.files[0];
    setIcon(target);
    let iconId = target?.id || '';
    if (target && target?.lastModified) {
      iconId = await onUploadLogo(target);
    }
    setIconId(iconId);
    setIconLoading(false);
  };
  const getEnvSettings = (currentSettings) => {
    if (!currentSettings?.idp?.settings) {
      currentSettings.idp = {
        ...currentSettings?.idp,
        settings: defaultIdpSettings,
      };
    }
    return currentSettings;
  };
  useEffect(() => {
    const tenantData = {
      name: selectedEditData.name,
      description: selectedEditData.description,
      modules: selectedEditData.modules?.split(',') || [],
      domain: selectedEditData.domain,
      use_logo: selectedEditData.use_logo || null,
      icon: selectedEditData.icon,
      logo: selectedEditData.logo,
      colors: selectedEditData.colors,
      settings: getEnvSettings(selectedEditData.settings || defaultSettings),
    };
    setTenantForm((prevForm) => ({
      ...prevForm,
      ...tenantData,
    }));
    setValue('name', selectedEditData.name);
    setValue('domain', selectedEditData.domain);
    setValue('use_logo', selectedEditData.use_logo);
    setValue('secondaryColor', selectedEditData.colors);
  }, [selectedEditData]);

  const handleImageState = () => {
    setLogo();
    setIcon();
  };
  const onLoadLogo = async (event) => {
    const target = event.target.files[0];
    setLogo(target);
    let logoId = target?.id || '';
    if (target && target?.lastModified) {
      logoId = await onUploadLogo(target);
    }
    setTenantForm((prevForm) => ({
      ...prevForm,
      logo: logoId,
    }));
    setValue('logo', logoId);
    setLogoId(logoId);
  };
  const onChangeColor = (value) => {
    const colorValue = {
      primaryColor: value?.secondaryColor,
      ...value,
      name: 'custom',
    };
    setValue('secondaryColor', colorValue);

    setTenantForm((prevForm) => ({
      ...prevForm,
      colors: colorValue,
    }));
  };
  const onUploadLogo = async (file) => {
    const form = new FormData();
    form.append('file', await createBlobObject(file), file.name);
    form.append('isPublic', true);
    const {
      data: {
        data: { id },
      },
    } = await userService.uploadAvatar(form);
    return id;
  };

  const handleChange = (e) => {
    const target = e.target;
    const inputValue = target.value;
    if (target.name !== 'email') {
      setTenantForm((prevForm) => ({
        ...prevForm,
        [target.name]: inputValue,
      }));
    } else {
      setTenantForm((prevForm) => ({
        ...prevForm,
        owner: {
          ...tenantForm?.owner,
          email: inputValue,
        },
      }));
    }
  };

  const getLogo = async (id) => {
    const response = await userService.getFile(id);
    return response?.data;
  };
  const getTenantImage = async () => {
    try {
      if (selectedEditData.logo) {
        const partnerLogo = await getLogo(selectedEditData.logo);

        if (partnerLogo) {
          const newPartnerLogo = {
            ...partnerLogo,
            name: partnerLogo?.filename_download,
            size: partnerLogo?.filesize,
          };
          setLogo(newPartnerLogo);
        }
        setValue('logo', partnerLogo.filename_download);
      }

      if (selectedEditData.icon) {
        const partnerIcon = await getLogo(selectedEditData.icon);

        if (partnerIcon) {
          setIcon({
            ...partnerIcon,
            name: partnerIcon?.filename_download,
            size: partnerIcon?.filesize,
          });
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  useEffect(() => {
    getTenantImage();
  }, [selectedEditData]);

  const handleAccessChange = (e) => {
    const target = e.target;
    if (target.name === 'use_logo' && target.checked) {
      setValue('use_logo', true);

      setTenantForm((prevForm) => ({
        ...prevForm,
        use_logo: true,
      }));
    } else {
      setValue('use_logo', '');

      setTenantForm((prevForm) => ({
        ...prevForm,
        use_logo: false,
      }));
    }
  };

  const handleFormSubmit = async () => {
    if (selectedCheckboxes?.length === 0) {
      return setErrorMessage('Please add module permissions');
    }
    const tenantData = {
      ...tenantForm,
      type: 'owner',
      icon: iconId,
      modules: selectedCheckboxes,
      logo: logoId,
    };
    if (selectedEditData.id) {
      const update = await handleUpdateTenant(tenantData);
      if (update) {
        setTenantForm({});
        reset(defaultOptions);
        handleImageState();
      }
    } else {
      const create = await handleCreateTenant(tenantData);
      if (create) {
        setTenantForm({});
        reset(defaultOptions);
        handleImageState();
      } else {
        return overflowing();
      }
    }

    overflowing();
    closeModal();

    if (!errors) {
      setTenantForm({});
    }
  };

  const closeModal = () => {
    overflowing();
    setShowModal(false);
    setSelectedEditData('');
    setTenantForm({
      name: '',
      domain: '',
      description: '',
      use_logo: false,
      modules: [],
      logo: '',
      icon: '',
      type: '',
      colors: '',
      email: '',
    });
    reset(defaultOptions);
    handleImageState();
  };

  useEffect(() => {
    if (Object?.keys(selectedEditData)?.length > 0) {
      setSelectedCheckboxes(selectedEditData?.modules);
    }
  }, [selectedEditData]);
  const onRemoveFile = () => {
    setValue('logo', '');
    setLogoId('');

    setTenantForm((prevForm) => ({
      ...prevForm,
      logo: '',
    }));
  };

  const handleEnableSSOLogin = (e) => {
    const { checked } = e.target;
    setTenantForm((prevForm) => ({
      ...prevForm,
      settings: {
        ...tenantForm.settings,
        idp: {
          ...tenantForm.settings.idp,
          enabled: checked,
          settings: !checked
            ? defaultIdpSettings
            : tenantForm.settings.idp.settings,
        },
      },
    }));
  };
  const handleSettingToggleChange = (e) => {
    const { name, checked } = e.target;

    setTenantForm((prevForm) => ({
      ...prevForm,
      settings: {
        ...tenantForm.settings,
        idp: {
          ...tenantForm.settings.idp,
          settings: {
            ...tenantForm.settings.idp.settings,
            [name]: checked,
          },
        },
      },
    }));
  };

  return (
    <SimpleModal
      modalTitle={
        selectedEditData?.id ? (
          <span>
            {constants.edit.title}{' '}
            <span className="text-muted">
              ({selectedEditData?.title || selectedEditData?.name})
            </span>
          </span>
        ) : (
          constants.create.addTenantModalTitle
        )
      }
      onHandleCloseModal={() => closeModal()}
      open={showModal}
      modalBodyClass="pipeline-board-edit-form overflow-y-auto"
      buttonLabel={
        selectedEditData?.id
          ? constants.edit.btnEditTenant
          : constants.create.btnAddTenant
      }
      handleSubmit={handleSubmit(handleFormSubmit)}
      allowCloseOutside={false}
      isLoading={showLoading}
      customModal="modal-dialog-custom"
    >
      <span className="font-size-sm">{constants.create.textGroupName}</span>
      <>
        <Row>
          <Col>
            <FormGroup>
              <Label htmlFor="" className="mt-0 col-form-label">
                <h5 className="mb-0">
                  <Asterick /> Name
                </h5>
              </Label>
              <InputValidation
                name="name"
                type="input"
                placeholder="Enter Name"
                value={tenantForm?.name}
                validationConfig={{
                  required: true,
                  inline: false,
                  pattern: /^[A-Za-z\s]+$/,
                  onChange: handleChange,
                  validateSpaces: true, // this will notify user if he keeps adding spaces in start and dont write other text
                }}
                errors={errors}
                register={register}
                errorDisplay="mb-0"
              />
            </FormGroup>
          </Col>
          <Col className="pl-0">
            <FormGroup>
              <Label htmlFor="" className="col-form-label">
                <h5 className="mb-0">
                  <Asterick /> Domain
                </h5>
              </Label>
              <InputValidation
                name="domain"
                type="input"
                fieldType="url"
                placeholder="Enter Domain"
                value={tenantForm?.domain}
                onKeyDown={handleKeyDown}
                ref={domainInputRef}
                validationConfig={{
                  required: true,
                  inline: false,
                  onChange: handleChange,
                  validateSpaces: true,
                }}
                errorMessage={getPatternErrorMessage('URL')}
                errors={errors}
                register={register}
                errorDisplay="mb-0"
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label htmlFor="" className="col-form-label">
            <h5 className="mb-0">Description</h5>
          </Label>
          <InputValidation
            name="description"
            type="textarea"
            placeholder="Enter Description"
            value={tenantForm?.description}
            validationConfig={{
              required: false,
              onChange: handleChange,
              maxLength: {
                value: 255,
                message: 'Description cannot exceed 255 characters.',
              },
            }}
            errors={errors}
            register={register}
            classNames="min-h-120"
          />
        </FormGroup>
        {!selectedEditData && (
          <FormGroup>
            <Label htmlFor="" className="col-form-label">
              <h5 className="mb-0">
                <Asterick /> Owner Email
              </h5>
            </Label>
            <InputValidation
              name="email"
              type="input"
              placeholder="Enter Owner Email Address"
              value={tenantForm.email}
              validationConfig={{
                required: true,
                inline: false,
                pattern: emailRegex,
                onChange: handleChange,
              }}
              errorMessage={getPatternErrorMessage('EMAIL')}
              errors={errors}
              register={register}
              errorDisplay="mb-0"
            />
          </FormGroup>
        )}
        <ExpandCollapseCard
          label="Select which modules to turn on/off for the tenant."
          heading="Modules"
        >
          <ModulesToggles
            modulesData={selectedEditData}
            selectedCheckboxes={selectedCheckboxes}
            setSelectedCheckboxes={setSelectedCheckboxes}
          />
        </ExpandCollapseCard>
        <ExpandCollapseCard
          label="Select which sections to turn on/off for the tenant for single sign on."
          heading="SSO Configuration"
        >
          <div className={tenantForm?.settings?.idp?.enabled ? 'mb-2' : 'mb-0'}>
            <h5 className="mb-0">
              <FormCheck
                id="toggleEnableIdp"
                name="toggleEnableIdp"
                type="switch"
                checked={tenantForm?.settings?.idp?.enabled}
                custom={true}
                label="Enable SSO Login"
                onChange={handleEnableSSOLogin}
                className="font-size-sm"
              />
            </h5>
          </div>
          {tenantForm?.settings?.idp?.enabled ? (
            <>
              {IDP_SETTINGS_TOGGLES.map((toggle) => (
                <div
                  key={toggle.id}
                  className="d-flex align-items-center py-1"
                  style={{ paddingLeft: 12 }}
                >
                  <FormCheck
                    id={toggle.key}
                    name={toggle.key}
                    type="checkbox"
                    checked={tenantForm?.settings?.idp?.settings[toggle.key]}
                    custom={true}
                    label={toggle.text}
                    onChange={handleSettingToggleChange}
                    className="font-size-sm2"
                  />
                  <a
                    className="icon-hover-bg d-flex align-items-center cursor-default"
                    style={{ left: -2 }}
                  >
                    <TooltipComponent title={toggle.description}>
                      <MaterialIcon
                        icon="info"
                        rounded
                        clazz="font-size-xl text-gray-900"
                      />
                    </TooltipComponent>
                  </a>
                </div>
              ))}
            </>
          ) : null}
        </ExpandCollapseCard>
        {selectedEditData && (
          <ExpandCollapseCard
            label="Configure your email server settings for sending notifications."
            heading="SMTP Configuration"
          >
            <SMTPConfiguration tenantId={selectedEditData.id} />
          </ExpandCollapseCard>
        )}
        <ExpandCollapseCard
          label="Update tenant's branding, logos, colors etc."
          heading={BRANDING_LABEL}
          state={true}
        >
          <div className="border-bottom border-gray-300 pb-4">
            <div className="d-flex row align-items-center justify-content-between">
              <Col md={6}>
                <h5>Icon</h5>
              </Col>
              <Col md={6}>
                {iconLoading ? (
                  <Spinner />
                ) : (
                  <DragDropUploadFile
                    file={icon}
                    setFile={setIcon}
                    onLoadFile={onLoadIcon}
                    preview
                    isIcon={true}
                    logoId={iconId || tenantForm?.icon}
                    chooseFileText={CHOOSE_IMAGE_FILE}
                    name="brandingIcon"
                    containerHeight={60}
                    allowedFormat={['.png', '.jpeg', '.jpg']}
                    displayFormats={['.png', '.jpeg', '.jpg']}
                    errors={errors}
                    uploadOnDrop={true}
                    showUploadIcon={false}
                    validationConfig={{
                      required: true,
                      inline: false,
                    }}
                  />
                )}
              </Col>
            </div>
            <div className="row d-flex align-items-center mt-4 justify-content-between">
              <Col md={6}>
                <h5 className="d-flex align-items-center">
                  <Asterick /> Logo&nbsp;
                  <IdfTooltip
                    placement="bottom"
                    text="A partner logo can be 280px wide and 80px tall. Please remove all extra white spaces around the logo before uploading."
                  >
                    <IdfIcon icon="help_outline" />
                  </IdfTooltip>
                </h5>
              </Col>
              <Col md={6}>
                <ControllerValidation
                  name={'logo'}
                  errors={errors}
                  form={tenantForm}
                  errorDisplay="mb-0"
                  control={control}
                  validationConfig={{
                    required: `Logo is required.`,
                  }}
                  renderer={({ field }) => (
                    <DragDropUploadFile
                      {...field}
                      file={logo}
                      setFile={setLogo}
                      onLoadFile={onLoadLogo}
                      name="logo"
                      classnames={'d-flex align-items-center'}
                      preview
                      logoId={logoId || tenantForm?.logo}
                      chooseFileText={CHOOSE_IMAGE_FILE}
                      containerHeight={85}
                      allowedFormat={['.png', '.jpeg', '.jpg']}
                      displayFormats={['.png', '.jpeg', '.jpg']}
                      uploadOnDrop={true}
                      showUploadIcon={false}
                      onRemoveFile={onRemoveFile}
                      errors={errors}
                      fieldState={getFieldState('logo')}
                      validationConfig={{
                        required: true,
                        inline: false,
                      }}
                      register={register}
                      errorDisplay="mb-0"
                    />
                  )}
                />
              </Col>
            </div>
          </div>
          <div className="row d-flex align-items-center mt-4 justify-content-between">
            <Col md={8}>
              <h5>
                <Asterick /> Use Logo Instead of Icon
              </h5>
            </Col>
            <Col md={4} className="text-right">
              <ControllerValidation
                name={'use_logo'}
                errors={errors}
                form={tenantForm}
                errorDisplay="mb-0"
                control={control}
                validationConfig={{
                  required: `Use logo is required.`,
                }}
                renderer={({ field }) => (
                  <CheckboxInput
                    {...field}
                    id={'useLogoInsteadOfIcon'}
                    name={'use_logo'}
                    fieldState={getFieldState('use_logo')}
                    validationConfig={{
                      required: true,
                      inline: false,
                    }}
                    value={tenantForm?.use_logo}
                    checked={tenantForm?.use_logo}
                    onChange={handleAccessChange}
                  />
                )}
              />
            </Col>
          </div>
          <div className="row d-flex align-items-center mt-4 justify-content-between">
            <Col md={8}>
              <h5 className="d-flex align-items-center">
                <Asterick /> Accent Color&nbsp;
                <IdfTooltip
                  placement="bottom"
                  text="An accent color will be applied to all links, buttons."
                >
                  <IdfIcon icon="help_outline" />
                </IdfTooltip>
              </h5>
            </Col>
            <Col md={4} className="text-right">
              <ControllerValidation
                name={'secondaryColor'}
                errors={errors}
                form={tenantForm}
                errorDisplay="mb-0"
                control={control}
                validationConfig={{
                  required: `Color is required.`,
                }}
                renderer={({ field }) => (
                  <FormColor
                    name="secondaryColor"
                    fieldState={getFieldState('secondaryColor')}
                    validationConfig={{
                      required: true,
                      inline: false,
                    }}
                    value={
                      tenantForm?.colors?.secondaryColor ||
                      selectedEditData.colors?.secondaryColor
                    }
                    onChange={onChangeColor}
                  />
                )}
              />
            </Col>
          </div>
        </ExpandCollapseCard>
      </>
    </SimpleModal>
  );
};

export default CreateTenantModal;

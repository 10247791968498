import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import MaterialIcon from './MaterialIcon';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';
import { ColorCoverage } from '../reports/reports.constants';

const ColorSelectionDropdown = ({
  options,
  handleFilterSelect,
  filterOptionSelected,
  btnToggleStyle = 'btn-sm',
  showOnlyIcon = false,
  menuClass,
  ignoreChildHover,
  openFilter,
  setOpenFilter,
  customKeys = ['key', 'name', 'description'],
  icon,
  validationConfig,
  fieldState,
  optionIconClass,
}) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef();
  useOutsideClickDropDown(menuRef, open, setOpen);
  return (
    <Dropdown show={openFilter || open} onToggle={setOpenFilter || setOpen}>
      <Dropdown.Toggle
        variant="white"
        className={`btn btn-white dropdown-toggle ${btnToggleStyle} ${
          validationConfig?.required
            ? 'border-left-4 border-left-danger rounded'
            : ''
        } ${
          fieldState?.invalid && !fieldState?.error?.ref?.value
            ? 'border-danger'
            : ''
        }`}
        id="dropdown-basic-colors"
      >
        <div className="d-flex align-items-center text-left w-100">
          {ColorCoverage[filterOptionSelected[1]?.value]?.icon ? (
            <div
              className={`rounded-circle d-flex ${
                ColorCoverage[filterOptionSelected[1]?.value]?.bg
              }`}
            >
              <MaterialIcon
                icon={ColorCoverage[filterOptionSelected[1]?.value]?.icon}
                rounded
                clazz={`fs-5 text-white`}
              />
            </div>
          ) : (
            <MaterialIcon
              rounded
              icon={
                ColorCoverage[filterOptionSelected[1]?.value]?.icon ||
                icon ||
                'filter_list'
              }
              clazz={`fs-5 ${showOnlyIcon ? '' : 'mr-1'}`}
            />
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{ zIndex: 250, width: 50 }}
        ref={menuRef}
        className={`p-0 z-index-250 py-1 ${ignoreChildHover}`}
      >
        <div className={`overflow-y-auto ${menuClass}`}>
          {options.map((option) => (
            <Dropdown.Item
              key={option.id}
              href="#"
              onClick={(e) => {
                if (option.key === -1) {
                  setOpen(true);
                  return;
                }
                handleFilterSelect(e, option);
              }}
              className={`px-3 ${
                filterOptionSelected &&
                filterOptionSelected[customKeys[0]] === option[customKeys[0]]
                  ? 'text-primary all-child'
                  : ''
              } ${option.showHide} ${
                option.key === -1
                  ? 'inactive font-weight-normal bg-white cursor-default'
                  : ''
              } ${
                optionIconClass &&
                filterOptionSelected &&
                filterOptionSelected[customKeys[0]] === option[customKeys[0]]
                  ? 'bg-gray-5'
                  : ''
              }`}
            >
              <div className="d-flex px-0 align-items-center justify-content-between py-1">
                <div className="d-flex align-items-center">
                  <div className={`mr-2 rounded-circle d-flex ${option.bg}`}>
                    <MaterialIcon
                      icon={option.icon}
                      rounded
                      clazz={`fs-5 text-white`}
                    />
                  </div>
                  <span
                    className={`d-flex flex-column ${
                      filterOptionSelected &&
                      filterOptionSelected[customKeys[0]] ===
                        option[customKeys[0]]
                        ? 'fw-bold'
                        : ''
                    }`}
                  >
                    <span className="d-flex text-capitalize align-items-center gap-1">
                      {option[customKeys[1]]}
                    </span>
                  </span>
                </div>
                {filterOptionSelected &&
                  filterOptionSelected[1]?.value === option[customKeys[0]] && (
                    <MaterialIcon icon="check" clazz="fw-bold" />
                  )}
              </div>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColorSelectionDropdown;

import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment-timezone';

import Alert from '../components/Alert/Alert';
import {
  DEFAULT_LANGUAGE,
  SERVER_ERROR,
  SUCCESS_INFO,
  LOGIN_INFO,
  CHANGE_LOGIN_INFO,
  TIMEZONE_DESCRIPTION,
  SAVE,
} from '../utils/constants';
import stringConstants from '../utils/stringConstants.json';
import { useProfileContext } from '../contexts/profileContext';
import userService from '../services/user.service';
import FormItem from '../components/profile/FormItem';
import Loading from '../components/Loading';
import AlertWrapper from '../components/Alert/AlertWrapper';
import IdfDropdownSearch from '../components/idfComponents/idfDropdown/IdfDropdownSearch';
import IdfFormInput from '../components/idfComponents/idfFormInput/IdfFormInput';
import useEnvSettings from '../hooks/useEnvSettings';

const timezone = `(${moment
  .parseZone(moment().format())
  .format('Z')}) ${moment.tz.guess()}`;

function Profile({ setActiveTab, loading, setLoading }) {
  const userInputs = stringConstants.users.inputs;
  const [firstLoading, setFirstLoading] = useState(false);
  const { profileInfo, setProfileInfo } = useProfileContext();
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    phone: '1 631-860-5659',
    email: '',
    title: '',
    language: DEFAULT_LANGUAGE,
    datetimeFormat: DEFAULT_LANGUAGE,
    timezone: '', // timeZone.value
    currency: 'USD',
  });
  const { disableProfileChanges, disablePasswordChanges } = useEnvSettings();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    async function getUser() {
      setFirstLoading(true);

      const {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        avatar,
        title,
        phone,
      } = profileInfo || {};

      setUserInfo({
        id,
        firstName,
        lastName,
        email,
        avatar,
        title,
        phone,
      });

      setFirstLoading(false);
    }

    getUser();
  }, [profileInfo]);

  function onHandleChange(e) {
    const target = e.target;
    let inputValue = target.value;
    if (inputValue.trim() === '') {
      inputValue = '';
    }
    setUserInfo({
      ...userInfo,
      [target.name]: inputValue,
    });
  }

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const userInfoBody = { ...userInfo };

    const resp = await userService
      .updateUserInfoById(profileInfo.id, {
        ...userInfoBody,
        first_name: userInfoBody.firstName,
        last_name: userInfoBody.lastName,
      })
      .catch((err) => console.log(err));
    setProfileInfo(userInfoBody);
    if (!resp) {
      setErrorMessage(SERVER_ERROR);
    } else {
      setSuccessMessage(SUCCESS_INFO);
    }

    setLoading(false);
    setProfileInfo((prev) => ({
      ...prev,
      first_name: userInfoBody.firstName,
      last_name: userInfoBody.lastName,
    }));
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <div className="card shadow-none border-0 mb-0">
        {firstLoading && <Loading bars={true} />}

        {!firstLoading && (
          <div className="card-body p-0">
            <form onSubmit={onHandleSubmit}>
              <div className="px-4 pt-3">
                <FormItem
                  title={userInputs.firstName.title}
                  labelFor="firstNameLabel"
                  classLabel="font-size-sm text-right"
                >
                  <input
                    type="text"
                    className="form-control mr-2 "
                    name="firstName"
                    id="firstNameLabel"
                    disabled={disableProfileChanges === true}
                    placeholder={`${userInputs.firstName.placeholderName}`}
                    aria-label={`${userInputs.firstName.placeholderName}`}
                    value={userInfo.firstName}
                    data-uw-styling-context="true"
                    onChange={onHandleChange}
                  />
                </FormItem>

                <FormItem
                  title={userInputs.lastName.title}
                  labelFor="lastNameLabel"
                  classLabel="font-size-sm text-right"
                >
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastNameLabel"
                    disabled={disableProfileChanges === true}
                    placeholder={`${userInputs.lastName.placeholderName}`}
                    aria-label={`${userInputs.lastName.placeholderName}`}
                    value={userInfo.lastName}
                    data-uw-styling-context="true"
                    onChange={onHandleChange}
                  />
                </FormItem>

                <FormItem
                  title={userInputs.title.title}
                  labelFor="titleLabel"
                  classLabel="font-size-sm text-right"
                >
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="titleLabel"
                    disabled={disableProfileChanges === true}
                    placeholder={userInputs.title.placeholder}
                    aria-label={userInputs.title.placeholder}
                    value={userInfo.title}
                    data-uw-styling-context="true"
                    onChange={onHandleChange}
                  />
                </FormItem>

                <FormItem
                  title={userInputs.phoneNumber.title}
                  labelFor="phoneLabel"
                  classLabel="font-size-sm text-right"
                >
                  <IdfFormInput
                    className="mb-0 w-100"
                    placeholder={userInputs.phoneNumber.placeholder}
                    value={userInfo}
                    name={'phone'}
                    maxLength={14}
                    disabled={disableProfileChanges === true}
                    data-uw-styling-context="true"
                    onChange={(e) => onHandleChange(e)}
                    autocomplete="off"
                  />
                </FormItem>

                <FormItem
                  title={userInputs.email.title}
                  labelFor="emailLabel"
                  classLabel="font-size-sm text-right"
                >
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="emailLabel"
                    placeholder={userInputs.email.placeholder}
                    aria-label={userInputs.email.placeholder}
                    disabled={true}
                    value={userInfo.email}
                    data-uw-styling-context="true"
                    onChange={onHandleChange}
                  />
                </FormItem>

                <FormItem title="Timezone" classLabel="font-size-sm text-right">
                  <IdfDropdownSearch
                    id="timezone"
                    className="mt-2"
                    name="timezone"
                    showAvatar={false}
                    data={[]}
                    value={timezone}
                    disabled
                  />
                  <span>{TIMEZONE_DESCRIPTION}</span>
                </FormItem>

                {!disablePasswordChanges && (
                  <FormItem>
                    <p className="mb-0">{LOGIN_INFO}</p>
                    <a
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab(2);
                      }}
                    >
                      {CHANGE_LOGIN_INFO}
                    </a>
                  </FormItem>
                )}
              </div>
              {!disableProfileChanges && (
                <div className="px-4">
                  <button
                    type="submit"
                    id="save-profile-btn"
                    className="btn btn-sm btn-primary d-none"
                    data-uw-styling-context="true"
                  >
                    {loading ? <Spinner className="spinner-grow-xs" /> : SAVE}
                  </button>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default Profile;

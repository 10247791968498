import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import userService from '../../services/user.service';
import { useProfileContext } from '../../contexts/profileContext';
import NewKindOfSearchDropdown from '../commons/NewKindOfSearchDropdown';

export const OwnerSelectDropdown = ({
  label = 'Owner',
  data,
  setData,
  keys = ['assigned_user', 'assigned_user_id'],
}) => {
  const { profileInfo } = useProfileContext();
  const [ownerSearch, setOwnerSearch] = useState({
    search: '',
  });
  const [ownerError, setOwnerError] = useState(false);
  const [users, setUsers] = useState([]);
  const getUsers = async (search) => {
    const { data } = await userService.getUsers(
      { status: 'active', search },
      { page: 1, limit: 5 }
    );
    setUsers(data?.users);
  };

  const userStateChange = (e) => {
    const match = e.target.value.match(/([A-Za-z])/g);
    if (match && match.length >= 2) {
      setOwnerSearch({
        ...ownerSearch,
        search: e.target.value,
      });
      getUsers(e.target.value);
    } else {
      setUsers([]);
    }
  };
  useEffect(() => {
    getUsers();
  }, [ownerSearch]);

  useEffect(() => {
    setData({
      ...data,
      [keys[0]]: profileInfo,
      [keys[1]]: profileInfo?.id,
    });
  }, [profileInfo]);

  return (
    <FormGroup
      row
      className={`align-items-center mt-3 mr-1 new-kind-of-owner-select`}
    >
      <Label md={4} className="text-right font-size-sm col-form-label">
        {label}
      </Label>
      <Col md={8} className="pl-0">
        <NewKindOfSearchDropdown
          readOnly={true}
          pillButton="text-black bg-gray-5 fs-7 rounded-pill font-weight-medium py-1"
          errorClass="owner-btn py-1 pl-2 pr-4 min-w-185px"
          preData={users}
          placeholder="Select Owner"
          optionsClass="owner-options"
          showAvatarButton
          onItemSelect={(item) => {
            setData({
              ...data,
              [keys[0]]: item,
              [keys[1]]: item?.id,
            });
            setOwnerError(false);
          }}
          currentSelected={data?.[keys[0]] || profileInfo}
          onInputSearch={userStateChange}
        />
        {ownerError && (
          <p className="text-danger mt-2 text-sm text-left">
            Please Select Owner
          </p>
        )}
      </Col>
    </FormGroup>
  );
};

export default OwnerSelectDropdown;

export const phoneRegex = /\d{3}-\d{3}-\d{4}/g;
export const timeRegex =
  /([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9] ([AaPp][Mm])/g;
export const anyRegex = /(.|\s)*/;

export const COMPLETED = 'completed';
export const IN_PROGRESS = 'in_progress';

export const DEFAULT_LANGUAGE = 'English (US)';
export const SUCCESS_INFO = 'Profile Saved';
export const UPLOAD_ERROR = 'Error uploading avatar.';
export const SERVER_ERROR = 'Server error!! try again or contact to support.';
export const SAVE = 'Save changes';
export const ERROR_FIRST_NAME_REQUIRED = 'The first name is required.';
export const ERROR_LAST_NAME_REQUIRED = 'The last name is required.';
export const LOGIN_INFO = 'Your login info';
export const CHANGE_LOGIN_INFO = ' Change your login info here.';
export const PASSWORD_REQUIREMENTS = 'Password requirements:';
export const CONGRATULATIONS = 'Congratulations';
export const CORRECT_LABEL = "You're Correct!";
export const CLOSE = 'close';
export const NOT_QUITE = 'Not quite, but close!';
export const RETAKE_LABEL = 'Retake Lesson';
export const RETAKE_COURSE = 'Retake Course';

export const FINISH_COURSES = 'Back To Courses';
export const RETAKE_QUIZ_LABEL = 'Retake Quiz';
export const NEXT_LABEL = 'Next Lesson';
export const QUIZ = 'quiz';
export const SLIDE = 'slide';
export const VIDEO = 'video';
export const COMPLETED_LESSON = 'You completed your lesson.';
export const START_NEW_LESSON = 'Start New Lesson';
export const START_LESSON = 'Start Lesson';
export const CONTINUE_LESSON = 'Continue Lesson';
export const PAGE_CREATE_REQUIRED = 'Page title is required';
export const OPTIONS_LENGTH_ERROR =
  'You must to have at least 2 options and max 5 options';
export const BAD_UPLOAD_VIDEO = 'Bad Format, video was not uploaded';
export const WISTIA_UPLOAD_VIDEO = 'Please provide valid video url.';
export const OPTIONS_ANSWER = 'You should have at least 1 answer';
export const EMPTY_NAME = 'First name and Last name should not empty';
export const EMPTY_COMP_NAME = 'Company is required';
export const EMPTY_INSIGHT_NAME = 'Insight is required';

export const EMPTY_CURRENCY = 'You should have a currency';
export const OPTIONS_WITHOUT_DESCRIPTION = 'Options should have a description';
export const SEARCH_LESSONS = 'Search Lessons';
export const SEARCH_CATEGORIES = 'Search Categories';
export const DELETE_CATEGORY = 'Category Deleted';
export const SEARCH_COURSES = 'Search Courses';
export const SEARCH = 'Search';
export const CONTACT_CREATED = 'Contact Created';
export const DEAL_CONTACT = 'Opportunity Created';
export const SOMETHING_IS_WRONG = 'Something went wrong, try again.';
export const DEAL_UPDATED = 'Opportunity Saved';
export const DEAL_REMOVED = 'Opportunity Deleted';
export const COMPANY_CREATED = 'Company Created';
export const INSIGHT_CREATED = 'Insight has been created';

export const SUBMIT_SUCCESSFULLY = 'Support Ticket Submitted';
export const HELPCENTER_DESCRIPTION =
  'Please submit your request below and we’ll get back to you shortly.';
export const SUPPORT_TICKET = 'Open a support ticket';
export const SEND_MESSAGE = 'Send Message';
export const NAME_LABEL = 'Name';
export const CANCEL_LABEL = 'Cancel';
export const ROLE_CREATED =
  'The role has been created successfully. Redirecting to set permissions.';
export const ERROR_ROLE_EXIST = 'The role already exist.';
export const ADD_NEW_PROFILE_LABEL = 'Add New Profile';
export const EMAIL_LABEL = 'Email';
export const MESSAGE_LABEL = 'Message';
export const MESSAGE_PLACEHOLDER = 'Add your message';
export const SIGN_OUT = 'Log Out';

export const NO_REPORTS_AVAILABLE = 'No reports available';
export const NO_REPORTS_AVAILABLE_ICON = 'note_stack';
export const PROFILE_LABEL = 'Profile';
export const FILTER_LABEL = 'Filter';
export const FILTER_APPLY = 'Apply';
export const ADD_SLIDE = 'Add Text';
export const ADD_QUIZ = 'Add Quiz';
export const ADD_VIDEO = 'Add Video';
export const PUBLISH_LESSON = 'Publish Lesson';
export const PUBLISHED_LESSON = 'Lesson Saved';
export const PUBLISHED = 'published';
export const DRAFT = 'draft';
export const ADD_TO_LESSON = 'Add to Favorites';
export const REMOVE_FROM_FAVORITES = 'Remove from Favorites';
export const COURSES_COMMING_SOON = 'Category courses coming soon';
export const LESSONS_COMMING_SOON = 'Category lessons coming soon';
export const CHOOSE_IMAGE_FILE = 'Drag a file here';
export const LESSON_SETTINGS_LABEL = 'Lesson Settings';
export const SLIDE_DEFAULT_TEXT = 'Slide #1: Slide Name';
export const TITLE_LABEL = 'title';
export const CONTENT_LABEL = 'content';
export const TAG_LABEL = 'tag';
export const MAX_POINTS = 'max_points';
export const DURATION = 'duration';
export const SELECT_OPTIONS_DESCRIPTION =
  'Directly select the answer that will be correct.';
export const QUESTION_REVIEW_LABEL = 'Question review';
export const LESSON_CREATE_REQUIRED = 'Lesson Name and Category are required';
export const ALL_LABEL = 'All';
export const NO_DATA_YET = 'No data yet';
export const STATUS = 'status';
export const EMAIL_LOCATION = 'email_location';
export const EMAIL_LOCATION_1 = 'email_location_1';
export const EMAIL_LOCATION_2 = 'email_location_2';
export const PHONE_LOCATION = 'phone_location';
export const PHONE_LOCATION_2 = 'phone_location_2';
export const PHONE_LOCATION_3 = 'phone_location_3';
export const PHONE_LOCATION_4 = 'phone_location_4';
export const SEARCH_FOR_COMPANY = 'Search for Company';
export const SEARCH_FOR_INSIGHT = 'Search for Insight';

export const DEAL_TYPE = 'tenant_deal_stage_id';
export const ADDRESS_STATE = 'address_state';
export const ADDRESS_STREET = 'address_street';
export const SEARCH_FOR_CATEGORY = 'Search for category';
export const CATEGORY_REQUIRED = 'Category is required.';
export const SEARCH_FOR_CONTACT = 'Search for contact';
export const NO_DEAL = 'No Pipeline';
export const MAX_WEIGHT_ERROR_MESSAGE = 'Maximum file size allowed is 25 MB';
export const MAX_WEIGHT = 26214400; // 25 mb

export const OWNER = 'Owners';
export const DEAL_DELETE_CONFIRMATION =
  'Are you sure you want to delete this deal? This change is irreversible.';
export const AFFIRMATIVE_ANSWER = 'Yes, Delete';
export const ACCEPT = 'Yes, accept';

export const EXPIRED_INVITATION_ERROR =
  'Your invite link has expired, please obtain a new invitation from the Identifee team.';

export const PDF_UPLOAD_ERROR = 'Could not save the PDF';
export const PDF_FORMAT_ERROR = 'The Format is invalid';
export const QUIZ_QUESTION_LABEL = 'What is the question?';
export const LESSON_UPDATED = 'Lesson Saved';
export const LESSON_CREATED = 'Lesson Created';
export const VIDEO_LINK_FORMAT =
  'https://identifee.wistia.com/medias/jru7iafy58';
export const INVALID_EMAIL = 'Invalid Email';
export const ADD_INSIGHT = 'Add Insight';

export const NO_SEARCH_RESULTS = 'No search results';
export const NO_DATA_AVAILABLE = 'No data available';
export const RESOURCE_NOT_FOUND = 'Root Group does not exist';
export const LEARNING_PATH = 'Learning Path';
export const COURSES_COMMING_SOON_TEXT = 'Courses coming soon';
export const COURSES = 'Courses';
export const PIPELINE = 'Pipeline';
export const FILE_REMOVED = 'File Removed';
export const FILE_DOESNT_EXIST =
  "This file was removed or You don't have permission to access this";
export const ASSIGNED_OWNERS = 'Assigned Owners';
export const PRINCIPAL_OWNER_LABEL = '';
export const SECONDARY_OWNER_LABEL = '';

export const SEARCH_ASSIGNMENTS = 'Search Assignments';
export const ADD_ASSIGNMENTS = 'Add Assignment';

export const DEFAULT_PASSWORD_INFO = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export const REQUIREMENTS_PASSWORD = [
  'Minimum 8 characters long - the more, the better',
  'At least one lowercase character',
  'At least one uppercase character',
  'At least one number, symbol, or whitespace character',
];

export const MIN_PASSWORD_LENGTH = 8;
export const PASSWORD_STRENGTH = {
  default: {
    status: 'Very Weak',
    percentage: 1,
    bgColor: 'danger',
  },
  0: {
    status: 'Very Weak',
    percentage: 1,
    bgColor: 'danger',
  },
  4: {
    status: 'Weak',
    percentage: 20,
    bgColor: 'danger',
  },
  8: {
    status: 'Normal',
    percentage: 40,
    bgColor: 'warning',
  },
  12: {
    status: 'Normal',
    percentage: 60,
    bgColor: 'warning',
  },
  16: {
    status: 'Medium',
    percentage: 80,
    bgColor: 'info',
  },
  20: {
    status: 'Strong',
    percentage: 100,
    bgColor: 'success',
  },
};

export const moduleConstants = [
  {
    name: 'contact',
    singularName: 'contact',
    pluralName: 'contacts',
  },
  {
    name: 'organization',
    singularName: 'company',
    pluralName: 'companies',
  },
  {
    name: 'product',
    singularName: 'product',
    pluralName: 'products',
  },
  {
    name: 'task',
    singularName: 'Task',
    pluralName: 'Tasks',
  },
  {
    name: 'call',
    singularName: 'call',
    pluralName: 'calls',
  },
  {
    name: 'event',
    singularName: 'event',
    pluralName: 'events',
  },
  {
    name: 'deal',
    singularName: 'pipeline',
    pluralName: 'pipelines',
  },
];

export const paginationDefault = {
  page: 1,
  limit: 25,
};

export const paginationProspects = {
  page: 1,
  limit: 10,
};

export const paginationOptions = [
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 75, title: '75' },
  { value: 100, title: '100' },
];

export const initialLessonState = {
  id: '',
  title: '',
  content: '',
  category_id: null,
  max_points: null,
  max_attempts: null,
  duration: null,
  tags: [],
  documents: '',
  icon: '',
  videoId: '',
};

export const initialCreateRoleState = {
  name: '',
  description: '',
  admin_access: false,
  owner_access: false,
};

export const DEFAULT_PASSWORD_RULES = {
  passwordLength: false,
  lowercase: false,
  uppercase: false,
  specialCharacter: false,
  number: false,
};

export const ACTIVITY_FEED_TYPES = {
  link: 'link',
  call: 'call',
  task: 'task',
  event: 'event',
  note: 'note',
  file: 'file',
  fileDeleted: 'fileDeleted',
  deletion: 'deletion',
  creation: 'creation',
  updated: 'updated',
  contactLinked: 'contactLinked',
  contactUnlinked: 'contactUnlinked',
  organizationLinked: 'organizationLinked',
  organizationUnlinked: 'organizationUnlinked',
  lessonCompleted: 'lessonCompleted',
  lessonStarted: 'lessonStarted',
  courseCompleted: 'courseCompleted',
  courseStarted: 'courseStarted',
  organization: 'organization',
};

export const ACTIVITY_FEED_THEMES = {
  [ACTIVITY_FEED_TYPES.organizationLinked]: {
    icon: 'corporate_fare',
    color: 'bg-soft-green',
  },
  [ACTIVITY_FEED_TYPES.organizationUnlinked]: {
    icon: 'domain_disabled',
    color: 'bg-soft-red',
  },
  [ACTIVITY_FEED_TYPES.contactLinked]: {
    color: 'bg-soft-green',
    icon: 'person_add_alt',
  },
  [ACTIVITY_FEED_TYPES.contactUnlinked]: {
    color: 'bg-soft-red',
    icon: 'person_off',
  },
  [ACTIVITY_FEED_TYPES.note]: {
    color: 'step-icon-soft-warning',
    icon: 'description',
  },
  [ACTIVITY_FEED_TYPES.call]: {
    color: 'bg-soft-green',
    icon: 'call',
  },
  [ACTIVITY_FEED_TYPES.event]: {
    color: 'bg-soft-green',
    icon: 'event',
  },
  [ACTIVITY_FEED_TYPES.meeting]: {
    color: 'bg-soft-cyan',
    icon: 'groups',
  },
  [ACTIVITY_FEED_TYPES.task]: {
    color: 'bg-soft-yellow',
    icon: 'task',
  },
  [ACTIVITY_FEED_TYPES.deadline]: {
    color: 'bg-soft-red',
    icon: 'flag',
  },
  [ACTIVITY_FEED_TYPES.email]: {
    color: 'bg-soft-purple',
    icon: 'email',
  },
  [ACTIVITY_FEED_TYPES.lunch]: {
    color: 'bg-soft-pink',
    icon: 'restaurant_menu',
  },
  [ACTIVITY_FEED_TYPES.file]: {
    color: 'step-icon-soft-info',
    icon: 'attachment',
  },
  [ACTIVITY_FEED_TYPES.link]: {
    color: 'step-icon-soft-info',
    icon: 'attachment',
  },
  [ACTIVITY_FEED_TYPES.fileDeleted]: {
    color: 'bg-soft-red',
    icon: 'attachment',
  },
  [ACTIVITY_FEED_TYPES.deletion]: {
    color: 'bg-soft-red',
    icon: 'delete',
  },
  [ACTIVITY_FEED_TYPES.report]: {
    color: 'bg-soft-green',
    icon: 'analytics',
  },
  [ACTIVITY_FEED_TYPES.organization]: {
    color: 'bg-soft-green',
    icon: 'analytics',
  },
  [ACTIVITY_FEED_TYPES.lessonCompleted]: {
    icon: 'class',
    color: 'bg-soft-green',
  },
  [ACTIVITY_FEED_TYPES.lessonStarted]: {
    icon: 'flag',
    color: 'bg-soft-yellow',
  },
  [ACTIVITY_FEED_TYPES.courseCompleted]: {
    icon: 'cast_for_education',
    color: 'bg-soft-green',
  },
  [ACTIVITY_FEED_TYPES.courseStarted]: {
    icon: 'flag',
    color: 'bg-soft-yellow',
  },
  [ACTIVITY_FEED_TYPES.link]: {
    icon: 'link',
    color: 'bg-soft-blue',
  },
  // new types (create, update) activities
  creation: {
    color: 'step-icon-soft-dark',
    icon: 'text_snippet',
  },
  updated: {
    color: 'step-icon-soft-dark',
    icon: 'loupe',
  },
  deleted: {
    color: 'bg-soft-red',
    icon: 'cancel',
  },
};

export const NAME_UNKNOWN_USER = 'Unknown User';
export const NAME_INVITED_USER = 'User Invited';
export const SEND_EMAIL_SUCCESS = 'Email was sent successfully';
export const USER_UPDATE_SUCCESS = 'User Saved';
export const STATUS_DELETED = 'deleted';

export const badgeColorStatus = [
  {
    status: 'active',
    color: 'status-green',
  },
  {
    status: 'invited',
    color: 'status-yellow',
  },
  {
    status: 'invite_cancelled',
    color: 'status-red',
  },
  {
    status: 'deactivated',
    color: 'status-gray',
  },
  {
    status: STATUS_DELETED,
    color: 'status-red',
  },
];

export const LABEL_BUTTON_RESEND_INVITATION = 'Resend invitation';
export const LABEL_BUTTON_ACTIVATE_USER = 'Activate User';
export const TEXT_INFO_MODAL_SUSPEND =
  'Are you sure you want to Deactivate this user? If you want then you can reactivate it.';
export const TEXT_INFO_MODAL_ACTIVE =
  'Are you sure you want to reactivate this user? If you want then you can Deactivate it.';
export const TEXT_INFO_MODAL_CANCEL =
  'Are you sure you want to Cancel Invite? If you want then you can resend it.';
export const TEXT_INFO_MODAL_RESEND =
  'Are you sure you want to Resend Invite? If you want then you can Cancel it.';
export const TEXT_INFO_REMOVE_FILE =
  'Are you sure you want to remove this file?';

export const USER_SUSPENDED = 'User suspended';
export const USER_ACTIVE = 'User active';

export const STATUS_ACTIVE = 'active';
export const STATUS_INVITED = 'invited';
export const STATUS_SUSPENDED = 'deactivated';
export const ROLE_LABEL = 'Role';
export const DEALS_LABEL = 'Deals';

export const DEALS_LABEL_BUTTON = 'Add Pipeline'; // Reptitive code, Add Pipeline already defined.

export const VALID_FILE_FORMATS = [
  '.png',
  '.jpeg',
  '.jpg',
  '.doc',
  '.docx',
  '.pdf',
  '.xls',
  '.xlsx',
];

export const checkAndAllowFileUpload = (file, formats = VALID_FILE_FORMATS) => {
  const fileExtension = file.name.split('.').pop().toLowerCase();
  return formats.includes(`.${fileExtension}`);
};

export const NO_REPORT_SELECTED =
  'No report selected. Please, choose a report from the left menu to get started.';

export const groupAttrData = {
  id: '',
  name: '',
  parent_id: null,
};

export const CANT_UPDATE_OVERVIEW_INFO =
  "You don't have permission to edit this info";

export const CANT_REMOVE_DEAL = "You don't have permission to remove this Deal";

export const TIMEZONE_DESCRIPTION =
  'Timezone is updated automatically to match your computer timezone';

export const BRANDING_LABEL = 'Branding';
export const ACCESS_URL_LABEL = 'Access URL';
const getYesterdayDate = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today;
};

const yesterdayDate = getYesterdayDate();

export const ActivitiesFiltersList = [
  {
    key: 'MyOverdueActivities',
    name: 'My Overdue Activities',
    filter: { self: true, done: false, endDate: new Date() },
  },
  {
    key: 'MyClosedActivities',
    name: 'My Completed Activities',
    filter: { self: true, done: true },
  },
  {
    key: 'TodayActivities',
    name: "Today's Activities",
    filter: {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    },
  },
  {
    key: 'TodayOverdueActivities',
    name: 'Today + Overdue Activities',
    filter: {
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      done: false,
    },
  },
  {
    key: 'TomorrowActivities',
    name: "Tomorrow's Activities",
    filter: {
      startDate: new Date(
        new Date(new Date().setDate(new Date().getDate() + 1)).setHours(
          0,
          0,
          0,
          0
        )
      ),
      endDate: new Date(
        new Date(new Date().setDate(new Date().getDate() + 1)).setHours(
          23,
          59,
          59,
          999
        )
      ),
    },
  },
  {
    key: 'OpenActivities',
    name: 'Scheduled Activities',
    filter: { done: false, startDate: new Date() },
  },
  {
    key: 'ClosedActivities',
    name: 'Completed Activities',
    filter: { done: true },
  },
  {
    key: 'OverdueActivities',
    name: 'Overdue Activities',
    filter: { done: false, endDate: new Date() },
  },
  {
    key: 'NextAndOverdueActivities',
    name: 'Next 7 Days + Overdue',
    filter: {
      done: false,
      endDate: new Date(
        new Date(new Date().setDate(new Date().getDate() + 7)).setHours(
          23,
          59,
          59,
          999
        )
      ),
    },
  },
];

export const ChecklistFilters = [
  {
    key: 'OverDue',
    name: 'My Overdue Checklists',
    filters: [
      {
        key: 'initialDueDateRange[end]',
        value: yesterdayDate.toISOString(),
      },
      {
        key: 'organizationChecklistProgress[status]',
        value: ['pending', 'inProgress'],
      },
    ],
  },
  {
    key: 'Pending',
    name: 'My Not Started Checklists',
    filters: [
      {
        key: 'organizationChecklistProgress[status]',
        value: 'pending',
      },
    ],
  },
  {
    key: 'InProgress',
    name: 'My In Progress Checklists',
    filters: [
      {
        key: 'organizationChecklistProgress[status]',
        value: 'inProgress',
      },
    ],
  },
  {
    key: 'Completed',
    name: 'My Completed Checklists',
    filters: [
      {
        key: 'organizationChecklistProgress[status]',
        value: 'completed',
      },
    ],
  },
  {
    key: 'assigned',
    name: 'My Assigned Checklists',
    filters: [],
  },
];

export const reportPages = [
  {
    key: '50',
    name: '50',
    filter: '',
  },
  {
    key: '100',
    name: '100',
    filter: '',
  },
  {
    key: '300',
    name: '300',
    filter: '',
  },
  {
    key: '500',
    name: '500',
    filter: '',
  },
  {
    key: 'all',
    name: 'Show All',
    filter: '',
  },
];

export const VIDEO_PLAYER_WIDTH = 810;

export const defaultGlossary = {
  'Accounts Payable (AP) Automation':
    'The use of technology and software tools to automate and streamline the accounts payable process, including invoice receipt, verification, and approval.',
  'Accounts Receivable (AR) Automation':
    'The use of technology and software tools to automate and streamline the accounts receivable process, including invoicing, payment collection, and customer management.',
  'Cash Conversion Cycle (CCC)':
    "A financial metric that measures the time it takes for a company to convert its investments in inventory and other resources into cash flow from sales. It represents the duration between the company's initial outlay of cash for inventory and the subsequent receipt of cash from customers. A shorter CCC indicates better efficiency in managing working capital and generating cash flow. CCC = DIO + DSO - DPO.",
  'Commercial Card':
    'A type of card, such as a corporate credit card or business charge card, used by businesses for purchasing and managing expenses.',
  'Days Inventory Outstanding (DIO)':
    'The average number of days it takes for a business to sell its inventory.',
  'Days Payable Outstanding (DPO)':
    'The average number of days it takes a business to pay its suppliers.',
  'Days Sales Outstanding (DSO)':
    'The average number of days it takes a business to collect payment from customers after a sale.',
  'Earnings Credit Rate (ECR)':
    'A percentage rate offered by financial institutions on account balances that can be used to offset banking fees and charges. It represents the value of the earnings credit that a company or individual receives based on the average balance in their account.',
  'Enterprise Resource Planning (ERP) System':
    "Integrated software systems that consolidate and manage various aspects of a company's operations, including finance, human resources, supply chain, and customer relationship management.",
  'Estimated Total Payments':
    'The projected or anticipated sum of money that a company expects to pay for various expenses within a specific timeframe.',
  'Estimated Total Receivables':
    'The projected or expected total amount of money that a company anticipates receiving from its customers or clients for goods or services provided within a specific timeframe.',
  'Positive Pay (Check and ACH)':
    'A fraud prevention service offered by banks where a company provides authorized outgoing payments, and the financial institution verifies incoming payments before processing items.',
};

export const defaultGlossaryWorkingCapital = {
  'Accounts Payable (AP)':
    'The amount of money owed by a business to its suppliers or\n' +
    '  creditors for goods and services received but not yet paid for. Accounts payable are listed\n' +
    "  on the company's balance sheet as a current liability, as they are typically due within a\n" +
    '  short period, usually one year or less.',
  'Accounts Receivable (AR)':
    'This represents the money owed to a business by its customers\n' +
    'for goods or services delivered but not yet paid for. Accounts receivable are recorded as\n' +
    'an asset on the balance sheet, as they represent funds that the company expects to\n' +
    'receive within a short period, usually one year or less',
  Automation:
    'The use of technology to streamline processes, reducing manual effort and\n' +
    'increasing efficiency. AR/AP automation helps in reducing errors, speeding up\n' +
    'transaction processing, and providing real-time financial insights, which can\n' +
    'significantly improve working capital management and cash conversion cycle efficiency.',
  'Cash Conversion Cycle (CCC)':
    'A metric that expresses the time (in days) it takes for a\n' +
    'company to convert its investments in inventory and other resources into cash ows\n' +
    'from sales',
  'Cash Released':
    'Optimizing working capital components such as inventory levels,\n' +
    'accounts receivable, and accounts payable to improve cash ow. By reducing the cash\n' +
    'tied up in these areas, a business can increase its liquidity and have more cash available\n' +
    'for investment or other operational needs',
  'Current Assets':
    'Assets that are expected to be converted into cash, sold, or consumed\n' +
    'within a year. This includes cash, accounts receivable, inventory, and other short-term\n' +
    'assets.',
  'Current Liabilities':
    'Short-term financial obligations due within one year, such as\n' +
    'accounts payable, short-term loans, and other similar liabilities.',
  'Days Sales Outstanding (DSO)':
    'A measure of the average number of days it takes a\n' +
    'company to collect payment after a sale has been made.',
  'Days Inventory Outstanding (DIO)':
    'The average number of days a company holds\n' +
    'inventory before selling it.',
  'Days Payable Outstanding (DPO)':
    'The average number of days a company takes to pay its\n' +
    'bills and invoices.',
  'Working Capital':
    "A financial metric that measures a company's efficiency and its\n" +
    'short-term financial health, calculated as current assets divided by current liabilities',
};

export const FRAUD_MITIGATION_VIDEO_ID = 'kbstczu1a1';
export const APP_VERSION_KEY = 'idf-app-version';

export const TABLE_RECORDS_LIMIT = 25;

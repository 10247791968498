import React, { useCallback, useContext, useMemo, useState } from 'react';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { AlertMessageContext } from '../../../contexts/AlertMessageContext';
import ReportService from '../../../services/report.service';
import {
  cleanDataWidgets,
  getNAICSWithIndustry,
  overflowing,
} from '../../../utils/Utils';
import {
  ActionTypes,
  ReportTypes,
} from '../../../components/reports/reports.constants';
import {
  convertEcrRateToBps,
  getReportName,
  sumItemValues,
  updateJsonObject,
} from '../../../components/reports/reports.helper.functions';
import { defaultGlossary } from '../../../utils/constants';
import MetricsService from '../../../services/metrics.service';
import moment from 'moment/moment';

const SyncTMReportModal = ({
  show,
  setShow,
  report,
  organization,
  handleGenerateReport,
  setParentModalShow,
}) => {
  const { setErrorMessage } = useContext(AlertMessageContext);
  const [refreshing, setRefreshing] = useState(false);

  const updateManualReportFromCoreData = (metrics) => {
    const paymentMethodsUsed = {
      Checks: metrics.volume.checks.payables,
      ACH: metrics.volume.ach.payables,
      Wires: metrics.volume.wires.payables,
    };

    const typesOfReceivables = {
      ACH: metrics.volume.ach.receivables,
      Checks: metrics.volume.checks.receivables,
      Wires: metrics.volume.wires.receivables,
    };

    const paymentRisksValueCheck =
      metrics.services.positivePay.check.overallCoverage;
    const paymentRisksValueAch =
      metrics.services.positivePay.ach.overallCoverage;
    const paymentRisksValuePayeeMatch =
      metrics.services.positivePay.check.addOn.payeeMatch.overallCoverage;
    const paymentRisks = {
      balance: {
        total: metrics.accounts.source.bank.balance,
        isChecked:
          paymentRisksValueCheck !== 'none' && paymentRisksValueAch !== 'none',
        multipleAccounts: metrics.isMultiAccountAggregation,
      },
      fraudPreventionProducts: updateJsonObject({
        'Positive Pay': paymentRisksValueCheck,
        'ACH Positive Pay': paymentRisksValueAch,
        'Payee Match': paymentRisksValuePayeeMatch,
      }),
    };

    // FE sum for payables/receivables:
    const paymentMethodsUsedJson = updateJsonObject(paymentMethodsUsed);
    const estimatedTotalPayables = sumItemValues(paymentMethodsUsedJson);

    const typesOfReceivablesJson = updateJsonObject(typesOfReceivables);
    const estimatedTotalReceivables = sumItemValues(typesOfReceivablesJson);
    const reportMapped = {
      value1: organization.name,
      ...getNAICSWithIndustry(organization),
      value2: metrics.analysisDate,
      enableCashVault: !!metrics.volume.cash.receivables,
      value5: metrics.paidFees.paidFromEarningsAllowanceAmount,
      value6: convertEcrRateToBps(metrics.accounts.earningsCreditRate) + '',
      value7: metrics.accounts.source.bank.balance,
      value8:
        metrics.accounts.source?.investment
          ?.balanceNeededToOffsetFeesWithEarningsCreditRate,
      value9: metrics.paidFees.paidFromBankAmount,
      opportunity: metrics.checksToACHSavings[0].savingsAmount + '',
      estimatedTotalPayables: Math.round(estimatedTotalPayables),
      estimatedTotalReceivables: Math.round(estimatedTotalReceivables),
      paymentMethodsUsed: paymentMethodsUsedJson,
      typesOfReceivables: typesOfReceivablesJson,
      paymentRisks,
      glossary: defaultGlossary,
      totalFees: metrics.paidFees.totalAmount,
      coreData: {
        reportDate: metrics.analysisDate,
      },
    };

    const manualInput = Object.hasOwn(report, 'manualInput')
      ? report.manualInput
      : report;

    return {
      name: reportMapped.value1,
      date: reportMapped.value2,
      type: ReportTypes.Treasury,
      manualInput: {
        widgets: cleanDataWidgets(manualInput.widgets),
        reportPages: manualInput.reportPages,
        glossary: manualInput.glossary || defaultGlossary,
        ...reportMapped,
        isAuto: true, // track whether this was generated by core
      },
    };
  };

  const handleConfirmDeleteReport = async () => {
    try {
      setRefreshing(true);
      const coreData = report?.manualInput?.coreData ?? report.coreData;
      const rptDate = coreData?.reportDate?.split('T')[0];
      const coreDataReports =
        await MetricsService.getOrganizationTreasuryMetricsData(
          organization.id,
          {
            date: moment(rptDate).format('YYYY-MM'),
          }
        );
      if (coreDataReports?.data?.length) {
        const newCoreData = updateManualReportFromCoreData(
          coreDataReports.data[0]
        );
        await ReportService.updateReport(report.reportId, newCoreData);
        overflowing();
        setParentModalShow(false);
        handleGenerateReport(report, ActionTypes.UPDATE, {
          ...newCoreData,
          reportId: report.reportId,
          key: report.reportId,
        });
      } else {
        setErrorMessage(
          "Nothing to be synced. You're already viewing latest data."
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <DeleteConfirmationModal
      showModal={show}
      loader={refreshing}
      setShowModal={setShow}
      customEvent={handleConfirmDeleteReport}
      itemsConfirmation={[{ ...report, title: getReportName(report) }]}
      heading="Refresh Report"
      description="Are you sure you want to refresh this Report?"
      positiveBtnText="Refresh"
    />
  );
};

const useResyncTreasuryReportModal = (
  handleGenerateReport,
  setParentModalShow
) => {
  const [showModal, setShowModal] = useState(false);
  const [report, setReport] = useState('');
  const [organization, setOrganization] = useState('');

  const SyncTMReportModalCallback = useCallback(() => {
    return (
      <SyncTMReportModal
        show={showModal}
        setShow={setShowModal}
        report={report}
        organization={organization}
        handleGenerateReport={handleGenerateReport}
        setParentModalShow={setParentModalShow}
      />
    );
  }, [
    showModal,
    setShowModal,
    report,
    setReport,
    organization,
    setOrganization,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      setReport,
      setOrganization,
      SyncTMReportModal: showModal ? SyncTMReportModalCallback : () => <div />,
    }),
    [setShowModal, SyncTMReportModalCallback]
  );
};

export default useResyncTreasuryReportModal;

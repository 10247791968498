import { useState, useEffect } from 'react';
import ProspectSaveSearch from './ProspectSaveSearch';
import { numbersWithComma } from '../../../utils/Utils';
import Skeleton from 'react-loading-skeleton';

const Counter = ({ max }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 500) {
      setCount(max); // skip the increment and set to max directly if max is greater than 500
      return;
    }
    const interval = setInterval(() => {
      if (count < max) {
        setCount(count + 1);
      }
    }, 2);

    return () => clearInterval(interval);
  }, [count, max]);

  return <span>{numbersWithComma(count)}</span>;
};

const ProspectResults = ({
  filter,
  pagination,
  type,
  setErrorMessage,
  setSuccessMessage,
  resultsOnly = false,
  saveSearchOnly = false,
  loading = false,
}) => {
  return (
    <div className="stats">
      {resultsOnly ? (
        <div className="stats">
          {loading ? (
            <ul className="w-100">
              <li className="flex-fill px-2">
                <Skeleton width={100} height={10} />
              </li>
            </ul>
          ) : (
            <ul>
              <li className="font-size-sm2">
                <div className="d-flex align-items-center gap-1">
                  <label className="mb-0 fs-7 font-weight-normal">
                    Results
                  </label>
                  <span className="text-gray-400">•</span>
                  <label className="mb-0 text-black">
                    <Counter max={pagination?.total || 0} />
                  </label>
                </div>
              </li>
            </ul>
          )}
        </div>
      ) : (
        ''
      )}
      {saveSearchOnly ? (
        <ProspectSaveSearch
          filter={filter}
          type={type}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default ProspectResults;

import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import Heading from '../components/heading';
import Security from './Security';
import Profile from './Profile';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import useEnvSettings from '../hooks/useEnvSettings';
import RightSlidingDrawer from '../components/modal/RightSlidingDrawer';
import MaterialIcon from '../components/commons/MaterialIcon';
import Avatar from '../components/Avatar';
import {
  NAME_UNKNOWN_USER,
  SERVER_ERROR,
  SUCCESS_INFO,
  UPLOAD_ERROR,
} from '../utils/constants';
import { useProfileContext } from '../contexts/profileContext';
import { TenantContext } from '../contexts/TenantContext';
import { useKeycloak } from '../contexts/KeycloakProvider';
import { useAppContext } from '../contexts/appContext';
import ButtonIcon from '../components/commons/ButtonIcon';
import authService from '../services/auth.service';
import ModalAvatar from '../components/modal/ModalAvatar';
import AlertWrapper from '../components/Alert/AlertWrapper';
import Alert from '../components/Alert/Alert';
import filesService from '../services/files.service';
import userService from '../services/user.service';
import { base64ToBlob } from '../utils/Utils';
import AvatarHoverImage from '../components/AvatarHoverImage';

const ProfileHeader = ({ setShow }) => {
  const { profileInfo, setProfileInfo } = useProfileContext();
  const { disableProfileChanges } = useEnvSettings();
  const { setCount } = useContext(TenantContext);
  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();
  const keycloak = useKeycloak();

  const logout = async () => {
    setCount(2);
    authService.logout();
    if (!keycloak) {
      userHasAuthenticated(false);
      setProfileInfo({});
      history.push('/login');
    } else {
      await keycloak?.logout();
    }
  };

  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onHandleSaveAvatar = async ({ src, name }) => {
    setLoadingAvatar(true);
    const form = new FormData();
    form.append('file', await base64ToBlob(src), name);
    const avatarResult = await userService.uploadAvatar(form).catch((_) => {
      setErrorMessage(UPLOAD_ERROR);
    });
    const result = avatarResult?.data;

    setProfileInfo((prev) => ({
      ...prev,
      avatar: result.data.id,
      avatarSrc: src,
    }));

    if (result?.data?.id) {
      await updateAvatarUser(result.data.id);
      setLoadingAvatar(false);
    }
  };

  const updateAvatarUser = async (avatarId) => {
    const dataSend = {
      avatar: avatarId,
    };

    const resp = await userService
      .updateUserInfoById(profileInfo.id, dataSend)
      .catch((err) => console.log(err));

    if (!resp) {
      setErrorMessage(SERVER_ERROR);
    } else {
      setSuccessMessage(SUCCESS_INFO);
    }
    setOpenModalAvatar(false);
  };

  const removeFile = async () => {
    setLoadingAvatar(true);
    filesService
      .removeFileById(profileInfo.avatar)
      .then(() => {
        updateAvatarUser(null);

        setProfileInfo((prev) => ({
          ...prev,
          avatar: null,
          avatarSrc: null,
        }));
        setLoadingAvatar(false);
      })
      .catch(() => {
        setErrorMessage('error');
      });
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <ModalAvatar
        open={openModalAvatar}
        onHandleClose={() => setOpenModalAvatar(false)}
        userInfo={profileInfo}
        onRemove={removeFile}
        loading={loadingAvatar}
        onSaveAvatar={onHandleSaveAvatar}
        type="profile"
      />

      <div className="w-100">
        <div className="d-flex align-items-center w-100 justify-content-between gap-2">
          {profileInfo?.id ? (
            <div className="d-flex gap-2 align-items-center">
              {disableProfileChanges ? (
                <label htmlFor="avatar" className="mb-0">
                  <Avatar
                    user={profileInfo}
                    defaultSize="lg"
                    classModifiers="bg-primary-soft text-primary"
                    sizeIcon="font-size-lg"
                  />
                </label>
              ) : (
                <AvatarHoverImage
                  type="contact"
                  data={profileInfo}
                  size="lg"
                  setOpenModalAvatar={setOpenModalAvatar}
                />
              )}

              <div>
                <h3
                  className="mb-0 d-flex align-items-center text-capitalize"
                  data-uw-styling-context="true"
                >
                  {profileInfo &&
                  (profileInfo.first_name || profileInfo?.last_name)
                    ? `${profileInfo?.first_name} ${profileInfo?.last_name}`
                    : NAME_UNKNOWN_USER}
                </h3>
                <div className="d-flex align-items-center gap-2">
                  <p className="mb-0 d-flex align-items-center gap-1 fs-7">
                    <a href={`mailto:${profileInfo?.email}`}>
                      {profileInfo?.email}
                    </a>
                  </p>
                  <span className="text-muted-light">•</span>
                  <p
                    onClick={logout}
                    className="mb-0 fs-7 text-red font-weight-medium cursor-pointer text-hover-underline"
                  >
                    Sign Out
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <span className="font-weight-medium">Loading...</span>
          )}
          <div>
            <div className="d-flex gap-2 align-items-center">
              <a
                className="icon-hover-bg cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }}
              >
                <MaterialIcon icon="close" size="fs-5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ProfilePage = ({ show, setShow }) => {
  const [activeTab, setActiveTab] = useState(1);
  const { disablePasswordChanges } = useEnvSettings();
  const [loading, setLoading] = useState(false);

  const ProfileTab = {
    title: 'Profile',
    component: (
      <Profile
        setActiveTab={setActiveTab}
        loading={loading}
        setLoading={setLoading}
      />
    ),
    tabId: 1,
  };
  const SecurityTab = {
    title: 'Security',
    component: <Security loading={loading} setLoading={setLoading} />,
    tabId: 2,
  };

  let tabsData = [ProfileTab];

  if (!disablePasswordChanges) {
    tabsData = [ProfileTab, SecurityTab];
  }
  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
    }
  };

  const toggleDrawer = () => {
    setShow((prevState) => !prevState);
  };

  const handleSubmit = () => {
    if (activeTab === 1) {
      const saveProfileButton = document.getElementById('save-profile-btn');
      if (saveProfileButton) {
        saveProfileButton.click();
      }
    } else if (activeTab === 2) {
      const changePasswordButton = document.getElementById(
        'change-password-btn'
      );
      if (changePasswordButton) {
        changePasswordButton.click();
      }
    }
  };

  return (
    <>
      <RightSlidingDrawer
        open={show}
        containerWidth={670}
        modalBodyClass="p-0 overflow-x-hidden"
        modalClass="max-w-670"
        withCard={true}
        toggleDrawer={toggleDrawer}
        header={<ProfileHeader setShow={setShow} />}
        body={
          <>
            <Heading pageHeaderDivider="mb-0" useBc={true} showGreeting>
              <AnimatedTabs
                activeTab={activeTab}
                tabsData={tabsData}
                tabClasses="px-4 pt-1"
                borderClasses="border-bottom"
                toggle={toggle}
              />
            </Heading>
            <TabContent>
              <TabPane className="position-relative">
                {tabsData.find((item) => item.tabId === activeTab)?.component}
              </TabPane>
            </TabContent>
          </>
        }
        footer={
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center gap-2">
              <ButtonIcon
                color="white"
                onclick={toggleDrawer}
                classnames="font-weight-normal btn-sm"
                label="Cancel"
              />
              <ButtonIcon
                color="primary"
                classnames="btn-sm"
                onclick={handleSubmit}
                loading={loading}
                label="Save"
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default ProfilePage;

import React from 'react';
import MaterialIcon from '../../../commons/MaterialIcon';

const formatNumber = (num) => {
  return num.toLocaleString('en-US');
};

const Payments = ({
  label,
  payment,
  trendType,
  trendDetails,
  whenPrinting,
}) => {
  if (trendType === 'Today') {
    trendDetails = {};
  }
  return (
    <div className="d-flex flex-column align-items-start">
      <span
        className={`${
          whenPrinting
            ? 'fs-9 sub-text font-weight-normal'
            : 'fs-7 font-weight-medium'
        }`}
      >
        {label}
      </span>
      <div className="d-flex align-items-center">
        <span
          className={`font-weight-semi-bold ${
            whenPrinting ? 'fs-9 sub-text' : 'fs-7'
          } ${trendDetails.color}`}
        >
          {formatNumber(payment)}
        </span>
        <div className={`${trendDetails.color} d-flex`}>
          {trendDetails.icon}
        </div>
      </div>
    </div>
  );
};

const Amount = ({ label, amount, whenPrinting }) => (
  <div className="d-flex flex-column align-items-start pl-4_2-">
    <span
      className={`${
        whenPrinting
          ? 'fs-9 sub-text font-weight-normal'
          : 'fs-7 font-weight-medium'
      }`}
    >
      {label}
    </span>
    <span
      className={`font-weight-semi-bold ${
        whenPrinting ? 'fs-9 sub-text' : 'fs-7'
      }`}
    >
      ${formatNumber(amount)}
    </span>
  </div>
);

const WidgetIconCell = ({ icon, type, whenPrinting }) => (
  <div className="widget-cell flex-column gap-1">
    <div
      className="d-flex align-items-center bg-primary-soft rounded-circle justify-content-center"
      style={{
        width: whenPrinting ? 30 : 45,
        height: whenPrinting ? 30 : 45,
      }}
    >
      <MaterialIcon
        icon={icon}
        clazz="text-primary"
        size={whenPrinting ? 'fs-5' : 'fs-4'}
        filled
      />
    </div>
    <span
      className={`font-weight-semi-bold pl-1 ${
        whenPrinting ? 'fs-9 sub-text' : 'fs-7'
      }`}
    >
      {type}
    </span>
  </div>
);

const PaymentStatsWidget = ({ widgetConfig, whenPrinting }) => {
  const getTrendIcon = (trend) => {
    switch (trend) {
      case 'up':
        return (
          <MaterialIcon icon={`arrow_drop_up`} className="text-green d-flex" />
        );
      case 'down':
        return (
          <MaterialIcon icon={`arrow_drop_down`} className="text-red d-flex" />
        );
      default:
        return (
          <MaterialIcon
            icon={`arrow_drop_down`}
            clazz="text-green hidden opacity-0"
          />
        );
    }
  };

  const getTrendDetails = (type) => {
    const { todayPayments = 0, futurePayments = 0 } =
      widgetConfig?.data[type] || {};

    if (!todayPayments || !futurePayments) {
      return {
        trending: '',
        icon: (
          <MaterialIcon
            icon={`arrow_drop_down`}
            clazz="text-green hidden opacity-0"
          />
        ),
        color: 'text-black',
      };
    }

    const trending = futurePayments > todayPayments;
    return {
      trending,
      icon: getTrendIcon(trending ? 'up' : 'down'),
      color: trending ? 'text-green' : 'text-red',
    };
  };

  return (
    <div
      className="widget-table widget-border-2 pt-2"
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      {whenPrinting ? (
        <div className="widget-row align-items-center widget-heading py-1">
          <h2 className="widget-cell align-items-start"></h2>
          <h3 className="widget-cell fs-8 align-items-start">Today</h3>
          <h3 className="widget-cell fs-8 align-items-start">Future</h3>
        </div>
      ) : (
        <div className="widget-row align-items-center widget-heading py-xl-2">
          <h2 className="widget-cell align-items-start"></h2>
          <h3 className="widget-cell align-items-start">Today</h3>
          <h3 className="widget-cell align-items-start">Future</h3>
        </div>
      )}
      {widgetConfig?.items?.map((item, index) => {
        const { type } = item;
        const trendDetails = getTrendDetails(type);
        return (
          <div
            key={index}
            className={`widget-row align-items-center ${
              whenPrinting ? 'py-1' : 'py-xl-4'
            }`}
          >
            <WidgetIconCell
              icon={item.icon}
              type={item.type}
              whenPrinting={whenPrinting}
            />
            <div className="widget-cell">
              <Payments
                label="Payments"
                trendType="Today"
                payment={widgetConfig?.data[item.type]?.todayPayments || 0}
                trendDetails={trendDetails}
                whenPrinting={whenPrinting}
              />
              <Amount
                label="Amount"
                amount={widgetConfig?.data[item.type]?.todayAmount || 0}
                whenPrinting={whenPrinting}
              />
              <Payments
                label="Payments"
                trendType="Future"
                payment={widgetConfig?.data[item.type]?.futurePayments || 0}
                trendDetails={trendDetails}
                whenPrinting={whenPrinting}
              />
              <Amount
                label="Amount"
                amount={widgetConfig?.data[item.type]?.futureAmount || 0}
                whenPrinting={whenPrinting}
              />
            </div>

            {index < widgetConfig?.items?.length - 1 && <hr className="" />}
          </div>
        );
      })}
    </div>
  );
};

export default PaymentStatsWidget;

import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { SVBGWData } from '../../constants/widgetsConstants';

const SvbGlossaryWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <>
      <Card className="report-widget">
        <CardBody className="justify-content-center align-items-center d-flex">
          <div className="flex-fill w-100">
            <div className="d-flex flex-column gap-2 text-left text-black fs-9">
              {SVBGWData.glossaryData?.map((item) => (
                <span key={item.id}>{item.text}</span>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default SvbGlossaryWidget;

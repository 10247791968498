import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

const API_URL = process.env.REACT_APP_API_URL;
const ORG_URL = `${API_URL}/api/organizations`;
const SUB_ORG_URL = `${API_URL}/api/subOrganizations`;

class MetricsServices extends BaseRequestService {
  getOrganizationTreasuryMetricsData(orgId, data = {}, cancelToken) {
    return this.post(`${ORG_URL}/${orgId}/metrics/lan`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }

  getOrganizationMerchantMetricsData(orgId, data = {}, cancelToken) {
    return this.post(`${ORG_URL}/${orgId}/metrics/ein`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }

  getSubOrganizationMerchantMetricsData(subOrgId, data = {}, cancelToken) {
    return this.post(`${SUB_ORG_URL}/${subOrgId}/metrics/ein`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }
}

export default new MetricsServices();

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReportTileStyles, ReportTypes } from './reports.constants';
import { PdfFileNames } from './reports.helper.functions';
import { PermissionsConstants } from '../../utils/permissions.constants';
import TreasuryManagementReport from '../peopleProfile/contentFeed/TreasuryManagementReport';
import WorkingCapitalAnalysisReport from '../peopleProfile/contentFeed/WorkingCapitalAnalysisReport';
// eslint-disable-next-line no-unused-vars
import MerchantReportAnalysisReport from '../peopleProfile/contentFeed/MerchantReportAnalysisReport';
import { isModuleAllowed } from '../../utils/Utils';
import { useTenantContext } from '../../contexts/TenantContext';
import FraudReport from '../peopleProfile/contentFeed/FraudReport';
import { Dropdown } from 'react-bootstrap';
import MaterialIcon from '../commons/MaterialIcon';
import AccountStructureReport from '../peopleProfile/contentFeed/AccountStructureReport';
import CommercialCardReport from '../peopleProfile/contentFeed/CommercialCardReport';
import useHash from '../../hooks/useHash';
import MerchantReportComerica from '../peopleProfile/contentFeed/MerchantReportComerica';
import useIsTenant from '../../hooks/useIsTenant';
import MerchantReportSVB from '../peopleProfile/contentFeed/MerchantReportSVB';

const ReportTile = ({ tab, onSelect, onCreate, itemWidth }) => {
  const reportStyle = ReportTileStyles[tab.key];

  return (
    <a
      key={tab.id}
      className={`report-tile text-center scroll-item hover-actions cursor-pointer font-size-sm2`}
      onClick={() => onSelect(tab)}
      style={{ minWidth: itemWidth }}
    >
      <div className={`${reportStyle.styleClass} report-tile-icon`}>
        <MaterialIcon icon={reportStyle.icon} size="fs-4" />
      </div>
      <p
        className="mb-0 font-size-sm2 text-nowrap text-center text-gray-900 font-weight-semi-bold cursor-pointer pt-2"
        dangerouslySetInnerHTML={{ __html: tab.name.split(' ').join('<br />') }}
      />
    </a>
  );
};

const ReportTiles = ({ tabs, onSelect, onCreate, itemWidth = 100 }) => {
  const { tenant } = useTenantContext();
  // any report is allowed then create a nav menu
  const hasEngagementReports = tabs.some((tb) =>
    isModuleAllowed(tenant?.modules, tb.permission)
  );

  const totalItems = tabs.length;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxItemsInView, setMaxItemsInView] = useState(5);
  const [showArrows, setShowArrows] = useState(false);
  const scrollContainerRef = useRef(null);
  const scrollContentRef = useRef(null);

  useEffect(() => {
    const updateMaxItemsInView = () => {
      const containerWidth = scrollContentRef.current?.offsetWidth || 0;
      const totalTabsWidth = totalItems * itemWidth;

      // If all tabs fit in the container, don't show arrows
      if (totalTabsWidth <= containerWidth + 30) {
        setShowArrows(false);
      } else {
        setShowArrows(true);
        const itemsInView = Math.floor(containerWidth / itemWidth);
        setMaxItemsInView(itemsInView || 1);
      }
    };

    // Initial calculation and recalculate on window resize
    updateMaxItemsInView();
    window.addEventListener('resize', updateMaxItemsInView);

    return () => window.removeEventListener('resize', updateMaxItemsInView);
  }, [tabs, totalItems, itemWidth]);

  const handleScroll = (direction) => {
    const container = scrollContentRef.current;

    let newPosition = scrollPosition + direction * itemWidth * maxItemsInView;
    // Bound the scroll position
    if (newPosition < 0) {
      newPosition = 0;
    } else if (newPosition > (totalItems - maxItemsInView) * itemWidth) {
      newPosition = (totalItems - maxItemsInView) * itemWidth;
    }

    setScrollPosition(newPosition);
    container.scrollTo({ left: newPosition, behavior: 'smooth' });
  };

  return (
    <>
      {hasEngagementReports ? (
        <div
          className="horizontal-scroll-container p-3 pb-5"
          ref={scrollContainerRef}
        >
          {showArrows && (
            <div
              className={`arrow-scroll left-arrow ${
                scrollPosition === 0 ? 'faded' : ''
              }`}
              onClick={() => handleScroll(-1)}
            >
              <MaterialIcon icon="chevron_left" size="fs-3" />
            </div>
          )}

          <div className="scrollable-content" ref={scrollContentRef}>
            <nav
              className={`d-flex gap-6- scroll-items justify-content-center align-items-center gap-1`}
            >
              {tabs.map((tab) => (
                <>
                  {isModuleAllowed(tenant?.modules, tab.permission) ? (
                    <ReportTile
                      key={tab.id}
                      tab={tab}
                      onSelect={onSelect}
                      onCreate={onCreate}
                      itemWidth={itemWidth}
                    />
                  ) : null}
                </>
              ))}
            </nav>
          </div>

          {showArrows && (
            <div
              className={`arrow-scroll right-arrow ${
                scrollPosition >=
                totalItems * itemWidth - maxItemsInView * itemWidth
                  ? 'faded'
                  : ''
              }`}
              onClick={() => handleScroll(1)}
            >
              <MaterialIcon icon="chevron_right" size="fs-3" />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

const ReportTilesDropdown = ({
  tabs,
  setTabs,
  setSelectedTab,
  setIsDefaultView,
  selectedTab,
}) => {
  const { tenant } = useTenantContext();
  const [show, setShow] = useState(false);
  const { updateHash } = useHash();

  // any report is allowed then create a nav menu
  const hasEngagementReports = tabs.some((tb) =>
    isModuleAllowed(tenant?.modules, tb.permission)
  );

  const onSelect = (tab) => {
    const newTabs = [...tabs].map((tb) =>
      tb.id === tab.id ? { ...tab, isActive: true } : { ...tb, isActive: false }
    );
    setSelectedTab(tab);
    setTabs(newTabs);
    setIsDefaultView(false);
    updateHash('');
  };

  const getActiveReport = () => {
    const found = tabs.find((tb) => tb.isActive === true);
    return { ...found, icon: ReportTileStyles[found.key].icon };
  };
  return (
    <>
      {hasEngagementReports ? (
        <div className="d-flex align-items-center gap-1">
          <button
            className="btn btn-sm d-flex align-items-center icon-hover-bg"
            onClick={() => {
              updateHash('');
              setSelectedTab(null);
              setIsDefaultView(true);
            }}
          >
            <MaterialIcon icon="keyboard_backspace" clazz="fs-5" />
          </button>
          <Dropdown show={show} onToggle={setShow}>
            <Dropdown.Toggle
              variant="white"
              size="sm"
              className={`btn btn-white bg-primary-soft text-black dropdown-toggle ${
                show ? 'bg-gray-200' : ''
              }`}
              id="dropdown-basic"
            >
              <div className="d-flex align-items-center text-left w-100">
                <MaterialIcon
                  icon={getActiveReport() ? getActiveReport()?.icon : 'apps'}
                  rounded
                  clazz={'mr-1'}
                />
                {getActiveReport() ? getActiveReport()?.name : 'Select Reports'}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={`p-0 py-1`} style={{ width: 300 }}>
              <div className={`overflow-y-auto`}>
                {tabs.map((tab) => (
                  <>
                    {isModuleAllowed(tenant?.modules, tab.permission) ? (
                      <Dropdown.Item
                        key={tab.key}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onSelect(tab);
                        }}
                        className={`py-1 px-2 ${
                          ReportTileStyles[tab.key].styleClass
                        }`}
                      >
                        <div className="d-flex align-items-center justify-content-between py-1">
                          <div className="d-flex gap-1 align-items-center">
                            <div
                              className={`${
                                ReportTileStyles[tab.key].styleClass
                              } p-2 rounded-circle`}
                            >
                              <MaterialIcon
                                icon={ReportTileStyles[tab.key].icon}
                                size="fs-5 d-flex"
                              />
                            </div>
                            <span className="font-weight-medium text-black">
                              {tab.name}
                            </span>
                          </div>
                          {tab.isActive && (
                            <MaterialIcon
                              icon="check"
                              clazz={`fw-bold ${
                                ReportTileStyles[tab.key].styleClass
                              }`}
                            />
                          )}
                        </div>
                      </Dropdown.Item>
                    ) : null}
                  </>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : null}
    </>
  );
};

const EngagementReportTiles = ({
  tabs,
  setTabs,
  setSelectedTab,
  setIsDefaultView,
}) => {
  const { updateHash, hash } = useHash();

  const onSelect = (tab) => {
    const newTabs = [...tabs].map((tb) =>
      tb.id === tab.id ? { ...tab, isActive: true } : { ...tb, isActive: false }
    );
    setSelectedTab(tab);
    setTabs(newTabs);
    setIsDefaultView(false);
  };

  const onCreate = (tab) => {
    const newTabs = [...tabs].map((tb) =>
      tb.id === tab.id ? { ...tab, isActive: true } : { ...tb, isActive: false }
    );
    setSelectedTab(tab);
    setTabs(newTabs);
    setIsDefaultView(false);
    updateHash(`reports/${tab.key.toLowerCase()}/new`);
  };

  useEffect(() => {
    if (hash?.includes('report')) {
      const segments = hash.split('/');
      if (segments.length >= 3) {
        const reportName = segments[1];
        const foundTab = tabs?.find((report) => report.key === reportName);
        if (foundTab) {
          onSelect(foundTab);
        }
      }
    }
  }, [hash]);

  return (
    <ReportTiles
      tabs={tabs}
      onSelect={onSelect}
      onCreate={onCreate}
      setIsDefaultView={setIsDefaultView}
    />
  );
};

const useEngagementReportTabs = ({
  organizationId,
  currentTab,
  selectedTenant,
  getProfileInfo,
  profileInfo,
  refreshCounts,
}) => {
  const { tenant } = useTenantContext();
  const { isComericaBank, isSVB } = useIsTenant();
  const [organization, setOrganization] = useState({
    ...profileInfo,
    id: organizationId,
  });

  const ReportTabsList = [
    {
      id: 1,
      name: PdfFileNames[ReportTypes.Treasury],
      key: ReportTypes.Treasury,
      component: (org, localTenant, readOnly) => (
        <TreasuryManagementReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          refreshCounts={refreshCounts}
          readOnly={readOnly}
        />
      ),
      permission: PermissionsConstants.ModulesReportNames.Treasury,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Treasury
      ),
      isActive: false,
    },
    {
      id: 2,
      name: PdfFileNames[ReportTypes.WorkingCapital],
      key: ReportTypes.WorkingCapital,
      component: (org, localTenant, readOnly) => (
        <WorkingCapitalAnalysisReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          refreshCounts={refreshCounts}
          getProfileInfo={getProfileInfo}
          readOnly={readOnly}
        />
      ),
      permission: PermissionsConstants.ModulesReportNames.WorkingCapital,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.WorkingCapital
      ),
      isActive: false,
    },
    {
      id: 3,
      name: PdfFileNames[ReportTypes.Fraud],
      permission: PermissionsConstants.ModulesReportNames.Fraud,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Fraud
      ),
      key: ReportTypes.Fraud,
      icon: 'problem',
      component: (org, localTenant, readOnly) => (
        <FraudReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          refreshCounts={refreshCounts}
          readOnly={readOnly}
        />
      ),
      isActive: false,
    },
    {
      id: 4,
      name: PdfFileNames[ReportTypes.Merchant],
      key: ReportTypes.Merchant,
      component: (org, localTenant, readOnly) => (
        <>
          {isComericaBank ? (
            <MerchantReportComerica
              organization={org || organization}
              currentTab={currentTab}
              selectedTenant={localTenant}
              getProfileInfo={getProfileInfo}
              refreshCounts={refreshCounts}
              readOnly={readOnly}
            />
          ) : isSVB ? (
            <MerchantReportSVB
              organization={org || organization}
              currentTab={currentTab}
              selectedTenant={localTenant}
              getProfileInfo={getProfileInfo}
              refreshCounts={refreshCounts}
              readOnly={readOnly}
            />
          ) : (
            <MerchantReportAnalysisReport
              organization={org || organization}
              currentTab={currentTab}
              selectedTenant={localTenant}
              getProfileInfo={getProfileInfo}
              refreshCounts={refreshCounts}
              readOnly={readOnly}
            />
          )}
        </>
      ),
      permission: PermissionsConstants.ModulesReportNames.Merchant,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Merchant
      ),
      isActive: false,
    },
    {
      id: 5,
      name: PdfFileNames[ReportTypes.AccountStructure],
      key: ReportTypes.AccountStructure,
      component: (org, localTenant, readOnly) => (
        <AccountStructureReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          readOnly={readOnly}
          refreshCounts={refreshCounts}
        />
      ),
      permission: PermissionsConstants.ModulesReportNames.AccountStructure,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.AccountStructure
      ),
      isActive: false,
    },
    {
      id: 6,
      name: PdfFileNames[ReportTypes.CommercialCard],
      key: ReportTypes.CommercialCard,
      component: (org, localTenant, readOnly) => (
        <CommercialCardReport
          organization={org || organization}
          currentTab={currentTab}
          selectedTenant={localTenant}
          getProfileInfo={getProfileInfo}
          readOnly={readOnly}
          refreshCounts={refreshCounts}
        />
      ),
      permission: PermissionsConstants.ModulesReportNames.CommercialCard,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.CommercialCard
      ),
      isActive: false,
    },
  ];
  // it should be the very first one that is enabled/available
  const enabledReports = ReportTabsList?.filter((tb) => tb.isEnabled);
  const firstEnabledTab = {};
  const [tab, setTab] = useState({});
  const [isDefaultView, setIsDefaultView] = useState(true);
  enabledReports.sort((a, b) => a.name.localeCompare(b.name));
  const [reportTabs, setReportTabs] = useState(
    enabledReports.map((mp) => ({
      ...mp,
      isActive: mp.key === firstEnabledTab.key,
    }))
  );

  // wait for organization full object gets loaded then update it locally
  useEffect(() => {
    if (profileInfo?.id) {
      setOrganization(profileInfo);
    }
  }, [profileInfo]);

  const ReportTilesCallback = useCallback(() => {
    return (
      <EngagementReportTiles
        tabs={reportTabs}
        setTabs={setReportTabs}
        selectedTab={tab}
        setSelectedTab={setTab}
        setIsDefaultView={setIsDefaultView}
      />
    );
  }, [tab, setTab]);

  const ReportDropdownCallback = useCallback(() => {
    return (
      <ReportTilesDropdown
        tabs={reportTabs}
        setTabs={setReportTabs}
        selectedTab={tab}
        setSelectedTab={setTab}
        setIsDefaultView={setIsDefaultView}
      />
    );
  }, [tab, setTab]);

  return useMemo(
    () => ({
      selectedTab: tab,
      setSelectedTab: setTab,
      tabs: reportTabs,
      setTabs: setReportTabs,
      EngagementReportTiles: ReportTilesCallback,
      EngagementReportDropdown: ReportDropdownCallback,
      isDefaultView,
      setIsDefaultView,
      ReportTabsList,
      enabledReports,
      firstEnabledTab,
    }),
    [setTab, ReportTilesCallback]
  );
};

export default useEngagementReportTabs;

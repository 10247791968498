import React, { useState, useEffect, useContext } from 'react';
import { format } from 'date-fns';
import { useProfileContext } from '../../../contexts/profileContext';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import Avatar from '../../../components/Avatar';
import { TenantContext } from '../../../contexts/TenantContext';
import { isModuleAllowed, isPermissionAllowed } from '../../../utils/Utils';
import ReviewMyAssignmentsBanner from '../../../components/Assignments/ReviewMyAssignmentsBanner';

const DateFormat = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000); // update every second
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="flex flex-column time-now">
        <h1 className="fs-5 text-truncate font-weight-semi-bold mb-0 text-right">
          {format(time, 'hh:mm a')}
        </h1>
        <p className="mb-0 text-right">{format(new Date(), 'EEEE, MMMM d')}</p>
      </div>
      <MaterialIcon icon="event" size="fs-1" clazz="text-black-50" />
    </>
  );
};

const Header = () => {
  const { profileInfo } = useProfileContext();
  const { tenant } = useContext(TenantContext);

  const isMyLearnAssignmentsAllowed =
    isModuleAllowed(tenant.modules, 'learns_assignments') &&
    isPermissionAllowed('lessons', 'view');

  return (
    <>
      <div className="page-header organization-header p-3 px-4 mb-0">
        <div className="row">
          <div className="col-sm mb-2 mb-sm-0">
            <div className="d-flex gap-1 align-items-center flex-wrap">
              <Avatar
                user={profileInfo}
                classModifiers="mr-2-"
                sizeIcon="avatar-dark text-white"
                defaultSize="lg"
              />
              <div className="d-flex flex-column ml-2">
                <h1 className="fs-5 text-truncate font-weight-semi-bold mb-0">
                  {`Hello, ${profileInfo?.first_name}!`}
                </h1>
                <p className="mb-0">{`Here's your overview for today`}</p>
              </div>
              {isMyLearnAssignmentsAllowed && <ReviewMyAssignmentsBanner />}
              <div className="row align-items-center gap-3 no-gutters ml-auto">
                <DateFormat />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

import routes from '../../../utils/routes.json';
import { useHistory } from 'react-router-dom';
import { useTenantContext } from '../../../contexts/TenantContext';
import { isModuleAllowed, isPermissionAllowed } from '../../../utils/Utils';

import MaterialIcon from '../../../components/commons/MaterialIcon';
import { ADD_INSIGHT } from '../../../utils/constants';
import { useModuleContext } from '../../../contexts/moduleContext';
import useIsTenant from '../../../hooks/useIsTenant';

export default function IDASection() {
  const history = useHistory();
  const { tenant } = useTenantContext();
  const { moduleMap } = useModuleContext();
  const { isSynovus } = useIsTenant();

  const isProspectsAllowed =
    isModuleAllowed(tenant.modules, 'prospecting') &&
    isPermissionAllowed('prospects', 'view');

  const isCompaniesAllowed =
    isModuleAllowed(tenant.modules, 'companies') &&
    isPermissionAllowed('organizations', 'create');

  const isContactsAllowed =
    isModuleAllowed(tenant.modules, 'contacts') &&
    isPermissionAllowed('contacts', 'create');

  const isOpportunitiesAllowed =
    isModuleAllowed(tenant.modules, 'pipelines') &&
    isPermissionAllowed('deals', 'create');

  const sections = [
    {
      icon: 'person_search',
      label: 'Find prospects',
      path: routes.prospecting,
      permission: isProspectsAllowed,
    },
    {
      id: 7,
      label: isSynovus ? ADD_INSIGHT : `Add ${moduleMap.organization.singular}`,
      key: 'add_company',
      icon: 'corporate_fare',
      permission: isCompaniesAllowed,
    },
    {
      id: 8,
      label: `Add ${moduleMap.contact.singular}`,
      key: 'add_contact',
      icon: 'supervisor_account',
      permission: isContactsAllowed,
    },
    {
      id: 9,
      label: `Add ${moduleMap.deal.singular}`,
      key: 'add_opportunity',
      icon: 'monetization_on',
      permission: isOpportunitiesAllowed,
    },
    {
      id: 10,
      label: 'More',
      key: 'add_record',
      icon: 'add',
      permission:
        isCompaniesAllowed || isContactsAllowed || isOpportunitiesAllowed,
    },
  ];

  const handlClick = (section) => {
    if (section.path) {
      handleRedirect(section);
    } else {
      const button = document.getElementById(section.key);
      if (button) button.click();
    }
  };

  const handleRedirect = (section) => {
    const path = section.search
      ? `${section.path}#${section.search}`
      : section.path;
    history.push(path);
  };

  const filteredSections = sections?.filter((item) => item.permission);

  if (!filteredSections?.length) return;

  return (
    <div className="more-options d-flex gap-2 flex-row flex-nowrap justify-content-center py-2">
      {filteredSections?.map((section, index) => (
        <button
          key={index}
          className="d-flex gap-1 align-items-center justify-content-center bg-hover-soft-gray px-3 border-0 bg-gray-300 text-black"
          aria-label={section.label}
          onClick={() => handlClick(section)}
        >
          <MaterialIcon icon={section.icon} />
          <span className="mb-0">{section.label}</span>
        </button>
      ))}
    </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import {
  isModuleAllowed,
  isPermissionAllowed,
  roundNumbers,
} from '../../../utils/Utils';
import Skeleton from 'react-loading-skeleton';
import { useProfileContext } from '../../../contexts/profileContext';
import organizationService from '../../../services/organization.service';
import contactService from '../../../services/contact.service';
import activityService from '../../../services/activity.service';
import checklistService from '../../../services/checklist.service';
import { TenantContext } from '../../../contexts/TenantContext';
// import dealService from '../../../services/deal.service';

const MY_KEY = 'My';
// const ALL_KEY = 'All';

const FILTER_STATUSES = {
  pending: { key: 'pending', label: 'Not Started', clazz: 'pending' },
  inProgress: { key: 'inProgress', label: 'In Progress', clazz: 'progress' },
  overdue: { key: 'overdue', label: 'Overdue', clazz: 'overdue' },
  completed: { key: 'completed', label: 'Completed', clazz: 'completed' },
};

const FormattedValue = ({ className = '', value, type = '' }) => {
  const values =
    type === '$'
      ? `$${roundNumbers(value, 'short', 2)}`
      : type === '%'
      ? `${roundNumbers(value, 'short', 2)}%`
      : roundNumbers(value, 'short', 2);

  return (
    <>
      <p
        className={`fs-18 text-truncate font-weight-semi-bold mb-0 ${className}`}
        style={{ lineHeight: '20px' }}
      >
        {values}
      </p>
    </>
  );
};

const StatsLoader = () => {
  return (
    <div className="mt-2_1 mb-1 w-100 d-flex align-items-center justify-content-between">
      <Skeleton width={50} height={10} />
      <Skeleton width={50} height={10} />
      <Skeleton width={50} height={10} />
      <Skeleton width={50} height={10} />
    </div>
  );
};

const StatItem = ({ loading, data, selectedFilter }) => {
  const sortedData = data?.items?.sort(
    (a, b) => (a.id === 'overdue' ? 1 : b.id === 'overdue' ? -1 : 0) // set overdue option at end
  );

  return (
    <Link
      to={`${data?.path}#ownership=${selectedFilter.id}`}
      className="w-100 d-flex flex-column py-3 px-3_2 prospect-filter-wrapper hover-actions hover-border-primary"
    >
      <div className="d-flex justify-content-between">
        <p className="fs-6 text-truncate d-flex gap-1 text-black font-weight-semi-bold mb-0">
          {data.title}{' '}
          <span className="action-items position-relative" style={{ top: 2 }}>
            <MaterialIcon icon="east" clazz="d-flex" size="fs-5" />{' '}
          </span>
        </p>
        <div className="stats-icon">
          <MaterialIcon icon={data.icon} size="fs-4" />
        </div>
      </div>
      <div className="px-2 mt-3 d-flex align-items-center justify-content-between">
        {loading ? (
          <StatsLoader />
        ) : (
          <>
            {sortedData?.length ? (
              <>
                {sortedData?.map((item) => (
                  <div key={item.id} className="d-flex flex-column">
                    <FormattedValue
                      className={
                        item.id === 'overdue' ? 'text-danger' : 'text-black'
                      }
                      value={
                        selectedFilter?.id === MY_KEY
                          ? item.myValue
                          : item.allValue
                      }
                      type={item.type}
                    />
                    <p
                      className={`fs-7 mb-0 font-weight-medium ${
                        item.id === 'overdue' ? 'text-danger' : 'text-black-50'
                      }`}
                      style={{ lineHeight: '20px' }}
                    >
                      {item.label}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <div className="w-100 h-100 d-flex align-items-center justify-content-between">
                <p className="text-muted text-center w-100">{`No ${data.title?.toLowerCase()} available`}</p>
              </div>
            )}
          </>
        )}
      </div>
    </Link>
  );
};

export default function SideBarStats({ selectedFilter }) {
  const { profileInfo } = useProfileContext();
  const { tenant } = useContext(TenantContext);
  const [loading, setLoading] = useState(true);
  const [statsFetched, setStatsFetched] = useState({});

  const [stats, setStats] = useState([
    {
      id: 'accounts',
      title: 'Accounts',
      icon: 'corporate_fare',
      path: '/accounts',
      items: [
        {
          id: 'organizations',
          label: 'Companies',
          myValue: 0,
          allValue: 0,
        },
        {
          id: 'contacts',
          label: 'Contacts',
          myValue: 0,
          allValue: 0,
        },
        {
          id: 'organizations',
          label: 'Recently Modified',
          myValue: 0,
          allValue: 0,
        },
      ],
    },
    // {
    //   id: 'opportunities',
    //   title: 'Opportunities',
    //   icon: 'trending_up',
    //   path: '/pipeline',
    //   items: [
    //     {
    //       id: 'amount',
    //       label: 'Value',
    //       type: '$',
    //       myValue: 0,
    //       allValue: 0,
    //     },
    //     {
    //       id: 'count',
    //       label: 'Total',
    //       myValue: 0,
    //       allValue: 0,
    //     },
    //     {
    //       id: 'count',
    //       label: 'Recently Modified',
    //       myValue: 0,
    //       allValue: 0,
    //     },
    //   ],
    // },
    {
      id: 'activities',
      title: 'Activities',
      icon: 'event_available',
      path: '/activities',
      items: [
        {
          id: 'task',
          label: 'Tasks',
          myValue: 0,
          allValue: 0,
        },
        {
          id: 'event',
          label: 'Events',
          myValue: 0,
          allValue: 0,
        },
        {
          id: 'call',
          label: 'Calls',
          myValue: 0,
          allValue: 0,
        },
        {
          id: 'overdue',
          label: 'Overdue',
          myValue: 0,
          allValue: 0,
        },
      ],
    },
    {
      id: 'checklists',
      title: 'Checklists',
      icon: 'checklist',
      path: '/checklist',
      items: [
        {
          id: 'notStarted',
          label: 'Not Started',
          myValue: 0,
          allValue: 0,
        },
        {
          id: 'inProgress',
          label: 'InProgress',
          myValue: 0,
          allValue: 0,
        },
        {
          id: 'completed',
          label: 'Completed',
          myValue: 0,
          allValue: 0,
        },
        {
          id: 'overdue',
          label: 'Overdue',
          myValue: 0,
          allValue: 0,
        },
      ],
    },
  ]);

  const isActivitiesAllowed =
    isModuleAllowed(tenant.modules, 'activities') &&
    isPermissionAllowed('activities', 'view');

  const isChecklistsAllowed =
    isModuleAllowed(tenant.modules, 'checklist') &&
    isPermissionAllowed('checklists', 'view');

  const isCompaniesAllowed =
    isModuleAllowed(tenant.modules, 'companies') &&
    isPermissionAllowed('organizations', 'view');

  const isOpportunitiesAllowed =
    isModuleAllowed(tenant.modules, 'pipelines') &&
    isPermissionAllowed('deals', 'create');

  const getCounts = async () => {
    const pagination = { page: 1, limit: 1 };
    const filter = { filter: {} };
    const ownerFilter = {
      filter: { assigned_user_id: profileInfo?.id },
    };
    const queryParams = { self: false };
    const ownerQueryParams = { self: true };

    const fetchChecklistByStatus = (status, assignedUserId) =>
      checklistService.getOrgCheckLists({
        ...pagination,
        'organizationChecklistProgress[status]': status,
        ...(assignedUserId !== undefined && {
          ownerId: assignedUserId,
        }),
      });

    const self = selectedFilter?.id === MY_KEY;
    const keysToFetch = self
      ? [
          'MyOrganization',
          'MyContacts',
          'MyOpportunities',
          'MyActivities',
          'MyNotStartedChecklists',
          'MyInProgressChecklists',
          'MyCompletedChecklists',
          'MyOverdueChecklists',
        ]
      : [
          'AllOrganizations',
          'AllContacts',
          'AllOpportunities',
          'AllActivities',
          'AllNotStartedChecklists',
          'AllInProgressChecklists',
          'AllCompletedChecklists',
          'AllOverdueChecklists',
        ];

    const countRequests = [
      {
        id: 'organizations',
        parentId: 'accounts',
        valueKey: 'allValue',
        key: 'AllOrganizations',
        apiCall: () =>
          organizationService.getOrganizations({ ...filter }, pagination),
      },
      {
        id: 'organizations',
        parentId: 'accounts',
        valueKey: 'myValue',
        key: 'MyOrganization',
        apiCall: () =>
          organizationService.getOrganizations({ ...ownerFilter }, pagination),
      },
      {
        id: 'contacts',
        parentId: 'accounts',
        valueKey: 'allValue',
        key: 'AllContacts',
        apiCall: () => contactService.getContacts({ ...filter }, pagination),
      },
      {
        id: 'contacts',
        parentId: 'accounts',
        valueKey: 'myValue',
        key: 'MyContacts',
        apiCall: () =>
          contactService.getContacts({ ...ownerFilter }, pagination),
      },
      // {
      //   parentId: 'opportunities',
      //   key: 'AllOpportunities',
      //   apiCall: () =>
      //     dealService.getStatusSummary('9835be8f-c65a-475c-8ba8-96f9ee8acc7e'),
      // },
      // {
      //   parentId: 'opportunities',
      //   key: 'MyOpportunities',
      //   apiCall: () =>
      //     dealService.getStatusSummary('9835be8f-c65a-475c-8ba8-96f9ee8acc7e'),
      // },
      {
        parentId: 'activities',
        key: 'AllActivities',
        apiCall: () => activityService.getStats({}, queryParams),
      },
      {
        parentId: 'activities',
        key: 'MyActivities',
        apiCall: () => activityService.getStats({}, ownerQueryParams),
      },
      {
        id: 'notStarted',
        parentId: 'checklists',
        valueKey: 'allValue',
        key: 'AllNotStartedChecklists',
        apiCall: () => fetchChecklistByStatus(FILTER_STATUSES.pending.key),
      },
      {
        id: 'notStarted',
        parentId: 'checklists',
        valueKey: 'myValue',
        key: 'MyNotStartedChecklists',
        apiCall: () =>
          fetchChecklistByStatus(FILTER_STATUSES.pending.key, profileInfo?.id),
      },
      {
        id: 'inProgress',
        parentId: 'checklists',
        valueKey: 'allValue',
        key: 'AllInProgressChecklists',
        apiCall: () => fetchChecklistByStatus(FILTER_STATUSES.inProgress.key),
      },
      {
        id: 'inProgress',
        parentId: 'checklists',
        valueKey: 'myValue',
        key: 'MyInProgressChecklists',
        apiCall: () =>
          fetchChecklistByStatus(
            FILTER_STATUSES.inProgress.key,
            profileInfo?.id
          ),
      },
      {
        id: 'completed',
        parentId: 'checklists',
        valueKey: 'allValue',
        key: 'AllCompletedChecklists',
        apiCall: () => fetchChecklistByStatus(FILTER_STATUSES.completed.key),
      },
      {
        id: 'completed',
        parentId: 'checklists',
        valueKey: 'myValue',
        key: 'MyCompletedChecklists',
        apiCall: () =>
          fetchChecklistByStatus(
            FILTER_STATUSES.completed.key,
            profileInfo?.id
          ),
      },
      {
        id: 'overdue',
        parentId: 'checklists',
        valueKey: 'allValue',
        key: 'AllOverdueChecklists',
        apiCall: () =>
          fetchChecklistByStatus([
            FILTER_STATUSES.pending.key,
            FILTER_STATUSES.inProgress.key,
          ]),
      },
      {
        id: 'overdue',
        parentId: 'checklists',
        valueKey: 'myValue',
        key: 'MyOverdueChecklists',
        apiCall: () =>
          fetchChecklistByStatus(
            [FILTER_STATUSES.pending.key, FILTER_STATUSES.inProgress.key],
            profileInfo?.id
          ),
      },
    ];

    // filter requests based on `keysToFetch` and avoid redundant API calls
    const filteredRequests = countRequests.filter(
      (request) =>
        keysToFetch.includes(request.key) && !statsFetched[request.key]
    );

    if (filteredRequests.length === 0) {
      return; // all api's already fetched
    }

    setLoading(true);
    try {
      const responses = await Promise.allSettled(
        filteredRequests?.map((request) => request.apiCall())
      );

      const updatedCounts = [];

      filteredRequests.forEach((request, index) => {
        if (request.parentId === 'activities') {
          const data = responses[index].value?.data;
          const counts = data?.counts;
          const overdueCounts = data?.overdue;
          if (counts) {
            setStats((prevStats) =>
              prevStats.map((stat) => {
                if (stat.id === 'activities') {
                  const updatedItems = stat.items.map((item) => {
                    if (item.id === 'overdue' && overdueCounts) {
                      // Sum all overdue values for task, event, and call
                      const totalOverdue = Object.values(overdueCounts).reduce(
                        (sum, value) => sum + value,
                        0
                      );
                      return request.key === 'MyActivities'
                        ? { ...item, myValue: totalOverdue }
                        : { ...item, allValue: totalOverdue };
                    }

                    const updatedValue = counts[item.id] || 0;
                    return request.key === 'MyActivities'
                      ? { ...item, myValue: updatedValue }
                      : { ...item, allValue: updatedValue };
                  });

                  return { ...stat, items: updatedItems };
                }
                return stat;
              })
            );
          }
        }
        // else if (request.parentId === 'opportunities') {
        //   const data = responses[index].value?.data;
        //   if (Array.isArray(data) && data.length) {
        //     const totalCount = data.reduce(
        //       (sum, item) => sum + (item.count || 0),
        //       0
        //     );
        //     const totalAmount = data.reduce(
        //       (sum, item) => sum + (item.amount || 0),
        //       0
        //     );

        //     setStats((prevStats) =>
        //       prevStats.map((stat) => {
        //         if (stat.id === 'opportunities') {
        //           const updatedItems = stat.items.map((item) => {
        //             if (item.id === 'count') {
        //               return request.key === 'MyOpportunities'
        //                 ? { ...item, myValue: totalCount }
        //                 : { ...item, allValue: totalCount };
        //             }

        //             if (item.id === 'amount') {
        //               return request.key === 'MyOpportunities'
        //                 ? { ...item, myValue: totalAmount }
        //                 : { ...item, allValue: totalAmount };
        //             }

        //             return item;
        //           });

        //           return { ...stat, items: updatedItems };
        //         }
        //         return stat;
        //       })
        //     );
        //   }
        // }
        else {
          const count =
            responses[index].status === 'fulfilled'
              ? responses[index].value?.data?.pagination?.count || 0
              : 0;

          updatedCounts.push({
            ...request,
            count,
          });
        }

        setStatsFetched((prev) => ({
          ...prev,
          [request.key]: true,
        }));
      });

      updateStats(updatedCounts);
    } catch (error) {
      console.error('Error fetching counts:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateStats = (updatedCounts) => {
    setStats((prevStats) =>
      prevStats.map((section) => {
        const parentUpdate = updatedCounts.find(
          (count) => count.parentId === section.id
        );
        if (parentUpdate) {
          return {
            ...section,
            items: section.items.map((item) => {
              const updatedCount = updatedCounts.find(
                (count) => count.id === item.id && count.parentId === section.id
              );
              if (updatedCount) {
                return {
                  ...item,
                  [updatedCount.valueKey]: updatedCount.count,
                };
              }

              return item;
            }),
          };
        }
        return section;
      })
    );
  };

  useEffect(() => {
    getCounts();
  }, [selectedFilter]);

  const permissions = {
    accounts: isCompaniesAllowed,
    activities: isActivitiesAllowed,
    checklists: isChecklistsAllowed,
    opportunities: isOpportunitiesAllowed,
  };

  const filteredStats = stats?.filter((item) => permissions[item.id]);

  return (
    <>
      <div className="w-100 d-flex flex-column gap-2">
        {filteredStats?.map((item) => (
          <StatItem
            key={item.id}
            data={item}
            loading={loading}
            selectedFilter={selectedFilter}
          />
        ))}
      </div>
    </>
  );
}

import { CardBody } from 'reactstrap';
import {
  formatNumber,
  transformTo2D,
  formatDecimalFixed,
  roundOrShowAll,
  numbersWithComma,
} from '../../../../utils/Utils';
import CutoutWidget from './CutoutWidget';
import moment from 'moment';
import {
  APWData,
  RefundAndChargebacksAmountProcessedData,
} from '../../constants/widgetsConstants';

const splitDateAndGetMonthYear = (dateString) => {
  try {
    return moment(dateString?.split('T')[0]).format('MMMM YYYY');
  } catch (e) {
    return moment().format('MMMM YYYY');
  }
};

const threshold = 999999999;
const decimals = 2;
const AmountProcessedWidget = ({ widgetConfig, whenPrinting }) => {
  const data = widgetConfig?.items[0];
  const transactions = transformTo2D(data.transactions, 3);
  const isAggregateView = widgetConfig?.aggregate === true;
  const isFeeBreakdownWidget =
    widgetConfig?.header === RefundAndChargebacksAmountProcessedData.header;

  const trimCompanyName = () => {
    return (
      widgetConfig?.data?.companyName ||
      aggregateOrIndividualObject?.Customer_Name
    ).replace(/\s*\(\d+\)$/, '');
  };
  /// Company Name did not have chargebacks in timeframe or between timeframe for aggregate
  const aggregateOrIndividualObject = isAggregateView
    ? widgetConfig?.data?.aggregate
    : widgetConfig?.data;
  const totalAmount = isFeeBreakdownWidget
    ? {
        percentage: aggregateOrIndividualObject[data.key],
        amount: aggregateOrIndividualObject?.TotalGrossVolume,
      }
    : {
        percentage: aggregateOrIndividualObject[data.key],
        amount: aggregateOrIndividualObject[data.key],
      };

  const dateRangeLabel = isAggregateView
    ? ` monthly between ${splitDateAndGetMonthYear(
        aggregateOrIndividualObject.monthRange.start
      )} - ${splitDateAndGetMonthYear(
        aggregateOrIndividualObject.monthRange.end
      )}`
    : `in ${splitDateAndGetMonthYear(
        aggregateOrIndividualObject?.reportDate || new Date().toISOString()
      )}`;

  let headerText;
  if (isFeeBreakdownWidget) {
    headerText = RefundAndChargebacksAmountProcessedData.aggregateHeader;
  } else {
    headerText = isAggregateView ? APWData.aggregateHeader : APWData.header;
  }

  let subheaderText = `${trimCompanyName()} processed ${
    isAggregateView ? ' on average ' : ''
  } ${
    data.showCurrencySymbol
      ? roundOrShowAll(totalAmount.percentage, threshold, decimals)
      : data.showPercentSymbol
      ? `${formatNumber(totalAmount.amount)}`
      : `${formatDecimalFixed(totalAmount.percentage, 0)}`
  } ${dateRangeLabel}.`;

  if (isFeeBreakdownWidget) {
    if (!totalAmount?.percentage || totalAmount?.percentage === 0) {
      subheaderText = `${trimCompanyName()} did not have chargebacks ${dateRangeLabel}.`;
    } else {
      // if has chargebacks
      /* if (isAggregateView) {
        subheaderText = `${trimCompanyName()} had a chargeback rate of ${formatDecimalFixed(
          totalAmount.percentage,
          2
        )}% with an average ${roundOrShowAll(
          totalAmount.amount,
          threshold,
          decimals
        )} per month from ${splitDateAndGetMonthYear(
          aggregateOrIndividualObject.monthRange.start
        )} to ${splitDateAndGetMonthYear(
          aggregateOrIndividualObject.monthRange.end
        )}.`;
      } else {
        subheaderText = `${trimCompanyName()} had a chargeback rate of ${formatDecimalFixed(
          totalAmount.percentage,
          2
        )}% on amount of ${roundOrShowAll(
          totalAmount.amount,
          threshold,
          decimals
        )} in ${splitDateAndGetMonthYear(
          aggregateOrIndividualObject?.reportDate || new Date().toISOString()
        )}.`;
      } */
      subheaderText =
        'A chargeback percentage exceeding 1% may prevent you from accepting card payments.';
    }
  }

  const getCutoutTitle = (label) => {
    switch (label) {
      case 'Average Fee':
        return 'Average Fee Per Transaction';
      case 'Average Transaction':
        return 'Average Transaction Amount';
      case 'Total Transactions':
        return 'Average Monthly Transactions';
      case 'Total Fees':
        return 'Average Monthly Fees';
      case 'Net Sales':
        return 'Average Monthly Net Sales';
      case 'Chargeback Dollars':
        return isAggregateView
          ? 'Average Monthly Chargebacks ($)'
          : 'Chargebacks ($)';
      case 'Average Chargeback':
        return 'Average Chargeback ($)';
      default:
        return label;
    }
  };

  return (
    <div
      className={`d-flex flex-column w-100 px-2 report-widget ${
        whenPrinting ? 'p-3' : 'p-4'
      }`}
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      {widgetConfig.header && (
        <span className="font-weight-medium text-align-left mb-2">
          {headerText}
        </span>
      )}
      <div
        className="d-flex flex-column align-items-center position-relative border-2 border-green-6 justify-content-center bg-green-5"
        style={{
          height: `${whenPrinting ? '180px' : '230px'}`,
          borderRadius: 'var(--rpt-widget-border-radius)',
        }}
      >
        <h1 className="fs-1 font-weight-bolder text-green-900">
          {data.showCurrencySymbol
            ? roundOrShowAll(totalAmount.percentage, threshold, decimals)
            : data.showPercentSymbol
            ? `${formatDecimalFixed(
                totalAmount.percentage,
                !totalAmount.percentage ? 0 : 2
              )}%`
            : `${formatDecimalFixed(totalAmount.percentage, 0)}`}
        </h1>
        <span className="font-weight-medium font-size-sm">{subheaderText}</span>
      </div>
      {transactions.map((row, rowIndex) => (
        <div className={`d-flex py-0 gap-2 w-100`} key={rowIndex}>
          {row.map((item, itemIndex) => (
            <CutoutWidget
              key={itemIndex}
              widgetConfig={widgetConfig}
              whenPrinting={whenPrinting}
              printingClasses="py-3 mt-4"
              cutoutConfig={{
                icon: item.icon,
                text: getCutoutTitle(item.label),
                bg: `bg-white flex-1 border-green-6 rpt-rounded`,
                iconConfig: {
                  icon: item.icon,
                  fg: 'text-green',
                  bg: 'bg-green-5',
                  filled: true,
                  rounded: true,
                },
              }}
            >
              <CardBody className={`py-4 text-center justify-content-center`}>
                <h1 className={`mb-0 font-weight-bold font-size-lg`}>
                  {item.showCurrencySymbol
                    ? formatNumber(aggregateOrIndividualObject[item.key] || 0)
                    : item.showPercentSymbol
                    ? `${formatDecimalFixed(
                        aggregateOrIndividualObject[item.key] || 0,
                        0
                      )}%`
                    : `${numbersWithComma(
                        aggregateOrIndividualObject[item.key] || 0,
                        {
                          maximumFractionDigits: 0,
                        }
                      )}`}
                </h1>
              </CardBody>
            </CutoutWidget>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AmountProcessedWidget;

export const WidgetTypes = {
  QRCode: 'QRCode', // the one that will have text on left and qrcode on right
  Icon: 'Icon', // the one with icon on right side, single icon with or without shield as bg
  IconSet: 'IconSet', // will add more as the dev goes on, where we want to add bunch of icons
  EPG: 'EPG', // electronic payments for growth
  IVR: 'IVR', // Improve Vendor Relationships = Electronic Payments (now) :\
  HSW: 'HSW', // Hours spent weekly = Automating Accounts Receivable (now) :\
  BEC: 'BEC', // Business Email Compromise
  EPS: 'EPS', // Electronic Payments =  Savings
  PEO: 'PEO', // Paper to Electronic Opportunity
  ESTPR: 'ESTPR', // Estimated Payable Receivable
  DPO: 'DPO', // Days Payable Outstanding
  DSO: 'DSO', // Days Sales Outstanding
  BAR: 'BAR', // Balances at Risk
  BAEC: 'BAEC', // Balances and Earnings Credit,
  GLOSS: 'GLOSS', // Glossary
  DISC: 'DISC',
  BLANK: 'BLANK',
  AAR: 'AAR', // Automating Accounts Receivables
  CPMR: 'CPMR', // Check Payments = More Risk
  CBPR: 'CBPR', // International Banking: Cross-Boarder Payments
  PFS: 'PFS', // International Banking: Paying Foreign Suppliers
  ICF: 'ICF', // Improve Your Cash Flow: Working Capital Report
  TODD: 'TODD', // The One Day Difference: Working Capital Report
  UWC: 'UWC', // Understanding Working Capital: Working Capital Report
  APO: 'APO', // Accounts Payable/Receivable Overview: Working Capital Report
  OTP: 'OTP', // DPO/DSO Opportunity to Improve: Working Capital Report
  FTI: 'FTI', // Factors that impact DPO/DSO: Working Capital Report,
  LOC: 'LOC', // Line of Credit: Working Capital Report
  UIE: 'UIE', // Utilization and Interest Expense: Working Capital Report
  RTM: 'RTM', // Insights: The Right Treasury Management Solutions: Working Capital Report
  IP: 'IP', // Instant Payments
  IVREP: 'IVREP', // Improve Vendor Relationship Electronic Payments
  PC: 'PC', // Payment Comparison
  PF: 'PF', // Payment Fraud Continues To Be A Significant Risk
  HSWR: 'HSWR', // Hours Spent Weekly On Reconciling Payments
  FSE: 'FSE', // How Can We Help, Solutions to Empower - Fraud Report
  FBAR: 'FBAR', // Balances at Risk - Fraud Report,
  PFIB: 'PFIB', // Payment Fraud Impacts All Businesses - Fraud Report
  VCW: 'VCW', // Total Active Vendors & Total $ of Payments
  PSW: 'PSW', // Today and Future payments stats
  YBW: 'YBW', // Your Benefits Widget
  VAP: 'VAP', // Vendor Analysis Phases
  AC: 'AC', // Acceptance Conditions
  IVREPH: 'IVREPH',
  FP: 'FP', // Future Plans
  PT: 'PT', // Payment Trends and Payment Types Charts
  APW: 'APW', // Total dollers processed widget
  OVW: 'OVW', // Overview widget
  FBCW: 'FBCW', // Fee Breakdown Chart Widget
  TCW: 'TCW', // Team Contacts Widget
  FREE: 'FREE', // Free form text widget
  ARRW: 'ARRW', // All rights reserved widget
  CCSW: 'CCSW', // Controllable Cost Savings widget
  MFW: 'MFW', // Merchant Fees widget
  ISW: 'ISW', // Interchange Savings widget
  FCW: 'FCW', // Chargebacks widget
  SVBGW: 'SVBGW', // SVB Glosary widget
};

const FactorsWidth = 70;
export const FraudWhatYouCanDoHeading = 'Fraud - What You Can Do';
export const AFPSource = 'Association for Financial Professionals';
export const OldAFPSource = 'The Association for Financial Professionals';

export const PFIBData = {
  heading: 'Payment Fraud Impacts All Businesses',
  items: [
    {
      id: 1,
      icon: 'zone_person_urgent',
      style: 'rpt-red-box-heading',
      variant: 'rpt-red',
      value: 65,
      text: `<b>65% of organizations</b> surveyed were victims of payment fraud attacks or attempts in the last twelve months.`,
    },
    {
      id: 2,
      icon: 'currency_exchange',
      style: 'rpt-orange-box-heading',
      variant: 'rpt-orange',
      value: 10,
      text: `The majority of these organizations recoup <b>less than 10%</b> of funds stolen due to fraud.`,
    },
  ],
  source: AFPSource,
};

export const FraudSolutionToEmpowerData = {
  heading: 'How We Can Help',
  description: `Solutions that empower you to navigate and secure your financial journey.`,
};

export const SimplifyingFraudData = {
  heading: 'Help Your Business Fight Fraud',
  oldHeading: 'Simplifying Fraud Prevention For Your Business',
  videoId: 'rysf9558p1',
  videoDuration: '8 min',
  channel: 'https://fast.wistia.com/embed/channel/rysf9558p1',
  description: `<p>Watch and share our bite-sized playlist of 3 videos designed to educate you and your team on the impacts of fraud, the common tactics used by criminals, and actionable steps you can take to safeguard your business against financial threats.</p>`,
};

export const HSWRData = {
  heading: 'Hours Spent Weekly On Reconciling Payments',
  description: 'Automate accounts receivable to save time and money.',
  cutoutHeading: 'Time Breakdown of Survey Results',
  barData: [
    {
      key: '< 1 hr',
      value: 5,
      percentage: '34%',
      color: '65%',
      multiply: 17,
    },
    {
      key: '1 - 3 hrs',
      value: 5.2,
      percentage: '36%',
      color: '50%',
      multiply: 17,
    },
    {
      key: '4 - 6 hrs',
      value: 3,
      percentage: '14%',
      color: '85%',
      multiply: 17,
    },
    {
      key: '> 6 hrs',
      value: 3.3,
      percentage: '16%',
      color: '75%',
      multiply: 17,
    },
  ],
  source: AFPSource,
};

export const PFData = {
  heading: 'B2B Fraud by Payment Type',
  description:
    'Check payments are the most vulnerable to fraud, with 63% of survey respondents reporting check fraud compared to only 9% for virtual card payments.',
  source: AFPSource,
};

export const PCData = {
  heading: 'Payment Comparison',
  paymentComparison: [
    {
      header: null,
      items: ['Average Cost', 'Funding', 'Risk of Fraud', 'Reconciliation'],
    },
    {
      header: { icon: 'credit_card', title: 'Virtual Card' },
      items: ['$0.30', 'Next Day', 'Lowest', 'Fastest'],
      bg: 'bg-primary-soft',
    },
    {
      header: { icon: 'account_balance', title: 'ACH' },
      items: ['$0.25', 'Next Day', 'Low', 'Faster'],
    },
    {
      header: { icon: 'price_change', title: 'Instant Payments' },
      items: ['$2.00', 'Instant', 'Low', 'Fastest'],
      bg: 'bg-primary-soft',
    },
    {
      header: { icon: 'checkbook', title: 'Check' },
      items: ['$2.98', '4 Days', 'Highest', 'Manual'],
    },
  ],
  source: AFPSource,
};

const IPData = {
  heading: 'Instant Payments',
  description:
    'Organizations are using faster payments to support a variety of transactions to meet customer demands.',
  cutoutHeading: 'The Industry is Moving',
  demands: [
    { icon: 'location_city', label: 'B2B Payments' },
    { icon: 'order_approve', label: 'Bill Pay' },
    { icon: 'currency_exchange', label: 'Disbursements' },
    { icon: 'universal_currency', label: 'Payroll' },
    { icon: 'move_up', label: 'Wire Transfers' },
  ],
  percentageLabels: [
    { percentage: '44%', labels: ['are implementing', 'FedNow'] },
    {
      percentage: '62%',
      labels: ['have implemented or are', 'implementing RTP'],
    },
    {
      percentage: '86%',
      labels: ['have implemented or are', 'implementing Same Day ACH'],
    },
  ],
  source: 'US Faster Payments Council',
};

const IVREPData = {
  heading: 'Improve Vendor Relationships',
  description: "Vendor's increasingly favor electronic payments.",
  blockData: [
    'Faster Receipt of Payment',
    'Enhanced Remittance to Improve Reconciliation',
    'More Secure Than Checks',
  ],
};

export const LOCData = {
  heading: 'Line of Credit',
  description:
    'Let’s take a look at your average credit utilization over the past 12 months.',
};

export const UIEData = {
  heading: 'Utilization and Interest Expense',
  expensesInsights: [
    {
      id: 2,
      icon: 'arrow_circle_up',
      text: 'Peak Utilization',
      key: 'peakUtilization',
      value: 0,
      borderClasses: 'border-right border-bottom',
    },
    {
      id: 3,
      icon: 'arrow_circle_down',
      text: 'Low Utilization',
      key: 'lowUtilization',
      value: 0,
      borderClasses: 'border-bottom',
    },
    {
      id: 4,
      icon: 'event_note',
      text: 'Average Monthly Utilization',
      key: 'avgMonthlyUtilization',
      value: 0,
      borderClasses: 'border-right',
    },
  ],
  expenses: [
    {
      id: 1,
      icon: 'export_notes',
      text: 'Line Limit',
      key: 'lineLimit',
      value: 0,
      borderClasses: 'border-right border-bottom',
    },
    {
      id: 2,
      icon: 'arrow_circle_up',
      text: 'Peak Utilization',
      key: 'peakUtilization',
      value: 0,
      borderClasses: 'border-right border-bottom',
    },
    {
      id: 3,
      icon: 'arrow_circle_down',
      text: 'Low Utilization',
      key: 'lowUtilization',
      value: 0,
      borderClasses: 'border-bottom',
    },
    {
      id: 4,
      icon: 'event_note',
      text: 'Average Monthly Utilization',
      key: 'avgMonthlyUtilization',
      value: 0,
      borderClasses: 'border-right',
    },
    {
      id: 5,
      icon: 'heap_snapshot_large',
      text: 'Average Annual Rate',
      key: 'avgAnnualRate',
      percentage: true,
      value: 0,
      borderClasses: 'border-right',
    },
    {
      id: 6,
      icon: 'monetization_on',
      text: 'Total Interest Expense',
      key: 'totalInterestExpense',
      value: 0,
    },
  ],
};
export const RTMData = {
  heading: 'Insights: The Right Treasury Management Solutions',
  icon: 'lightbulb',
};

export const DPOFTIData = {
  heading: 'Factors That Impact DPO',
  extraType: 'DPO',
  description:
    'In your accounts payable process there are several solutions and strategies that you can use to impact DPO.',
  factors: [
    {
      icon: 'credit_card',
      heading: 'Commercial Card',
      width: FactorsWidth,
      isChecked: true,
    },
    {
      icon: 'event',
      heading: 'Early Pay Discounts',
      width: FactorsWidth,
      isChecked: true,
    },
    {
      icon: 'acute',
      heading: 'Faster Payments',
      width: FactorsWidth,
      isChecked: true,
    },
  ],
};

export const DSOFTIData = {
  heading: 'Factors That Impact DSO',
  extraType: 'DSO',
  description:
    'In your accounts receivable process there are several solutions and strategies that you can use to impact DSO.',
  factors: [
    {
      icon: 'credit_card',
      heading: 'Card Acceptance',
      width: FactorsWidth,
      isChecked: true,
    },
    {
      icon: 'event',
      heading: 'Offering Early Pay Discounts',
      width: 110,
      isChecked: true,
    },
    {
      icon: 'acute',
      heading: 'Accepting Faster Payments',
      width: 130,
      isChecked: true,
    },
  ],
};

export const DPODSOFactors = {
  DPO: DPOFTIData.factors,
  DSO: DSOFTIData.factors,
};

export const DPOTPData = {
  heading: 'DPO Opportunity to Improve',
  extraType: 'DPO',
  description:
    'Let’s see how your DPO compares to the industry average and the best-in-class.',
  powerAndPotential: [
    {
      icon: 'calendar_clock',
      heading: 'The Power <br /> of One Day',
      description: 'A single day of improvement will free-up cash flow of:',
      xValue: 'oneDayDPODiff',
    },
    {
      icon: 'event',
      heading: 'The Potential of <br /> Best-In-Class',
      description:
        'An improvement of (bestInClassDPO) days will free-up cash flow of:',
      xValue: 'bestInClassDPO',
    },
  ],
};

export const DSOTPData = {
  heading: 'DSO Opportunity to Improve',
  extraType: 'DSO',
  description:
    'Let’s see how your DSO compares to the industry average and the best-in-class.',
  powerAndPotential: [
    {
      icon: 'calendar_clock',
      heading: 'The Power <br /> of One Day',
      description: 'A single day of improvement will free-up cash flow of:',
      xValue: 'oneDayDSODiff',
    },
    {
      icon: 'event',
      heading: 'The Potential of <br /> Best-In-Class',
      description:
        'An improvement of bestInClassDSO days will free-up cash flow of:',
      xValue: 'bestInClassDSO',
    },
  ],
};

export const APOData = {
  heading: 'Days Payable Outstanding (DPO)',
  valueText: 'Your DPO',
  extraType: 'DPO',
  description: `<p>On average, it takes your organization $X days to settle bills with suppliers.</p><p class="mb-0">By extending DPO, you’re holding on to your cash longer, potentially reducing  the need to borrow and improving your working capital.</p>`,
};
export const AROData = {
  heading: 'Days Sales Outstanding (DSO)',
  valueText: 'Your DSO',
  extraType: 'DSO',
  description: `<p>On average, it takes your organization $X days to receive payment for a sale.</p><p class="mb-0">DSO can be decreased by collecting from your clients faster. This has a positive effect on working capital and cash available for debt repayment or investment.</p>`,
};
export const UWCData = {
  heading: 'Understanding Working Capital',
  videoId: '2qtb05b8vw',
  videoDuration: '2 min',
  description: `<p>When you unlock cash from accounts payable and accounts receivable, it positively impacts your working capital.</p> <p>This allows you to pay down loans, invest in growth initiatives or technology, or move money into higher earning investments.</p>`,
};

export const TODDData = {
  heading: 'The One Day Difference',
  description: `<p class="rpt-print">By improving your accounts payable and accounts receivable processes, your organization will have more cash available.</p>
        <p>This enables your organization to invest in short term assets, borrow less on a line of credit, and ultimately, grow your business.</p>`,
};

export const ICFData = {
  heading: 'Improve Your Cash Flow',
  description:
    'Days payable outstanding (DPO) and days sales outstanding (DSO) are two critical indicators of healthy working capital management.',
  moreDescription: [
    'Based on the data provided, you could unlock $X by improving to the industry average.',
    'Based on the data provided, you are performing better than your industry peer group. However, improving your DPO and DSO can still have a positive impact on your working capital, allowing you to become best-in-class.',
  ],
};

export const FraudPreventData = {
  heading: 'What You Can Do To Prevent Fraud',
  description:
    'A robust fraud prevention protocol combining education, tools, and technology is crucial for safeguarding your business.',
  dos: [
    {
      text: 'Verify using contact <br /> information on file',
      icon: 'contact_phone',
      rounded: false,
      filled: true,
    },
    {
      text: 'Validate urgent requests <br /> before acting',
      icon: 'paid',
      rounded: false,
      filled: true,
    },
    {
      text: 'Enable two-factor <br /> authentication',
      icon: 'deployed_code_account',
      rounded: true,
      filled: false,
    },
  ],
  donts: [
    {
      text: 'Verify source before <br /> moving data/funds',
      icon: 'person_check',
      rounded: true,
      filled: false,
    },
    {
      text: 'Avoid clicking unknown <br /> links/attachments',
      icon: 'attach_email',
      rounded: false,
      filled: true,
    },
    {
      text: 'Verify emails with <br /> contact on file',
      icon: 'contacts',
      rounded: false,
      filled: true,
    },
  ],
};

export const FraudMitigationData = {
  heading: 'Fraud Mitigation',
  videoId: '7329bypgjt',
  extraType: 'Fraud',
  videoDuration: '3 min',
  description:
    "<p>65% of companies have experienced a fraud attempt in the last 12 months.</p> <p>Learn how to combat business fraud effectively by learning common check and ACH fraud tactics, utilizing tools like Positive Pay, and implement a robust fraud prevention strategy to safeguard your company's financial well-being.</p>",
  source: AFPSource,
};
export const DSOCashFlowData = {
  heading: 'Days Sales Outstanding (DSO)',
  icons: ['wallet', 'monetization_on', 'account_balance'],
  description:
    '<p class="mb-0">Accelerate Cash Flow with Expanded Payment Methods. To enhance your working capital and streamline receivables collections, consider expanding your payment methods. Offering customers a variety of payment options can significantly reduce your Days Sales Outstanding (DSO) and optimize your working capital cycle.</p>',
};
export const HoursSpentWeeklyData = {
  heading: 'Automating Accounts Receivable',
  description:
    'Over 55% of companies have automated their accounts receivable reconciliation.',
  description2:
    'Over 55% of companies have largely or fully automated their accounts receivable reconciliation. Enhancing automation accelerates receipt posting and reduces days sales outstanding, positively impacting your working capital.',
  cutoutHeading: 'Benefits Include',
  blockData: [
    'Better Visibility',
    'Happier Customers',
    'Cost Savings',
    'Improved Accuracy',
    'Decreased Fraud',
  ],
  source: AFPSource,
};

export const DisclaimerDataFFB = {
  disclaimerHeading: 'Disclaimer',
  disclaimer:
    'The information provided in this report is intended for informational purposes only and does not constitute financial advice. The accuracy and completeness of the data used in this report cannot be guaranteed. Any projections or forecasts contained herein are based on assumptions and may not reflect actual results. First Fidelity Bank does not guarantee any specific savings or returns from the recommendations or strategies presented in this report. It is recommended that you consult with a qualified financial advisor before making any investment decisions based on the information contained in this report. Speak with your Treasury Service Consultant for more information.',
};
export const DisclaimerData = {
  disclaimerHeading: 'Disclaimer',
  disclaimer:
    'The information provided in this report from predictive models is intended for discussion purposes only. The accuracy and completeness of the outcomes cannot be guaranteed, therefore the financial institution providing this report does not guarantee any specific savings or returns from the recommendations or strategies presented.',
};
export const ImproveVendorRelationshipData = {
  heading: 'Electronic Payments',
  cutoutHeading: 'Benefits Include',
  description:
    'Faster payment types like Card, Automated Clearing House (ACH), and Instant Payments improve financial management.',
  blockData: [
    'Improved working capital',
    'Enhanced security',
    'Fast and accurate reconciliation',
    'Reduced cost',
    'Incentives for early payment',
    'More convenience',
  ],
};
export const ElectronicPaymentsToGrowthData = {
  heading: 'Companies are Prioritizing the Move to Electronic Payments',
  source: AFPSource,
  description:
    '<p>Migrating to electronic payments can help you maintain your competitive advantage.</p> <p class="mb-0">Seventy-three percent of organizations surveyed are currently in the process of transitioning their B2B payments from paper checks to electronic payments.</p>',
};

export const ElectronicPaymentsSavingsData = {
  heading: 'Check Payments = Higher Cost + Poor Vendor Experience + More Risk',
  cutoutHeading: 'Cost Comparison',
  description: [
    'Require manual processing, check stock management, and postage.',
    'Payees dislike manual processing and lack of remittance data.',
    'Experience the highest fraud rate of any payment type.',
  ],
  costComparison: [
    { big: '$2.98', small: 'Cost Per Check' },
    { big: '$0.30', small: 'Cost Per Card Transaction' },
    { big: '$0.25', small: 'Cost Per ACH Transaction' },
  ],
  source: AFPSource,
};
export const VendorsCountData = {
  data: {},
  items: [
    {
      title: 'Total Active Vendors',
      key: 'activeVendors',
      value: 0,
      showCurrencySymbol: false,
    },
    {
      title: 'Total $ of Payments',
      key: 'paymentAmount',
      value: 0,
      showCurrencySymbol: true,
    },
  ],
};

export const PaymentStatsTypes = {
  Checks: 'Checks',
  Wire: 'Wire Transfer',
  Ach: 'ACH Payment',
  Card: 'Card',
};
export const PaymentStatsData = {
  data: {},
  items: [
    {
      type: PaymentStatsTypes.Checks,
      icon: 'article',
      today: {
        payments: 0,
        amount: 0,
      },
      future: {
        payments: 0,
        amount: 0,
        trend: 'down',
      },
    },
    {
      type: PaymentStatsTypes.Wire,
      icon: 'swap_horiz',
      today: {
        payments: 0,
        amount: 0,
      },
      future: {
        payments: 0,
        amount: 0,
        trend: 'neutral',
      },
    },
    {
      type: PaymentStatsTypes.Ach,
      icon: 'account_balance_wallet',
      today: {
        payments: 0,
        amount: 0,
      },
      future: {
        payments: 0,
        amount: 0,
        trend: 'up',
      },
    },
    {
      type: PaymentStatsTypes.Card,
      icon: 'credit_card',
      today: {
        payments: 0,
        amount: 0,
      },
      future: {
        payments: 0,
        amount: 0,
        trend: 'up',
      },
    },
  ],
};

export const YourBenefitsData = {
  header: 'Your benefits',
  benefits: [
    [
      {
        icon: 'savings',
        benefit: 'Cost savings by moving to electronic payments from check.',
      },
      {
        icon: 'data_usage',
        benefit:
          'Increased access to remittance data to make reconciliation easier.',
      },
    ],
    [
      {
        icon: 'security',
        benefit: 'Much lower likelihood of successful fraud attempts.',
      },
      {
        icon: 'trending_up',
        benefit: 'Example Revenue Share - $10,536 in revenue share at 100 bps.',
      },
    ],
  ],
};

export const VendorAnalysisPhasesData = {
  data: {
    titleKey0: 'Phase I - Virtual Card Matches',
    descriptionKey0:
      'Virtual card matches denote that these vendors have accepted a virtual card from other payers. They will require minimal work to onboard if they accept virtual card from you.',
    titleKey1: 'Phase II - Qualified Matches',
    descriptionKey1:
      'Qualified matches denote that we\'ve on a name or more, but more work will need to be done to onboard these onto your program. This is where we will spend a majority of time on our "campaign" to start.',
    titleKey2: 'Phase III - Strategic Outreach',
    descriptionKey2:
      'Strategic Outreach matches include ACH to Card opportunity, those vendors with incomplete, or specified categories that typically do not accept (individuals, education, insurance, etc.). We will review these after "qualified."\'',
  },
  items: [
    {
      title: 'Phase I - Virtual Card Matches',
      description:
        'Virtual card matches denote that these vendors have accepted a virtual card from other payors. They will require minimal work to onboard if they accept virtual card from you.',
      titleKey: 'titleKey0',
      descriptionKey: 'descriptionKey0',
      venderCountKey: 'venderCountKey0',
      totalSpentKey: 'totalSpentKey0',
      conversationRateKey: 'conversationRateKey0',
      accentColor: '#DFF0DD',
      bg: 'green',
      fg: 'text-green',
    },
    {
      title: 'Phase II - Qualified Matches',
      description:
        'Qualified matches denote that we\'ve on a name or more, but more work will need to be done to onboard these onto your program. This is where we will spend a majority of time on our "campaign" to start.',
      titleKey: 'titleKey1',
      descriptionKey: 'descriptionKey1',
      venderCountKey: 'venderCountKey1',
      totalSpentKey: 'totalSpentKey1',
      conversationRateKey: 'conversationRateKey1',
      accentColor: '#FCE9D4',
      bg: 'orange',
      fg: 'text-orange',
    },
    {
      title: 'Phase III - Strategic Outreach',
      description:
        'Strategic Outreach matches include ACH to Card opportunity, those vendors with incomplete, or specified categories that typically do not accept (individuals, education, insurance, etc.). We will review these after "qualified."',
      titleKey: 'titleKey2',
      descriptionKey: 'descriptionKey2',
      venderCountKey: 'venderCountKey2',
      totalSpentKey: 'totalSpentKey2',
      conversationRateKey: 'conversationRateKey2',
      accentColor: '#FFD6D6',
      bg: 'red',
      fg: 'text-red',
    },
  ],
};

export const AcceptanceConditionsData = {
  header: 'Best Practices for Supplier Acceptance',
  conditions: [
    'Align supplier outreach with payment cycles and system readiness to maximize the effectiveness of each call.',
    'Make the most of supplier interactions by discussing electronic payment options during contract renewal or when the supplier calls about a missing check.',
    'Reach out to suppliers regularly; those previously unable to accept card may be capable.',
    'Partner with non-accepting suppliers that are interested in the benefits of card acceptance.',
  ],
};

export const IVREPHData = {
  header: 'Improve Vendor Relationships',
  subHeader:
    'Vendors increasingly favor electronic payments due to their universal acceptance and benefits.',
  improvements: [
    {
      icon: 'security',
      text: 'Enhanced security over other payment type',
    },
    {
      icon: 'speed',
      text: 'Improved cash flow with faster receipt of payment',
    },
    {
      icon: 'compare_arrows',
      text: 'Enhanced remittance to improve reconciliation',
    },
  ],
};

export const FPData = [
  {
    icon: 'assignment',
    title: 'Define Scope',
    description:
      'Recommend solutions that fit with your business strategy and identify automation opportunities to drive efficiency.',
  },
  {
    icon: 'analytics',
    title: 'Analyze Data',
    description:
      'Create a goal and a plan to reach that goal leveraging our experience in accounts payable transformation.',
  },
  {
    icon: 'handshake',
    title: 'Partner',
    description:
      'Align our subject matter experts with your allocated resources to execute a successful onboarding campaign.',
  },
  {
    icon: 'trending_up',
    title: 'Maximize',
    description:
      "Provide ongoing support to identify new card opportunities optimizing your card program's effectiveness throughout its lifecycle.",
  },
];

export const PaymentTrendsData = {
  title: 'Percentage of Organizations B2B Payments Received by Check',
  subtitle: '',
  footnote: AFPSource,
  lineChartData: {
    labels: ['2017', '2018', '2019', '2020', '2021', '2022', '2023'],
    data: [74, 82, 74, 71, 66, 63, 65],
    description: '',
  },
  barChartData: {
    title: 'Projected Payment Type Migration',
    labels: [
      'ACH',
      'Wire transfers',
      'ACH debits',
      'Checks',
      'Corporate/commercial credit cards',
      'Third-party pay-outs',
      'Real-time Payments',
    ],
    labelsPdf: [
      'ACH',
      'Wire transfers',
      'ACH debits',
      'Checks',
      'Credit cards',
      'TPP',
      'RTP',
    ],
    data: [47, 39, 20, 18, 5, 2, 1],
  },
  infoBox: {
    icon: 'credit_card',
    text: 'Companies continue to invest in electronic payment methods to automate AP processes and improve vendor relationships.',
  },
};

export const APWData = {
  data: {},
  header: 'Total dollars processed',
  aggregateHeader: 'Average Monthly Dollars Processed',
  items: [
    {
      companyName: 'Belle Tire',
      amount: 12345,
      startDate: 'June 2021',
      endDate: 'February 2022',
      key: 'TotalGrossVolume', // transactions.totalGrossAmount
      metricsKey: 'transactions.totalGrossAmount',
      showCurrencySymbol: true,
      showPercentSymbol: false,
      transactions: [
        {
          key: 'TotalTransactions', // transactions.count
          metricsKey: 'transactions.count',
          label: 'Total Transactions',
          aggregateLabel: 'Average Monthly Transactions',
          icon: 'compare_arrows',
          showCurrencySymbol: false,
          showPercentSymbol: false,
        },
        {
          key: 'TotalFees', // fees.totalAmount
          metricsKey: 'fees.totalAmount',
          label: 'Total Fees',
          aggregateLabel: 'Average Monthly Fees',
          icon: 'payments',
          showCurrencySymbol: true,
          showPercentSymbol: false,
        },
        {
          key: 'AverageTransactions', // transactions.averageAmount
          metricsKey: 'transactions.averageAmount',
          label: 'Average Transaction',
          aggregateLabel: 'Average Transaction Amount',
          icon: 'currency_exchange',
          showCurrencySymbol: true,
          showPercentSymbol: false,
        },
        {
          key: 'AverageFee', // fees.averageAmountPerTransaction
          metricsKey: 'fees.averageAmountPerTransaction',
          label: 'Average Fee',
          aggregateLabel: 'Average Fee Per Transaction',
          icon: 'attach_money',
          showCurrencySymbol: true,
          showPercentSymbol: false,
        },
        {
          key: 'AuthorizationApprovalPercentage', // transactions.authorized.percentageOfTotalTransactions
          metricsKey: 'transactions.authorized.percentageOfTotalTransactions',
          label: 'Authorization to Approval Percentage',
          icon: 'verified',
          showCurrencySymbol: false,
          showPercentSymbol: true,
        },
        {
          key: 'EffectiveRate', // fees.effectiveRate
          metricsKey: 'fees.effectiveRate',
          label: 'Effective Rate',
          icon: 'pie_chart',
          showCurrencySymbol: false,
          showPercentSymbol: true,
        },
      ],
    },
  ],
};

export const OVWData = {
  header: 'Overview',
  items: [
    '$$CustomerName$$ processed $$TotalGrossVolume$$ across $$TotalTransactions$$ total transactions.',
    'Authorization to approval rate measures how many authorization attempts compared to the number of approved transactions (by count).',
    'Your effective rate of $$EffectiveRate$$ determines how much fees (as a %) you pay on each transaction. Use your effective rate to manage expense trends. If you note sizable increases, Comerica recommends you contact your service representative for additional consultation.',
  ],
};

export const FBCWData = {
  data: {
    ChartFeeKey: 80,
    InterchangeFees: 56,
    NetworkFees: 30,
    CommercialBankFees: 14,
  },
  header: 'Fee Breakdown',
  items: [
    {
      key: 'NonBankFees', // fees.nonBankTotalAmount
      metricsKey: 'fees.nonBankTotalAmount',
      label:
        'of all your merchant processing fees are not controlled by Comerica Merchant Services.',
      list: [
        {
          key: 'InterchangePercentage', // fees.byType[type='interchange'].percentageOfTotalFeeAmount
          metricsKey:
            "fees.byType[type='interchange'].percentageOfTotalFeeAmount",
          beforeText: 'Approximately',
          afterText: 'of your fees are interchange fees.',
          enabled: true,
          description:
            "(Interchange is controlled by your customer's Card Issuing Financial Institutions)",
        },
        {
          key: 'NetworkFeesPercentage', // fees.byType[type='network'].percentageOfTotalFeeAmount
          metricsKey: "fees.byType[type='network'].percentageOfTotalFeeAmount",
          beforeText: 'Approximately',
          afterText: 'of your fees are network fees.',
          enabled: true,
          description:
            '(Network Fees are controlled by Visa, Mastercard, Discover, Amex)',
        },
        {
          key: 'BankFeesPercentage', // fees.byType[type='bank'].percentageOfTotalFeeAmount
          metricsKey: "fees.byType[type='bank'].percentageOfTotalFeeAmount",
          beforeText: 'Approximately',
          afterText: 'of your fees are Comerica bank fees.',
          enabled: true,
          description:
            '(Bank Fees cover account servicing, regulatory reporting requirements, and risk management)',
        },
      ],
    },
  ],
};

export const FBCOWData = {
  header: 'Overview',
  items: [
    'Interchange Fees fall into two categories: uncontrollable and controllable.',
    'Uncontrollable Interchange can include type of card presented, such as debit versus credit or commercial.',
    'Controllable Interchange can include payment channel, or amount of information passed within a transaction.',
    'Your Comerica Merchant Services team can help you identify opportunities to reduce Controllable Interchange expense.',
  ],
};

export const RefundAndChargebacksAmountProcessedData = {
  data: {},
  header: 'Refunds and Chargebacks',
  aggregateHeader: 'Chargebacks',
  items: [
    {
      companyName: 'Belle Tire',
      key: 'ChargebackPercentage', // chargeBack.percentageOfTotalTransactions
      metricsKey: 'chargeBacks.percentageOfTotalTransactions',
      startDate: 'June 2021',
      endDate: 'February 2022',
      showCurrencySymbol: false,
      showPercentSymbol: true,
      transactions: [
        {
          key: 'ChargebackCount', // chargeBacks.count
          metricsKey: 'chargeBacks.count',
          label: '# of Chargebacks',
          icon: 'compare_arrows',
          showCurrencySymbol: false,
          showPercentSymbol: false,
        },
        {
          key: 'ChargebackDollars', // chargeBacks.totalAmount
          metricsKey: 'chargeBacks.totalAmount',
          label: 'Chargeback Dollars',
          icon: 'payments',
          showCurrencySymbol: true,
          showPercentSymbol: false,
        },
        {
          key: 'AverageChargeback', // chargeBacks.averageAmount
          metricsKey: 'chargeBacks.averageAmount',
          label: 'Average Chargeback',
          icon: 'currency_exchange',
          showCurrencySymbol: true,
          showPercentSymbol: false,
        },
      ],
    },
  ],
};

export const RefundAndChargebacksOverviewData = {
  header: 'Overview',
  items: [
    'Based on your activity, your company is managing chargeback risk within card brand guidelines. Should this percentage increase, consult your Comerica Merchant Services team to learn more about managing chargebacks.',
  ],
};

export const RecommendationsDidYouKnowData = {
  header: 'Did you know?',
  items: [
    'iQ is a self service portal for Comerica Merchant Services clients available at no additional cost. Enroll: www.accessmyIQ.com',
    'iQ allows you to better research and manage your operations, respond to chargebacks, or even process transactions in the event of other software failure.',
    'Call 866.382.4057 to enroll.',
  ],
};

export const ARRWData = {
  stamp: '© 2024, Comerica Bank. All rights reserved. Member FDIC.',
  statement:
    'New Merchant Services program offerings are subject to underwriting, credit, and collateral approval. All information contained herein is for informational purposes only and no guarantee is expressed or implied. Terms and conditions apply. Gateway costs not included. Depending on processing requirements, other costs may be in scope.',
  enrollmentStatement:
    'Automatic Enrollment: iQ is a self service portal for Comerica Merchant Services clients and available at no additional cost: www.accessmyIQ.com.',
};

export const HorizontalWidgets = [
  {
    id: 1,
    widgetConfig: ElectronicPaymentsSavingsData,
    type: WidgetTypes.EPS,
  },
  {
    id: 2,
    widgetConfig: ImproveVendorRelationshipData,
    type: WidgetTypes.IVR,
  },
  {
    id: 3,
    widgetConfig: FraudPreventData,
    type: WidgetTypes.BEC,
  },
  {
    id: 4,
    widgetConfig: HoursSpentWeeklyData,
    type: WidgetTypes.HSW,
  },
  {
    id: 5,
    widgetConfig: IPData,
    type: WidgetTypes.IP,
  },
  {
    id: 6,
    widgetConfig: IVREPData,
    type: WidgetTypes.IVREP,
  },
  {
    id: 7,
    widgetConfig: PCData,
    type: WidgetTypes.PC,
  },
  {
    id: 8,
    widgetConfig: HSWRData,
    type: WidgetTypes.HSWR,
  },
  {
    id: 9,
    widgetConfig: PFData,
    type: WidgetTypes.PF,
  },
  {
    id: 10,
    widgetConfig: {
      ...UWCData,
    },
    type: WidgetTypes.QRCode,
  },
  {
    id: 11,
    widgetConfig: {
      ...FraudMitigationData,
    },
    type: WidgetTypes.QRCode,
  },
  {
    id: 12,
    widgetConfig: {
      ...PFIBData,
    },
    type: WidgetTypes.PFIB,
  },
];

export const CCSWData = {
  data: {},
  header: 'Controllable Cost Savings',
  description: 'Identifying ways to reduce the cost of credit card payments.',
  numbersHeading: 'By The Numbers*',
  transactions: [
    {
      key: 'totalTransactions',
      title: 'Total Transactions',
      value: '5,470',
      format: 'number',
      type: '-/MoM',
    },
    {
      key: 'averageTransaction',
      title: 'Average Transactions',
      value: '$2,439.46',
      format: 'currency',
      type: '-/MoM',
    },
    {
      key: 'totalDollarsProcessed',
      title: 'Total Dollars Processed',
      value: '$13,343.82',
      format: 'currency',
      type: '-/MoM',
    },
    {
      key: 'averageFee',
      format: 'currency',
      title: 'Average Fee',
      value: '$78.73',
      type: '-/MoM',
    },
    {
      key: 'totalFees',
      format: 'currency',
      title: 'Total Fees',
      value: '$430.02',
      type: '-/MoM',
    },
    {
      key: 'refundPercentage',
      title: 'Refund Percentage (Transaction)',
      value: '0.15%',
      format: 'percentage',
      type: '-/MoM',
    },
    {
      key: 'authorizationToApprovalPercentage',
      title: 'Authorization to Approval Rate',
      value: '18%',
      format: 'percentage',
      type: '',
    },
    {
      key: 'effectiveRate',
      title: 'Effective Rate',
      value: '3.23%',
      format: 'percentage',
      type: '',
    },
  ],
  savingsOpportunity: {
    heading: 'Savings Opportunity*',
    key: 'amexDisclosure',
    note: '*American Express fees excluded.',
    chartData: {
      percentage: '46%',
      data: [46, 54],
      colors: ['#e60808', '#eee'],
    },
  },
};

export const MFWData = {
  data: {},
  header: 'How Merchant Fees Are Calculated',
  description:
    'There are three main categories that make up your merchant processing fees.',
  key: 'nonBankFeesPercentage',
  bankFeePercentage:
    ' of all your merchant processing fees are not controlled by Silicon Valley Bank.',
  feePercentageCardsData: [
    {
      percentage: '75%',
      chartData: [75, 25],
      chartColors: ['#e60808', '#eee'],
      key: 'interchangeFeesAsAPercentOfTotalFees',
      borderClass: 'border-red',
      enabled: true,
      description: [
        ' of your fees are interchange fees.',
        'Regulated Debit: 0.05% & $0.22 Per Transaction',
        'Corporate Credit: 2.95% & $0.10 Per Transaction',
        'Card Issuer (Interchange)',
      ],
    },
    {
      percentage: '38%',
      chartData: [38, 62],
      key: 'networkFeesAsAPercentOfTotalFees',
      chartColors: ['#737373', '#D9D9D9'],
      borderClass: 'widget-border-2',
      enabled: true,
      description: [
        ' of your fees are network fees.',
        'Assessment Fee: 0.14% Per Transaction',
        'Access Fee: $0.0195 Per Transaction',
        'Visa, MasterCard, Amex, Discover (Card Network)',
      ],
    },
    {
      percentage: '19%',
      chartData: [19, 81],
      key: 'bankFeesAsAPercentOfTotalFees',
      chartColors: ['#737373', '#D9D9D9'],
      borderClass: 'widget-border-2',
      enabled: true,
      description: [' of your fees are SVB fees.', 'SVB (Acquiring Bank)'],
    },
  ],
};

export const ISWData = {
  data: {},
  header: 'Interchange Savings',
  summaryHeading: 'Interchange Fee Summary',
  potentialSavings: 'Potential <span class="text-red">Monthly</span> Savings',
  keyDollars: 'ccisDollars',
  keyCount: 'ccisCount',
  description:
    ' transactions had the potential to clear at a lower rate known as Level 2. Talk with your Merchant Account Manager for further details.',
};

export const FCWData = {
  data: {},
  header: 'Chargebacks',
  cardsData: [
    {
      key: 'visaChargebackPercentage',
      title: 'Visa Chargeback Percentage',
      value: '0.25%',
      format: 'percentage',
    },
    {
      key: 'mastercardChargebackPercentage',
      title: 'Mastercard Chargeback Percentage',
      value: '0.13%',
      format: 'percentage',
    },
    {
      key: 'chargebackDollars',
      title: 'Chargeback Dollars',
      value: '$558,196',
      format: 'currency',
    },
    {
      key: 'averageChargeback',
      title: 'Average Chargeback',
      value: '$737.38',
      format: 'currency',
    },
  ],
};

export const ChargebackDefaultWidgetData = {
  id: 15,
  heading: 'Benefits of Level II and Level III (B2B)',
  videoId: '4ukz7psg0d',
  videoDuration: '3 min',
  description:
    'Discover the Advantages of Level II and Level III (B2B) Processing: Learn how providing comprehensive transaction data can lead to lower processing fees for businesses, potentially saving thousands of dollars annually, while understanding the eligibility factors that determine qualification for reduced rates.',
};

export const SVBGWData = {
  data: {},
  glossaryData: [
    {
      id: 'para1',
      text: '© 2025 SVB Financial Group. All rights reserved. SVB, SVB FINANCIAL GROUP, SILICON VALLEY BANK, MAKE NEXT HAPPEN NOW and the chevron device are trademarks of SVB Financial Group, used under license. Silicon Valley Bank is a member of the FDIC and the Federal Reserve System. Silicon Valley Bank is the California bank subsidiary of SVB Financial Group (Nasdaq: SIVB).',
    },
    {
      id: 'para2',
      text: 'New Merchant Services program offerings are subject to underwriting, credit, and collateral approval. All information contained herein is for informational purposes only and no guarantee is expressed or implied. Terms and conditions apply. Proposal is based on the above assumptions. Gateway costs not included. Depending on processing requirements, other costs may be in scope.',
    },
    {
      id: 'para3',
      text: 'Automatic Enrollment: Commercial Card Interchange Service (CCIS) VISA and MasterCard apply different interchange rates to commercial card transactions based on the level of transaction detail that a Merchant provides in its settlement files. Including the sales tax amount with each transaction can help you secure a lower interchange level (although other qualification factors also apply). With CCIS, if you do not enter the sales tax information, subject to other factors, including your existing rate and location, SVB will automatically add it for you and share in the savings with you.',
    },
    {
      id: 'para4',
      text: `This adjustment is only applied when CIS has improved your interchange qualification. Eligible merchants will see the fee portion (quarter of amount saved) reflected on your monthly statements as "Commercial Card IC Savings Adjustment." There is nothing you need to do, if sales tax is required at your location you will be auto-enrolled. This service will begin on July 1, 2021, and you may see savings beginning with your July monthly statement. If you would like to learn more about this beneficial service, or if you prefer to not participate in this savings opportunity, please contact customer service at the number on your statement. Please note that if you are eligible to receive the lower interchange (as determined by the card networks) you always have the option of entering the transaction details on your own (outside of the SVB Automatic Enrollment process) to secure a lower interchange level.`,
    },
  ],
};

export const SVBVideoWidgets = [
  ChargebackDefaultWidgetData,
  {
    id: 16,
    heading: 'Intro To Merchant Pricing',
    videoId: '4evjd3f76p',
    videoDuration: '4 min',
    description:
      'Understand the three main merchant pricing models in payment processing – interchange plus, tiered, and flat rate – each designed to suit different business types and transaction volumes, helping you choose the best fit for your specific needs.',
  },
  {
    id: 17,
    heading: 'Decoding the Fee Structure in Card Transactions',
    videoId: 'iiuph7jrij',
    videoDuration: '3 min',
    description:
      'Dive into the complexities of card transaction fees and discover their significance for businesses managing card payments.',
  },
  {
    id: 18,
    heading: 'How Are Interchange Fees Assessed?',
    videoId: '4ukz7psg0d',
    videoDuration: '3 min',
    description:
      'Discover the Advantages of Level II and Level III (B2B) Processing: Learn how providing comprehensive transaction data can lead to lower processing fees for businesses, potentially saving thousands of dollars annually, while understanding the eligibility factors that determine qualification for reduced rates.',
  },
  {
    id: 19,
    heading: 'Impact of MCC on Card Processing Fees',
    videoId: '0cramwjwvr',
    videoDuration: '2 min',
    description:
      "Discover how Merchant Category Code (MCC) affects your business's card processing fees, influencing interchange fees and authorization rates, and learn the importance of understanding your MCC for effective transaction cost management.",
  },
  {
    id: 20,
    heading: 'Strategies To Optimize Interchange',
    videoId: 'rckup1d5n8',
    videoDuration: '2 min',
    description:
      'Explore strategies for minimizing interchange fees and increasing profitability as a merchant.',
  },
  {
    id: 21,
    heading: 'The Benefits of Interchange Plus Pricing',
    videoId: '7peou14zy0',
    videoDuration: '3 min',
    description:
      'Explore interchange plus pricing in merchant services for transparency and potential cost savings while considering its complexity, including fluctuating fees and varied rates.',
  },
  {
    id: 22,
    heading: 'The Components of Interchange Plus Pricing',
    videoId: '54jweqrxyh',
    videoDuration: '4 min',
    description:
      'Explore the transparency and cost-saving potential of interchange plus pricing for high-volume businesses with diverse transaction types.',
  },
  {
    id: 23,
    heading: 'Understanding Chargebacks',
    videoId: 'qnk4v8d3ct',
    videoDuration: '3 min',
    description:
      'Learn about chargebacks, their consequences for businesses, and the importance of understanding and effectively managing them to protect consumers and merchants alike.',
  },
  {
    id: 24,
    heading: 'Achieving PCI Compliance',
    videoId: 'jur5xpej6l',
    videoDuration: '4 min',
    description:
      'Discover essential steps for PCI compliance: Understand standards, conduct gap analysis, and implement effective security policies for secure credit card processing.',
  },
];

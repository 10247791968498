import React, { useEffect, useState } from 'react';
import { useProfileContext } from '../../contexts/profileContext';
import {
  DemoTenantsKeys,
  InputType,
  ReportFormErrorFields,
  ReportTypes,
  TreasuryReportSections,
} from './reports.constants';
import {
  checkIfNaicsOrReportDateEmpty,
  cleanDataWidgets,
  formatCurrency,
  getDifferenceWidgetVisibility,
  NAICS_STORAGE_KEY,
  overflowing,
  parseCurrencyOrNormal,
  sortObjectKeys,
} from '../../utils/Utils';
import ReportService from '../../services/report.service';
import OrganizationService from '../../services/organization.service';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import SimpleModalCreation from '../modal/SimpleModalCreation';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  ListGroup,
  Row,
} from 'reactstrap';
import SicNaicsAutoComplete from '../prospecting/v2/common/SicNaicsAutoComplete';
import ReactDatepicker from '../inputs/ReactDatpicker';
import { Accordion, FormCheck, InputGroup } from 'react-bootstrap';
import TooltipComponent from '../lesson/Tooltip';
import MaterialIcon from '../commons/MaterialIcon';
import {
  ColorCoverageWithEditableControls,
  SwitchInputWithEditableControls,
} from '../layouts/CardLayout';
import NoDataFound from '../commons/NoDataFound';
import useIsTenant from '../../hooks/useIsTenant';
import useSicNaicsChangeDetect from '../../hooks/useSicNaicsChangeDetect';
import useWidgetsLibrary from '../reportbuilder/widgets/useWidgetsLibrary';
import {
  DisclaimerData,
  WidgetTypes,
} from '../reportbuilder/constants/widgetsConstants';
import _ from 'lodash';
import IconHeadingBlock from '../reportbuilder/blocks/IconHeadingBlock';
import WidgetWithActionButtons from '../reportbuilder/widgets/WidgetWithActionButtons';
import {
  calculateOpportunity,
  sumItemValues,
  updateWidgetBySection,
} from './reports.helper.functions';
import TheDifferenceWidget from '../reportbuilder/widgets/TheDifferenceWidget';
import ReportAccordionToggle from './ReportAccordionToggle';
import ReportSectionIncludedLabel from './ReportSectionIncludedLabel';
import ValidationErrorText from '../commons/ValidationErrorText';
import DisclaimerAccordion from './DisclaimerAccordion';
import {
  DefaultRiskColor,
  useRiskColors,
} from '../reportbuilder/RiskColorsBlock';
import ReportModalTitle from './ReportModalTitle';

const WidgetsBySection = ({
  icon,
  widgets,
  section,
  onAddWidget,
  selectedTenant,
  onDeleteWidget,
  setSelectedWidget,
  setShowWidgetsLibrary,
}) => {
  const heading =
    section === 'Disclaimer' && widgets[section]
      ? widgets[section][0]?.widgetConfig?.data?.disclaimerHeading
      : _.startCase(section);

  return (
    <>
      <div className="text-left">
        <br />
        <IconHeadingBlock
          heading={heading}
          icon={icon}
          showIcon={false}
          containerStyle="gap-1 justify-content-between px-3 mb-0"
          reportType={ReportTypes.Treasury}
        />
      </div>
      <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
        {widgets[section]?.map((widget, index) => (
          <WidgetWithActionButtons
            key={index}
            widget={widget}
            section={section}
            onAddWidget={onAddWidget}
            onDeleteWidget={onDeleteWidget}
            setSelectedWidget={setSelectedWidget}
            setShowWidgetsLibrary={setShowWidgetsLibrary}
            selectedTenant={selectedTenant}
            actionButtons={{
              remove: { text: 'Remove', icon: 'delete' },
              add: { text: 'Replace', icon: 'refresh' },
            }}
          />
        ))}
      </ListGroup>
    </>
  );
};

const GenerateTreasuryReportModal = ({
  report,
  widgets,
  isManual,
  isEdited,
  setReport,
  reportPages,
  insightsData,
  organization,
  selectedReport,
  accordionBlock,
  scrapeMetaData,
  selectedTenant,
  refetchInsights,
  feeAllocationData,
  refetchProfileInfo,
  openGenerateReport,
  handleGenerateReport,
  setOpenGenerateReport,
}) => {
  const [modalWidgets, setModalWidgets] = useState({});
  const [modalReportPages, setModalReportPages] = useState({});
  const [currentAccordionKey, setCurrentAccordionKey] = useState(
    TreasuryReportSections.Overview
  );
  const [enabledDifferenceWidget, setEnabledDifferenceWidget] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [deleteBtnConfig, setDeleteBtnConfig] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [reportsToDelete, setReportsToDelete] = useState([]);
  const { profileInfo } = useProfileContext();
  const { isSVB, isComericaBank } = useIsTenant();
  const [reportModal, setReportModal] = useState({});
  const { setShowModal, setCompany, NaicsModal } = useSicNaicsChangeDetect(
    reportModal,
    organization,
    refetchInsights,
    refetchProfileInfo
  );

  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const [errorFields, setErrorFields] = useState({});
  const onChangeRiskColor = (color) => {
    setRiskColors(
      [...riskColors].map((cl) => ({
        ...cl,
        isSelected: cl.id === color.id,
      }))
    );
    const newReport = {
      ...reportModal,
      paymentRisks: {
        ...reportModal.paymentRisks,
        balance: {
          ...reportModal.paymentRisks.balance,
          selectedColor: color,
        },
      },
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Fraud,
      WidgetTypes.BAR,
      newReport
    );
  };

  const { riskColors, setRiskColors, RiskColorsBlock } =
    useRiskColors(onChangeRiskColor);

  useEffect(() => {
    // if the owner viewing give him a delete option only
    if (isEdited && profileInfo.id === selectedReport.createdById) {
      setDeleteBtnConfig({
        label: 'Delete Report',
        show: true,
        loading: false,
        onClick: () => {
          setReportsToDelete([selectedReport]);
          setShowDeleteReportModal(true);
        },
      });
    } else {
      setDeleteBtnConfig(null);
    }
    setErrorFields(ReportFormErrorFields);
  }, [isEdited]);

  const getBoxColor = (rpt) =>
    rpt?.paymentRisks?.balance?.selectedColor || DefaultRiskColor;

  useEffect(() => {
    // always open first accordion whenever modal is opened
    if (openGenerateReport) {
      setCurrentAccordionKey(TreasuryReportSections.Overview);
      delete report?.paymentMethodsUsed['Automated Payable Solution'];
      delete report?.typesOfReceivables['Automated Receivables Solution'];
      delete report?.paymentMethodsUsed['Automated Payables Solution'];
      delete report?.typesOfReceivables['Automated Receivable Solution'];
      const cloned = _.cloneDeep(widgets);

      // check enabledDifferenceWidget flag and show in state
      cloned[TreasuryReportSections.Overview].forEach((widget) => {
        if (widget.type === WidgetTypes.PEO) {
          const enabled = getDifferenceWidgetVisibility(widget.widgetConfig);
          widget.widgetConfig.data = {
            enableDifferenceWidget: enabled,
            opportunity: widget.widgetConfig.data.opportunity,
          };
          setEnabledDifferenceWidget(enabled);
        }
      });
      const riskColor = getBoxColor(report);
      setRiskColors(
        [...riskColors].map((cl) => ({
          ...cl,
          isSelected: cl.id === riskColor.id,
        }))
      );
      setModalWidgets(cloned);
      setModalReportPages(_.cloneDeep(reportPages));
      const clonedRpt = _.cloneDeep(report);
      setReportModal({
        ...clonedRpt,
        disclaimer: clonedRpt.disclaimer || DisclaimerData.disclaimer,
        disclaimerHeading:
          clonedRpt.disclaimerHeading || DisclaimerData.disclaimerHeading,
      });
    }
    setErrorFields(ReportFormErrorFields);
  }, [openGenerateReport]);

  const handleAccordionClick = (eventKey) => {
    setCurrentAccordionKey(eventKey);
  };

  const setReportCurrencyValue = (key, value, section, widgetType) => {
    const newReport = {
      ...reportModal,
      [key]: value,
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(section, widgetType, newReport);
  };

  const handleUpdateWidgetBySectionType = (section, widgetType, newReport) => {
    const newWidgets = updateWidgetBySection(
      modalWidgets,
      section,
      widgetType,
      newReport
    );
    setModalWidgets(newWidgets);
  };

  const handleReportPageToggle = (section) => {
    const updatedReportPages = {
      ...modalReportPages,
      [section]: {
        ...modalReportPages[section],
        enabled: !modalReportPages[section]?.enabled,
      },
    };

    // we need to make sure at-least 1 page is active, so if user toggles all off and trying to toggle last off make sure to avoid it.
    const remainingEnabledSections = Object.entries(updatedReportPages).filter(
      ([key, value]) => value.enabled && key !== section
    ).length;

    if (!remainingEnabledSections) {
      setErrorMessage('At least one section must be enabled.');
      return;
    }
    setModalReportPages(updatedReportPages);
  };

  const handleDifferenceWidgetToggleChange = (e) => {
    const { checked } = e.target;
    setEnabledDifferenceWidget(checked);

    const newReport = {
      ...reportModal,
      enableDifferenceWidget: checked,
    };
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Overview,
      WidgetTypes.PEO,
      newReport
    );
  };

  const handleChangeInputDisclaimer = (e) => {
    const { value } = e.target;
    const newReport = {
      ...reportModal,
      disclaimer: value,
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Disclaimer,
      WidgetTypes.DISC,
      newReport
    );
  };

  const handleChangeInputDisclaimerHeading = (e) => {
    const { value } = e.target;
    const newReport = {
      ...reportModal,
      disclaimerHeading: value,
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Disclaimer,
      WidgetTypes.DISC,
      newReport
    );
  };

  const handleECRRate = (e, section, widgetType) => {
    // only allow greater than 0
    const regex = /^[1-9]\d*(\.\d*)?$/;
    const { value } = e.target;
    if (value === '' || regex.test(value)) {
      setReportCurrencyValue('value6', value, section, widgetType);
    }
  };

  const handleTotalFeesChange = (e) => {
    const { value } = e.target;
    const cleanValue = value?.replaceAll(',', '');
    const numericValue = Number(cleanValue);
    if (numericValue <= 99999) {
      const currency = formatCurrency(value);
      setReportCurrencyValue(
        'totalFees',
        currency,
        TreasuryReportSections.FeeSummary,
        WidgetTypes.BAEC
      );
    }
  };

  const handleChangeInput = (e, key, type, section, widgetType) => {
    const newValue = e.target.value;
    const isCurrency = type === InputType.Currency;
    const isComma = type === InputType.Comma;
    // Ensure input does not start with a dot
    if (newValue?.startsWith('.')) {
      setReportCurrencyValue(key, '', section, widgetType);
      return;
    }
    if (isCurrency) {
      const currency = formatCurrency(newValue);
      setReportCurrencyValue(key, currency, section, widgetType);
    } else if (isComma) {
      if (!newValue || newValue === '0') {
        setReportCurrencyValue(key, '', section, widgetType);
      } else {
        const cleanValue = newValue?.replaceAll(',', '');
        setReportCurrencyValue(key, cleanValue, section, widgetType);
      }
    } else {
      setReportCurrencyValue(key, newValue, section, widgetType);
    }
  };

  useEffect(() => {
    handleAccordionClick(accordionBlock);
  }, [accordionBlock]);

  const handleManualEntryOrFileEntry = async () => {
    let reportObject = null;
    if (
      checkIfNaicsOrReportDateEmpty(reportModal, errorFields, setErrorFields, [
        'value1',
        'value2',
      ])
    ) {
      return;
    }
    try {
      setGenerating(true);
      if (isManual) {
        if ('key' in selectedReport) {
          if (selectedReport?.isManual) {
            // update case
            reportObject = await ReportService.updateReport(
              selectedReport.key,
              {
                name: reportModal.value1,
                date: reportModal.value2,
                manualInput: {
                  ...reportModal,
                  feeAllocation: feeAllocationData,
                  widgets: cleanDataWidgets(modalWidgets),
                  reportPages: modalReportPages,
                  isAuto: false,
                },
              }
            );
            setSuccessMessage('Report Created');
          } else {
            reportObject = await ReportService.updateReport(
              selectedReport.key,
              {
                name: reportModal.value1,
                date: reportModal.value2,
                manualInput: {
                  ...reportModal,
                  feeAllocation: feeAllocationData,
                  widgets: cleanDataWidgets(modalWidgets),
                  reportPages: modalReportPages,
                  isAuto: false,
                },
              }
            );
            setSuccessMessage('Report Saved');
          }
        } else {
          // diff api to call when manual generate
          reportObject = await OrganizationService.createManualReport(
            organization.id,
            {
              name: reportModal.value1,
              date: reportModal.value2,
              type: ReportTypes.Treasury,
              manualInput: {
                ...reportModal,
                feeAllocation: feeAllocationData,
                widgets: cleanDataWidgets(modalWidgets),
                reportPages: modalReportPages,
                isAuto: false,
              },
            }
          );
          setSuccessMessage('Report Created');
        }
      } else {
        if (scrapeMetaData?.report?.reportId) {
          reportObject = await ReportService.updateReport(
            scrapeMetaData.report.reportId,
            {
              name: reportModal.value1,
              date: reportModal.value2,
              manualInput: {
                ...reportModal,
                feeAllocation: feeAllocationData,
                widgets: cleanDataWidgets(modalWidgets),
                reportPages: modalReportPages,
                isAuto: false,
              },
            }
          );
          setSuccessMessage('Report Saved');
        } else {
          // diff api to call when manual generate
          reportObject = await OrganizationService.createManualReport(
            organization.id,
            {
              name: reportModal.value1,
              date: reportModal.value2,
              type: ReportTypes.Treasury,
              manualInput: {
                ...reportModal,
                feeAllocation: feeAllocationData,
                widgets: cleanDataWidgets(modalWidgets),
                reportPages: modalReportPages,
                isAuto: false,
              },
            }
          );
          setSuccessMessage('Report Created');
        }
      }
      // close the modal etc.
      setReport(reportModal);
      handleGenerateReport(
        reportObject,
        cleanDataWidgets(modalWidgets),
        modalReportPages
      );
    } catch (e) {
      console.log(e);
    } finally {
      setGenerating(false);
    }
  };

  const handleConfirmDeleteReport = async () => {
    try {
      // call delete api
      await ReportService.deleteReport(selectedReport.key);
      setSuccessMessage('Report Deleted');
      setShowDeleteReportModal(false);
      handleGenerateReport({ ...selectedReport, action: 'DELETED' });
    } catch (e) {
      console.log(e);
    }
  };

  const estMapping = {
    paymentMethodsUsed: 'estimatedTotalPayables',
    typesOfReceivables: 'estimatedTotalReceivables',
  };

  const handlePayableReceivableToggle = (e, entry, jsonObjectKey) => {
    const isChecked = e.target.checked;
    let newReport = {
      ...reportModal,
      [jsonObjectKey]: {
        ...reportModal[jsonObjectKey],
        [entry[0]]: {
          ...reportModal[jsonObjectKey][entry[0]],
          isChecked,
          itemValue: !isChecked
            ? 0
            : report[jsonObjectKey][entry[0]]?.itemValue,
        },
      },
    };
    const sum =
      jsonObjectKey === 'typesOfReceivables'
        ? sumItemValues({
            ...newReport[jsonObjectKey],
          })
        : sumItemValues(newReport[jsonObjectKey]);
    newReport = {
      ...newReport,
      [estMapping[jsonObjectKey]]: sum,
      enableDifferenceWidget: enabledDifferenceWidget,
    };
    if (jsonObjectKey === 'paymentMethodsUsed' && entry[0] === 'Checks') {
      newReport.opportunity = calculateOpportunity(
        !isChecked ? 0 : report[jsonObjectKey][entry[0]].itemValue,
        selectedTenant
      );
    }
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Overview,
      [WidgetTypes.ESTPR, WidgetTypes.PEO],
      newReport
    );
  };

  const handlePayableReceivableNumbersTextChange = (
    e,
    entry,
    jsonObjectKey
  ) => {
    const value = e.target.value;
    const newReport = {
      ...reportModal,
      [jsonObjectKey]: {
        ...reportModal[jsonObjectKey],
        [entry[0]]: {
          ...reportModal[jsonObjectKey][entry[0]],
          itemValue: value,
          isChecked: parseFloat(value) > 0,
        },
      },
    };

    const sum =
      jsonObjectKey === 'typesOfReceivables'
        ? sumItemValues({
            ...newReport[jsonObjectKey],
          })
        : sumItemValues({
            ...reportModal[jsonObjectKey],
            [entry[0]]: {
              ...reportModal[jsonObjectKey][entry[0]],
              itemValue: e.target.value,
            },
          });
    const updatedSumReport = {
      ...newReport,
      [estMapping[jsonObjectKey]]: sum,
      enableDifferenceWidget: enabledDifferenceWidget,
      opportunity:
        jsonObjectKey === 'paymentMethodsUsed' && entry[0] === 'Checks'
          ? calculateOpportunity(e.target.value, selectedTenant)
          : newReport.opportunity,
    };
    setReportModal(updatedSumReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Overview,
      [WidgetTypes.ESTPR, WidgetTypes.PEO],
      updatedSumReport
    );
  };

  const handlePayableReceivableToggleTextChange = (e, entry, jsonObjectKey) => {
    const keyText = e.target.value;
    const newReport = {
      ...reportModal,
      [jsonObjectKey]: {
        ...reportModal[jsonObjectKey],
        [entry[0]]: {
          ...reportModal[jsonObjectKey][entry[0]],
          key: keyText,
        },
      },
      enableDifferenceWidget: enabledDifferenceWidget,
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Overview,
      WidgetTypes.ESTPR,
      newReport
    );
  };

  const handlePayableReceivableToggleRemove = (entry, jsonObjectKey) => {
    const newReport = {
      ...reportModal,
      [jsonObjectKey]: {
        ...reportModal[jsonObjectKey],
      },
      enableDifferenceWidget: enabledDifferenceWidget,
    };
    delete newReport[jsonObjectKey][entry[0]];
    const sum = sumItemValues(newReport[jsonObjectKey]);
    const updatedSumReport = { ...newReport, [estMapping[jsonObjectKey]]: sum };
    setReportModal(updatedSumReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Overview,
      WidgetTypes.ESTPR,
      updatedSumReport
    );
  };

  const handlePayableReceivableToggleAdd = (entry, jsonObjectKey) => {
    const newReport = {
      ...reportModal,
      [jsonObjectKey]: {
        ...reportModal[jsonObjectKey],
        [entry[0]]: {
          // key: entry[0],
          key: '',
          value: 0,
          itemValue: 0,
          isChecked: true,
          isEdited: true,
        },
      },
      enableDifferenceWidget: enabledDifferenceWidget,
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Overview,
      WidgetTypes.ESTPR,
      newReport
    );
  };

  const handlePaymentRisksToggle = (e, entry) => {
    const { value } = e.target;

    const newReport = {
      ...reportModal,
      paymentRisks: {
        ...reportModal.paymentRisks,
        fraudPreventionProducts: {
          ...reportModal.paymentRisks.fraudPreventionProducts,
          [entry[0]]: {
            key: entry[0],
            value: value.name,
          },
        },
      },
    };

    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Fraud,
      WidgetTypes.BAR,
      newReport
    );
  };

  const handlePaymentRisksMultipleAccountsToggle = (e) => {
    const newReport = {
      ...reportModal,
      paymentRisks: {
        ...reportModal.paymentRisks,
        balance: {
          ...reportModal.paymentRisks.balance,
          multipleAccounts: e.target.checked,
        },
      },
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Fraud,
      WidgetTypes.BAR,
      newReport
    );
  };

  const handleCashVaultToggle = (e) => {
    const newReport = {
      ...reportModal,
      enableCashVault: e.target.checked,
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Overview,
      WidgetTypes.ESTPR,
      newReport
    );
  };

  const handlePaymentRisksToggleTextChange = (e, entry) => {
    const keyText = e.target.value;
    const newReport = {
      ...reportModal,
      paymentRisks: {
        ...reportModal.paymentRisks,
        fraudPreventionProducts: {
          ...reportModal.paymentRisks.fraudPreventionProducts,
          [entry[0]]: {
            key: keyText,
            value: entry[1]?.value,
          },
        },
      },
    };
    setReportModal(newReport);
    handleUpdateWidgetBySectionType(
      TreasuryReportSections.Fraud,
      WidgetTypes.BAR,
      newReport
    );
  };

  const onAddWidget = (e, newWidget, oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: crypto.randomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: crypto.randomUUID() }
          : { ...wg }
      );
    }
    setModalWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
    setShowWidgetsLibrary(false);
  };

  const onDeleteWidget = (oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    setModalWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
  };

  const getUpdatedWidgets = (sectionWidgets, widgetType, insights) => {
    // set update report object into the editing widget
    return sectionWidgets?.map((widget) =>
      widget.type === widgetType
        ? {
            ...widget,
            widgetConfig: { data: report, insightsData: insights },
          }
        : { ...widget }
    );
  };

  useEffect(() => {
    if (insightsData) {
      // then update both DSO/DPO widgets if exist in widgets array
      const payablesSectionWidgets =
        modalWidgets[TreasuryReportSections.Payables];
      const receivablesSectionWidgets =
        modalWidgets[TreasuryReportSections.Receivables];

      setModalWidgets({
        ...modalWidgets,
        [TreasuryReportSections.Payables]: getUpdatedWidgets(
          payablesSectionWidgets,
          WidgetTypes.DPO,
          insightsData
        ),
        [TreasuryReportSections.Receivables]: getUpdatedWidgets(
          receivablesSectionWidgets,
          WidgetTypes.DSO,
          insightsData
        ),
      });
    }
  }, [insightsData]);

  return (
    <>
      <NaicsModal />
      <WidgetsLibraryModal />
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      <DeleteConfirmationModal
        showModal={showDeleteReportModal}
        setShowModal={setShowDeleteReportModal}
        setSelectedCategories={setReportsToDelete}
        event={handleConfirmDeleteReport}
        itemsConfirmation={reportsToDelete}
        heading="Delete Report"
        description="Are you sure you want to delete this Report?"
      />
      {openGenerateReport && (
        <SimpleModalCreation
          modalTitle={<ReportModalTitle type={ReportTypes.Treasury} />}
          open={openGenerateReport}
          bankTeam={false}
          saveButton="Save Report"
          bodyClassName="p-0 overflow-x-hidden treasury-report-modal"
          isLoading={generating}
          deleteButton={deleteBtnConfig}
          handleSubmit={handleManualEntryOrFileEntry}
          size="xxl"
          onHandleCloseModal={() => {
            overflowing();
            setOpenGenerateReport(!openGenerateReport);
            setReportModal({});
          }}
        >
          <Row>
            <Col xl={5} lg={5} md={12} sm={12} xs={12}>
              <div className="pl-3 py-3">
                <FormGroup>
                  <Label for="title">Company Name</Label>
                  <input
                    name="name"
                    type="text"
                    value={reportModal.value1}
                    onChange={(e) => {
                      setReportModal({
                        ...reportModal,
                        value1: e.target.value,
                      });
                      setErrorFields({
                        ...errorFields,
                        name: {
                          ...errorFields.name,
                          isShow: !e.target.value,
                        },
                      });
                    }}
                    className={`form-control border-left-4 border-left-danger ${
                      errorFields?.name?.isShow ? 'border-danger' : ''
                    }`}
                  />
                  {errorFields?.name?.isShow && (
                    <ValidationErrorText text={errorFields.name.message} />
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="title">NAICS</Label>
                  <SicNaicsAutoComplete
                    data={reportModal}
                    setData={setReportModal}
                    placeholder="Enter a NAICS code"
                    setErrorMessage={setErrorMessage}
                    customKey="valueN"
                    callKey={NAICS_STORAGE_KEY}
                    callType="getNaicsCodes"
                    showFieldError={errorFields.naics?.isShow}
                    isDisabled={!!organization.naics_code}
                    onSelect={(item, naicsSicOnly, naicsTitle) => {
                      setReportModal({
                        ...reportModal,
                        valueN: item ? [item] : [],
                        valueNaicsSic: naicsSicOnly,
                        industry: naicsTitle,
                      });
                      setReport({
                        ...report,
                        valueN: item ? [item] : [],
                        valueNaicsSic: naicsSicOnly,
                        industry: naicsTitle,
                      });
                      setErrorFields({
                        ...errorFields,
                        naics: {
                          ...errorFields.naics,
                          isShow: !naicsSicOnly,
                        },
                      });
                      if (naicsSicOnly) {
                        if (naicsSicOnly !== organization.naics_code) {
                          setShowModal(true);
                          setCompany(organization);
                        }
                      }
                    }}
                  />
                  {errorFields.naics?.isShow && (
                    <ValidationErrorText text={errorFields.naics.message} />
                  )}
                </FormGroup>
                <FormGroup className="date-wrapper">
                  <Label for="title">Report Date</Label>
                  <ReactDatepicker
                    id={'rptDate'}
                    name={'date'}
                    todayButton="Today"
                    value={reportModal.value2}
                    autoComplete="off"
                    className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                      errorFields.reportDate?.isShow ? 'border-danger' : ''
                    }`}
                    placeholder="Select Report Date"
                    format="MMMM yyyy"
                    onChange={(date) => {
                      setReportModal({
                        ...reportModal,
                        value2: date,
                      });
                      setErrorFields({
                        ...errorFields,
                        reportDate: {
                          ...errorFields.reportDate,
                          isShow: !date,
                        },
                      });
                      setReportCurrencyValue(
                        'value2',
                        date,
                        TreasuryReportSections.FeeSummary,
                        WidgetTypes.BAEC
                      );
                    }}
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                  {errorFields.reportDate?.isShow && (
                    <ValidationErrorText
                      text={errorFields.reportDate.message}
                    />
                  )}
                </FormGroup>
                <FormGroup>
                  <Accordion activeKey={currentAccordionKey}>
                    <Card>
                      <ReportAccordionToggle
                        eventKey={TreasuryReportSections.Overview}
                        callback={handleAccordionClick}
                        setCurrentAccordionKey={setCurrentAccordionKey}
                      >
                        {TreasuryReportSections.Overview}
                        <ReportSectionIncludedLabel
                          section={TreasuryReportSections.Overview}
                          reportPages={modalReportPages}
                          handleReportPageToggle={handleReportPageToggle}
                        />
                      </ReportAccordionToggle>
                      <Accordion.Collapse
                        eventKey={TreasuryReportSections.Overview}
                      >
                        <CardBody>
                          <Row className="mb-2">
                            <Col md={12}>
                              <FormGroup className="mb-0">
                                <label className="d-flex align-items-center">
                                  <span>Monthly Payables Volume</span>
                                  {Object.entries(
                                    sortObjectKeys(
                                      reportModal?.paymentMethodsUsed || {}
                                    ) || {}
                                  ).length < 5 ? (
                                    <TooltipComponent
                                      title="Add Payable"
                                      capitalizeText={false}
                                    >
                                      <a
                                        className="icon-hover-bg cursor-pointer action-items"
                                        onClick={() => {
                                          handlePayableReceivableToggleAdd(
                                            [
                                              `New Payable ${new Date()
                                                .getTime()
                                                .toString()
                                                .slice(-5)}`,
                                            ],
                                            'paymentMethodsUsed'
                                          );
                                        }}
                                      >
                                        <MaterialIcon icon="add_circle" />{' '}
                                      </a>
                                    </TooltipComponent>
                                  ) : (
                                    <></>
                                  )}
                                </label>
                              </FormGroup>
                              {Object.entries(
                                sortObjectKeys(
                                  reportModal?.paymentMethodsUsed || {}
                                ) || {}
                              ).map((entry) => (
                                <SwitchInputWithEditableControls
                                  id={`paymentMethodsUsed-${entry[0]}`}
                                  key={`paymentMethodsUsed-${entry[0]}`}
                                  entry={entry}
                                  section={TreasuryReportSections.Overview}
                                  selectedTenant={selectedTenant}
                                  placeHolderText="Enter payable name"
                                  itemsLength={
                                    Object.entries(
                                      reportModal?.paymentMethodsUsed || {}
                                    )?.length
                                  }
                                  checked={
                                    entry[1]?.isChecked === undefined
                                      ? true
                                      : entry[1]?.isChecked
                                  }
                                  label={
                                    entry[1]?.key ===
                                    'Automated Payable Solution'
                                      ? 'Automated Payables Solution'
                                      : entry[1]?.key
                                  }
                                  onChange={(e) =>
                                    handlePayableReceivableToggle(
                                      e,
                                      entry,
                                      'paymentMethodsUsed'
                                    )
                                  }
                                  controls={{
                                    maxLength: 50,
                                    onChange: (e) => {
                                      handlePayableReceivableToggleTextChange(
                                        e,
                                        entry,
                                        'paymentMethodsUsed'
                                      );
                                    },
                                    onChangeNumber: (e) => {
                                      handlePayableReceivableNumbersTextChange(
                                        e,
                                        entry,
                                        'paymentMethodsUsed'
                                      );
                                    },
                                    onRemove: () => {
                                      handlePayableReceivableToggleRemove(
                                        entry,
                                        'paymentMethodsUsed'
                                      );
                                    },
                                  }}
                                />
                              ))}
                            </Col>
                          </Row>
                          <Row className="mb-2 mt-3">
                            <Col md={12}>
                              <FormGroup className="mb-0">
                                <label className="d-flex align-items-center">
                                  <span>Monthly Receivables Volume</span>
                                  {Object.entries(
                                    sortObjectKeys(
                                      reportModal?.typesOfReceivables || {},
                                      'typesOfReceivables'
                                    ) || {}
                                  ).length < 5 ? (
                                    <TooltipComponent
                                      title="Add Receivable"
                                      capitalizeText={false}
                                    >
                                      <a
                                        className="icon-hover-bg cursor-pointer action-items"
                                        onClick={() => {
                                          handlePayableReceivableToggleAdd(
                                            [
                                              `New Receivable ${new Date()
                                                .getTime()
                                                .toString()
                                                .slice(-5)}`,
                                            ],
                                            'typesOfReceivables'
                                          );
                                        }}
                                      >
                                        <MaterialIcon icon="add_circle" />{' '}
                                      </a>
                                    </TooltipComponent>
                                  ) : (
                                    <></>
                                  )}
                                </label>
                              </FormGroup>
                              {Object.entries(
                                sortObjectKeys(
                                  reportModal?.typesOfReceivables || {}
                                ) || {}
                              )?.map((entry) => (
                                <SwitchInputWithEditableControls
                                  id={`typesOfReceivables-${entry[0]}`}
                                  key={`typesOfReceivables-${entry[0]}`}
                                  selectedTenant={selectedTenant}
                                  checked={
                                    entry[1]?.isChecked === undefined
                                      ? true
                                      : entry[1]?.isChecked
                                  }
                                  entry={entry}
                                  section={TreasuryReportSections.Overview}
                                  label={entry[1]?.key}
                                  placeHolderText="Enter receivable name"
                                  itemsLength={
                                    Object.entries(
                                      reportModal?.typesOfReceivables || {}
                                    )?.length
                                  }
                                  onChange={(e) =>
                                    handlePayableReceivableToggle(
                                      e,
                                      entry,
                                      'typesOfReceivables'
                                    )
                                  }
                                  controls={{
                                    maxLength: 50,
                                    onChange: (e) => {
                                      handlePayableReceivableToggleTextChange(
                                        e,
                                        entry,
                                        'typesOfReceivables'
                                      );
                                    },
                                    onChangeNumber: (e) => {
                                      handlePayableReceivableNumbersTextChange(
                                        e,
                                        entry,
                                        'typesOfReceivables'
                                      );
                                    },
                                    onRemove: () => {
                                      handlePayableReceivableToggleRemove(
                                        entry,
                                        'typesOfReceivables'
                                      );
                                    },
                                  }}
                                />
                              ))}
                              <FormGroup>
                                <div
                                  className={`d-flex pt-2 mb-3 align-items-center`}
                                >
                                  <FormCheck
                                    type="switch"
                                    key="enableCashVault"
                                    custom={true}
                                    id={`enableCashVault`}
                                    name={`enableCashVault`}
                                    checked={reportModal?.enableCashVault}
                                    onChange={(e) => handleCashVaultToggle(e)}
                                    label=""
                                    className="fs-7"
                                  />
                                  <Label className="mb-0">Cash Vault</Label>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            <div className="d-flex align-items-center justify-content-between">
                              <Label for="title">
                                <TheDifferenceWidget
                                  selectedTenant={selectedTenant}
                                />
                              </Label>
                              <FormCheck
                                type="switch"
                                id="toggleDifferenceWidget"
                                name="toggleDifferenceWidget"
                                onChange={handleDifferenceWidgetToggleChange}
                                custom={true}
                                checked={enabledDifferenceWidget}
                                label="Active"
                              />
                            </div>
                            <InputGroup className="align-items-center bg-gray-200 cursor-not-allowed">
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <input
                                name="opportunity"
                                type="text"
                                readOnly={!enabledDifferenceWidget}
                                value={
                                  parseCurrencyOrNormal(
                                    reportModal.opportunity
                                  ) === 0
                                    ? ''
                                    : parseCurrencyOrNormal(
                                        reportModal.opportunity
                                      )
                                }
                                placeholder="0"
                                onChange={(e) =>
                                  handleChangeInput(
                                    e,
                                    'opportunity',
                                    InputType.Currency,
                                    TreasuryReportSections.Overview,
                                    WidgetTypes.PEO
                                  )
                                }
                                className="form-control"
                              />
                            </InputGroup>
                          </FormGroup>
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <ReportAccordionToggle
                        eventKey={TreasuryReportSections.Payables}
                        callback={handleAccordionClick}
                        dontExpand={true}
                        setCurrentAccordionKey={setCurrentAccordionKey}
                      >
                        {TreasuryReportSections.Payables}
                        <ReportSectionIncludedLabel
                          section={TreasuryReportSections.Payables}
                          reportPages={modalReportPages}
                          handleReportPageToggle={handleReportPageToggle}
                        />
                      </ReportAccordionToggle>
                    </Card>
                    <Card>
                      <ReportAccordionToggle
                        eventKey={TreasuryReportSections.Receivables}
                        callback={handleAccordionClick}
                        dontExpand={true}
                        setCurrentAccordionKey={setCurrentAccordionKey}
                      >
                        {TreasuryReportSections.Receivables}
                        <ReportSectionIncludedLabel
                          section={TreasuryReportSections.Receivables}
                          reportPages={modalReportPages}
                          handleReportPageToggle={handleReportPageToggle}
                        />
                      </ReportAccordionToggle>
                    </Card>
                    <Card>
                      <ReportAccordionToggle
                        eventKey={TreasuryReportSections.Fraud}
                        callback={handleAccordionClick}
                        setCurrentAccordionKey={setCurrentAccordionKey}
                      >
                        {TreasuryReportSections.Fraud}
                        <ReportSectionIncludedLabel
                          section={TreasuryReportSections.Fraud}
                          reportPages={modalReportPages}
                          handleReportPageToggle={handleReportPageToggle}
                        />
                      </ReportAccordionToggle>
                      <Accordion.Collapse
                        eventKey={TreasuryReportSections.Fraud}
                      >
                        <>
                          {isSVB ||
                          selectedTenant?.key === DemoTenantsKeys.svb ? (
                            <CardBody>
                              <NoDataFound
                                title={
                                  <div className="text-gray-search fs-7 font-weight-medium">
                                    No fraud data to be adjusted
                                  </div>
                                }
                                icon="draw"
                                iconStyle="font-size-2em text-gray-search"
                                containerStyle="text-gray-900"
                              />
                            </CardBody>
                          ) : (
                            <CardBody>
                              <FormGroup>
                                <Label for="title">Balance</Label>
                                <InputGroup className="align-items-center">
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <input
                                    name="value7"
                                    type="text"
                                    value={parseCurrencyOrNormal(
                                      reportModal.value7
                                    )}
                                    placeholder="0"
                                    onChange={(e) =>
                                      handleChangeInput(
                                        e,
                                        'value7',
                                        InputType.Currency,
                                        TreasuryReportSections.Fraud,
                                        WidgetTypes.BAR
                                      )
                                    }
                                    className="form-control"
                                  />
                                </InputGroup>
                              </FormGroup>
                              <FormGroup>
                                <div
                                  className={`d-flex pt-1 mb-3 align-items-center`}
                                >
                                  <FormCheck
                                    type="switch"
                                    key="multipleAccounts"
                                    custom={true}
                                    id={`multipleAccounts`}
                                    name={`multipleAccounts`}
                                    checked={
                                      reportModal?.paymentRisks?.balance
                                        ?.multipleAccounts
                                    }
                                    onChange={(e) =>
                                      handlePaymentRisksMultipleAccountsToggle(
                                        e
                                      )
                                    }
                                    label=""
                                    className="fs-7"
                                  />
                                  <Label className="mb-0">
                                    Multiple Accounts
                                  </Label>
                                </div>
                              </FormGroup>
                              <FormGroup className="mb-0">
                                <Label>Fraud Prevention Products</Label>
                              </FormGroup>
                              {Object.entries(
                                reportModal?.paymentRisks
                                  ?.fraudPreventionProducts || {}
                              ).map((entry) => (
                                <ColorCoverageWithEditableControls
                                  id={entry[0]}
                                  key={entry[0]}
                                  entry={entry}
                                  selectedTenant={selectedTenant}
                                  section={TreasuryReportSections.Fraud}
                                  checked={entry[1]?.value > 0}
                                  label={entry[1]?.key}
                                  onChange={(e) =>
                                    handlePaymentRisksToggle(e, entry)
                                  }
                                  controls={{
                                    maxLength: 50,
                                    onChange: (e) => {
                                      handlePaymentRisksToggleTextChange(
                                        e,
                                        entry
                                      );
                                    },
                                  }}
                                />
                              ))}
                              <FormGroup className="mb-0 mt-2">
                                <div className="d-flex align-items-center justify-content-end ml-auto">
                                  <RiskColorsBlock />
                                </div>
                              </FormGroup>
                            </CardBody>
                          )}
                        </>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <ReportAccordionToggle
                        eventKey={TreasuryReportSections.FeeSummary}
                        callback={handleAccordionClick}
                        setCurrentAccordionKey={setCurrentAccordionKey}
                      >
                        Fees
                        <ReportSectionIncludedLabel
                          section={TreasuryReportSections.FeeSummary}
                          reportPages={modalReportPages}
                          handleReportPageToggle={handleReportPageToggle}
                        />
                      </ReportAccordionToggle>
                      <Accordion.Collapse
                        eventKey={TreasuryReportSections.FeeSummary}
                      >
                        <CardBody>
                          <FormGroup>
                            <Label for="title">Total Fees Paid</Label>
                            <InputGroup className="align-items-center">
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <input
                                name="totalFees"
                                type="text"
                                value={parseCurrencyOrNormal(
                                  reportModal.totalFees
                                )}
                                placeholder="0"
                                onChange={handleTotalFeesChange}
                                className="form-control"
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <Label for="title">Total Bank Fees</Label>
                            <InputGroup className="align-items-center">
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <input
                                name="value5"
                                type="text"
                                placeholder="0"
                                value={parseCurrencyOrNormal(
                                  reportModal.value5
                                )}
                                onChange={(e) =>
                                  handleChangeInput(
                                    e,
                                    'value5',
                                    InputType.Currency,
                                    TreasuryReportSections.FeeSummary,
                                    WidgetTypes.BAEC
                                  )
                                }
                                className="form-control"
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <Label for="title">
                              Earnings Credit Rate (bps)
                            </Label>
                            <InputGroup className="align-items-center">
                              <input
                                name="value6"
                                type="text"
                                value={parseCurrencyOrNormal(
                                  reportModal.value6
                                )}
                                placeholder="0"
                                onChange={(e) =>
                                  handleECRRate(
                                    e,
                                    TreasuryReportSections.FeeSummary,
                                    WidgetTypes.BAEC
                                  )
                                }
                                className="form-control"
                              />
                              <InputGroup.Append>
                                <InputGroup.Text>bps</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <Label for="title">
                              Average Collected Balances
                            </Label>
                            <InputGroup className="align-items-center">
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <input
                                name="value7"
                                type="text"
                                value={parseCurrencyOrNormal(
                                  reportModal.value7
                                )}
                                placeholder="0"
                                onChange={(e) =>
                                  handleChangeInput(
                                    e,
                                    'value7',
                                    InputType.Currency,
                                    TreasuryReportSections.FeeSummary,
                                    WidgetTypes.BAEC
                                  )
                                }
                                className="form-control"
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <Label for="title">Earnings Credit Allowance</Label>
                            <InputGroup className="align-items-center">
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <input
                                name="value9"
                                type="text"
                                value={parseCurrencyOrNormal(
                                  reportModal.value9
                                )}
                                placeholder="0"
                                onChange={(e) =>
                                  handleChangeInput(
                                    e,
                                    'value9',
                                    InputType.Currency,
                                    TreasuryReportSections.FeeSummary,
                                    WidgetTypes.BAEC
                                  )
                                }
                                className="form-control"
                              />
                            </InputGroup>
                          </FormGroup>
                          {!isComericaBank && (
                            <FormGroup>
                              <Label for="title">
                                Average Collected Balances Needed to Offset Fees
                              </Label>
                              <InputGroup className="align-items-center">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <input
                                  name="value8"
                                  type="text"
                                  value={parseCurrencyOrNormal(
                                    reportModal.value8
                                  )}
                                  placeholder="0"
                                  onChange={(e) =>
                                    handleChangeInput(
                                      e,
                                      'value8',
                                      InputType.Currency,
                                      TreasuryReportSections.FeeSummary,
                                      WidgetTypes.BAEC
                                    )
                                  }
                                  className="form-control"
                                />
                              </InputGroup>
                            </FormGroup>
                          )}
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <ReportAccordionToggle
                        eventKey={TreasuryReportSections.Glossary}
                        callback={handleAccordionClick}
                        dontExpand={true}
                        setCurrentAccordionKey={setCurrentAccordionKey}
                      >
                        {TreasuryReportSections.Glossary}
                        <ReportSectionIncludedLabel
                          section={TreasuryReportSections.Glossary}
                          reportPages={modalReportPages}
                          handleReportPageToggle={handleReportPageToggle}
                        />
                      </ReportAccordionToggle>
                    </Card>
                    <Card>
                      <ReportAccordionToggle
                        eventKey={TreasuryReportSections.Disclaimer}
                        callback={handleAccordionClick}
                        setCurrentAccordionKey={setCurrentAccordionKey}
                      >
                        {reportModal.disclaimerHeading}
                        <ReportSectionIncludedLabel
                          section={TreasuryReportSections.Disclaimer}
                          reportPages={modalReportPages}
                          handleReportPageToggle={handleReportPageToggle}
                        />
                      </ReportAccordionToggle>
                      <DisclaimerAccordion
                        eventKey={TreasuryReportSections.Disclaimer}
                        reportModal={reportModal}
                        handleChangeInputDisclaimer={
                          handleChangeInputDisclaimer
                        }
                        handleChangeInputDisclaimerHeading={
                          handleChangeInputDisclaimerHeading
                        }
                      />
                    </Card>
                  </Accordion>
                </FormGroup>
              </div>
            </Col>
            <Col
              xl={7}
              lg={7}
              md={12}
              sm={12}
              xs={12}
              className={`bg-white treasury-modal position-relative overflow-x-hidden pb-3 pl-0 ${
                currentAccordionKey === null
                  ? 'd-flex align-items-center justify-content-center'
                  : ''
              }`}
            >
              {currentAccordionKey === null && (
                <NoDataFound
                  title="No page selected"
                  description="Please expand a page menu from left to edit values"
                  icon="analytics"
                  containerStyle="text-gray-900"
                />
              )}
              {currentAccordionKey === TreasuryReportSections.Overview && (
                <WidgetsBySection
                  widgets={modalWidgets}
                  icon="account_balance"
                  section={TreasuryReportSections.Overview}
                  onAddWidget={onAddWidget}
                  onDeleteWidget={onDeleteWidget}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  selectedTenant={selectedTenant}
                  reportPages={modalReportPages}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
              {currentAccordionKey === TreasuryReportSections.Payables && (
                <WidgetsBySection
                  widgets={modalWidgets}
                  icon="price_check"
                  section={TreasuryReportSections.Payables}
                  onAddWidget={onAddWidget}
                  onDeleteWidget={onDeleteWidget}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  selectedTenant={selectedTenant}
                  reportPages={modalReportPages}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
              {currentAccordionKey === TreasuryReportSections.Receivables && (
                <WidgetsBySection
                  widgets={modalWidgets}
                  icon="price_check"
                  section={TreasuryReportSections.Receivables}
                  onAddWidget={onAddWidget}
                  onDeleteWidget={onDeleteWidget}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  selectedTenant={selectedTenant}
                  reportPages={modalReportPages}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
              {currentAccordionKey === TreasuryReportSections.Fraud && (
                <WidgetsBySection
                  widgets={modalWidgets}
                  icon="price_check"
                  section={TreasuryReportSections.Fraud}
                  onAddWidget={onAddWidget}
                  onDeleteWidget={onDeleteWidget}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  selectedTenant={selectedTenant}
                  reportPages={modalReportPages}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
              {currentAccordionKey === TreasuryReportSections.FeeSummary && (
                <WidgetsBySection
                  widgets={modalWidgets}
                  icon="price_check"
                  section={TreasuryReportSections.FeeSummary}
                  onAddWidget={onAddWidget}
                  onDeleteWidget={onDeleteWidget}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  selectedTenant={selectedTenant}
                  reportPages={modalReportPages}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
              {currentAccordionKey === TreasuryReportSections.Glossary && (
                <WidgetsBySection
                  widgets={modalWidgets}
                  icon="menu_book"
                  section={TreasuryReportSections.Glossary}
                  onAddWidget={onAddWidget}
                  onDeleteWidget={onDeleteWidget}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  selectedTenant={selectedTenant}
                  reportPages={modalReportPages}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
              {currentAccordionKey === TreasuryReportSections.Disclaimer && (
                <WidgetsBySection
                  widgets={modalWidgets}
                  icon="menu_book"
                  section={TreasuryReportSections.Disclaimer}
                  onAddWidget={onAddWidget}
                  onDeleteWidget={onDeleteWidget}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  selectedTenant={selectedTenant}
                  reportPages={modalReportPages}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
            </Col>
          </Row>
        </SimpleModalCreation>
      )}
    </>
  );
};
export default GenerateTreasuryReportModal;

import React, { useState, useEffect } from 'react';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import { CardButton } from '../../layouts/CardLayout';
import RocketReachOrganizationCard from './RocketReachOrganizationCard';
import {
  arrayToCsv,
  isModuleAllowed,
  isPermissionAllowed,
  overflowing,
  parseNaics,
} from '../../../utils/Utils';
import BulkImportService from '../../../services/bulkImport.service';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import routes from '../../../utils/routes.json';
import { useHistory } from 'react-router-dom';
import organizationService from '../../../services/organization.service';
import Skeleton from 'react-loading-skeleton';
import prospectService from '../../../services/prospect.service';
import LookupPeopleLoader from '../../loaders/LookupPeople';
import AlreadyImportedWaterMark from './AlreadyImportedWaterMark';

const convertRocketReachObjectToCompanyObject = (rrObjects) => {
  return rrObjects.map((prospect) => ({
    name: prospect.name,
    employees: prospect.employees,
    address_street: prospect.address,
    annual_revenue: prospect.revenue,
    total_revenue: prospect.revenue,
    industry: prospect.industry,
    address_city: prospect.city,
    address_state: prospect.state,
    address_country: prospect.country,
    address_postalcode: prospect.postal_code,
    sic_code: parseNaics(prospect.sic),
    naics_code: parseNaics(prospect.naics),
    ticker_symbol: prospect.ticker,
    avatar: prospect.logo_url,
    phone_office: prospect.phone,
    phone_fax: prospect.fax,
    website: prospect.domain,
    external_id: '' + prospect.id,
  }));
};

const ImportOrganizations = ({
  openImportModal,
  setOpenImportModal,
  prospects,
  clearSelection,
  tenant,
  toggleConfetti,
}) => {
  const [loading, setLoading] = useState(false);
  const [imported, setImported] = useState(false);
  const [failedItems, setFailedItems] = useState([]);
  const history = useHistory();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isImported, setIsImported] = useState(false);
  const modalTitle = prospects.length > 1 ? 'companies' : 'company';
  const [importedList, setImportedList] = useState([]);
  const [newProspects, setNewProspects] = useState([]);
  const [saving, setSaving] = useState(false);

  const fetchOrganizationByExternalId = async () => {
    setLoading(true);
    try {
      const requests = prospects?.map(async ({ id, name, ...rest }) => {
        const [orgResponse, companyResponse] = await Promise.all([
          organizationService.getOrganizations(
            { external_id: id },
            { limit: 1 }
          ),
          prospectService.getCompany({ id, name }),
        ]);

        return {
          organization: orgResponse.data.organizations[0] || null,
          company: { ...rest, ...companyResponse.data } || null,
        };
      });

      const responses = await Promise.all(requests);

      const allOrganizations = responses
        .map((resp) => resp.organization)
        .filter(Boolean);

      const rrProspects = responses.map((resp) => resp.company).filter(Boolean);

      setNewProspects(rrProspects);

      if (allOrganizations.length) {
        setImportedList(
          allOrganizations.map((org) => parseInt(org.external_id))
        );
        setIsImported(prospects.length === allOrganizations.length);
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    openImportModal && fetchOrganizationByExternalId();
  }, [prospects, imported, openImportModal]);

  useEffect(() => {
    if (imported) {
      setTimeout(() => {
        if (window.location.pathname === '/prospecting') {
          history.push(routes.caccounts);
        }
      }, 1200);
    }
  }, [imported]);

  const handleImportOrg = async () => {
    setSaving(true);
    try {
      const companies = convertRocketReachObjectToCompanyObject(newProspects);
      const headers = Object.keys(companies[0]).map((m) => ({
        label: m,
        key: m,
      }));
      const csvData = arrayToCsv(headers, companies);
      const blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8',
      });
      const file = new File([blob], 'companies-import', {
        type: 'text/csv;charset=utf-8',
      });
      const formData = new FormData();
      formData.append('file', file, file.name);
      const service = new BulkImportService();
      const { itemsFailed } = await service.bulkImport(
        formData,
        'organizations',
        { updateExisting: true }
      );
      if (itemsFailed.length) {
        setFailedItems([...itemsFailed]);
      } else {
        overflowing();
        const numCompanies = companies.length;
        const successMessage =
          numCompanies === 1
            ? '1 Company Imported'
            : `${numCompanies} Companies Imported`;
        setSuccessMessage(successMessage);
        toggleConfetti();
        clearSelection();
        setOpenImportModal(false);
        setImported(true);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage('Error import company. Please try again later.');
    } finally {
      setSaving(false);
    }
  };

  const handleCloseModal = () => {
    overflowing();
    setFailedItems([]);
    setImportedList([]);
    setIsImported(false);
    setOpenImportModal(false);
    clearSelection();
  };

  const getImportedListMsg = (multiple) => {
    const importedCount = importedList?.length || 0;
    const prospectsCount = prospects?.length || 0;
    if (prospectsCount === 0) {
      return '';
    }
    if (prospectsCount === importedCount) {
      return multiple
        ? `All ${importedCount} companies are already imported.`
        : 'This company is already imported.';
    }
    if (importedCount > 0) {
      return multiple
        ? `(${importedCount} ${
            importedCount > 1 ? 'companies are' : 'company is'
          } already imported)`
        : importedCount === 1
        ? `(${importedCount} company is already imported)`
        : '';
    }
    return prospectsCount === 1 ? 'Would you like to import this company?' : '';
  };

  return (
    <div>
      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      {openImportModal && (
        <SimpleModalCreation
          open={openImportModal}
          modalTitle={`Import ${modalTitle}`}
          bodyClassName="text-center"
          customModal="w-50"
          onHandleCloseModal={handleCloseModal}
          noFooter
          bankTeam
        >
          {failedItems.length ? (
            <div>
              <div className="mt-2 d-flex align-items-center mb-4">
                <div>
                  <p className="font-inter text-left mb-2 mt-4">
                    We were unable to import following {modalTitle}:
                  </p>
                  <ul className={`list-disc`}>
                    {failedItems.map((item) => (
                      <li
                        className="font-weight-medium text-left ml-4"
                        key={item.id}
                      >
                        <p className="mb-1">{item.name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-center">
                <p className="font-inter text-left">
                  {loading ? (
                    <Skeleton height={10} width={300} />
                  ) : (
                    <>
                      {(() => {
                        const remainingProspects =
                          prospects?.length - importedList?.length || 0;
                        if (remainingProspects <= 0) {
                          return getImportedListMsg(prospects?.length > 1);
                        }
                        if (prospects?.length > 1) {
                          return `Would you like to import ${remainingProspects} ${
                            remainingProspects === 1 ? 'company' : 'companies'
                          }? ${getImportedListMsg(true)}`;
                        }
                        return getImportedListMsg(false);
                      })()}
                    </>
                  )}
                </p>
              </div>
              <div
                className="card-body d-flex flex-column gap-2 p-0 mb-0"
                style={{
                  maxHeight: 510,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {loading ? (
                  <LookupPeopleLoader
                    count={2}
                    circle={<Skeleton height={60} width={60} circle />}
                    container
                  />
                ) : (
                  <>
                    {newProspects?.map(({ id, ...prospect }) => {
                      const alreadyImported = importedList.includes(id);
                      return (
                        <div key={id} className="card position-relative">
                          <div className="card-body">
                            <div
                              className={`rocketreach-card ${
                                alreadyImported ? 'opacity-md' : ''
                              }`}
                            >
                              <RocketReachOrganizationCard
                                prospect={prospect}
                                showDescription={false}
                              />
                            </div>
                            {alreadyImported && <AlreadyImportedWaterMark />}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}
          <div className="d-flex align-items-center gap-2 mt-3 mb-0">
            <CardButton
              type="button"
              title="Cancel"
              className="btn btn-sm btn-outline-danger w-100"
              onClick={handleCloseModal}
            />
            {isPermissionAllowed('organizations', 'create') &&
              isModuleAllowed(
                tenant?.modules,
                'prospecting_company_import'
              ) && (
                <CardButton
                  type="button"
                  title={isImported ? 'Imported' : 'Import'}
                  variant="primary"
                  className="btn-sm w-100"
                  isLoading={saving}
                  disabled={failedItems.length || isImported}
                  onClick={handleImportOrg}
                />
              )}
          </div>
        </SimpleModalCreation>
      )}
    </div>
  );
};

export default ImportOrganizations;
